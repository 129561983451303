import Config from "../config";
import {IconButton} from "@material-ui/core";
import OpenWithIcon from "@material-ui/icons/OpenWith";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import {Alert, Button, ButtonGroup, Col, Modal, Row, Table} from "react-bootstrap";
import useMoodleLogin from "../hooks/moodle-login";
import {useEffect, useState} from "react";
import axios from "axios";
import ModuleDisplay from "./module-display";
import RegistrationPicker from "./registration-picker";
import {useLazyQuery} from "@apollo/client";
import {GET_COURSE_DB_WITH_ROUNDS} from "../gql-query/select-query";
import {toast} from "react-toastify";

const ParticipantsTable = ({scheduleID,participants,showModule,onSynced})=>{

    const [moodleLogin,sync] = useMoodleLogin();
    const [selectedUser,setSelectedUser] = useState();
    const [courseWithRound,setCourseWithRound] = useState();
    const [syncing,setSyncing] = useState(false);

    const [loadCourse, loadCourseState] = useLazyQuery(GET_COURSE_DB_WITH_ROUNDS);


    useEffect(()=>{
        // syncScore().then(()=>{
        //     if(onSynced)onSynced();
        // });
        // window.onfocus = async () => {
        //     if(scheduleID && onSynced){
        //         await syncScore();
        //         if(onSynced)onSynced();
        //     }
        // };
        // return ()=>{
        //     window.onfocus=null;
        // }
    },[]);

    useEffect(()=>{
        setCourseWithRound(loadCourseState.data?.courseDBbyID);
    },[loadCourseState.data])

    useEffect(()=>{
        if(selectedUser){
            let {schedule} = selectedUser
            loadCourse({
                fetchPolicy:'network-only',
                variables:{
                    id:schedule.course.id
                }
            })
        }
    },[selectedUser])

    async function syncScore(){
        try{
            await axios.get(Config.api_url+'/sync-score?schedule='+scheduleID);
        }catch(e){
            console.log(e);
        }
    }

    function syncMoodleScore(e){
        e.preventDefault();
        setSyncing(true);
        toast.info('Syncing...');
        syncScore().then(()=>{
            setSyncing(false);
            toast.success('Synced');
        }).catch(e=>{
            setSyncing(false);
            toast.error('Sync failed');
        });
    }

    if(!moodleLogin)return <Alert>Loading...</Alert>
    return <>
        <div className='p-2'>
            {syncing?
                <Button variant="info" disabled>Syncing . . .</Button>
                :
                <Button variant={'warning'} onClick={syncMoodleScore.bind(this)}>Sync score</Button>
            }
        </div>
        <Table responsive>
            <thead>
            <tr>
                <th>#</th>
                <th width="150">ID</th>
                <th width="250">Full name</th>
                <th>Group</th>
                <th width="120">Email</th>
                <th>Score</th>
                {/*<th>Action</th>*/}
            </tr>
            </thead>
            <tbody>
            {participants.map((participant,i)=>{
                return <tr key={participant.id}>
                    <td><a href={'/#'+participant.id}>{i+1}</a></td>
                    <td>{participant.user.studentID?participant.user.studentID:participant.user.username}</td>
                    <td>{participant.user.prefix}{participant.user.fname} {participant.user.lname}</td>
                    <td>
                        {showModule &&
                        <div className="mb-1">
                            <ModuleDisplay quizSet={participant?.scheduleRound?.quizSet}/>
                        </div>
                        }
                        <div className="text-capitalize">{participant?.checkIn?.scheduleRoundCheckIn?.groupName || '-'}</div>
                    </td>
                    <td>
                        <div style={{width:120,wordBreak:'break-word'}}>
                            {
                                participant.user.email
                                // participant?.checkIn?
                                //     <>Yes</>
                                //     :
                                //     <>No</>
                            }
                        </div>
                    </td>
                    <td>
                        {participant?.examResult?.quizResults?
                            <>
                                {participant?.examResult?.quizResults.map((r,n)=>{

                                    return <div key={n}>
                                        {r.dqQuiz.name} : {!isNaN(r.score)?
                                        <>
                                            <span>{r.score}%</span>
                                            &nbsp;|&nbsp;
                                            <a href={`${Config.moodleURL}/mod/quiz/review.php?attempt=${r.attemptID}`} target='_blank'>Review</a>
                                        </>
                                        :
                                        r.attemptID?
                                            <div>
                                                <a href={`${Config.moodleURL}/mod/quiz/review.php?attempt=${r.attemptID}`} target='_blank'>Review</a>
                                                &nbsp;|&nbsp;
                                                <>
                                                    {
                                                        r.examStat=='submitted'?
                                                            <a href={`${Config.moodleURL}/mod/quiz/comment.php?attempt=${r.attemptID}&slot=1`} target='_blank'>Score</a>
                                                            :
                                                            <span style={{color:'red'}}>Not yet</span>
                                                    }
                                                </>
                                            </div>
                                            :
                                            <div>-</div>
                                    }
                                    </div>
                                })}
                            </>
                            :
                            <>-</>
                        }
                    </td>
                    {/*<td>*/}
                    {/*    <ButtonGroup aria-label="Basic example">*/}
                    {/*        <Button variant="secondary" size="sm" onClick={e=>{*/}
                    {/*            setSelectedUser(participant);*/}
                    {/*        }}><OpenWithIcon/></Button>*/}
                    {/*        <Button variant="danger" size="sm"><HighlightOffIcon/></Button>*/}
                    {/*    </ButtonGroup>*/}
                    {/*    /!*<IconButton>*!/*/}
                    {/*    /!*    <OpenWithIcon/>*!/*/}
                    {/*    /!*</IconButton>*!/*/}
                    {/*    /!*<IconButton>*!/*/}
                    {/*    /!*    <HighlightOffIcon/>*!/*/}
                    {/*    /!*</IconButton>*!/*/}
                    {/*</td>*/}
                </tr>
            })}
            </tbody>
        </Table>
        <Modal show={!!selectedUser} onHide={e=>setSelectedUser(null)}>
            <Modal.Header closeButton>
                <Modal.Title>Modal heading</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <RegistrationPicker
                    removeAble={false}
                    userRegistrations={[]}
                    currentCourse={courseWithRound}
                    currentSchedule={selectedUser?.schedule}
                    selectedRounds={[]}
                    modules={[selectedUser?.scheduleRound?.quizSet]}
                    onRemoveRound={()=>{}}
                    onSelectedRound={()=>{}}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={e=>setSelectedUser(null)}>
                    Close
                </Button>
                <Button variant="primary" onClick={e=>{

                }}>
                    Save Changes
                </Button>
            </Modal.Footer>
        </Modal>
    </>
}
ParticipantsTable.defaultProps = {
    participants:[],
};
export default ParticipantsTable;
