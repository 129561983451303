import {Alert, Button, Col, FormControl, FormGroup, FormLabel, ListGroupItem, Row} from "react-bootstrap";
import moment from "moment";
import {useFieldArray} from "react-hook-form";
import DateTimePickerWrapper from "./datetime-picker-wrapper";



const CourseScheduleRound = ({control,indexKey,courseDB,register,setValue,errors,trigger})=>{


    const {fields,append,remove} = useFieldArray({
        control,
        name:`quizSet.${indexKey}.rounds`,
    })

    function removeRound(id){
        remove(id)
    }

    function showError(index,key,msg,type='all'){
        if(type=='all'){
            return <>
                {errors?.quizSet?.[indexKey]?.rounds?.[index]?.[key] &&
                <Alert variant='danger'>{msg}</Alert>
                }
            </>
        }
        return <>
            {errors?.quizSet?.[indexKey]?.rounds?.[index]?.[key]?.type==type &&
            <Alert variant='danger'>{msg}</Alert>
            }
        </>
    }

    return <>
        {fields.map((field,index)=>{
            let currentRound=`quizSet.${indexKey}.rounds.${index}`;
            return <ListGroupItem key={field.id}>
                <Row>
                    <Col xs='12'>
                        <h4>Round {index + 1}</h4>
                        <hr/>
                    </Col>
                    <Col lg="6" xl="3">
                        <FormGroup>
                            <FormLabel>Start</FormLabel>
                            <DateTimePickerWrapper
                                name={`${currentRound}.examStart`}
                                control={control} onChange={value => {
                                if(value){
                                    // setDefaultValue({...defaultValue,...{'startDateTime':value}})
                                    let added3Hours = moment(value).add(3, 'hours').toDate();
                                    setValue(`${currentRound}.examEnd`, added3Hours,{shouldValidate:true});
                                    // trigger(`${currentRound}.examEnd`);
                                }
                            }}/>
                            {showError(index,'examStart','Please choose start time.')}
                        </FormGroup>
                    </Col>
                    <Col lg="6" xl="3">
                        <FormGroup>
                            <FormLabel>End</FormLabel>
                            <DateTimePickerWrapper
                                name={`${currentRound}.examEnd`}
                                control={control}/>
                            {showError(index,'examEnd','Please choose end time.','required')}
                            {showError(index,'examEnd','Please choose end time.','typeError')}
                            {showError(index,'examEnd','Please choose end time more than start time.','min')}
                        </FormGroup>
                    </Col>
                    <Col lg="6" xl="3">
                        <FormGroup>
                            <FormLabel>Location</FormLabel>
                            <FormControl as='select' {...register(`${currentRound}.location`)}>
                                <option value=''>- Choose -</option>
                                {courseDB.locations.map(l=>{
                                    return <option key={l.id} value={l.id}>{l.name}</option>
                                })}
                            </FormControl>
                            {showError(index,'location','Please choose location.')}
                        </FormGroup>
                    </Col>
                    <Col lg="6" xl="3">
                        <FormGroup>
                            <FormLabel>Seats</FormLabel>
                            <FormControl {...register(`${currentRound}.maxSeats`)}/>
                            {showError(index,'maxSeats','Please input seats.')}
                        </FormGroup>
                    </Col>
                    <Col align='right'>
                        <Button variant='danger'
                                onClick={removeRound.bind(this, index)}>X</Button>
                    </Col>
                </Row>
            </ListGroupItem>
        })}
    </>
}
export default CourseScheduleRound;
