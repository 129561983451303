import {Helmet} from "react-helmet";
import Config from "../../config";
import {useContext, useEffect, useState} from "react";
import {observer} from "mobx-react";
import StateContext from "../../mobx/global-context";
import {Alert, Button, Col, Container, Row} from "react-bootstrap";
import LeftMenu from "./left-menu";
import {createTag, onHelmetReady, scriptTag} from "../../services/helper";
import {StyleSheet, css} from "aphrodite";
import TopMenu from "./top-menu";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCoffee,faBars} from '@fortawesome/free-solid-svg-icons'
import {logout} from "../../services/requester";
import "./../../css/back-end.css";
import {createTheme, ThemeProvider} from '@material-ui/core/styles'

const url = `${Config.backEndPath}`

const Index = ({children}) => {
    const [ready, setReady] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);
    const state = useContext(StateContext);

    const theme = createTheme({
        overrides:{
            MuiFormControl:{
                root:{
                    padding:'1rem'
                }
            }
        }
    });

    useEffect(()=>{
        new Promise(async resolve=>{
            let tasks=[
                createTag('link',{rel:'stylesheet',type:'text/css',href:`${url}/app-assets/vendors/css/vendors.min.css`},'head'),
                createTag('link',{rel:'stylesheet',type:'text/css',href:`${url}/app-assets/css/bootstrap.css`},'head'),
                createTag('link',{rel:'stylesheet',type:'text/css',href:`${url}/app-assets/css/bootstrap-extended.css`},'head'),
                createTag('link',{rel:'stylesheet',type:'text/css',href:`${url}/app-assets/css/colors.css`},'head'),
                createTag('link',{rel:'stylesheet',type:'text/css',href:`${url}/app-assets/css/components.css`},'head'),
                createTag('link',{rel:'stylesheet',type:'text/css',href:`${url}/app-assets/css/themes/dark-layout.css`},'head'),
                createTag('link',{rel:'stylesheet',type:'text/css',href:`${url}/app-assets/css/themes/semi-dark-layout.css`},'head'),
                createTag('link',{rel:'stylesheet',type:'text/css',href:`${url}/app-assets/css/core/menu/menu-types/vertical-menu.css`},'head'),
                createTag('link',{rel:'stylesheet',type:'text/css',href:`${url}/assets/css/style.css`},'head'),
                createTag('script',{src:'/v2/theme/back-end/app-assets/vendors/js/vendors.min.js'}),
                // createTag('script',{src:`/dev/theme/back-end/app-assets/js/core/app-menu.js`})
            ]
            ///SCRIPT
            // await createTag('script',{src:`/dev/theme/back-end/app-assets/js/core/app-menu.js`});
            // await createTag('script',{src:`/dev/theme/back-end/app-assets/js/core/app.js`});
            await Promise.all(tasks)
            function fnUpdateSize(){
                let size = window.$(window).width();
                if(size>=1200){
                    state.sideMenu('show-menu-only')
                }else{
                    state.sideMenu('hide');
                }
            }
            fnUpdateSize();
            if(window.$(window).on('resize',()=>{
                fnUpdateSize();
            }))
            setReady(true);
        })
    },[])

    useEffect(() => {
        if (state.currentUser === null) {
            document.location.href = '/api/login?redirect=' + Config.root + '/admin';
        }
    }, [state.currentUser]);

    function onLogout(e) {
        e.preventDefault();
        state.logout()
    }

    if (!state.currentUser) return <Alert variant='info'>Loading...</Alert>;
    return (
        <ThemeProvider theme={theme}>
            <Helmet onChangeClientState={(newState, addedTags, removedTags) => {}}>
                <title>{Config.siteName}</title>
            </Helmet>
            {ready?
            <>
                <TopMenu onOpenLeftMenu={() => {
                    setMenuOpen(!menuOpen);
                }}/>
                <LeftMenu/>
                <div className={css(style.wrapper)}>
                    <Container className={css(style.content)}>
                        <Row>
                            <Col>
                                <div className="content-wrapper">
                                    <div className="content-body">
                                        <section className="mt-4">
                                            {children}
                                        </section>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                    <div className={`sidenav-overlay${state.currentSideMenu.overlay}`} onClick={e=>{
                        state.sideMenu('hide')
                    }}></div>
                </div>
                <footer className={css(style.footer)}>
                    COPYRIGHT © 2021
                    <a className="ml-25" href="dq.kku.ac.th" target="_blank">KKU DQ</a>
                    <span className="d-none d-sm-inline-block">, All rights Reserved</span>
                </footer>
                <button className="btn btn-primary btn-icon scroll-top" type="button"><i data-feather="arrow-up"/>
                </button>
            </>
                :
                <Alert>Loading...</Alert>
            }
        </ThemeProvider>
    );
}

const style = StyleSheet.create({
    content: {
        paddingTop: '70px',
        '@media (min-width: 1200px)': {
            paddingLeft: '280px'
        }
    },
    leftMenu: {
        // transition: 'all 200ms',
    },
    leftMenuClose: {
        // '@media (max-width: 768px)': {
        //     transform: 'translate3d(-240px, 0, 0) !important',
        // }
    },
    leftMenuOpen: {
        transform: 'translate3d(0px, 0, 0) !important',
    },
    wrapper: {
        height: 'auto !important',
        minHeight: 'calc(100% - 87px)',
        marginBottom: '-50px'
    },
    footer: {
        height: '50px',
        padding: '10px',
        paddingTop: '20px',
        textAlign: 'center'
    }
});

export default observer(Index);
