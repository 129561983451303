import {useHistory, useParams} from "react-router-dom";
import gql from "graphql-tag";
import {useQuery} from "@apollo/client";
import React, {useEffect, useState} from "react";
import {createGroup, getThaiDateBetweenSingleDay} from "../../services/client-helper";
import {CSVLink, CSVDownload} from "react-csv";
const COURSE_CHECK_IN_LIST = gql`
query StudentCheckInList($roundID: ID!) {
  studentCheckInList(roundID: $roundID) {
    id
    course {
      id
      name
      shortname
      fullname
      summary      
    }
    bill {
      id
      amount
      isPaid
    }
    user {
      id
      username
      studentID
      prefix
      fname
      lname
      ssoRole {
        id
        name
      }
    }
    checkIn {
      id
      schedule {
        id        
      }
      scheduleRound {
        id
      }
      scheduleRoundCheckIn{
        id
        groupName
        googleMeetURL
        lastUpdate        
      }
      avatarName
      approved
      lastUpdate
    }
  }
}
`;

let GET_ROUND_DETAIL = gql`
    query GET_ROUND($roundID:ID!) {
        getRoundDetail(roundID:$roundID) {
            examStart
            examEnd
            quizSet{
                name
                minutes
                bg_color
                text_color
            }
            location {
                id
                name
            }
            schedule{
                title
                course{
                    name
                    fullname
                }
            }
        }
    }
`
const CheckInPrintStudent = () => {
    // const [participants,setParticipants]  = useState([]);
   const {roundID} = useParams();
    const resCheckIn = useQuery(COURSE_CHECK_IN_LIST, {
        fetchPolicy: 'network-only', variables: {
            roundID
        }
    });
    const resRoundDetail = useQuery(GET_ROUND_DETAIL, {
        fetchPolicy: 'network-only', variables: {
            roundID
        }
    });
    const [checkInList, setCheckList] = useState([]);
    useEffect(() => {
        if (resCheckIn.data?.studentCheckInList) {
            let checkListByGroup = createGroup(resCheckIn.data?.studentCheckInList,'checkIn.scheduleRoundCheckIn.groupName')
            setCheckList(checkListByGroup);
            // setCourse(resCheckIn.data?.studentCheckInList?.[0]?.course)
        }
    }, [resCheckIn.data])

    if(resCheckIn.loading)return <div>Loading...</div>
    if(resCheckIn.error)return <div>Error</div>
    return <div>
        <div className="mt-2">
            <CSVLink className="btn btn-primary" filename={`participants_.csv`} data={[]}>Download
                CSV</CSVLink>
        </div>
    </div>

}
export default CheckInPrintStudent;
