import {
    Button,
    Card,
    CardContent,
    CardMedia,
    Grid,
    makeStyles,
    Paper,
    TextField,
    Typography,
    ThemeProvider,
    createMuiTheme, Box
} from "@material-ui/core";
import axios from "axios";
import {useFormik} from 'formik';
import Config from "../../config";
import {toast} from "react-toastify";
import {useHistory} from "react-router-dom";
import {useMobxState} from "../../mobx/global-context";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSignInAlt} from "@fortawesome/free-solid-svg-icons";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        '& > *': {
            margin: 20
        }
    },
    title:{
        display: 'flex'
    }
}));
const LoginLocal = () => {
    const classes = useStyles();
    const history = useHistory();
    const state = useMobxState();
    const formik = useFormik({
        initialValues: {
            username: '',
            password: '',
        },
        onSubmit: values => {
            console.log(values);
            axios.post('/api/login-local', values).then(res => {
                if (res.data.errorMessage || res.data.message) {
                    toast.error(res.data.errorMessage || res.data.message);
                } else {
                    if(res.data?.user?.role?.name=='student'){
                        document.location.href=Config.root+'/my';
                    }else{
                        document.location.href=Config.root+'/admin';
                    }
                }
            });
        }
    });
    return <>
        <Box pt={5}>
            <Grid container justifyContent='center'>
                <Grid item xs={12} sm={6}>
                    <Paper elevation={0}>
                        <Card>
                            <CardContent>
                                <div className={classes.title}>
                                    <FontAwesomeIcon icon={faSignInAlt} size='2x'/>
                                    <Typography className="ml-2" variant='h6'>Admin Authorization</Typography>
                                </div>
                                <form className={classes.root} noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
                                    <TextField label="Username" name='username' variant="filled" onChange={formik.handleChange}
                                               value={formik.values.username}/>
                                    <TextField type='password' label="Password" variant="filled" name='password'
                                               onChange={formik.handleChange} value={formik.values.password}/>
                                    <Button type='submit' className='mt-2' variant='outlined' color='primary'>Login</Button>
                                    <Button className='mt-0' variant='outlined' color='default' onClick={e=>{
                                        document.location.href=Config.root;
                                    }}>Back</Button>
                                </form>
                            </CardContent>
                        </Card>
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    </>
}
export default LoginLocal;
