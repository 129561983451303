import {useQuery} from "@apollo/client";
import {GET_COURSES_WITH_CONTENTS} from "../../gql-query/select-query";
import {Alert, Card, Row, Col, ListGroup, ListGroupItem, Button, Image} from "react-bootstrap";
import {useEffect} from "react";
import {getCourseQuizFromContent} from "../../services/helper";
import {createMoodleLink} from "../../services/requester";

const Dashboard = ()=>{
    const {loading, error, data, refetch} = useQuery(GET_COURSES_WITH_CONTENTS);

    useEffect(()=>{
        window.onfocus = () => {
            refetch();
        };
        return ()=>{
            window.onfocus=null;
        }
    },[]);

    if(loading) return <Alert>Loading...</Alert>;
    return <>
        <h1>Dashboard / Course Overview</h1>
        {/*<MoodleIFrame/>*/}
        {
            data.courses.map(v=>{
                let quizContent=getCourseQuizFromContent(v.courseContent);
                return <Row key={v.id} className="mb-2">
                    <Col>
                        <Card>
                            <Card.Header>
                                <Row>
                                    <Col>
                                        {v.fullname}
                                    </Col>
                                    <Col xs='auto'>
                                        <a className="btn btn-primary" target='_blank' href={createMoodleLink(`/course/view.php?id=${v.id}`)}>Edit course</a>
                                    </Col>
                                </Row>
                            </Card.Header>
                            <Card.Body>
                                {quizContent.length>0 ?
                                <ListGroup>
                                    {quizContent.map(q=>
                                        <ListGroupItem key={q.id}>
                                            <Row>
                                                <Col><Image src={q.modicon}/> {q.name}</Col>
                                                <Col xs='auto'>
                                                    {/*<Button variant="info" className="mr-1" onClick={e=>{*/}
                                                    {/*    startMoodle(`/mod/quiz/view.php?id=${q.id}`)*/}
                                                    {/*}}>View</Button>*/}
                                                    <a className="btn btn-info mr-1" target='_blank' href={createMoodleLink(`/mod/quiz/view.php?id=${q.id}`)}>View</a>
                                                    <a className="btn btn-warning" target='_blank' href={createMoodleLink(`/mod/quiz/edit.php?cmid=${q.id}`)}>Edit</a>
                                                    {/*<Button variant="warning" onClick={e=>{*/}
                                                    {/*    startMoodle(`/mod/quiz/edit.php?cmid=${q.id}`)*/}
                                                    {/*}}>Edit</Button>*/}
                                                </Col>
                                            </Row>
                                        </ListGroupItem>
                                    )}
                                </ListGroup>
                                    :
                                    <div>- None quiz -</div>
                                }
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            })
        }
    </>
}
export default Dashboard;
