import {Button, Card, Form, FormCheck, FormControl, FormGroup, FormLabel} from "react-bootstrap";
import FormCheckLabel from "react-bootstrap/FormCheckLabel";
import {StyleSheet, css} from 'aphrodite';
import {Box} from "@material-ui/core";
import {useMutation, useQuery} from "@apollo/client";
import {GET_SYSTEM_CONFIG} from "../../gql-query/select-query";
import {useEffect, useState} from "react";
import {toast} from "react-toastify";
import {SAVE_SYSTEM_CONFIG} from "../../gql-query/save-query";
import _ from 'lodash';

const AdminConfig = () => {
    const {data, loading} = useQuery(GET_SYSTEM_CONFIG, {fetchPolicy: 'network-only'});
    const [config, setConfig] = useState();
    const [mutate] = useMutation(SAVE_SYSTEM_CONFIG,{
        variables:{
            config:_.pick(config,[
                'wallet_enabled',
                'change_schedule_enabled',
                'auto_cancel_expired_bill',
                'bill_ttl_hours',
                'check_in_group_size'
            ])
        }
    });

    useEffect(() => {
        if (data?.systemConfig) {
            setConfig(data.systemConfig);
        }
    }, [data])

    function onFormChange(e,type='string') {
        let v = e.target.type!='checkbox'?e.target.value:e.target.checked;
        setConfig({
            ...config,
            [e.target.name]:type=='number'?parseInt(v):v
        })
    }

    async function onSubmit(e){
        e.preventDefault();
        if(config?.auto_cancel_expired_bill && config?.bill_ttl_hours<1){
            toast.error('Bill alive must be greater than 0');
        }else{
            try{
                let result = await mutate();
                toast.success('Config saved.')
            }catch(e){
                toast.error(e.message)
            }
        }
    }

    if (loading) return <div>Loading...</div>
    return <>
        <Card>
            <Card.Body>
               <Form onSubmit={onSubmit}>
                   <h1>System Configuration</h1>
                   <hr/>
                   <FormGroup controlId="wallet">
                       <FormLabel>Wallet</FormLabel>
                       <FormCheck checked={config?.wallet_enabled || false} className="ml-1" label="Enabled"
                                  name="wallet_enabled"
                                  onChange={onFormChange}
                       />
                   </FormGroup>
                   <FormGroup controlId="change_schedule">
                       <FormLabel>Allow change schedule</FormLabel>
                       <FormCheck checked={config?.change_schedule_enabled || false} className="ml-1" label="Enabled"
                                  name="change_schedule_enabled"
                                  onChange={onFormChange}
                       />
                   </FormGroup>
                   <FormGroup controlId="auto_cancel_schedule">
                       <FormLabel>Automatic cancel schedule when not paid state.</FormLabel>
                       <FormCheck checked={config?.auto_cancel_expired_bill || false} className="ml-1" label="Enabled"
                                  name="auto_cancel_expired_bill"
                                  onChange={onFormChange}
                       />
                   </FormGroup>
                   {config?.auto_cancel_expired_bill &&
                   <FormGroup controlId="auto_cancel_schedule">
                       <FormLabel>Bill alive hours.</FormLabel>
                       <FormControl value={config?.bill_ttl_hours || ''} width="100"
                                    name="bill_ttl_hours"
                                    onChange={e=>onFormChange(e,'number')}
                       />
                   </FormGroup>
                   }
                   <FormGroup controlId="check_in_group_size">
                       <FormLabel>Check-In group size.</FormLabel>
                       <FormControl value={config?.check_in_group_size || ''} width="100"
                                    name="check_in_group_size"
                                    onChange={e=>onFormChange(e,'number')}
                       />
                   </FormGroup>
                   <Box p={1}>
                       <hr/>
                   </Box>
                   <FormGroup className="mt-2">
                       <Box display="flex" justifyContent='flex-end'>
                           <Button type="submit" variant="primary">Save Config</Button>
                       </Box>
                   </FormGroup>
               </Form>
            </Card.Body>
        </Card>
    </>
}
const styles = StyleSheet.create({
    globals: {
        '*h1': {
            color: 'red'
        }
    }
})
export default AdminConfig;
