import * as yup from 'yup';

const quizSetSchemaObject={
    id:yup.string().required().label('Quiz-Group / ID'),
    name:yup.string().required().label('Quiz-Group / Name'),
    quiz_mode:yup.string().required().label('Quiz-Group / Quiz Mode'),
    retest_mode:yup.string().required().label('Quiz-Group / Retest Mode'),
    price:yup.number().integer().min(1).label('Quiz-Group / Price'),
    minutes:yup.number().integer().min(1).label('Quiz-Group / Default Time'),
    text_color:yup.string().label('Quiz-Group / Text Color'),
    bg_color:yup.string().label('Quiz-Group / BG Color'),
    quizzes:yup.array().of(yup.object().shape({
        id:yup.string().required()
    })).min(1).required().label('Quiz-Group / Quiz'),
}

const QuizSetSchemaWithPrice = yup.object({...quizSetSchemaObject});
const QuizSetSchemaNoPrice = (()=>{
    let bufferObj = {...quizSetSchemaObject};
    bufferObj.price=yup.number().integer().label('Quiz-Group / Price');
    return yup.object(bufferObj);
})();


const orConditionsSchema = yup.object().shape({
    quizSet:yup.object().shape({
        id:yup.string().required()
    }).required(),
    condition:yup.string().required(),
});

const quizzes = yup.object().shape({
    id:yup.string().required(),
    passGrade:yup.number().integer().min(1).max(100).required(),
});

export const locationFormSchema = yup.object().shape({
    id:yup.string().required(),
    address:yup.string().required().label('Address'),
    maxSeats:yup.number().required().positive().integer().min(1)
        .label('Max seats')
        .typeError((msg)=>{
        return `Seat value must be number`
    }),
    name:yup.string().required().label('Location Name'),
});

export const dqQuizSchema = yup.object().shape({
    id:yup.string().required(),
    name:yup.string().required().label('Quiz Name'),
    en:yup.string().min(1).required().label('EN Quiz'),
    th:yup.string().min(1).required().label('TH Quiz'),
});

export const courseFormSchema = yup.object().shape({
    id:yup.string().required(),
    locations:yup.array().of(locationFormSchema).min(1).required().label('Locations'),
    dqQuizzes:yup.array().of(dqQuizSchema).min(1).required().label('DQ Quiz'),
    isPaid:yup.boolean().nullable(),
    defaultCourse:yup.boolean().nullable(),
    ssoRoles:yup.array().of(yup.string()).required(),
    customRoleJSON:yup.string().nullable(),
    langFilterField:yup.string().nullable(),
    langFilterValue:yup.string().nullable(),
    orConditions:yup.array().of(
        yup.object().shape({
            andConditions:yup.array().of(orConditionsSchema)
        })
    ).min(1).required().test('Condition','Condition is not possible to pass.',or=>{
        for(let i of or){
            for(let x of i.andConditions){
                if(x.condition==='must_pass'){
                    return true;
                }
            }
        }
    }),
    quizzes:yup.array().of(quizzes).required().label('Quizzes'),
    quizSet:yup.mixed().when('isPaid',{
        is:true,
        then:yup.array().of(QuizSetSchemaWithPrice).min(1).required(),
        otherwise:yup.array().of(QuizSetSchemaNoPrice)
    }).label('Quiz-Group'),
    freeTimes:yup.mixed().when('isPaid',{
        is:true,
        then:yup.number().integer().min(1).max(99).nullable(),
        otherwise:yup.number().default(0).nullable(),
    }),
    removed:yup.array().of(yup.object().shape({
        key:yup.string().required(),
        id:yup.string().required(),
    })),
});
