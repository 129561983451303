import gql from "graphql-tag";

export const DELETE_SINGLE_REG = gql`
    mutation DeleteSingleRegistration($regID: ID) {
      deleteSingleRegistration(regID: $regID)
    }
`;
export const DELETE_SCHEDULE = gql`
    mutation DeleteSchedule($id: ID,$regID: ID,$userID: ID) {
      deleteSchedule(id: $id,regID: $regID,userID: $userID)
    }
`;
export const DELETE_COURSE_EXAM_SCHEDULE = gql`
    mutation RemoveCourseScheduleByID($scheduleID:ID) {
      removeCourseScheduleByID(scheduleID:$scheduleID){
        success
        error
        message
      }
    }
`;