import {getMongooseID} from "../services/requester";
import {Form} from "react-bootstrap";
import QuizSet from "./quiz-set";
import _ from "lodash";
import React from "react";
import {CourseSettingHeadBar} from "../page/admin/exam-schedule-course-setting";

const CourseQuizGroupSetup = ({courseForm,updateUI,resetError,displayError})=>{
    return <>
        <CourseSettingHeadBar title='(6) Module Setup' onClick={()=>{
           return new Promise(async resolve=>{
               let id = await getMongooseID();
               courseForm.quizSet=[...courseForm.quizSet,{
                   id,
                   name: '',
                   quiz_mode: 'rnd',
                   retest_mode: 'quiz',
                   minutes: 60,
                   price: 100,
                   text_color:'#000000',
                   bg_color:'#b6b6b6',
                   quizzes: [],
               }];
               // resetError('quizSet')
               updateUI();
               resolve();
           });
        }}/>
        <Form.Group>
            {/*{displayError('quizSet')}*/}
            {
                courseForm.quizSet.map((qSet, i) => {
                    return <QuizSet key={i}
                                    className='mb-1'
                                    quizzes={courseForm.quizzes}
                                    data={qSet}
                                    courseForm={courseForm}
                                    onChange={() => {
                                        updateUI();
                                    }}
                                    onRemove={() => {
                                        courseForm.removed.push({key:'quizSet',id:qSet.id});
                                        courseForm.quizSet = _.reject(courseForm.quizSet, q => q === qSet);
                                        updateUI();
                                    }}
                    />
                })
            }

        </Form.Group>
    </>
}
export default CourseQuizGroupSetup;
