import {createRef, useCallback, useEffect, useRef, useState} from "react";
import $ from 'jquery';
import {Alert, Button, FormControl} from "react-bootstrap";
import axios from "axios";
import {createExamMoodleAuth} from "../services/requester";
import {observer} from "mobx-react";
import {useMobxState} from "../mobx/global-context";
import * as mobx from "mobx";

const MoodleIFrame = ({visible = true, url, onload, onUnload}) => {
    const state = useMobxState();
    const [ready, setReady] = useState(true);
    const ifrmRef = useRef();
    const frmDocument = useRef();
    const frmWindow = useRef();
    const [lang, setLang] = useState('th');
    const [forceENG,setForceENG]=useState(false);

    const iFrameMessage = useCallback((event)=>{
        console.log('addEventListener=',event?.data);
    })

    useEffect(()=>{
        let user = mobx.toJS(state.currentUser);
        console.log('user==',user);
        if(user?.studentID?.match(/3290/)){
            setForceENG(true);
        }
    },[])

    function mdLogout() {
        axios.get('https://dq.kku.ac.th/exam/local/dq/logout.php').then(() => {
            console.log('Exam Logout');
        });
    }


    useEffect(() => {
        // window.removeEventListener("message",iFrameMessage);
        // window.addEventListener("message", iFrameMessage,false);
        /////////////////////////
        state.backdrop = true;
        ifrmRef.current.onload = function () {
            ifrmRef.current.contentWindow.onunload = function () {
                //state.backdrop=true;
                // mdLogout();
                if (onUnload) onUnload();
                console.log('onunload');
            }
            frmDocument.current = this.contentWindow.document;
            frmWindow.current = this.contentWindow.window;
            setReady(true);
            state.backdrop = false;
            if (onload) onload();
            frmWindow.current.postMessage('FROM REACT DATA', '*');
        }
        // new Promise(async resolve => {
        //     let url = `https://dq.kku.ac.th/exam/login/index.php?token=`+await createExamMoodleAuth(reg_round_id);
        //     console.log('===========',url);
        //     resolve();
        // });
        return () => {
            state.backdrop = false;
            // window.removeEventListener('message',iFrameMessage,false);
            console.log('IFRAME UNLOAD.........');
        }
    }, []);


    const getURL = () => {
        if(forceENG){
            return url + `&lang=en`;
        }
        if (url.match(/\?/)) {
            return url + `&lang=${lang}`;
        } else {
            return url + `?lang=${lang}`;
        }
    }

    return <>
        {!visible && <Alert variant="info">Loading...</Alert>}
        {forceENG?
         <>
             <div style={{
                 padding:5,
                 backgroundColor:'#ffb6b6',
                 borderRadius:5,
                 margin: 5,
                 display: 'inline-block',
             }}>ENG ONLY</div>
         </>
            :
            <>
                <div style={{
                    padding:5,
                    borderRadius:5,
                    margin: 5,
                    display: 'inline-block',
                }}>Language: {lang == 'th' ?
                    <>
                        <span>TH</span> | <a href='#' onClick={e => {
                        e.preventDefault();
                        setLang('en');
                    }}>EN</a>
                    </>
                    :
                    <>
                        <a href='#' onClick={e => {
                            e.preventDefault();
                            setLang('th')
                        }}>TH</a> | <span>EN</span>
                    </>
                }</div>
            </>
        }
        <div style={{visibility: visible ? 'visible' : 'hidden'}}>
            <iframe frameBorder="0" ref={ifrmRef} src={getURL()} width="100%" style={{
                display: ready ? 'block' : 'none',
                height: 'calc(100vh - 200px)'
            }}/>
        </div>
    </>
}
export default observer(MoodleIFrame);
