import XBreadcrumb from "./xbreadcrumb";
import {getThaiDateBetween} from "../services/client-helper";
import {Box, Paper} from "@material-ui/core";
import {useQuery} from "@apollo/client";
import {GET_SCHEDULE_PARTICIPANTS} from "../gql-query/select-query";
import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import ParticipantsTable from "./participants-table";

const ExamScheduleParticipantsAll = ()=>{

    const {id,scheduleID} = useParams();
    const [schedule,setSchedule] = useState();
    const [participants,setParticipants] = useState([]);

    const resSchedule = useQuery(GET_SCHEDULE_PARTICIPANTS, {
        variables: {scheduleID},
        fetchPolicy: "network-only",
    });
    useEffect(() =>{
        if(resSchedule.data){
            setSchedule(resSchedule.data.scheduleParticipant);
            let participants=[];
            resSchedule.data?.scheduleParticipant?.rounds.forEach(r=>{
                participants=[...participants,...r.participants];
            })
            setParticipants(participants);
            console.log('participants===',participants);
        }
    },[resSchedule.data]);
    if(resSchedule.loading || !schedule)return <div>Loading...</div>;
    const {course,rounds} = schedule;
    return <>
        <XBreadcrumb data={[
            {title: `Exam Schedule: ${course?.shortname}`, url: '/admin/exam-schedule/' + id},
            {title: course.fullname,url:`/admin/exam-schedule/${id}/participants/${scheduleID}`},
            {title: 'All Participants'},
        ]}/>
        <Paper>
            <Box p={3}>
                <ParticipantsTable showModule participants={participants} scheduleID={scheduleID} onSynced={()=>{
                    resSchedule.refetch();
                }}/>
            </Box>
        </Paper>
        </>
}
export default ExamScheduleParticipantsAll
