import {Box, Button, Card, CardContent, CardHeader, Grid, Paper} from "@material-ui/core";
import gql from "graphql-tag";
import {useQuery} from "@apollo/client";
import {Alert, Image} from "react-bootstrap";
import Config from "../../config";

const GET_CERTIFICATED = gql`
{
  userCertificated {
    id
    verifiedTHDate
    fileName    
    fileLink
    thumbnailLink
    signed
    course{
        fullname
    }
  }
}
`
const Certificated = ()=>{
    const resCertificated = useQuery(GET_CERTIFICATED,{fetchPolicy:'network-only'});

    if(resCertificated.loading)return <Alert>Loading...</Alert>
    return <Paper>
        <Box p={2}>
            <h3>My Certificate</h3>
            <hr/>
            {resCertificated.data?.userCertificated.length == 0 ?
            <Alert variant='info'>You not have certificate yet.</Alert>
                :
                <Grid container justifyContent='center'>

                    {resCertificated.data?.userCertificated.map(cer=>{
                        return <Grid item xs={12} md={6}>
                            <Card>
                                <CardHeader title={cer.course?.fullname}/>
                                <CardContent>
                                    <a target='_blank' href={cer.fileLink} style={{textDecoration:'none'}}>
                                        <Box>
                                            <div className="text-center">
                                                <Image fluid src={`${Config.api_url}${cer.thumbnailLink}`}/>
                                            </div>
                                        </Box>
                                        <Box mt={2} display='flex' justifyContent='right'>
                                            <Button variant='contained' color='primary'>Download</Button>
                                        </Box>
                                    </a>
                                </CardContent>
                            </Card>
                        </Grid>
                    })}
                </Grid>
            }
        </Box>
    </Paper>
}
export default Certificated;
