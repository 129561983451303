import {
    Badge,
    Button,
    Card,
    Col,
    Form,
    FormControl, FormGroup, FormLabel,
    Image,
    InputGroup,
    ListGroup,
    ListGroupItem,
    Row
} from "react-bootstrap";
import _ from "lodash";
import {useEffect, useState} from "react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faLevelUpAlt, faPenSquare} from "@fortawesome/free-solid-svg-icons";
import {SketchPicker} from 'react-color'
import {StyleSheet, css} from 'aphrodite';
import ColorPicker from "./color-picker";

// function useForceUpdate(){
//     const [value, setValue] = useState(0); // integer state
//     return () => setValue(value => value + 1); // update the state to force render
// }

const QuizSet = ({quizzes, className, onChange, data, onRemove, courseForm}) => {

    useEffect(() => {
        data.dqQuizzes?.forEach(q => {
            let existed = courseForm?.dqQuizzes?.find(dq => dq.id == q.id);
            if (!existed) data.quizzes = _.reject(data.quizzes, q2 => q2.id == q.id);
        })
    }, [courseForm]);


    function selectQuiz(e) {
        let v = e.target.value;
        let existed = courseForm.dqQuizzes.find(q => q.id == v)
        if (existed) {
            data.quizzes = [...data.quizzes, {id: existed.id}];
            onChange();
        }
    }

    function getMoodleQuiz(id) {
        return quizzes.find(q => q.id == id);
    }

    return <Card className={className}>
        <Card.Body>
            <div className="mb-1">
                <Row>
                    <Col>
                        <Badge variant='warning'>{data.name ? data.name : 'Module Name'}</Badge>
                    </Col>
                    <Col xs='auto'>
                        <Button variant='danger' className="btn-sm" onClick={e => {
                            onRemove();
                        }}>Remove</Button>
                    </Col>
                </Row>
            </div>
            <div className="pl-1">
                <Row>
                    <Col xs='12' md='4'>
                        <FormLabel>Module Name</FormLabel>
                        <FormControl value={data.name || ''} className="mb-1"
                                     placeholder='Module name'
                                     onChange={e => {
                                         let v = e.target.value;
                                         data.name = v;
                                         onChange();
                                     }}/>
                    </Col>
                    <Col xs='6' md='3'>
                        <FormGroup>
                            <FormLabel>Quiz Mode</FormLabel>
                            <FormControl as='select' name='quiz_mode' defaultValue={data.quiz_mode || 'rnd'} onChange={e => {
                                let v = e.target.value;
                                data.quiz_mode = v;
                                onChange();
                            }}>
                                <option value='rnd'>Random</option>
                                <option value='all'>All Quiz</option>
                            </FormControl>
                        </FormGroup>
                    </Col>
                    {data.quiz_mode == 'all' &&
                    <Col>
                        <FormGroup>
                            <FormLabel>Retest Mode</FormLabel>
                            <FormControl as='select' name='retest_mode' defaultValue={data.retest_mode || 'quiz'} onChange={e => {
                                let v = e.target.value;
                                data.retest_mode = v;
                                onChange();
                            }}>
                                <option value='quiz'>Quiz</option>
                                <option value='module'>Module</option>
                            </FormControl>
                        </FormGroup>
                    </Col>
                    }
                    <Col xs='6' md='3'>
                        <FormGroup>
                            <FormLabel>Default Time (Minutes)</FormLabel>
                            <FormControl value={data.minutes || ''} onChange={e => {
                                let v = e.target.value;
                                data.minutes = v;
                                onChange();
                            }}/>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Label className="small">Select Quiz</Form.Label>
                        <Form.Control as="select" value='0' onChange={e => selectQuiz(e)} className="mb-1">
                            <option value="0">- Choose -</option>
                            {
                                courseForm
                                    ?.dqQuizzes
                                    ?.filter(q => !!!(data.quizzes && data.quizzes.find(qid => qid.id == q.id)))
                                    ?.map(dqQuiz => <option key={dqQuiz.id} value={dqQuiz.id}>{dqQuiz.name}</option>)
                            }
                        </Form.Control>
                    </Col>
                    {courseForm.isPaid &&
                    <>
                        <Col md='2'>
                            <FormGroup>
                                <FormLabel>Register Price / THB</FormLabel>
                                <FormControl name='price' value={data.price || 0} onChange={e => {
                                    let v = e.target.value;
                                    data.price = v;
                                    onChange();
                                }}/>
                            </FormGroup>
                        </Col>
                    </>
                    }
                    <Col md='3'>
                        <ColorPicker title='Text-Color' value={data.text_color} onChange={hex=>{
                            data.text_color=hex;
                            onChange();
                        }}/>
                    </Col>
                    <Col md='3'>
                        <ColorPicker title='BG-Color' value={data.bg_color} onChange={hex=>{
                            data.bg_color=hex;
                            onChange();
                        }}/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ListGroup>
                            {
                                data.quizzes && data.quizzes.map(qID => {
                                    let dqQuiz = courseForm?.dqQuizzes?.find(q => q.id == qID.id);
                                    if (!dqQuiz) return null;
                                    let lang = {
                                        th: getMoodleQuiz(dqQuiz.th),
                                        en: getMoodleQuiz(dqQuiz.en),
                                    }
                                    return <ListGroupItem key={'selected_' + dqQuiz.id}>
                                        <Row>
                                            <Col>
                                                <div style={{fontSize: 18}}>
                                                    <FontAwesomeIcon icon={faPenSquare}/><span
                                                    className="ml-1">{dqQuiz.name}</span>
                                                </div>
                                            </Col>
                                            <Col xs='12' md='auto pt-1 pt-md-0'>
                                                <ListGroup>
                                                    <ListGroupItem>
                                                        <Badge variant="primary">TH</Badge>
                                                        <span className="ml-1">{lang.th?.name}</span>
                                                        <small className="ml-1 fa-pull-right"><FontAwesomeIcon
                                                            icon={faLevelUpAlt}/> {lang.th?.passGrade}%</small>
                                                    </ListGroupItem>
                                                    <ListGroupItem>
                                                        <Badge variant="danger">EN</Badge>
                                                        <span className="ml-1">{lang.en?.name}</span>
                                                        <small className="ml-1 fa-pull-right"><FontAwesomeIcon
                                                            icon={faLevelUpAlt}/> {lang.en?.passGrade}%</small>
                                                    </ListGroupItem>
                                                </ListGroup>
                                            </Col>
                                            <Col xs='12' md='auto' className="my-auto ml-auto p-1 p-md-0 text-right">
                                                <Button variant='danger' className='btn-sm' onClick={e => {
                                                    data.quizzes = _.reject(data.quizzes, qid => qid.id == dqQuiz.id);
                                                    onChange();
                                                }}>X</Button>
                                            </Col>
                                        </Row>
                                    </ListGroupItem>
                                })
                            }
                        </ListGroup>
                    </Col>
                </Row>
            </div>
        </Card.Body>
    </Card>
}
const styles = StyleSheet.create({
    buttonGroup: {
        borderRadius: '0px 20px 20px 0px'
    }
})
export default QuizSet;
