import {Alert, Button, Card, Form, Col, FormControl, FormGroup, Row, ListGroup} from "react-bootstrap";
import {Box, Paper} from "@material-ui/core";
import {useEffect, useRef, useState} from "react";
import axios from "axios";
import {toast} from "react-toastify";

const CheckInConfig = () => {

    const [allowedIp, setAllowedIp] = useState([]);
    const [allowedIpString, setAllowedIpString] = useState('');
    const [ignoreList, setIgnoreList] = useState([]);
    const [schedules, setSchedules] = useState([]);
    const [enabled, setEnabled] = useState(false);
    const selectScheduleEl = useRef(null);

    useEffect(()=>{
      new Promise(async ()=>{
          try{
              let result = await axios.get('/api/schedules');
              setSchedules(result.data);
              /////
              let resultConfig = await axios.get('/api/get-check-in-config');
              setAllowedIp(resultConfig.data.allowed_ip || []);
              setIgnoreList(resultConfig.data.ignore_schedule_ids.map(id=>result.data.find(s=>s.id===id)));
              setAllowedIpString(resultConfig.data.allowed_ip?.join('\n') || '');
              setEnabled(resultConfig.data.enabled);
          }catch (e){
              console.log(e);
              toast.error('Failed to load schedule data');}
      })
    },[])

    async function saveData(e){
        e.preventDefault();
        let form = {
            allowed_ip:allowedIp.filter(v=>!!v) || [],
            ignore_schedule_ids:ignoreList?.map(s=>s.id) || [],
            enabled
        }
        try {
            await axios.post('/api/save-check-in-config', form);
            toast.success('Saved successfully');
        }catch (e){
            console.log(e);
            toast.error('Failed to save');
        }
    }

    return <>
        <h1>Examination Check-In allowed ip configuration</h1>
        <Row>
            <Col>
                <Paper>
                    <Box p={2}>
                        <Form.Group controlId='enabled'>
                            <Form.Check type="checkbox" id='enabled' label={'Enabled'} value='enabled' checked={enabled} onChange={e=>{
                                setEnabled(e.target.checked);
                            }}/>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label column={'sm'}>Allowed IP-Address</Form.Label>
                            <Form.Control disabled={!enabled} as="textarea" rows={10} width='100%' value={allowedIpString}
                                          placeholder={`example: \n10.101.78\n10.101.79\n10.212`}
                                          onChange={e=>{
                                setAllowedIpString(e.target.value);
                                if(e.target.value.replace(/\s/g,'').length>0){
                                    setAllowedIp(e.target.value.split('\n'));
                                }else{
                                    setAllowedIp([]);
                                }
                            }} />
                        </Form.Group>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <Form.Label column={'sm'}>Select Schedule</Form.Label>
                                    <Form.Control disabled={!enabled} ref={selectScheduleEl.current} as={"select"} onChange={e=>{
                                        let id = e.target.value;
                                        if(!id)return;
                                        if(ignoreList.find(s=>s.id===id))return;
                                        let schd = schedules.find(s=>s.id===id);
                                        if(!schd)return;
                                        setIgnoreList(old=>[...old,schd]);
                                        selectScheduleEl.current?.setSelectedIndex(0);
                                    }}>
                                        <option value={''}>Select Schedule</option>
                                        {schedules.map((s,i)=><option key={i} value={s.id}>{s.code} | {s.title}</option>)}
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <Form.Label column={'sm'}>Ignore List (Can access by any network.)</Form.Label>
                                    <ListGroup>
                                        {ignoreList.length===0 && <Alert className='m-2' variant='info'>No online schedule selected</Alert>}
                                        {ignoreList.map((schd,j)=><ListGroup.Item key={j}>
                                            <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',gap:2}}>
                                                <div>{schd.code} | {schd.title}</div>
                                                <Button variant='danger' onClick={e=>{
                                                    setIgnoreList(old=>old.filter(s=>s.id!==schd.id));
                                                }}>Remove</Button>
                                            </div>
                                        </ListGroup.Item>)}
                                    </ListGroup>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Button type='button' variant='success' onClick={e=>saveData(e)}>Save</Button>
                    </Box>
                </Paper>
            </Col>
        </Row>
    </>
}
export default CheckInConfig;
