import {Alert, Button} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {useMobxState} from "../mobx/global-context";
import {observer} from "mobx-react";
import Countdown from "./countdown";
import Config from "../config";
import {useHistory} from "react-router-dom";
import moment from "moment";


const StartExamButton = ({serverTime,reg}) => {
    const [examReady, setExamReady] = useState({});
    const history = useHistory();


    function getCounter(showDay=false) {

        return <div>
            <Countdown title="Start In:" showDay={showDay} expireTimestamp={reg.scheduleRound.examStart} style={{fontSize: 'inherite'}}
                       onExpired={e => {
                           setExamReady(prevState => ({
                               ...prevState,
                               [reg.id]: true,
                           }))
                       }}/>
        </div>
    }

    function showButton(checkInBTN = false) {
        return <div className="text-right text-md-center">
            <Button variant='btn btn-primary' onClick={e => {
                e.stopPropagation();
                if (reg.schedule.useSeb) {
                    document.location.href = `seb://${Config.base_url}/dq.seb`;
                    history.push(`/my/examination/${reg?.id}`)
                } else {
                    history.push(`/my/examination/${reg?.id}`)
                }
            }}>
                {checkInBTN ?
                    <>
                        <div>{reg.schedule.useSeb ? 'Check In (SEB)' : 'Check In'}</div>

                    </>
                    :
                    <>{reg.schedule.useSeb ? 'Start (SEB)' : 'Start'}</>
                }

            </Button>
            {
                checkInBTN &&
                <div className="text-right text-md-center pt-2">
                    <div>Exam start in</div>
                    <div>{getCounter(false)}</div>
                </div>
            }
        </div>
    }

    function timeDiffStartExam(){
        let min = moment(parseInt(reg.scheduleRound.examStart)).diff(serverTime, 'minutes');
        return min;
    }

    if(reg.bill && !reg.bill.isPaid){
        return <Alert variant="danger">Please Pay</Alert>
    }

    return <>
        {reg?.examResult?.quizEnd ?
            <>
                <Alert variant="success">Submitted</Alert>
            </>
            :
            <>
                {
                    serverTime <= moment(parseInt(reg.scheduleRound.examEnd, 10)).toDate()?
                        <>
                            {
                                timeDiffStartExam() >= 60 ?
                                    <>
                                        {getCounter(true)}
                                    </>
                                    :
                                    <>
                                        {
                                            !examReady?.[reg.id]?
                                                showButton(true)
                                                :
                                                showButton()
                                        }
                                    </>
                            }
                        </>
                        :
                        <>
                            <Alert variant="secondary" style={{marginBottom:0}}>Ended</Alert>
                        </>
                }
            </>
        }
    </>
}
export default StartExamButton;
