import {Badge, Col, Container, Nav, Navbar, Row} from "react-bootstrap";
import {css} from "aphrodite";
import Config from "../../config";
import {observer} from "mobx-react";
import {useContext} from "react";
import StateContext, {useMobxState} from "../../mobx/global-context";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faBars,faUser} from "@fortawesome/free-solid-svg-icons";

const TopMenu = ({onOpenLeftMenu}) => {

    const state = useMobxState();

    return <>
        {/*<Navbar variant="light">*/}
        {/*        <a className="d-block d-md-none mr-auto ml-2"*/}
        {/*           href='#'*/}
        {/*           onClick={onOpenLeftMenu}>*/}
        {/*            <FontAwesomeIcon size={'2x'} icon={faBars}/>*/}
        {/*        </a>*/}
        {/*        <Navbar.Brand className="mr-auto ml-md-2" href="#home">KKU DQ Examination</Navbar.Brand>*/}
        {/*        <Nav className='ml-auto d-none d-md-flex'>*/}
        {/*            {state.currentUser &&*/}
        {/*                <>*/}
        {/*                    <div>Logged in as: <Badge variant='light'>{state.currentUser.username} | {state.currentUser.prefix}{state.currentUser.fname} {state.currentUser.lname}</Badge></div>*/}
        {/*                    <Nav.Link onClick={e=>state.logout()} className="btn btn-danger ml-4">Logout</Nav.Link>*/}
        {/*                </>*/}
        {/*            }*/}
        {/*        </Nav>*/}
        {/*</Navbar>*/}
        <nav
            className="header-navbar navbar navbar-expand-lg align-items-center floating-nav navbar-light navbar-shadow">
            <div className="navbar-container d-flex content">
                <div className="bookmark-wrapper d-flex align-items-center">
                    <ul className="nav navbar-nav d-xl-none">
                        <li className="nav-item"><a className="nav-link menu-toggle" href={void 0} onClick={e=>{
                            state.sideMenu('show')
                        }}><FontAwesomeIcon icon={faBars} /> </a></li>
                    </ul>
                    <ul className="nav navbar-nav">
                        <li className="nav-item d-none d-lg-block"><a className="nav-link nav-link-style"><i
                            className="ficon" data-feather="moon"></i></a></li>
                    </ul>
                </div>
                <ul className="nav navbar-nav align-items-center ml-auto">
                    <li className="nav-item dropdown dropdown-user"><a
                        className="nav-link dropdown-toggle dropdown-user-link" id="dropdown-user"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        {state.currentUser &&
                            <>
                                <div className="user-nav">
                                    <span className="user-name font-weight-bolder">{state.currentUser.prefix}{state.currentUser.fname} {state.currentUser.lname}</span><span
                                    className="user-status">{state.currentUser.username} | <span className='text-capitalize'>{state.currentUser.role}</span></span>
                                </div>
                                <span className="avatar"><FontAwesomeIcon icon={faUser} size={"2x"}/><span
                                    className="avatar-status-online"></span></span>
                            </>
                        }
                    </a>
                        <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdown-user">
                            {/*<a*/}
                            {/*className="dropdown-item"><i className="mr-50"*/}
                            {/*                             data-feather="user"></i> Profile</a><a*/}
                            {/*className="dropdown-item"><i className="mr-50"*/}
                            {/*                             data-feather="mail"></i> Inbox</a><a*/}
                            {/*className="dropdown-item"><i className="mr-50"*/}
                            {/*                             data-feather="check-square"></i> Task</a><a*/}
                            {/*className="dropdown-item"><i className="mr-50"*/}
                            {/*                             data-feather="message-square"></i> Chats</a>*/}
                            {/*<div className="dropdown-divider"></div>*/}
                            {/*<a className="dropdown-item"><i className="mr-50"*/}
                            {/*                                data-feather="settings"></i> Settings</a><a*/}
                            {/*    className="dropdown-item"><i className="mr-50"*/}
                            {/*                                 data-feather="credit-card"></i> Pricing</a><a*/}
                            {/*    className="dropdown-item"><i className="mr-50"*/}
                            {/*                                 data-feather="help-circle"></i> FAQ</a>*/}
                            <a className="dropdown-item" onClick={e=>{
                                e.preventDefault();
                                state.logout();
                            }}><i className="mr-50" data-feather="power"></i> Logout</a>
                        </div>
                    </li>
                </ul>
            </div>
        </nav>
    </>
}
export default observer(TopMenu);
