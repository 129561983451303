import {
    Box,
    Button, Checkbox,
    Fade,
    FormControl,
    FormControlLabel,
    FormLabel, Grid,
    makeStyles,
    Radio,
    RadioGroup, Typography
} from "@material-ui/core";
import React, {useState} from "react";
import RegisterStepButton from "./register-step-button";
import {useMobxState} from "../mobx/global-context";
import {observer} from "mobx-react";
import {runInAction} from "mobx";
import {Alert} from "@material-ui/lab";

const useStyles = makeStyles(theme => ({
    root: {

    }
}))

const RegisterStep0 = ({courseDB, setCurrentStep}) => {
    const classes = useStyles();
    const state = useMobxState();


    const {courseDBbyID} = courseDB.data;
    return <>
        <Box p={2} className={classes.root}>
            <Box p={1}>
                <Typography variant="h5">Course ID</Typography>
                <Typography>{courseDBbyID.idnumber}</Typography>
            </Box>
            <Box p={1}>
                <Typography variant="h5">Course Name</Typography>
                <Typography>{courseDBbyID.fullname}</Typography>
            </Box>
            <Box p={1}>
                <Typography variant="h5">Summary</Typography>
                <div dangerouslySetInnerHTML={{__html: courseDB.data.courseDBbyID.summary}}></div>
            </Box>
            <Box p={1}>
                <Typography variant="h5">Pass conditions</Typography>
                <Box p={1}></Box>
                {courseDBbyID.orConditions.map((or, i) => {
                    return <React.Fragment key={i}>
                        <Typography variant='h6'>Condition {i + 1}</Typography>
                        <Box p={1}>
                            <Grid container spacing={1}>
                                {or.andConditions.map(and => {
                                    let strCondition = and.condition == 'must_pass' ?'Must passed':'Optional';
                                    return <Grid item xs={12} key={and.id}>
                                        <Alert severity={and.condition == 'must_pass'?'success':'info'} >
                                            <span>{and.quizSet.name}</span>
                                            <span style={{paddingLeft:5,paddingRight:5}}>/</span>
                                            <Box display='inline' style={{fontWeight:'bold'}}>
                                                {strCondition}
                                            </Box>
                                        </Alert>
                                    </Grid>
                                })}
                            </Grid>
                        </Box>
                    </React.Fragment>
                })}
            </Box>
        </Box>
        <RegisterStepButton condition={true} hr onNext={e => {setCurrentStep(1)}}/>
    </>
}
export default observer(RegisterStep0);
