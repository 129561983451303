import {
    Badge,
    Card,
    Col,
    FormControl,
    FormGroup,
    FormLabel,
    Image,
    ListGroup,
    ListGroupItem,
    Row,
    ButtonGroup, ToggleButton, Form
} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {CourseSettingHeadBar} from "../page/admin/exam-schedule-course-setting";
import {StyleSheet, css} from 'aphrodite';
import {useQuery} from "@apollo/client";
import {GET_ROLES, GET_SSO_ROLES} from "../gql-query/select-query";
import _ from 'lodash';


const CourseRoleQuizSetup = ({courseForm, updateUI}) => {

    const [customStudentID, setCustomStudentID] = useState({condition: '', year: ''});

    const {data, loading} = useQuery(GET_SSO_ROLES, {
        fetchPolicy: 'network-only'
    })

    useEffect(() => {
        if (data?.ssoRole) {
            let customRole
            courseForm.ssoRoles?.forEach(roleID => {
                let role = data.ssoRole?.find(r => r.id == roleID);
                if (role.name == 'Custom') {
                    customRole = role;
                    return;
                }
            });
        }
    }, [courseForm.ssoRoles]);
    

    useEffect(() =>{
        if(courseForm.customRoleJSON){
            try{
                let buffer = JSON.parse(courseForm.customRoleJSON);
                setCustomStudentID({...buffer});
            }catch(e){
                console.log(e);
            }
        }
    },[courseForm.customRoleJSON]);

    function customInputProps(name) {
        return {
            onChange: e => {
                let value = {
                    ...customStudentID,
                    [name]: e.target.value
                };
                setCustomStudentID(value)
                courseForm.customRoleJSON=JSON.stringify(value);
            },
            value:customStudentID?.[name] || ''
        }
    }

    if (loading) return <div>Loading...</div>
    return <>
        <CourseSettingHeadBar title="(1) Role setup"/>
        <Card>
            <Card.Body>
                <ButtonGroup className={css(styles.root)}>
                    {data.ssoRole?.map((r, i) => {
                        return <ToggleButton
                            key={i}
                            id={`radio-${i}`}
                            name={`role${i}`}
                            variant={i % 2 ? 'outline-success' : 'outline-danger'}
                            type="checkbox"
                            value={r.id}
                            checked={!!courseForm.ssoRoles?.includes(r.id)}
                            onChange={e => {
                                if (!courseForm.ssoRoles?.includes(r.id)) {
                                    courseForm.ssoRoles = [...courseForm?.ssoRoles || [], r.id];
                                } else {
                                    courseForm.ssoRoles = _.reject(courseForm.ssoRoles, v => v == r.id);
                                }
                                updateUI();
                            }}
                        >
                            <span className='text-capitalize' style={{paddingLeft: 5}}>{r.name}</span>
                            {r.name == 'Custom' && !!courseForm.ssoRoles?.includes(r.id) &&
                            <div className="mt-1">
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <FormControl as="select" {...customInputProps('condition')}>
                                                <option>- Condition -</option>
                                                <option value="gt">Student id &gt;</option>
                                                <option value="lt">Student id &lt;</option>
                                            </FormControl>
                                        </FormGroup>
                                    </Col>
                                    <Col xs='auto'>
                                        <FormControl as="select" {...customInputProps('year')}>
                                            <option>- Student year -</option>
                                            {[...new Array(10)].map((v, n) => <option key={n} value={60 + n}>{60 + n}xxxxxxx-x</option>)}
                                        </FormControl>
                                    </Col>
                                </Row>
                            </div>
                            }
                        </ToggleButton>
                    })}

                </ButtonGroup>
                <Form.Group className="mt-2">
                    <Form.Check onChange={e=>{
                        let v = e.target.checked;
                        courseForm.defaultCourse = v;
                        updateUI();
                    }} label="Default Course" id="default_course" checked={courseForm.defaultCourse || false}/>
                </Form.Group>
            </Card.Body>
        </Card>
    </>
}
const styles = StyleSheet.create({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: '.5rem',
        padding: '.5rem',
    }
});
export default CourseRoleQuizSetup;
