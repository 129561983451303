import {
    Box,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {useQuery} from "@apollo/client";
import {GET_COURSES_DB, GET_STUDENT_REGISTRATIONS} from "../../gql-query/select-query";
import {useHistory} from "react-router-dom";
import {Alert, Button, Card, Col, Row} from "react-bootstrap";
import moment from "moment";
import {getThaiDateBetween} from "../../services/client-helper";
import gql from "graphql-tag";

const _GET_COURSES_DB = gql`
query GET_COURSE($register:Boolean){
  coursesDB {
    id
    idnumber
    fullname
    shortname
    schedules(register:$register) {
      id
      title
      detail
      month
      year
      code
      registerStart
      registerEnd
      isAvailableSeats
      rounds {
        id    
        location {
          id
          name
        }    
      }
    }
    meCanRegistration
  }
}
`;


/**
 * @typedef {Object} Course
 * @property {string} fullname - The full name of the course.
 * @property {string} id - The unique identifier of the course.
 * @property {string} idnumber - The id number of the course.
 * @property {boolean} meCanRegistration - A flag indicating if the user can register for the course.
 * @property {Array} schedules - An array of schedules for the course.
 * @property {string} shortname - The short name of the course.
 */

const RegisterCourse = ()=>{
    const courses = useQuery(_GET_COURSES_DB,{fetchPolicy:'network-only',variables:{register:true}})
    const resReg = useQuery(GET_STUDENT_REGISTRATIONS);
    const history = useHistory();
    const [nearestCourseSchedules,setNearestCourseSchedules] = useState([]);
    const [nearestSchedule,setNearestSchedule] = useState();

    const [userRegs,setUserReg] = useState([]);

    useEffect(()=>{
        if(resReg.data){
            setUserReg(resReg?.data?.studentRegistrations);
        }
    },[resReg.data]);

    useEffect(()=>{
        if(courses.data){
            let {coursesDB} = courses.data;
            coursesDB.forEach(course=>{
                let nearestTime=-1;
                let end=0;
                // for(let schd of course?.schedules){
                //     console.log(parseInt(schd.registerStart,10),(new Date()).getTime());
                // }
                // course?.schedules?.filter(schd=>moment(parseInt(schd.registerStart,10)).toDate()>(new Date()).getTime()).forEach(schd=>{
                course?.schedules?.forEach(schd=>{
                    let ts = parseInt(schd.registerStart,10);
                    if(nearestTime==-1){
                        nearestTime=ts;
                        end=schd.registerEnd;
                        setNearestSchedule(schd);
                        console.log('===', schd);
                    }else{
                        if(nearestTime>ts) {
                            nearestTime = ts;
                            end=schd.registerEnd;
                            setNearestSchedule(schd);
                        }
                    }
                });
                if(nearestTime!=-1){
                    setNearestCourseSchedules([
                        ...nearestCourseSchedules,
                        {courseID:course.id,start:nearestTime,end}
                    ]);
                }
            });
        }
    },[courses.data]);

    /**
     * @param {Course[]} course
     */
    function isHasSchedule(courses){
        return !!courses.find(c=>c.schedules.length>0);
    }

    if(courses.loading || resReg.loading) return <Alert variant='info'>Loading...</Alert>
    return <Paper>
        <Box p={2}>
            <Typography variant="h5">Course List</Typography>
            <hr/>
            {courses.data?
                <div>
                    {courses.data?.coursesDB.filter(r => !!r.meCanRegistration).length == 0
                    || !isHasSchedule(courses.data?.coursesDB) ?
                        <Alert>No schedule to registration.</Alert>
                    :
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Course Code</TableCell>
                                        <TableCell>Name</TableCell>
                                        <TableCell>&nbsp;</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {courses.data?.coursesDB.filter(r=>!!r.meCanRegistration).map(course=>{
                                            let nearTime = nearestCourseSchedules.find(c=>c.courseID==course.id);
                                            return <TableRow key={course.id}>
                                                <TableCell>{course.idnumber}</TableCell>
                                                <TableCell>
                                                    <div className="mb-4">
                                                        <h4>{course.fullname}</h4>
                                                    </div>
                                                    {course.schedules.map(schd=>{
                                                        return <Card className='mb-2'>
                                                            <Card.Header>{schd.title}</Card.Header>
                                                            <Card.Body>
                                                                <Row>
                                                                    <Col>
                                                                        <strong>Month:</strong>
                                                                        <div>{schd.month}</div>
                                                                    </Col>
                                                                    <Col>
                                                                        <strong>Year:</strong>
                                                                        <div>{schd.year}</div>
                                                                    </Col>
                                                                    <Col>
                                                                        <strong>Code:</strong>
                                                                        <div>{schd.code}</div>
                                                                    </Col>
                                                                </Row>
                                                                {/*<hr/>*/}
                                                                {/*<strong>Selectable location:</strong>*/}
                                                                {/*<div>*/}
                                                                {/*    {[...new Set(nearestSchedule?.rounds?.map(r=>r.location.name))].map((name,i)=>{*/}
                                                                {/*        return <div>({i+1}) {name}</div>*/}
                                                                {/*    })}*/}
                                                                {/*</div>*/}
                                                            </Card.Body>
                                                            <Card.Footer>
                                                                {schd.isAvailableSeats?
                                                                    <>
                                                                        <Button
                                                                            onClick={e=>history.push(`/my/register/calendar/${course.id}/${schd.id}`)}
                                                                        >Register</Button> (<small>Register: {getThaiDateBetween(schd.registerStart,schd.registerEnd,"date-time")}</small>)
                                                                    </>
                                                                    :
                                                                    <Button variant='danger'
                                                                            onClick={e=>history.push(`/my/register/calendar/${course.id}/${schd.id}`)}
                                                                    >Sorry, Seats not available (ขออภัย..ที่นั่งสอบเต็มแล้ว)</Button>
                                                                }

                                                            </Card.Footer>
                                                        </Card>
                                                    })}
                                                    {/*<div>*/}
                                                    {/*    <small>Location:*/}
                                                    {/*        {[...new Set(nearestSchedule?.rounds?.map(r=>r.location.name))].map((name,i)=>{*/}
                                                    {/*        return <div>({i+1}) {name}</div>*/}
                                                    {/*    })}</small>*/}
                                                    {/*</div>*/}
                                                    {/*{nearTime &&*/}
                                                    {/*    <div className="p-2 mt-1" style={{border:'1px solid #e5e5e5',borderRadius:'10px',color:'gray'}}>*/}
                                                    {/*        <div><small>Registration: {getThaiDateBetween(nearTime.start,nearTime.end,'lll')}</small></div>*/}
                                                    {/*    </div>*/}
                                                    {/*}*/}
                                                </TableCell>
                                                <TableCell>
                                                    {/*{course.meCanRegistration?*/}
                                                    {/*    <Button*/}
                                                    {/*        variant='primary'*/}
                                                    {/*        onClick={e=>history.push('/my/register/calendar/'+course.id)}*/}
                                                    {/*    >Register</Button>*/}
                                                    {/*    :*/}
                                                    {/*    <>*/}
                                                    {/*        <Button disabled={true} variant='secondary'>Not available</Button>*/}
                                                    {/*    </>*/}
                                                    {/*}*/}
                                                </TableCell>
                                            </TableRow>
                                        }
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    }
                    {/*{courses.data?.coursesDB.length == 0  &&*/}
                    {/*<Alert variant="info">No course to registration.</Alert>*/}
                    {/*}*/}
                    <hr/>
                    <Box mt={4}>
                        <iframe
                            src="https://calendar.google.com/calendar/embed?height=600&amp;wkst=1&amp;bgcolor=%23ffffff&amp;src=j65n1hsu356ro85t2tfrf2tohg%40group.calendar.google.com&amp;color=%23AB8B00&amp;src=36af0s3o3eo5c0lod9np99vs00%40group.calendar.google.com&amp;color=%23711616&amp;ctz=Asia%2FBangkok"
                            style={{borderWidth:0}} width="100%" height="600" frameBorder="0"
                            scrolling="no"></iframe>
                    </Box>
                </div>
                :
                <div>Loading...</div>
            }
        </Box>
    </Paper>
}
export default RegisterCourse;
