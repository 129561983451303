import pkg from '../package.json';
const Config = {
    api_url:pkg.api_url,
    moodleURL:pkg.moodle_url,
    root:pkg.homepage,
    backEndPath:`${pkg.homepage}/theme/back-end`,
    frontEndPath:`${pkg.homepage}/theme/front-end`,
    siteName:pkg.site_name,
    base_url:pkg.base_url,
};
export default Config;
