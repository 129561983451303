import {Tab, Tabs} from "react-bootstrap";
import RegisterStep0 from "./register-step-0";
import React from "react";
import RegisterStep1 from "./register-step-1";
import RegisterStep2 from "./register-step-2";
import RegisterStep3 from "./register-step-3";
import RegisterStep4 from "./register-step-4";

const RegisterTabs = ({courseDB,userRegistrations,currentStep,setCurrentStep})=>{
    return <Tabs defaultActiveKey={0} activeKey={currentStep}
        onSelect={k=>setCurrentStep(k)}>
        <Tab eventKey={0} title="Course Information" disabled={currentStep == 4}>
            <RegisterStep0 {...{courseDB,userRegistrations}} setCurrentStep={setCurrentStep}/>
        </Tab>
        <Tab eventKey={1} title="(1) Quiz" disabled={currentStep<1 || currentStep == 4}>
            {currentStep==1 && <RegisterStep1 {...{courseDB,userRegistrations, setCurrentStep}}/>}
        </Tab>
        <Tab eventKey={2} title="(2) Schedule" disabled={currentStep<2 || currentStep == 4}>
            {currentStep==2 && <RegisterStep2 {...{courseDB,userRegistrations, setCurrentStep}}/>}
        </Tab>
        <Tab eventKey={3} title="(3) Confirm" disabled={currentStep<3 || currentStep == 4}>
            {currentStep==3 && <RegisterStep3 {...{courseDB,userRegistrations, setCurrentStep}}/>}
        </Tab>
        <Tab eventKey={4} title="(4) Payment" disabled={currentStep<4 || currentStep == 4}>
            {currentStep==4 && <RegisterStep4 {...{courseDB, setCurrentStep}}/>}
        </Tab>
    </Tabs>
}
RegisterTabs.defaultProps={
    currentStep:0
}
export default RegisterTabs;
