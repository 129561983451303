import {Alert, Badge} from "react-bootstrap";
import moment from "moment";
import Config from "../config";
import _ from 'lodash';
import {requestAPI} from "./requester";
import React from "react";

export function showInputError(objRef, key, errors) {
    return <>
        {errors[`${objRef.id}-${key}`] &&
        <Alert variant='danger'>{errors[`${objRef.id}-${key}`]}</Alert>
        }
    </>
}

export async function getServerTime() {
    try {
        let res = await requestAPI('GET', '/time');
        return new Date(res?.data?.serverTime);
    } catch (e) {
        return new Date();
    }
}

export function registerInputWithSchema(objRef, keyName, fnUpdateUI, formSchema, errors, fnSetError) {
    return {
        name: keyName,
        value: objRef[keyName],
        onChange: e => {
            let v = e.target.value;
            objRef[keyName] = v;
            fnUpdateUI();
            formSchema.validateAt(keyName, objRef, {abortEarly: true})
                .then(data => {
                    fnSetError({...errors, [`${objRef.id}-${keyName}`]: void 0})
                })
                .catch(e => {
                    fnSetError({...errors, [`${objRef.id}-${keyName}`]: e.message})
                })
        }
    }
}

export function getRegistrationCountWithThis(studentRegistrations = [], currentScheduleID) {
    let uniqueScheduleID = createGroup(studentRegistrations, 'schedule.id');
    let existed = uniqueScheduleID.find(v => v.group.id == currentScheduleID);
    let existedRegSchedule = studentRegistrations.find(v => v.schedule.id == currentScheduleID);
    if (existedRegSchedule) {
        let sortedRegistrationsBuffers = _.cloneDeep(studentRegistrations);
        sortedRegistrationsBuffers.sort((a, b) => {
            if (parseInt(a.created) > parseInt(b.created)) return 1;
            if (parseInt(a.created) < parseInt(b.created)) return -1;
            return 0;
        })
        return [...new Set(sortedRegistrationsBuffers.map(v => v.schedule.id))].indexOf(currentScheduleID) + 1;
    }
    return existed ? uniqueScheduleID.length : uniqueScheduleID.length + 1;
}


export function getThaiDateBetweenSingleDay(start, end, multipleLines = true) {
    return <div>
        {multipleLines ?
            <>
                <div>{moment(parseInt(start, 10)).format('Do MMM YYYY')}</div>
                <div>{moment(parseInt(start, 10)).format('HH:mm')} น. - {moment(parseInt(end, 10)).format('HH:mm')}</div>
            </>
            :
            <>
                <div>{moment(parseInt(start, 10)).format('Do MMM YYYY')} {moment(parseInt(start, 10)).format('HH:mm')} - {moment(parseInt(end, 10)).format('HH:mm')} น.</div>
            </>
        }

    </div>;
}

export function getThaiDate(timestamp, display) {
    let d = moment.unix(parseInt(timestamp) / 1000)
    if (!display) {
        return d.format('DD/MM/YYYY HH:mm:ss');
    }
    if (display == 'date') return d.format('DD/MM/YYYY');
    if (display == 'time') return d.format('HH:mm:ss');
}

export function getThaiDateBetween(start, end, display, jsx = false) {
    let format;
    if (display == 'date') {
        format = 'DD/MM/YYYY';
    } else if (display == 'time') {
        format = 'HH:mm';
    } else if (display == 'date-time') {
        format = 'DD/MM/YYYY HH:mm';
    } else {
        format = display;
    }
    let _start = moment(parseInt(start, 10)).format(format)
    let _end = moment(parseInt(end, 10)).format(format)
    if (jsx) {
        return <div><span>{_start} - {_end}</span></div>;
    } else {
        return `${_start} - ${_end}`;
    }
}

export const createGroup = (dataList = [], groupKeyName) => {
    let results = [];
    let groupKeys = [...new Set(dataList.map(v => _.get(v, groupKeyName)))];
    for (let key of groupKeys) {
        results.push({
            group: _.get(dataList.find(v => _.get(v, groupKeyName) == key),
                groupKeyName.substring(0, groupKeyName.lastIndexOf('.')) || groupKeyName
            ),
            data: dataList.filter(v => _.get(v, groupKeyName) == key)
        })
    }
    return results;
}

export const sort = (dataList = [], comparatorKey, orderBy = 'asc') => {
    let results = _.cloneDeep(dataList).sort((a, b) => {
        if (_.get(a, comparatorKey) === _.get(b, comparatorKey)) return 0;
        if (orderBy == 'asc') {
            return _.get(a, comparatorKey) > _.get(b, comparatorKey) ? 1 : -1;
        } else {
            return _.get(a, comparatorKey) > _.get(b, comparatorKey) ? -1 : 1;
        }
    });
    return results;
}

export const stripTags = (strInput) => {
    return strInput.replace(/<\/?[^>]+(>|$)/g, "");
}

export const pad2 = (number) => {
    return (parseInt(number) < 10 ? '0' : '') + String(number)
}


export function isCurrentDateBetween(start, end) {
    let currentDate = new Date();
    return currentDate >= start && currentDate < end;
}

export function isCurrentDateBetweenServerTime(serverTime, start, end) {
    let currentDate = new Date();
    return currentDate >= start && currentDate < end;
}
