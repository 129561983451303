import {useEffect, useState} from "react";
import {useApolloClient, useMutation} from "@apollo/client";
import gql from "graphql-tag";
import moment from 'moment';
import csvtojsonV2 from 'csvtojson';

const GET_COURSES = gql`
    query getCourses {
        coursesDB {
            id
            fullname
            summary
            schedules{
                id
                title
                code
                examStart
                examEnd
                show
            }
            dqQuizzes {
                id
                name
            }
        }
    }
`
const SUBMIT_GRADE = gql`
    mutation GradeImport($courseID:ID,$scheduleID:ID,$scoreData:[GradeImportInputType]){
        gradeImport(courseID:$courseID,scheduleID:$scheduleID,scoreData:$scoreData){
            success
            error
            message
            userFailed{
              _id
              username
              studentID
              fname
              lname
            }
        }
    }
`


const useGradeImporterViewModel = () => {
    const [_tmpScoreData,_setTmpScoreData] = useState(/** @type {ScoreData[]} */[]);
    const [scoreData,setScoreData] = useState(/** @type {ScoreData[]} */[]);
    const [courses,setCourses] = useState(/** @type {CourseWithSchedules[]} */[]);
    const [selectedCourse,setSelectedCourse] = useState('');
    const [selectedSchedule,setSelectedSchedule] = useState('');
    const [schedules,setShedules] = useState(/** @type {Schedule[]} */[]);
    const [dqQuizzes,setDQQuizzes] = useState(/** @type {DQQuiz[]} */[]);
    const [resultDate,setResultDate] = useState(/** @type {Date[]} */[null, null]);
    const [scoreResult,setScoreResult] = useState(/** @type {CourseResult} */{
        courseID:null,
        scheduleID:null,
        scoreData:[]
    });
    const [isFormValid,setIsFormValid] = useState(false);
    const [isSubmitting,setIsSubmitting] = useState(false);
    const [_submitGrade, {data}] = useMutation(SUBMIT_GRADE);
    const [updateOnlyNullZero,setUpdateOnlyNullZero] = useState(true);
    const apolloClient = useApolloClient();

    useEffect(() => {
        apolloClient.query({
            query:GET_COURSES
        }).then(({data})=>{
            setCourses(data.coursesDB);
        })
    }, []);

    useEffect(()=>{
        setScoreResult( {
            updateOnlyNullZero,
            courseID:selectedCourse,
            scheduleID:selectedSchedule,
            scoreData:scoreData
        })
    },[selectedCourse,selectedSchedule,scoreData,updateOnlyNullZero])

    function removeDataScore(dqQuizID){
        _setTmpScoreData(old=>old.filter(v=>v.dqQuizID!==dqQuizID));
        setScoreData(old=>old.filter(v=>v.dqQuizID!==dqQuizID));
    }

    function submitGrade(){
        console.log('scoreResult===', scoreResult);
        setIsSubmitting(true);
        _submitGrade({
            variables:scoreResult
        }).then(({data})=>{
            setIsSubmitting(false);
            console.log('data===', data);
            if(data.gradeImport.success){
                alert('Success');
                // setScoreData([]);
                // setSelectedCourse('');
                // setSelectedSchedule('');
                // setResultDate([null,null]);
            }else{
                setIsSubmitting(false);
                alert('Failed');
            }
        }).catch(e=>{
            setIsSubmitting(false);
            alert('Failed');
        })
    }

    function readFile(dqQuizID,element){
        let file = element.target?.files?.[0];
        if(!file)return;
        let reader = new FileReader();
        let data = [];
        reader.onload = async function(e){
            if(file.name.match(/\.csv$/)){
                console.log('CSV',e.target.result);
                data = await new Promise((resolve, reject) => {
                    csvtojsonV2({flatKeys:true}).fromString(e.target.result).then((fileContentJSON)=>{
                        resolve(fileContentJSON);
                    })
                })
            }else{
                console.log('JSON');
                let fileContentJSON = JSON.parse(e.target.result);
                if(fileContentJSON[0] && Array.isArray(fileContentJSON[0])){
                    data = fileContentJSON?.[0]
                }else{
                    data = fileContentJSON;
                }
            }
            let _scoreData = data.filter(g=>{
                // let {startedon} = g;
                // let isBetween;
                // try{
                //     if(!moment(resultDate[0]).isValid())throw new Error();
                //     const date = moment(startedon||g['Started on'], 'D MMMM YYYY h:mm A');
                //     isBetween = date.isBetween(moment(resultDate[0]),moment(resultDate[1]));
                // }catch (e){
                //     isBetween = true;
                // }
                // return isBetween;
                return true;
            }).map(g=>{
                let strDate = g.startedon || g['Started on'];
                strDate = strDate.trim();
                const date = moment(strDate, 'D MMMM YYYY  h:mm A');
                return {
                    dqQuizID,
                    username:g.username || g.Username,
                    grade:String(g.grade10000 || g['Grade/100.00']),
                    startedon:date,
                }
            })
            _setTmpScoreData(old=>{
                let data = [...old.filter(v=>v.dqQuizID!==dqQuizID),..._scoreData];
                // setScoreData([...data]);
                return data;
            });
        }
        reader.readAsText(file);
    }
    useEffect(()=>{
        if(scoreResult.courseID && scoreResult.scheduleID && scoreResult.scoreData.length>0){
            setIsFormValid(true);
        }else{
            setIsFormValid(false);
        }
    },[scoreResult])

    ///Date change
    useEffect(()=>{
        try{
            let start = moment(resultDate[0])
            let end = moment(resultDate[1])
            if(!start.isValid() || !end.isValid())throw new Error();
            setScoreData(_tmpScoreData.filter(s=>s.startedon.isBetween(start,end)))
        }catch (e){
            setScoreData(_tmpScoreData);
        }
    },[resultDate,_tmpScoreData])

    //Course or Schedule change
    useEffect(()=>{
        if(selectedCourse){
            setShedules(courses.find(c=>c.id===selectedCourse)?.schedules);
            if(selectedSchedule){
                setDQQuizzes(courses.find(c=>c.id===selectedCourse)?.dqQuizzes);
            }else{
                setDQQuizzes([]);
            }
        }else{
            setShedules([]);
            setDQQuizzes([]);
        }
    },[selectedCourse,selectedSchedule])


    return {
        readFile,
        scoreData,
        courses,
        schedules,
        selectedCourse,
        setSelectedCourse,
        setSelectedSchedule,
        dqQuizzes,
        setResultDate,
        resultDate,
        removeDataScore,
        isFormValid,
        scoreResult,
        submitGrade,
        isSubmitting,
        updateOnlyNullZero,
        setUpdateOnlyNullZero,
    };
}
export default useGradeImporterViewModel
