import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from "@fullcalendar/interaction";
import {
    Card,
    CardContent,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Typography,
    makeStyles, Box
} from "@material-ui/core";
import moment from "moment";
import {useEffect, useRef, useState} from "react";
import {getAllMonth, numberWithCommas} from "../../services/helper";
import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faMoneyBill} from "@fortawesome/free-solid-svg-icons";
import {Alert, Button, Form, Nav} from "react-bootstrap";
import {useApolloClient, useQuery} from "@apollo/client";
import {GET_BILL_BY_MONTH, GET_COURSES_DB, GET_KKU_BILL, GET_SCHEDULE_WITH_REF2} from "../../gql-query/select-query";
import DataTable from 'react-data-table-component';
import THBText from './../../services/thai-baht-text';
import {CSVLink, CSVDownload} from "react-csv";


const columns = [
    {
        name: 'Transaction Time',
        selector: row => row.transaction_time,
        format:row=>moment(row.transaction_time).format('lll'),
        sortable:true
    },
    {
        name: 'Transaction ID',
        selector: row => row.transaction_id,
    },
    {
        name: 'Email',
        selector: row => row.cust_email,
        sortable:true
    },
    {
        name: 'Amount',
        selector: row => row.amount,
        format:row=>`${row.amount} THB`,
        sortable:true,
    },
    {
        name: 'State',
        selector: row => row.pay_status,
        sortable:true
    },
];

const Billing = () => {
    const [events, setEvents] = useState([]);
    const calendar = React.useRef();
    const [viewMode, setViewMode] = useState('list');
    const [currentMonth, setCurrentMonth] = useState();
    const [selectedCourseID, setSelectedCourseID] = useState();
    const [selectedScheduleID, setSelectedScheduleID] = useState();
    const [selectedSchedule, setSelectedSchedule] = useState();
    const [selectedState, setSelectedState] = useState('all');
    const [billResults, setBillResults] = useState([]);
    const [csvBilling, setCsvBilling] = useState([]);
    const resCourses = useQuery(GET_COURSES_DB,{fetchPolicy:'network-only'});
    const resSchedules = useQuery(GET_SCHEDULE_WITH_REF2,{fetchPolicy:'network-only'});
    const apolloClient = useApolloClient();


    const {data, loading, error} = useQuery(GET_BILL_BY_MONTH, {
        fetchPolicy: 'cache-first',
        variables: {
            date: String(currentMonth?.getTime()),
            // mode: viewMode,
        },
        skip: currentMonth ? false : true,
    });

    const resKKUBill = useQuery(GET_KKU_BILL, {
        fetchPolicy: 'network-only',
        variables: {
            ref2: selectedSchedule?.ref2,
        },
        skip: !selectedSchedule?.ref2,
    });

    // useEffect(()=>{
    //     let ev = [];
    //     let s = -5;
    //     [...new Array(10)].map((v,i)=>{
    //         let bill = Math.round(Math.random()*200-1)+1;
    //         ev.push({
    //             title:`${bill} Bill / ${numberWithCommas((bill*100))} THB`,
    //             start:moment().add(s+i,'day').toDate(),
    //             allDay:true,
    //         });
    //     })
    //     setEvents([...ev]);
    // },[]);

    // useEffect(() => {
    //     if(data){
    //         let ev = [];
    //         data?.getRemoteBills.map(t=>{
    //             let [u,] = t.cust_email.split('@');
    //             if(viewMode=='day'){
    //                 let day = moment(new Date(t.transaction_time)).startOf('day').toDate();
    //                 let existed = ev.find(v=>v.start.getTime()==day.getTime());
    //                 if(!existed){
    //                     ev.push({
    //                         title: 'Total',
    //                         count:1,
    //                         amount:parseInt(t.amount),
    //                         start: day,
    //                         allDay: true,
    //                     });
    //                 }else{
    //                     existed.count+=1;
    //                     existed.amount+=t.amount;
    //                 }
    //             }else if(viewMode=='student'){
    //                 ev.push({
    //                     title: `${u}`,
    //                     amount:numberWithCommas((t.amount)),
    //                     start: t.transaction_time,
    //                     allDay: true,
    //                 });
    //             }else if(viewMode=='list'){
    //                 console.log(t);
    //             }
    //         })
    //         setEvents(ev);
    //     }
    // }, [data,viewMode])

    useEffect(() =>{
        if(selectedScheduleID){
            let schedule = resSchedules.data?.getSchedules?.find(schd=>schd.id==selectedScheduleID);
            if(schedule){
                setSelectedSchedule(schedule);
            }
        }
    },[selectedScheduleID]);

    useEffect(() =>{
       let bills =  selectedCourseID
           && selectedScheduleID
           && resKKUBill?.data?.getKKURemoteBills.filter(bill=>{
               switch(selectedState){
                   case 'all':
                       return true;
                   case 'not_paid':
                       return bill?.pay_status=='new'?true:false;
                   case 'paid':
                       return bill?.pay_status=='paid'?true:false;
                   case 'cancel':
                       return bill?.pay_status=='cancel_bill'?true:false;
               }
               return true;
           })
            ||
           [];
        setBillResults([...bills]);
    },[selectedCourseID,selectedScheduleID,selectedState,resKKUBill.loading])

    useEffect(() =>{
        generateCSV();
    },[billResults])

    function renderEvent(eventInfo) {
        let {amount,count}=eventInfo.event.extendedProps
        return <Box p={1}>
            <div className='text-center'><FontAwesomeIcon icon={faMoneyBill}/></div>
            {count &&
            <div className='text-center'>{count} Students</div>
            }
            <div className='text-center'>{eventInfo.event.title}</div>
            <div className='text-center'>{amount} THB</div>
        </Box>
    }

    useEffect(() => {
        if(viewMode=='list')return;
        let date = calendar.current.getApi().getDate();
        date.setDate(1);
        date.setHours(0, 0, 0, 0);
        setCurrentMonth(date);
    }, [viewMode])

    function setDateFromCalendar() {
        let date = calendar.current.getApi().getDate();
        date.setDate(1);
        date.setHours(0, 0, 0, 0);
        setCurrentMonth(date);
    }

    function generateCSV(){
        let csvBilling = [
            [
                'ID', 'เลขที่ใบเสร็จ', 'วันที่', 'เดือน', 'ปี', 'ได้รับเงินจาก',
                'รายการ', 'รายการ2', 'รายการ3', 'เป็นเงินสด', 'ค่าธรรมเนียมสอบคอม','ค่า....','ค่า.....',
                'รวม', 'ตัวอักษร','หมายเหตุ','เลขบิล'
            ]
        ];
        billResults.map(bill => {
            let {id,transaction_id,transaction_time,cust_name,amount}=bill;
            let [d,m,y] = moment(transaction_time).format('D/M/Y').split('/');
            let month = [
                'มกราคม',
                'กุมภาพันธ์',
                'มีนาคม',
                'เมษายน',
                'พฤษภาคม',
                'มิถุนายน',
                'กรกฎาคม',
                'สิงหาคม',
                'กันยายน',
                'ตุลาคม',
                'พฤศจิกายน',
                'ธันวาคม',
            ]
            csvBilling.push(
                [
                    id,
                    '',d,month?.[m-1]||m,parseInt(y)+543,
                    `${cust_name}`,
                    '410106999 รายได้ค่าธรรมเนียมอื่นๆ','การสอบหลักสูตรมาตรฐานความรู้ทางคอมพิวเตอร์',
                    '','TR เงินโอน',amount,
                    '','',amount,THBText(amount),
                    transaction_id,'เข้าบัญชี 551-3-02969-0'
                ]
            );
        });
        setCsvBilling(csvBilling)
        // console.log('csvBilling===', csvBilling);
    }

    return <>
        <Paper>
            <Card>
                <CardContent>
                    <Typography variant='h4'>KKU Payment Hub Report</Typography>
                    <hr/>
                    {/*<Typography>View mode</Typography>*/}
                    <Box mt={1}>
                        <Nav variant="tabs" activeKey={viewMode}>
                            <Nav.Item onClick={e => setViewMode('list')}>
                                <Nav.Link eventKey="list">Schedule</Nav.Link>
                            </Nav.Item>
                            {/*<Nav.Item onClick={e => setViewMode('student')}>*/}
                            {/*    <Nav.Link eventKey="student">Calendar / Student</Nav.Link>*/}
                            {/*</Nav.Item>*/}
                            {/*<Nav.Item onClick={e => setViewMode('day')}>*/}
                            {/*    <Nav.Link eventKey="day">Calendar / Day</Nav.Link>*/}
                            {/*</Nav.Item>*/}
                        </Nav>
                    </Box>
                    {/*<Grid container>*/}
                    {/*<Grid item>*/}
                    {/*    <FormControl style={{minWidth:200}}>*/}
                    {/*        <InputLabel id="month">Month</InputLabel>*/}
                    {/*        <Select labelId='month' onChange={e=>{*/}
                    {/*            let calendarApi = calendar.current.getApi();*/}
                    {/*            calendarApi.gotoDate(moment().toDate());*/}
                    {/*        }}>*/}
                    {/*            {getAllMonth().map(m=><MenuItem value={m+1}>{m}</MenuItem>)}*/}
                    {/*        </Select>*/}
                    {/*    </FormControl>*/}
                    {/*</Grid>*/}
                    {/*<Grid item>*/}
                    {/*    <FormControl style={{minWidth:200}}>*/}
                    {/*        <InputLabel id="year">Year</InputLabel>*/}
                    {/*        <Select labelId='year' onChange={e=>{*/}
                    {/*            let calendarApi = calendar.current.getApi();*/}
                    {/*            calendarApi.gotoDate(moment().toDate());*/}
                    {/*        }}>*/}
                    {/*            <MenuItem value={moment().toDate().getFullYear()}>{moment().toDate().getFullYear()}</MenuItem>*/}
                    {/*        </Select>*/}
                    {/*    </FormControl>*/}
                    {/*</Grid>*/}
                    {/*</Grid>*/}
                    <hr/>
                    {viewMode == 'list'?
                        <div>
                            <Form>
                                {!resCourses.loading &&
                                    <Form.Group>
                                        <Form.Label>Course</Form.Label>
                                        <Form.Control as='select' value={selectedCourseID} onChange={e=>{
                                            setSelectedCourseID(e.target.value);
                                        }}>
                                            <option value=''>- Choose -</option>
                                            {resCourses.data?.coursesDB?.map(course=>{
                                                return <option key={course.id} value={course.id}>{course.shortname} - ({course.idnumber})</option>
                                            })}
                                        </Form.Control>
                                    </Form.Group>
                                }
                                {!resSchedules.loading &&
                                    <Form.Group>
                                        <Form.Label>Schedule</Form.Label>
                                        <Form.Control as='select' value={selectedScheduleID} disabled={!!!selectedCourseID} onChange={e=>{
                                            setSelectedScheduleID(e.target.value);
                                        }}>
                                            <option value=''>- Choose -</option>
                                            {resSchedules.data?.getSchedules
                                                ?.filter(schd=>schd.course.id==selectedCourseID)
                                                ?.map(schd=>{
                                                return <option key={schd.id} value={schd.id}>{schd.title} - ({schd.code})</option>
                                            })}
                                        </Form.Control>
                                    </Form.Group>
                                }
                            </Form>
                            <Form>
                                <Form.Check value='all' checked={selectedState=='all'} id='stat1' name="state" inline type="radio" label='All' onChange={e=>setSelectedState('all')}/>
                                <Form.Check value='not_paid' checked={selectedState=='not_paid'} id='stat2' name="state" inline type="radio" label='Not Paid' onChange={e=>setSelectedState('not_paid')}/>
                                <Form.Check value='paid' checked={selectedState=='paid'} id='stat3' name="state" inline type="radio" label='Paid' onChange={e=>setSelectedState('paid')}/>
                                <Form.Check value='cancel' checked={selectedState=='cancel'} id='stat4' name="state" inline type="radio" label='Cancel' onChange={e=>setSelectedState('cancel')}/>
                            </Form>
                            {billResults.length>0 &&
                            <div className="mt-2">
                                <CSVLink className="btn btn-primary" filename={`billing_${selectedSchedule?.code}.csv`} data={csvBilling}>Download CSV</CSVLink>
                            </div>
                            }
                            <hr/>
                            {resKKUBill?.loading &&
                                <Alert variant="info">Loading data...</Alert>
                            }
                            <DataTable
                                title="Bill List"
                                columns={columns}
                                data={billResults}
                            />
                        </div>
                        :
                        <FullCalendar
                            ref={calendar}
                            plugins={[dayGridPlugin, interactionPlugin]}
                            initialView="dayGridMonth"
                            height="auto"
                            selectable={true}
                            select={info => {
                                console.log(info);
                            }}
                            customButtons={{
                                xToday: {
                                    text: 'Today',
                                    click: () => {
                                        let c = calendar.current.getApi();
                                        c.today();
                                        setDateFromCalendar();
                                    }
                                },
                                xPrev: {
                                    text: '< Prev',
                                    click: () => {
                                        let c = calendar.current.getApi();
                                        c.prev();
                                        setDateFromCalendar();
                                    }
                                },
                                xNext: {
                                    text: 'Next >',
                                    click: () => {
                                        let c = calendar.current.getApi();
                                        c.next();
                                        setDateFromCalendar();
                                    }
                                }
                            }}
                            headerToolbar={{
                                center: 'xToday',
                                right: 'xPrev,xNext'
                            }}
                            // dateClick={info => {
                            //     dialogLogin(info);
                            // }}
                            events={events}
                            eventContent={renderEvent}
                        />
                    }
                </CardContent>
            </Card>
        </Paper>
    </>
}
export default Billing;
