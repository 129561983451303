import {TransitionGroup} from "react-transition-group";
import {Grow} from "@material-ui/core";
import Countdown from "./countdown";
import {toast} from "react-toastify";
import {useMobxState} from "../mobx/global-context";
import {useEffect, useRef} from "react";
import {useMutation} from "@apollo/client";
import gql from "graphql-tag";
import {observer} from "mobx-react";
import Config from "../config";

const CLOSE_QUIZ = gql`
mutation CloseQuiz($regID: ID!) {
  closeQuiz(regID: $regID)
}
`

const StudentExamCountdown = ({style})=>{
    const state = useMobxState();
    const timeDiff = useRef(0);
    // const expired = useRef(false);

    const [closeQuiz] = useMutation(CLOSE_QUIZ,{
        variables:{
            regID:state?.examRegister?.id
        }
    });

    useEffect(() => {
       state.updateServerTime();
    }, []);


    return <>
        {
            state?.examScheduleRound &&
            <>
                {state?.examScheduleRound?.examStart <= state.serverTime.getTime()+timeDiff.current?
                    <>
                        <TransitionGroup>
                            {state.exam && <Grow>
                                <Countdown style={style} title="Exam close in" showDay={false} multiLine expireTimestamp={state.examScheduleRound.examEnd||0} onExpired={()=>{
                                    if(!state.examExpired){
                                        state.examExpired=true;
                                        toast.success('TIME IS UP!');
                                        closeQuiz().then(success=>{
                                            setTimeout(()=>{
                                                document.location.href=Config.root;
                                            },3000)
                                        });
                                        // expired.current=true;
                                    }
                                }}/>
                            </Grow>}
                        </TransitionGroup>
                    </>
                    :
                    <>
                        <Countdown title='Exam start in' showDay={false} multiLine expireTimestamp={state.examScheduleRound?.examStart||0}
                                   onExpired={()=>{
                                       document.location.reload();
                                   }}
                        />
                    </>
                }
            </>
        }
    </>
}
export default observer(StudentExamCountdown);
