import {ListItem, ListItemIcon, ListItemText} from "@material-ui/core";
import ScheduleIcon from "@material-ui/icons/Schedule";
import {Link, NavLink, useLocation} from "react-router-dom";
import {css, StyleSheet} from "aphrodite";
import {Nav} from "react-bootstrap";

const FrontEndTopLinkBT = ({to, exact, children, className,external,onClick}) => {
    const {pathname} = useLocation();

    function getSelected() {
        if (exact) {
            if (pathname == to) {
                return true;
            }
        } else {
            let regex = new RegExp(to, 'i');
            if (pathname.match(regex)) {
                return true;
            }
        }
        return false;
    }

    if(onClick)return <a className={'nav-link ' + css(styles.white)} href={to} onClick={onClick}>{children}</a>

    if(external){
        return <Nav.Link className={'nav-link ' + css(styles.white)} href={to}>{children}</Nav.Link>
    }
    return <NavLink {...{to}} className={'nav-link '+css(
        !getSelected()?styles.white:styles.active
    )}>{children}</NavLink>
}
export default FrontEndTopLinkBT;

const styles = StyleSheet.create({
    white:{
        fontSize:'.85rem',
        color:'#8c8c8c',
        transition:'all .3s',
        ':hover':{
            color:'#1e1e1e',
        }
    },
    active:{
        fontSize:'.85rem',
        color:'#007bff',
        transition:'all .3s',
        border:'1px solid #6bb3e2',
        borderRadius:10,
        padding:7
    },
})
