import {Button, makeStyles} from "@material-ui/core";
import {useEffect, useState} from "react";

const useStyles = makeStyles(theme => ({
    warning: {
        background: '#ff9800',
        color: 'white',
        '&:hover': {
            background: '#de8402',
        }
    },
    success: {
        background: '#4caf50',
        color: 'white',
        '&:hover': {
            background: '#439b47',
        }
    }
}))
const MButton = ({color,...props}) => {
    const classes = useStyles();
    const [colorState, setColorState] = useState();
    const [classColor, setClassColor] = useState();
    useEffect(() => {
        switch (color) {
            case 'warning':
                setClassColor(classes.warning);
                break;
            case 'success':
                setClassColor(classes.success);
                break;
            default:
                setColorState(color);
        }
    }, [])
    return <Button className={classColor} color={colorState} {...props}>{props.children}</Button>
}
export default MButton;