import Section from "../layouts/front-end/section";
import {Alert, Button, FormControl, FormGroup, FormLabel, Image, Modal} from "react-bootstrap";
import {useMobxState} from "../mobx/global-context";
import {useEffect, useState} from "react";
import {observer} from "mobx-react";
import gql from "graphql-tag";
import {useMutation, useQuery} from "@apollo/client";
import {v4 as uuidv4} from "uuid";
import XCKEditor from "../components/XCKEditor";
import React from 'react';
import _ from 'lodash';
import {toast} from "react-toastify";
import {makeStyles} from "@material-ui/core";
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CloseIcon from '@material-ui/icons/Close';
import { MdEdit } from "react-icons/md";
import {arrayMoveImmutable} from 'array-move';

const GET_FRONT_PAGE = gql`
{
  frontPage {
    id
    title
    blocks {
      id
      content
    }
    order
    lastUpdate
  }
}
`;
const SAVE_FRONT_PAGE = gql`
mutation SaveFrontPage($frontPageData: [GraphqlFrontPageInputType]) {
  saveFrontPage(frontPageData: $frontPageData) {
    success
    error
    message
  }
}
`;
const useStyle = makeStyles(theme=>({
    root:{
        '& .top-control':{
            cursor: "pointer",
            position: 'absolute',
            right: '20px',
            top: '0px',
            display:'flex',
            gap: 5,
            '& a:hover > svg':{
                color:'#fb2b47',
                transition:'all .2s ease-in'
            }
        }
    }
}))
const Home = () => {

    const state = useMobxState();
    const classes = useStyle();
    const resFrontPage = useQuery(GET_FRONT_PAGE,{
        fetchPolicy: 'cache-first'
    });
    const [mutateSave] = useMutation(SAVE_FRONT_PAGE);
    const [modal, setModal] = useState();
    const [rows, setRows] = useState([]);


    useEffect(() => {
        if (resFrontPage?.data?.frontPage) {
            setRows(_.cloneDeep(resFrontPage.data.frontPage));
        }
    }, [resFrontPage.data])

    function addRow() {
        setRows([...rows,
            {
                id: uuidv4(),
                title: '[Title]',
                blocks: [],
            }
        ])
    }

    function addBlock(row) {
        row.blocks.push({id: uuidv4(), content: '[Content]'});
        setRows([...rows]);
    }

    function isAdmin() {
        return state?.currentUser?.role == 'admin';
    }

    function up(index){
        if(index-1>=0){
            const buffers = arrayMoveImmutable(rows, index, index-1);
            setRows(buffers);
        }
    }

    function down(index){
        if(index+1<=rows.length-1){
            const buffers = arrayMoveImmutable(rows, index, index+1);
            setRows(buffers);
        }
    }

    async function save() {
        try{
            let res = await mutateSave({
                variables: {
                    frontPageData: rows.map(row=>({
                        id:row.id,
                        title:row.title,
                        blocks:row.blocks.map(block=>({
                            content:block.content
                        })),
                        order:0,
                    }))
                }
            });
            if(!res.error){
                toast.success('Saved.');
            }else{
                throw new Error(res);
            }
        }catch(e){
            toast.error(e.message);
        }
    }

    if (resFrontPage.loading) return <Alert>Loading...</Alert>
    return <div className={'container text-center '+classes.root}>
        {isAdmin() &&
        <>
            <Button onClick={addRow}>+ Add Row</Button>
            <hr/>
        </>
        }
        {rows.map((row,i) =>
            <React.Fragment key={row.id}>
                <div className="heading mt-5">
                    <div style={{position: 'relative'}}>
                        <h2 style={{cursor: isAdmin()?'pointer':'default'}} onClick={() => {
                           if(isAdmin()){
                               setModal({
                                   row,
                                   key: 'title'
                               })
                           }
                        }}>{row.title}</h2>
                        {isAdmin() &&
                        <span className='top-control'>
                            <a onClick={e => {
                                up(i);
                            }}><ExpandLessIcon/></a>
                             <a onClick={e => {
                                 down(i);
                             }}><ExpandMoreIcon/></a>
                            <a onClick={e => {
                                let tmpRows = _.reject(rows, v => v.id == row.id);
                                setRows([...tmpRows]);
                            }}><CloseIcon/></a>
                        </span>
                        }
                    </div>
                    {isAdmin() && <Button variant="secondary" className='mb-2' onClick={e => addBlock(row)}>+ Add Column</Button>}
                </div>
                <div className="line"></div>
                <div className="row">
                    {row.blocks?.map((block, index) =>
                        <div key={block.id} className="col">
                            <div className="box" style={{cursor: isAdmin()?'pointer':'default'}} onClick={() => {
                                if(isAdmin()){
                                    setModal({
                                        row,
                                        key: 'content',
                                        id: block.id,
                                        index,
                                    })
                                }
                            }}>
                                {isAdmin() &&
                                    <div style={{
                                        display:'flex',
                                        flexDirection:'row',
                                        justifyContent:'space-between',
                                    }}>
                                        <a style={{color:'#675f0a',cursor:'pointer'}} onClick={e=>{
                                            setModal({
                                                row,
                                                key: 'content',
                                                id: block.id,
                                                index,
                                            })
                                        }}>
                                            <MdEdit />
                                        </a>
                                        <a style={
                                            {
                                                cursor: "pointer",
                                                // position: 'absolute',
                                                right: '30px',
                                                color: '#dc3545',
                                                top: '10px',
                                            }
                                        } onClick={e => {
                                            e.stopPropagation();
                                            row.blocks = _.reject(row.blocks, b => b.id == block.id);
                                            setRows([...rows]);
                                        }}><CloseIcon/></a>
                                    </div>
                                }
                                <div className="text-left" dangerouslySetInnerHTML={{__html: block.content}}></div>
                            </div>
                        </div>
                    )}
                </div>
            </React.Fragment>
        )}
        {isAdmin() && rows?.length > 0 &&
            <>
                <hr/>
                <Button className="mt-2 mb-5" variant="primary" onClick={e => save()}>Save</Button>
            </>
        }
        <Modal show={!!modal} onHide={e => setModal(null)} size='lg'>
            {modal &&
                <>
                    <Modal.Header closeButton>
                    <Modal.Title>Edit front page content.</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormGroup>
                        {modal?.key == 'title' ?
                            <>
                                <FormLabel>Edit Title</FormLabel>
                                <FormControl value={modal?.row?.title} onChange={e => {
                                    let v = e.target.value;
                                    modal.row.title = v;
                                    setRows([...rows]);
                                }}/>
                            </>
                            :
                            <>
                                <FormLabel>Column {modal?.index + 1}</FormLabel>
                                <XCKEditor value={modal.row.blocks.find(b => b.id == modal.id)?.content}
                                           onChange={value => {
                                               modal.row.blocks.find(b => b.id == modal.id).content = value;
                                               setRows([...rows]);
                                           }}/>
                            </>
                        }
                    </FormGroup>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={e => setModal(null)}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={e => setModal(null)}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </>
            }
        </Modal>
    </div>
}
export default observer(Home);
