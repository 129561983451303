import * as yup from "yup";

export const LocalQuestionFormSchema = yup.object().shape({
    courseID:yup.string().required(),
    questionType:yup.string().required(),
    questions:yup.array().of(yup.object({
        id: yup.mixed(),
        name: yup.string().required(),
        title: yup.string().required(),
        detail: yup.string().required(),
        maxScore: yup.number().required(),
        metadata:yup.object({
            fileType: yup.string().required(),
            fileMaxSize: yup.number().required(),
        }).required()
    }))
});
