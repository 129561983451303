import {useApolloClient} from "@apollo/client";
import {useEffect, useState} from "react";
import {GET_CURRENT_USER} from "../gql-query/select-query";

const useSessionChecker = () => {
    const client = useApolloClient();
    const [user, setUser] = useState();
    useEffect(() => {
        let time = setInterval(async () => {
            client.query({query: GET_CURRENT_USER, fetchPolicy: "no-cache"})
                .then(res => {
                    setUser(res.data?.currentUser||null);
                })
                .catch(e => {
                    // setUser(null);
                })
        }, 30000);
        return ()=>{
            clearInterval(time);
        }
    }, []);

    return {data: user};
}
export default useSessionChecker;
