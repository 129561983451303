import {Button, Card, FormCheck, FormControl, FormGroup, FormLabel} from "react-bootstrap";
import useGradeImporterViewModel from "./view-model";
import DateTimeRangePicker from "@wojtekmaj/react-datetimerange-picker";
import GradeUploader from "./fragments/grade-uploader";

const GradeImporter = () => {
    const vm = useGradeImporterViewModel();
    return <Card>
        <Card.Body>
            <h1>Grade Importer</h1>
            <FormGroup>
                <FormLabel>Course</FormLabel>
                <FormControl as="select" onChange={e => vm.setSelectedCourse(e.target.value)}>
                    <option value=''>- Choose course -</option>
                    {vm?.courses?.map(c => {
                            return <option key={c.id} value={c.id}>{c.fullname}</option>
                        }
                    )}
                </FormControl>
            </FormGroup>
            <FormGroup>
                <FormLabel>Schedule</FormLabel>
                <FormControl as="select" onChange={e=>vm.setSelectedSchedule(e.target.value)}>
                    <option value=''>- Choose schedule -</option>
                    {vm.schedules?.map(schd => {
                            return <option key={schd.id} value={schd.id}>{schd.title}</option>
                        }
                    )}
                </FormControl>
            </FormGroup>
            <FormGroup>
                <FormLabel>Result date</FormLabel>
                <DateTimeRangePicker value={vm.resultDate} onChange={dateSet=>{
                    if(dateSet && dateSet.length===2){
                        vm.setResultDate(dateSet);
                    }else{
                        vm.setResultDate([null,null]);
                    }
                }}/>
            </FormGroup>
            <FormGroup>
                <FormCheck label='ปรับเฉพาะคนที่ไม่มีคะแนน' checked={vm.updateOnlyNullZero} onChange={e=>vm.setUpdateOnlyNullZero(old=>!old)}/>
                <div style={{margin:5}}>
                    {vm.updateOnlyNullZero?'คำสั่งตอนนี้ (เฉพาะคนที่ไม่มีคะแนน)':'คำสั่งตอนนี้ (ปรับทั้งหมด เพิ่มหรือลดคะแนนได้)'}
                </div>
            </FormGroup>
            {vm.dqQuizzes.map(q=>
                <GradeUploader readFile={vm.readFile} scoreData={vm.scoreData} key={q.id} name={q.name} dqQuizID={q.id}
                                 onRemove={e=>vm.removeDataScore(q.id)}
                />
            )}
           <Button disabled={!vm.isFormValid || vm.isSubmitting} className="mt-4" variant='primary' onClick={e=>{
               vm.submitGrade();
           }}>{vm.isSubmitting?'Loading...':'Submit'}</Button>
        </Card.Body>
    </Card>
}
export default GradeImporter;
