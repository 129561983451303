import {Box, Button, Card, CardContent, CardHeader, Grid, Paper} from "@material-ui/core";
import {observer} from "mobx-react";
import {useMobxState} from "../../mobx/global-context";
import {useEffect} from "react";
import {toJS} from "mobx";
import {Table} from "react-bootstrap";
import moment from 'moment';

const OldExamData = ()=>{

    const state = useMobxState();

    useEffect(() => {
        // console.log(toJS(state?.oldExamData));
    },[state?.oldExamData])


    function getScore(data){
        if(data.round.ModuleType==1){
            return data.score.theory
        }else{
            return data.score.workshop.map(wk=><div>{wk.type} : {wk.score}</div>)
        }
    }

    function getDate(data){
        let d = data.round.ExamDate.split(' ')?.[0];
        let s = data.round.ExamTimeStart.split(' ')?.[1];
        let e = data.round.ExamTimeEnd.split(' ')?.[1];
        d = moment(d).format('DD/MM/YYYY');
        return `${d} / ${s} - ${e}`;
    }

    return <Paper>
        <Box p={2}>
            <h3>My Exam History (EMS)</h3>
            <Box mt={4}>
                <Table striped>
                    <thead>
                    <td>#</td>
                    <td>Date</td>
                    <td>Schedule</td>
                    <td>Type</td>
                    <td>Bill</td>
                    <td>Score</td>
                    </thead>
                    <tbody>
                    {state?.oldExamData?.map((data,i)=>{
                        return <tr key={i}>
                            <td>{i+1}</td>
                            <td>{getDate(data)}</td>
                            <td>{data.scheduleDetail.SchdCode}</td>
                            <td>{data.round.ModuleType==1?'Theory':'Workshop'}</td>
                            <td>{data?.scheduleDetail?.billing?.amount || '-'}</td>
                            <td>{getScore(data)}</td>
                        </tr>
                    })}
                    </tbody>
                </Table>
            </Box>
        </Box>
    </Paper>
}
export default observer(OldExamData);
