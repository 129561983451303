import gql from "graphql-tag";
import {useQuery} from "@apollo/client";
import {Alert, Button, Card, Col, FormControl, FormGroup, Row} from "react-bootstrap";
import React, {useEffect, useRef, useState} from "react";
import {getThaiDate, getThaiDateBetween, pad2} from "../../services/client-helper";
import {Box, Paper} from "@material-ui/core";
import Clock from 'react-clock';
import moment from "moment";
import {useHistory} from "react-router-dom";
import dayGridPlugin from "@fullcalendar/daygrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
import thLocale from "@fullcalendar/core/locales/th";
import FullCalendar from "@fullcalendar/react";
import {useMobxState} from "../../mobx/global-context";
import {observer} from "mobx-react";
import CheckInViewModel from "../../view-models/check-in-view-models";
import {getAllMonth} from "../../services/helper";

const GET_CHECK_IN_COURSE = gql`
{
  courseCheckInList {
    id
    name
    idnumber
    schedules {
      id
      title
      month
      year
      registerStart
      registerEnd
      registeredCount
      rounds {
        id
        examStart
        examEnd
        quizSet {
          id
          name
          minutes
          text_color
          bg_color          
        }
        location {
          id
          name
        }
        maxSeats
        registeredCount
      }
    }
  }
}
`;
const CheckIn = () => {
    const courses = useQuery(GET_CHECK_IN_COURSE, {fetchPolicy: 'network-only'});
    // const [formData, setFormData] = useState({});
    const [dateList, setDateList] = useState([]);
    const history = useHistory();
    const calendar = useRef();
    // const [events, setEvents] = useState([]);
    // const [selectedSchedule,setSelectedSchedule] = useState();
    let {date,month,year} = CheckInViewModel().getSelectableFilterDate();
    let {setSelectedDate,setSelectedMonth,setSelectedYear,selectedDate,selectedMonth,selectedYear} = CheckInViewModel();
    const state = useMobxState();

    useEffect(() => {
        if(courses.data)updateCalendar();
        if(state.checkInFormData?.['selSchedule']!==''){
            setSelectedDate('');
            setSelectedMonth('');
            setSelectedYear('');
        }
    }, [
        courses.data,
        state.checkInFormData?.['selCourse'],
        state.checkInFormData?.['selSchedule']
    ]);

    useEffect(() => {
        if(selectedDate!=='' && selectedMonth==='' && selectedYear===''){
            state.checkInFormData['selSchedule']='';
        }
        if(!selectedDate || !selectedMonth || !selectedYear)return;
        let rounds = [];
        courses.data?.courseCheckInList
            ?.find(c => c.id == state.checkInFormData['selCourse'])
            ?.schedules
            .forEach(schd=>{
                let time = moment(`${selectedDate}/${selectedMonth}/${selectedYear}`,'DD/MM/YYYY').toDate().getTime();
                let round_in_date = schd.rounds.filter(r=>{
                    let examStartDay = moment.unix(r.examStart/1000).startOf('day').toDate().getTime()
                    return examStartDay == time;
                }).map(r=>({
                    ...r,
                    title:`${r.quizSet.name} / ${r.location.name} (${r.registeredCount||0})`,
                    scheduleName:schd.title,
                    quizSetName:r.quizSet.name,
                }))
                if(round_in_date.length>0){
                    rounds = [...rounds,...round_in_date];
                }
            })
        state.checkInEvents=rounds?.map(r => ({
            id:r.id,
            title:r.title,
            extendedProps:{
                scheduleName:r.scheduleName,
                quizSetName:r.quizSetName,
            },
            start:new Date(parseInt(r.examStart,10)),
            end:new Date(parseInt(r.examEnd,10)),
            backgroundColor:r.quizSet.bg_color,
        }||[]));
    }, [selectedDate, selectedMonth, selectedYear]);


    function renderEvent(evt){
        let {
            id,
            title,
            backgroundColor,
            textColor,
            extendedProps:{
                scheduleName,
                quizSetName,
                participantsCount,
            }
        } = evt.event;
        return <div style={{
            backgroundColor: '#e9e9e9',
            padding: 8,
            borderRadius: 10,
            // cursor:'pointer',
        }}>
            {scheduleName ?
                <div>
                    <div>{scheduleName}</div>
                    <hr/>
                    <div><span style={{
                        padding: 4,
                        backgroundColor: backgroundColor,
                        color: textColor,
                        borderRadius: 8,
                    }}>{quizSetName}</span> {title}</div>
                    <a onClick={e => e.stopPropagation()} target='_blank' className='btn btn-primary p-1 m-1'
                       href={`/v2/admin/check-in/${id}`}>View
                        ({participantsCount})</a>
                    <a onClick={e => e.stopPropagation()} target='_blank' className='btn btn-outline-primary p-1 m-1'
                       href={`/api/participants/${id}`}>Print
                        ({participantsCount})</a>
                </div>
                :
                <div>
                    <div><span>{quizSetName}</span> {title}</div>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                    }}>
                        <a onClick={e => e.stopPropagation()} target='_blank' className='btn btn-primary p-1 m-1'
                           href={`/v2/admin/check-in/${id}`}>View
                            ({participantsCount})</a>
                        <a onClick={e => e.stopPropagation()} target='_blank' className='btn btn-outline-primary p-1 m-1'
                           href={`/api/participants/${id}`}>Print
                            ({participantsCount})</a>
                    </div>
                </div>
            }
        </div>
    }

    function formController(name) {
        return {
            name,
            value: state.checkInFormData[name],
            onChange: e => {
                let {value, name} = e.target;
                switch (name) {
                    default:
                        state.checkInFormData[name]=value;
                }
            }
        }
    }


    function updateCalendar(){
        let rounds = courses.data?.courseCheckInList
            ?.find(c => c.id == state.checkInFormData['selCourse'])
            ?.schedules
            ?.find(schd => schd.id == state.checkInFormData['selSchedule'])
            ?.rounds
        let dateList = rounds?.map(r => moment(new Date(parseInt(r.examStart))).startOf('day').format('DD/MM/YYYY'));
        dateList = [...new Set(dateList)];
        // console.log(dateList);
        // setDateList(dateList);
        // for(let c of courses?.data?.courseCheckInList||[]){
        //    let scheduleObj = c?.schedules?.find(s=>s.id==formData['selSchedule']);
        //    if(scheduleObj){
        //        setSelectedSchedule(scheduleObj);
        //    }else{
        //        break;
        //    }
        // }
        // console.log('Rounds==', rounds);
        // console.log('dateList selSchedule==', dateList);
        state.checkInEvents=rounds?.map(r => ({
            id:r.id,
            title:`${r.quizSet.name} / ${r.location.name} (${r.registeredCount||0})`,
            // date:new Date(parseInt(r.examStart,10)),
            start:new Date(parseInt(r.examStart,10)),
            end:new Date(parseInt(r.examEnd,10)),
            backgroundColor:r.quizSet.bg_color,
            participantsCount: r.registeredCount||0,
        }||[]));
        if(calendar.current && rounds?.[0]){
            let c = calendar.current.getApi();
            let d = new Date(parseInt(rounds[0].examStart,10));
            c?.gotoDate(moment(d).startOf('day').toDate())
        }
    }

    if (courses.loading) return <Alert variant='info'>Loading...</Alert>
    return <>
        <h1>Examination Check-In</h1>
        <Row>
            <Col>
                <Paper>
                    <Box p={2}>
                        <FormGroup>
                            <label>Courses</label>
                            <FormControl as="select" {...formController('selCourse')}>
                                <option value=''>- Choose -</option>
                                {courses.data.courseCheckInList.map(course => {
                                    return <option key={course.id} value={course.id}>{course.name}</option>
                                })}
                            </FormControl>
                        </FormGroup>
                        {state.checkInFormData['selCourse'] &&
                        <FormGroup>
                            <label>Schedule</label>
                            <FormControl as="select" {...formController('selSchedule')}>
                                <option value=''>- Choose -</option>
                                {courses.data.courseCheckInList
                                    .find(c => c.id == state.checkInFormData['selCourse'])
                                    ?.schedules
                                    .map(schd => {
                                        return <option key={schd.id} value={schd.id}>{schd.title}</option>
                                    })}
                            </FormControl>
                        </FormGroup>
                        }
                        {state.checkInFormData['selCourse'] &&
                            <FormGroup>
                                <label>Schedules by date</label>
                                <Row>
                                    <Col>
                                        <FormControl as="select" value={selectedDate} onChange={e=>setSelectedDate(e.target.value)}>
                                            <option value=''>- Choose -</option>
                                            {date.map(v=> <option key={v} value={pad2(v)}>วันที่ {pad2(v)}</option>)}
                                        </FormControl>
                                    </Col>
                                    <Col>
                                        <FormControl as="select" value={selectedMonth} onChange={e=>setSelectedMonth(e.target.value)}>
                                            <option value=''>- Choose -</option>
                                            {month.map(v=> <option key={v} value={pad2(v)}>เดือน {getAllMonth()?.[v-1]}</option>)}
                                        </FormControl>
                                    </Col>
                                    <Col>
                                        <FormControl as="select" value={selectedYear} onChange={e=>setSelectedYear(e.target.value)}>
                                            <option value=''>- Choose -</option>
                                            {year.map(v=> <option key={v} value={v}>ปี {v}</option>)}
                                        </FormControl>
                                    </Col>
                                </Row>
                            </FormGroup>
                        }

                        {(state.checkInFormData['selCourse'] && state.checkInFormData['selSchedule']) &&
                        <Box p={2}>
                            {dateList.map((date,n) =>
                                <Card key={n}>
                                    <Card.Header><h1>{date}</h1></Card.Header>
                                    <Card.Body>

                                        <Row>
                                            {courses.data?.courseCheckInList
                                                ?.find(c => c.id == state.checkInFormData['selCourse'])
                                                ?.schedules
                                                ?.find(schd => schd.id == state.checkInFormData['selSchedule'])
                                                ?.rounds
                                                ?.filter(round => moment(new Date(parseInt(round.examStart))).startOf('day').format('DD/MM/YYYY') == date)
                                                .map(round => {
                                                    return <Col value={round.id} md={6}>
                                                        <Card style={{
                                                            boxShadow: '0 4px 24px 0 rgb(34 41 47 / 10%) !important',
                                                        }}>
                                                            <Card.Header style={{
                                                                background: round.quizSet.bg_color,
                                                                color: round.quizSet.text_color,
                                                                paddingTop: 10,
                                                                paddingBottom: 10,
                                                            }}>
                                                                {round.quizSet.name}
                                                            </Card.Header>
                                                            <Card.Body>

                                                                <Box p={2}>
                                                                    <Row>
                                                                        <Col xs={6}>
                                                                            <div className="text-center">
                                                                                {getThaiDate(round.examStart, 'time')}
                                                                            </div>
                                                                        </Col>
                                                                        <Col xs={6}>
                                                                            <div className="text-center">
                                                                                {getThaiDate(round.examEnd, 'time')}
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row className="mt-1">
                                                                        <Col xs={6}>
                                                                            <Box display='flex' justifyContent='center'>
                                                                                <Clock size={85}
                                                                                       value={new Date(parseInt(round.examStart))}/>
                                                                            </Box>
                                                                        </Col>
                                                                        <Col xs={6}>
                                                                            <Box display='flex' justifyContent='center'>
                                                                                <Clock size={85}
                                                                                       value={new Date(parseInt(round.examEnd))}/>
                                                                            </Box>
                                                                        </Col>
                                                                    </Row>
                                                                </Box>
                                                            </Card.Body>
                                                            <Card.Footer>
                                                                <Box display='flex' justifyContent='space-between'>
                                                                    <div className="text-center">
                                                                        <div>Participants</div>
                                                                        <div>{round.registeredCount}/{round.maxSeats}</div>
                                                                    </div>
                                                                    <Button variant='primary' onClick={e => {
                                                                        // history.push('/admin/check-in/' + round.id);
                                                                        window.open('/v2/admin/check-in/' + round.id, '_blank');
                                                                    }}>Check-In</Button>
                                                                </Box>
                                                            </Card.Footer>
                                                        </Card>
                                                    </Col>
                                                })}
                                        </Row>
                                    </Card.Body>
                                </Card>
                            )}
                        </Box>
                        }
                    </Box>

                    <FullCalendar
                        height='auto'
                        ref={calendar}
                        plugins={[dayGridPlugin, listPlugin, interactionPlugin]}
                        // initialView="dayGridMonth"
                        initialView="listMonth"
                        selectable={true}
                        headerToolbar={{
                            start: 'prev,next today',
                            center: 'title',
                            end: 'dayGridMonth,listMonth'
                        }}
                        select={info => {
                            // console.log(info);
                        }}
                        locale={thLocale}
                        eventClick={info=>{
                            let {id} = info.event;
                            // history.push('/admin/check-in/'+id);
                            //window.open('/v2/admin/check-in/' + id, '_blank');
                        }}
                        // dateClick={info => {
                        //     console.log(info);
                        // }}
                        events={state.checkInEvents}
                        eventContent={renderEvent}
                        // initialDate={}
                    />
                </Paper>
            </Col>
        </Row>
    </>
}
export default observer(CheckIn);
