import {Button, Col, Container, Form, FormControl, Image, Modal, Nav, Navbar, NavDropdown, Row} from "react-bootstrap";
import {Helmet} from "react-helmet";
import './style.css';
import NavbarBt from "./components/navbar-bt";
import HeroBt from "./components/hero-bt";
import {useLocation} from "react-router-dom";
import {useEffect, useState} from "react";

const Index = ({children}) => {

    const location = useLocation();
    const [navbarClass,setNavbarClass] = useState('');

    useEffect(() =>{
        // document.body.style.backgroundColor=`#ffc03b`;
        // console.log(location);
        window.onscroll = function() {
            if (window.pageYOffset > 0) {
                console.log('STATIC');
                setNavbarClass('navStatic');
            } else {
                console.log('NORMAL');
                setNavbarClass('');
            }
        };
    },[])

    return <>
        <Helmet bodyAttributes={{style: 'background-color : #ffc03b'}}/>
        <NavbarBt className={navbarClass}/>
        <HeroBt className="d-none d-lg-block" mini={location?.pathname=='/'?false:true}/>
        <Container>
            <div style={{
                // background:'linear-gradient(180deg, #ffc03b 0%, rgb(255 192 59) 36%, rgb(239 244 246) 60%)',
                borderRadius:10
            }
            }>
                {children}
            </div>
        </Container>
        <footer className="p-4 text-center" style={{backgroundColor:'#495057',color:'#fff'}}>© dq.kku.ac.th 2022. All rights reserved.</footer>
    </>
}
export default Index;
