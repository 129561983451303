import Config from "../../config";
import {Card, Col, Nav, Row} from "react-bootstrap";
import {useContext} from "react";
import StateContext from "../../mobx/global-context";
import {observer} from "mobx-react";
import {Link, NavLink} from "react-router-dom";
import {StyleSheet,css} from 'aphrodite';

const Hero = ()=>{
    const state = useContext(StateContext);
    const url = Config.frontEndPath;
    return <section className="slider d-flex align-items-center" id="slider" style={{display:'relative',overflow:'hidden'}}>
        {/*<div className={css(styles.overlay)}></div>*/}
        <div className="container">
            <div className="content">
                <div className="row d-flex align-items-center">
                    <div className="col-md-6">
                        <div className="left">
                            <h1>KKU DQ Examination</h1>
                            <p>The DQ exam is held every month except during the semester break. The opening for applications will start at the beginning of every month, around 1-5 days if not on public holidays</p>
                            <NavLink to="/my" className="btn-1">Start Exam</NavLink>
                            <Link to="/manual" className="btn-2">How to start an exam?</Link>
                        </div>
                    </div>
                    {/* Right*/}
                    <div className="col-md-6">
                        <div className="right">
                            <img src={`${Config.root}/img/exam.jpg`} alt="slider-img" className="img-fluid wow fadeInRight rounded" data-wow-offset={1} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
}

const styles = StyleSheet.create({
    overlay:{
        background:`url(${Config.root}/img/bg_overlay.png)`,
        width:'100vw',
        height:'100%',
        position:'absolute',
        left:0,
        top:0,
        backgroundPosition: 'center center',
        backgroundSize: 'cover',
        '@media(max-width:768px)':{
            width:'100vw',
        }
    }
})

export default observer(Hero);
