import {useMutation, useQuery} from "@apollo/client";
import {Alert, Button, Card, ListGroup, ListGroupItem, Table} from "react-bootstrap";
import {GET_EXAM_SCHEDULES} from "../gql-query/select-query";
import DataTable from "react-data-table-component";
import {useEffect, useState} from "react";
import moment from "moment";
import {getThaiDate} from "../services/client-helper";
import PeopleIcon from '@material-ui/icons/People';
import EditIcon from '@material-ui/icons/Edit';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import {IconButton} from "@material-ui/core";
import {useHistory} from "react-router-dom";
import AssessmentIcon from '@material-ui/icons/Assessment';
import {DELETE_COURSE_EXAM_SCHEDULE} from "../gql-query/delete-query";
import {toast} from "react-toastify";
import {confirmAlert} from "react-confirm-alert";

const ExamScheduleList = ({courseDB,handleShow})=>{

    const [schedule,setSchedule] = useState([]);
    const history = useHistory();

    const {data,loading,error,refetch} = useQuery(GET_EXAM_SCHEDULES,{
        fetchPolicy:"network-only",
        variables: {
            course:courseDB.id
        }
    });

    const [removeCourseScheduleByID] = useMutation(DELETE_COURSE_EXAM_SCHEDULE);


    const columns = [
        {
            name:'#',
            width:'50px',
            cell:(row,i)=><div>{i+1}</div>
        },
        {
            name:'Title',
            selector:'title',
            // allowOverflow:true,
            wrap:true,
            sortable:true,
        },
        {
            name:'Month',
            selector:'month',
            // wrap:true,
            // compact:true,
            width:'50px',
            sortable:true,
        },
        {
            name:'Register Start',
            selector:'registerStart',
            wrap:true,
            sortable:true,
            format:(row,index)=>getThaiDate(row.registerStart)
        },
        {
            name:'Register End',
            selector:'registerEnd',
            format:(row,index)=>getThaiDate(row.registerEnd)
        },
        {
            name:'Action',
            cell:row=><div><Button variant='info' onClick={e=>{
               handleShow(row.id);
            }}>View</Button></div>
        }
    ];

    useEffect(()=>{
        if(data) {
            setSchedule([...data.examSchedules]);
            console.log(data);
        }
    },[data]);
    
    async function removeSchedule(schedule){
        confirmAlert({
            title:'Delete course schedule',
            message:`
            Do you want to remove course schedule ${schedule.title} / ${schedule.code}
            `,
            buttons:[
                {
                    label:'Yes',
                    onClick:async ()=>{
                        try{
                            let res = await removeCourseScheduleByID({
                                variables:{
                                    scheduleID:schedule.id
                                }
                            });
                            await refetch();
                            let {error,message} = res.data?.removeCourseScheduleByID;
                            if(!error){
                                toast.success('Delete schedule success.');
                            }else{
                                console.log('message==',message);
                                throw new Error(message);
                            }
                        }catch(e){
                            toast.error(e.message);
                        }
                    }
                },
                {
                    label:'No',
                    onClick:async ()=>{}
                }
            ]
        })

    }

    if(loading)return <Alert variant='info'>Loading....</Alert>
    console.log('courseDB===', courseDB);
    return <>
        <Card>
            <Card.Body>
                {/*<DataTable*/}
                {/*    title="Exam Schedules"*/}
                {/*    columns={columns}*/}
                {/*    data={schedule}*/}
                {/*/>*/}
               <Table>
                   <thead>
                        <tr>
                            <th>Code</th>
                            <th>Schedule</th>
                            <th>Month</th>
                            <th>Action</th>
                        </tr>
                   </thead>
                   <tbody>
                   {schedule.length == 0 &&
                   <tr>
                       <td colSpan={4}><Alert variant='dark'>No Schedule found, Please create first.</Alert></td>
                   </tr>
                   }
                   {schedule.map(v=>{
                       return <tr key={v.id}>
                           <td>{v.code}</td>
                           <td>{v.title}</td>
                           <td>{v.month} / {v.year}</td>
                           <td>
                               <IconButton title='Participants' onClick={e=>{
                                   history.push(`/admin/exam-schedule/${courseDB.mdCourseID}/participants/${v.id}`)
                               }}><PeopleIcon/></IconButton>
                               <IconButton title='Edit' onClick={e=>{
                                   history.push(`/admin/exam-schedule/${courseDB.mdCourseID}/edit/${v.id}`)
                               }}><EditIcon/></IconButton>
                               <IconButton title='Remove' onClick={e=>removeSchedule(v)}><RemoveCircleIcon/></IconButton>
                               {/*<IconButton title='Report' onClick={e=>{*/}
                               {/*    history.push(`/admin/exam-schedule/${courseDB.mdCourseID}/report/${v.id}`)*/}
                               {/*}}><AssessmentIcon/></IconButton>*/}
                           </td>
                       </tr>
                   })}
                   </tbody>

               </Table>
            </Card.Body>
        </Card>
    </>
}

export default ExamScheduleList;
