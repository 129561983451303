import {
    Box, Button,
    Card,
    CardContent,
    CardHeader, Fade, makeStyles,
    Paper,
    Table, TableBody, TableCell,
    TableContainer,
    TableHead, TableRow,
    Typography
} from "@material-ui/core";
import {Alert} from "@material-ui/lab";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import thLocale from "@fullcalendar/core/locales/th";
import React, {useEffect, useRef, useState} from "react";
import clsx from "clsx";
import {useParams} from "react-router-dom";
import {useQuery} from "@apollo/client";
import {GET_COURSE_DB_WITH_ROUNDS} from "../gql-query/select-query";
import moment from "moment";
import _ from "lodash";
import {toast} from "react-toastify";
import {GrUserAdd} from 'react-icons/gr';
import listPlugin from '@fullcalendar/list';
import {BsCaretRight} from "react-icons/bs";
import RegisterStepButton from "./register-step-button";
import {BsCheckCircle} from "react-icons/bs";
import {useMobxState} from "../mobx/global-context";
import {observer} from "mobx-react";
import {autorun, runInAction} from "mobx";
import {createGroup, getRegistrationCountWithThis} from "../services/client-helper";
import RegistrationPicker from "./registration-picker";

const useStyles = makeStyles(theme => ({
    root: {
        '& .event': {
            width: '100%',
            borderRadius: 5,
            color: '#fff',
            transition: 'background-color 500ms'
        },
        '& .selected': {
            filter: 'brightness(1.2)',
            transition: 'background-color 500ms',
        }
    },

}))

const RegisterStep2 = ({courseDB, setCurrentStep,userRegistrations}) => {

    const classes = useStyles();
    const state = useMobxState();


    if(!courseDB)return <div>Loading...</div>
    return <Box p={2} className={classes.root}>

        <RegistrationPicker
            userRegistrations={userRegistrations.data?.studentRegistrations}
            currentCourse={courseDB.data.courseDBbyID}
            currentSchedule={state.registerStep2Data?.selectedSchedule}
            modules={state.registerQuizSet}
            selectedRounds={state.registerSelectedRounds}
            onSelectedRound={event=>{
                let buffers=_.reject(state.registerSelectedRounds,v=>v.quizSet.id==event.quizSet.id);
                runInAction(()=>{
                    state.registerSelectedRounds=[...buffers,event];
                });
            }}
            onRemoveRound={id=>{
                runInAction(()=> {
                    state.registerSelectedRounds = _.reject(state.registerSelectedRounds, v => v.id == id);
                });
            }}
        />
        <RegisterStepButton condition={state.registerSelectedRounds.length > 0||false} hr
                            onNext={e => setCurrentStep(3)} onBack={e => setCurrentStep(1)}/>

    </Box>
}
export default observer(RegisterStep2);
