import {ListItem, ListItemIcon, ListItemText} from "@material-ui/core";
import ScheduleIcon from "@material-ui/icons/Schedule";
import {Link, useLocation} from "react-router-dom";

const FrontEndLink = ({to, exact, children, icon}) => {
    const {pathname} = useLocation();

    function getSelected() {
        if (exact) {
            if (pathname == to) {
                return true;
            }
        } else {
            let regex = new RegExp(to, 'i');
            if (pathname.match(regex)) {
                return true;
            }
        }
        return false;
    }

    return <>
        <Link {...{to}} style={{textDecoration:'none'}}>
            <ListItem button selected={getSelected()}>
                <ListItemIcon style={{color:'#1e88e5'}}>{icon}</ListItemIcon>
                <ListItemText>{children}</ListItemText>
            </ListItem>
        </Link>

    </>
}
export default FrontEndLink;
