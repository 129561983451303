import {Alert, Badge, Button, Card, Col, Row, Table} from "react-bootstrap";
import {getThaiDateBetween} from "../../services/client-helper";
import {Box, IconButton, Paper} from "@material-ui/core";
import ModuleDisplay from "../../components/module-display";
import {useHistory, useParams} from "react-router-dom";
import XBreadcrumb from "../../components/xbreadcrumb";
import {useQuery} from "@apollo/client";
import {GET_SCHEDULE_PARTICIPANTS} from "../../gql-query/select-query";
import {useEffect, useState} from "react";
import OpenWithIcon from '@material-ui/icons/OpenWith';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Config from "../../config";
import useMoodleLogin from "../../hooks/moodle-login";
import axios from "axios";
import ParticipantsTable from "../../components/participants-table";
import {getAllMonth} from "../../services/helper";
import ScheduleDetailBlock from "../../components/schedule-detail-block";
import gql from "graphql-tag";


export const GET_SCHEDULE_PARTICIPANTS_MINI = gql`
    query ScheduleParticipant($scheduleID: ID,$filterRoundID: ID) {
        scheduleParticipant(scheduleID: $scheduleID) {
            id
            title
            month
            year
            examStart
            examEnd
            gradeDate
            scoreDate
            registeredCount
            registerStart
            registerEnd
            code
            course {
                id
                name
                fullname
                shortname
                summary
                locations {
                    id
                    name
                    address
                    maxSeats
                }
                isPaid
                freeTimes
                ssoRoles
                mdCourseID
            }
            scheduleType {
                id
                name
            }
            rounds {
                id
                examStart
                examEnd
                location {
                    id
                    name
                    maxSeats
                    address
                }
                maxSeats
                registeredCount
                quizSet {
                    id
                    name
                    minutes
                    text_color
                    bg_color
                }
                participants(filterRoundID:$filterRoundID) {
                    id
                    schedule{
                        id
                        examStart
                        examEnd
                        course{
                            id
                        }
                    }
                    scheduleRound {
                        quizSet {
                            id
                            name
                            minutes
                            text_color
                            bg_color
                        }
                    }
                    user {
                        id
                        username
                        prefix
                        fname
                        lname
                        authType
                        email
                        citizenId
                        ssoType
                        ssoRole {
                            id
                            name
                        }
                        studentID
                        faculty
                        department
                        mobile
                        fullname
                    }
                    checkIn{
                        id
                        avatarName
                        approved
                        lastUpdate
                        scheduleRoundCheckIn{
                            id
                            groupName
                            googleMeetURL
                            lastUpdate
                        }
                    }
                    examResult {
                        id
                        lang
                        quizResults {
                            attemptID
                            examStat
                            dqQuiz {
                                id
                                name
                                th {
                                    id
                                    cmid
                                    course {
                                        mdCourseID
                                    }
                                }
                                en {
                                    id
                                    cmid
                                    course {
                                        mdCourseID
                                    }
                                }
                                course {
                                    id
                                    mdCourseID
                                    fullname
                                    name
                                    shortname
                                }
                            }
                            name
                            score
                        }
                    }
                }
            }
        }
    }
`;
const ExamScheduleRoundParticipants = ()=>{
    const history = useHistory();
    const {id,scheduleID,roundID} = useParams();
    const [schedule,setSchedule] = useState();
    const [round,setRound] = useState();
    const resSchedule = useQuery(GET_SCHEDULE_PARTICIPANTS_MINI, {
        variables: {scheduleID,filterRoundID:roundID},
        fetchPolicy: "network-only",
    });

    useEffect(() =>{
        if(resSchedule.data){
            setSchedule(resSchedule.data.scheduleParticipant)
            let round = resSchedule.data.scheduleParticipant?.rounds?.find(round=>round.id==roundID);
            if(round)setRound(round);
        }
    },[resSchedule.data])
    if(resSchedule.loading || !schedule)return <Alert>Loading...</Alert>
    let {course} = schedule;
    return <>
        <XBreadcrumb data={[
            {title: `Exam Schedule: ${course?.shortname}`, url: '/admin/exam-schedule/' + id},
            {title: course.fullname,url:`/admin/exam-schedule/${id}/participants/${scheduleID}`},
            {title: getThaiDateBetween(round?.examStart,round?.examEnd,'lll')}
        ]}/>
        <Paper>

            <ScheduleDetailBlock schedule={schedule}/>
            <hr/>
            <Box p={3}>
                <Row>
                    <Col xs={12} className="pt-2">
                        <h3>Module</h3>
                        <div>
                            <ModuleDisplay quizSet={round?.quizSet}/>
                        </div>
                    </Col>
                    <Col xs={12} className="pt-2">
                        <h3>Exam date</h3>
                        <div>
                            {getThaiDateBetween(round?.examStart,round?.examEnd,'llll')}
                        </div>
                    </Col>
                    <Col xs={12} className="pt-2">
                        <h3>Participants</h3>
                        <ParticipantsTable participants={round?.participants} scheduleID={scheduleID} onSynced={()=>{
                            resSchedule.refetch();
                        }}/>
                    </Col>
                </Row>
            </Box>
        </Paper>
    </>
}
export default ExamScheduleRoundParticipants;
