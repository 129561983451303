import bg2 from "../img/bg2.png";
import {Button, Col, Image, Row} from "react-bootstrap";
import hero1 from "../img/hero1.png";
import Config from "../../../config";

const HeroBt = ({mini}) => {
    return <>
        <div id='hero' style={{
            borderBottom: '3px solid #d19c2e',
            display: 'flex', justifyContent: 'center',
            background: `url(${bg2})`,
            backgroundSize: 'cover',
            minHeight: mini ? 250 : 500,
        }} className={'mb-4'}>
            {mini ?
                <Row>
                    <Col md={12} lg={6} className="text-center">
                        <div style={{padding: 20}}>
                            <Image src={hero1} style={{maxWidth: '70%'}} fluid className="mb-4 mb-lg-2"/>
                        </div>
                    </Col>
                    <Col md={12} lg={6} className="text-center flex-vcenter">
                        <h3 style={{color: '#fff', textAlign: 'left'}}>หลักสูตรความฉลาดทางดิจิทัล Digital
                            intelligence</h3>
                    </Col>
                </Row>
                :
                <div style={{
                    right: 0,
                    padding: 20,
                }} className={'flex-vcenter'}>
                    <Row>
                        <Col md={12} lg={6} className="text-center">
                            <Image src={hero1} fluid className="mb-4 mb-lg-2"/>
                        </Col>
                        <Col md={12} lg={6}>
                            <h2 style={{color: '#fff'}} className="mb-4 text-center text-lg-left">
                                หลักสูตรความฉลาดทางดิจิทัล
                                Digital intelligence
                            </h2>
                            <div className="text-center text-lg-left">
                                {/*หลักสูตรที่สามารถเพิ่มพูนความรู้ด้านดิจิทัล การรู้เท่าทันข้อมูลสารสนเทศ*/}
                                {/*และเตรียมความพร้อมสำหรับอาชีพในอนาคตในหลากหลายสาขา*/}
                                Courses that can increase digital knowledge Information literacy and prepare for future careers in a variety of fields.
                            </div>
                            <div className="mb-5 text-center" style={{color: '#fff'}}>Free Online Study</div>
                            <div>
                                <div className="m-4 flex-center mb-4">
                                    <a className="btn btn-primary cbtn" target='_blank'
                                       href="https://x.kku.ac.th/course/index.php?categoryid=61" rel="noreferrer">Enroll To Course</a>
                                </div>
                                <Row>
                                    <Col xs={12} md={4}>
                                        <a className="btn btn-dark cbtn mb-2 mb-lg-0"
                                           style={{width: '100%'}}
                                           href={`${Config.base_url}/my/register`}

                                        >Exam Registration</a>
                                    </Col>
                                    <Col xs={12} md={4}>
                                        <a className="btn btn-dark cbtn mb-2 mb-lg-0"
                                           style={{width: '100%'}}
                                           href={`${Config.base_url}/my`}
                                        >Exam Schedule</a>
                                    </Col>
                                    <Col xs={12} md={4}>
                                        <a className="btn btn-dark cbtn mb-2 mb-lg-0"
                                           style={{width: '100%'}}
                                           href={`${Config.base_url}/my`}
                                        >Exam Result</a>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </div>
            }
        </div>

    </>
}
export default HeroBt;
