import {Box, Paper} from "@material-ui/core";
import {Alert, Badge, Button, FormControl, FormGroup, FormLabel, Modal, Table, Form} from "react-bootstrap";
import gql from "graphql-tag";
import {useApolloClient, useQuery} from "@apollo/client";
import {useEffect, useRef, useState} from "react";
import {getThaiDateBetween} from "../../services/client-helper";
import {toast} from "react-toastify";
import {CSVLink, CSVDownload} from "react-csv";
import axios from "axios";
import DoneIcon from '@material-ui/icons/Done';
import ModuleDisplay from "../../components/module-display";
import DateTimeRangePicker from "@wojtekmaj/react-datetimerange-picker";

const GET_CHECK_IN_COURSE = gql`
{
  courseExamReportList {
    id
    name
    idnumber
    schedules {
      id
      title
      month
      year
      registerStart
      registerEnd
      registeredCount
      examStart
      examEnd
      signEnabled
      rounds {
        id
        examStart
        examEnd
        quizSet {
          id
          name
          minutes
          text_color
          bg_color          
        }
        location {
          id
          name
        }
        maxSeats
        registeredCount
      }
    }
  }
}
`;
const GET_EXAM_RESULT = gql`
query ExamCourseReport($courseID: ID, $scheduleID: ID, $timeRange: [String]){
  examCourseReport(courseID: $courseID, scheduleID: $scheduleID, timeRange:$timeRange)
}
`;
const ExamReport = () => {
    const resResultList = useQuery(GET_CHECK_IN_COURSE, {fetchPolicy: 'network-only'});
    const apolloClient = useApolloClient();
    const [selectedCourseID, setSelectedCourseID] = useState();
    const [selectedScheduleID, setSelectedScheduleID] = useState();
    const [examResults, setExamResults] = useState([]);
    const [csvDataReg, setCsvDataReg] = useState([]);
    const [csvDataSign, setCsvDataSign] = useState([]);
    const [csvDataCertificate, setCsvDataCertificate] = useState([]);
    const [modalToken, setModelToken] = useState();
    const [isProcessing, setIsProcessing] = useState(false);

    const modal = useRef();
    const timer = useRef();
    const [progression,setProgression] = useState('');
    const [downloadLink,setDownloadLink] = useState('');
    const [useTimeToSelect,setUseTimeToSelect] = useState(false);
    const [selectedTimeRange,setSelectedTimeRange] = useState();

    useEffect(() => {
        setSelectedScheduleID(null);
    }, [selectedCourseID])

    useEffect(()=>{
        console.log('selectedTimeRange==',selectedTimeRange);
    },[selectedTimeRange])

    useEffect(() => {
        setExamResults([]);
    }, [selectedScheduleID])

    useEffect(()=>{
        clearInterval(timer.current);
        if(isProcessing){
            timer.current = setInterval(async ()=>{
                let p = await axios.get('/api/report-progression');
                setProgression(p.data.progression);
            }, 3000);
        }
    },[isProcessing])

    async function process() {
        setIsProcessing(true)
        try {
            let variables = useTimeToSelect && !!selectedTimeRange?
                {
                    courseID: selectedCourseID,
                    timeRange:selectedTimeRange,
                }
                :
                {
                    courseID: selectedCourseID,
                    scheduleID: selectedScheduleID,
                }
            let results = await apolloClient.query({
                query: GET_EXAM_RESULT,
                variables,
                fetchPolicy: 'network-only'
            });
            // let {examCourseReport} = results?.data
            // if(!examCourseReport){
            //     throw new Error('No result found.');
            // }
            setExamResults([]);
            // generateCSV(examCourseReport);
            toast.success('Results loaded.')
            setProgression('finished');
            let dlLink = `/api/report-file/${results?.data?.examCourseReport}`;
            setDownloadLink(dlLink)
        } catch (e) {
            console.log(e);
            toast.error(e.message);
        } finally {
            setIsProcessing(false);
        }
    }

    function generateCSV(examCourseReport) {
        const csvDataReg = examCourseReport.filter(r => r.user.studentID && r.isPassed).map(r => [r.user.studentID, 'S AU'])

        const csvDataSign = [
            ["ลำดับ", "รหัส", "ชื่อ-นามสกุล", "คณะ/สาขา"],
            ...examCourseReport.filter(r => r.user.studentID && r.isPassed).map((r, i) => [
                i + 1, r.user.studentID, r.user.fullname, `${r.user.faculty}${r.user.department && r.user.department!='-' ? ' / ' + r.user.department : ''}`
            ])
        ];
        setCsvDataReg(csvDataReg);
        setCsvDataSign(csvDataSign);
        setCsvDataCertificate(examCourseReport.filter(r => r.isPassed));
    }

    async function digitalSignToggle() {
        let digitalSignToggle = gql`
            mutation DigitalSignToggle($scheduleID: ID) {
              digitalSignToggle(scheduleID: $scheduleID)
            }
        `;
        await apolloClient.mutate({
            mutation: digitalSignToggle,
            variables: {
                scheduleID: selectedScheduleID
            },
            refetchQueries: [
                {
                    query: GET_CHECK_IN_COURSE,
                }
            ]
        })
    }

    function isDigitalSignEnabled() {
        if (selectedCourseID && selectedScheduleID) {
            let course = resResultList?.data?.courseExamReportList?.find(c => c.id == selectedCourseID);
            let schedule = course?.schedules?.find(schd => schd.id == selectedScheduleID);
            return schedule?.signEnabled;
        }
        return false;
    }

    async function generateCertificatedZip() {
        try {
            let resToken = await axios.get(`https://dq.kku.ac.th/api/generate-certificated-token/${selectedCourseID}/${selectedScheduleID}`)
            let url = `https://dq.kku.ac.th/api/generate-certificated/${resToken.data?.token}`;
            setModelToken(url);
        } catch (e) {
            toast.error(e.message);
        }
    }


    if (resResultList.loading) return <Alert>Loading...</Alert>
    return <Paper>
        <Box p={2}>
            <h3>Course exam result processor.</h3>
            <FormGroup>
                <FormLabel>Course</FormLabel>
                <FormControl as='select'
                             value={selectedCourseID || ''}
                             onChange={e => setSelectedCourseID(e.target.value)}
                >
                    <option value=''>- Choose Course -</option>
                    {resResultList.data.courseExamReportList.map(course =>
                        <option key={course.id} value={course.id}>{course.name}</option>
                    )}
                </FormControl>
            </FormGroup>

            {selectedCourseID &&
            <>
                <FormGroup>
                    <Form.Check id="useTimeToSelect" type="checkbox" value={useTimeToSelect} label="Use time select" onChange={e=>setUseTimeToSelect(old=>!old)} />
                </FormGroup>
                {useTimeToSelect?
                 <DateTimeRangePicker
                     value={selectedTimeRange}
                    onChange={setSelectedTimeRange}
                 />
                    :
                    <FormGroup>
                        <FormLabel>User is in schedule</FormLabel>
                        <FormControl as='select' value={selectedScheduleID || ''}
                                     onChange={e => setSelectedScheduleID(e.target.value)}>
                            <option value=''>- Choose Schedule -</option>
                            {resResultList.data.courseExamReportList.find(v => v.id == selectedCourseID)?.schedules.map(schd =>
                                <option key={schd.id} value={schd.id}>{schd.title} -
                                    ({getThaiDateBetween(schd.examStart, schd.examEnd, 'date-time')})</option>
                            )}
                        </FormControl>
                    </FormGroup>
                }
            </>
            }
            {(selectedCourseID && (selectedScheduleID || (selectedTimeRange && useTimeToSelect))) &&
            <Button disabled={isProcessing} className="mt-1 mr-2" variant="primary" onClick={e => process()}>
                {isProcessing ? 'Processing...' : 'Process'}
            </Button>}
            {examResults.length > 0 &&
            <Box>
                <CSVLink filename={'course_result_export_for_sign.csv'} data={csvDataSign}>
                    <Button disabled={csvDataSign.length<2} className="mt-1 mr-2" variant="secondary" onClick={e => process()}>Export for Signature sign
                        (.csv)</Button>
                </CSVLink>
                <CSVLink filename={'course_result_export_for_kku_reg.csv'} data={csvDataReg}>
                    <Button disabled={csvDataReg.length<1} className="mt-1 mr-2" variant="info" onClick={e => process()}>Export for KKU REG
                        (.csv)</Button>
                </CSVLink>
                {isDigitalSignEnabled() ?
                    <Button className="mt-1 mr-2" variant='outline-success' onClick={e => digitalSignToggle()}>
                        Enabled digital signature sign
                    </Button>
                    :
                    <Button disabled={csvDataCertificate.length<1} className="mt-1 mr-2" onClick={e => digitalSignToggle()}>
                        Enable digital signature sign
                    </Button>
                }

                {/*<Button className="mt-1 mr-2" onClick={e=>generateCertificatedZip()}>Certificated for digital signature (Link)</Button>*/}
            </Box>
            }
        </Box>
        {examResults.length > 0 ?
            <Table>
                <thead>
                <tr>
                    <th>User</th>
                    <th>Faculty</th>
                    <th>Result</th>
                    <th width="200">Course state</th>
                </tr>
                </thead>
                <tbody>
                {examResults.map(r => {
                        return <tr key={r.user.id}>
                            <td>{r.user.fullname}</td>
                            <td>{r.user.faculty}{r.user.department != '-' ? r.user.department : ''}</td>
                            <td>
                                <Box style={{maxHeight: 200, overflow: 'scroll'}}>
                                    <Table>
                                        <thead>
                                        <tr>
                                            <th width="180">Schedule</th>
                                            <th>Module</th>
                                            <th>Score</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {r.registers.map(reg => {
                                            let quiz_mode = reg.scheduleRound.quizSet.quiz_mode;
                                            // let {lang} = reg.examResult;
                                            return <>
                                            {quiz_mode=='rnd'?
                                                <tr key={reg.id}>
                                                    <td>{reg.schedule.title}</td>
                                                    <td><ModuleDisplay quizSet={reg.scheduleRound.quizSet}/></td>
                                                    <td>
                                                        {reg.examResult?.quizResults?.[0]?.score ?
                                                            <Badge style={{fontWeight:500}}
                                                                variant={reg.examResult.isPassed ? 'success' : 'danger'}>{reg.examResult?.quizResults?.[0]?.score}%</Badge>
                                                            :
                                                            '-'
                                                        }
                                                    </td>
                                                </tr>
                                                :
                                                <tr key={reg.id}>
                                                    <td>{reg.schedule.title}</td>
                                                    <td><ModuleDisplay quizSet={reg.scheduleRound.quizSet}/></td>
                                                    <td>
                                                        {!reg.examResult && '-'}
                                                        {reg.examResult?.quizResults.map(qr=>{
                                                            return <>
                                                                {qr?.score ?
                                                                    <Badge style={{fontWeight:500,margin:3}}
                                                                        variant={reg.examResult.isPassed ? 'success' : 'danger'}>{qr.dqQuiz?.name} : {qr?.score}%</Badge>
                                                                    :
                                                                    '-'
                                                                }
                                                            </>
                                                        })}

                                                    </td>
                                                </tr>
                                            }
                                            </>
                                        })}
                                        </tbody>
                                    </Table>
                                </Box>
                            </td>
                            <td>{
                                r.isPassed ?
                                    <>
                                        <Alert variant="success" className="text-center">{/*<DoneIcon/>*/}PASSED</Alert>
                                        {r?.certificated?.signed &&
                                        <Alert variant="success" className="text-center">{/*<DoneIcon/>*/}CERTIFICATED</Alert>
                                        }
                                    </>
                                    :
                                    <Alert variant="danger">FAILED</Alert>
                            }</td>
                        </tr>
                    }
                )}
                </tbody>
            </Table>
            :
            <>
                {selectedCourseID && (selectedScheduleID || selectedTimeRange) &&
                 <>
                     {isProcessing ?
                        <Alert variant="info">Processing please wait... {progression}</Alert>
                        :
                        <Alert variant="light">
                            {
                                downloadLink?
                                <a href={downloadLink} target='_blank'>Download report</a>
                                :
                                <Alert variant="warning">No result found.</Alert>
                            }
                        </Alert>
                    }
                 </>
                }
            </>
        }
        <Modal show={!!modalToken} onHide={e => setModelToken(null)} onShow={() => {
            modal.current?.select();
        }}>
            <Modal.Header closeButton>
                <Modal.Title>Generated Certificated.</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <FormGroup>
                    <FormControl ref={modal} as="textarea" readOnly rows={8} value={modalToken}
                                 onClick={e => e.target.select()}/>
                </FormGroup>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={e => setModelToken(null)}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    </Paper>
}
export default ExamReport;
