import {Breadcrumb} from "react-bootstrap";
import {Link} from "react-router-dom";
import React from 'react';
import {Box} from "@material-ui/core";

const XBreadcrumb = ({data})=>{
    return <Box>
        <Breadcrumb className="mb-2">
            {data.map((v,i)=>
            <React.Fragment key={i}>
                {v.url?
                    <li className="breadcrumb-item"><Link to={v.url}>{v.title}</Link></li>
                    :
                    <Breadcrumb.Item active>{v.title}</Breadcrumb.Item>
                }
            </React.Fragment>
            )}
        </Breadcrumb>
    </Box>
}
export default XBreadcrumb
