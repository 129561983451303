import {
    Alert, Badge,
    Button, Card,
    Col,
    Form,
    FormControl,
    FormGroup, FormLabel,
    ListGroup,
    ListGroupItem,
    Row,
    Tab,
    Tabs
} from "react-bootstrap";
import {Box, Typography} from "@material-ui/core";
import {CourseSettingHeadBar} from "../page/admin/exam-schedule-course-setting";
import {getMongooseID} from "../services/requester";
import {useEffect, useState} from "react";
import {registerInputWithSchema, showInputError} from "../services/client-helper";
import {dqQuizSchema} from "../yup-validators/course-form-schema";
import _ from 'lodash';
import {useQuery} from "@apollo/client";
import {GET_ALL_FILTERS} from "../gql-query/select-query";

const CourseMultipleLangQuizSetup = ({courseForm, quizzes, updateUI}) => {

    const [errors, setErrors] = useState({});
    const filters = useQuery(GET_ALL_FILTERS,{
        fetchPolicy:'network-only'
    })
    
    useEffect(()=>{
        console.log('lang quizzes===',quizzes);
    },[]);


    function addDQQuizzes() {
        return new Promise(async resolve => {
            if (!courseForm.dqQuizzes) courseForm.dqQuizzes = [];
            let id = await getMongooseID();
            courseForm.dqQuizzes=[...courseForm.dqQuizzes,{
                id,
                name: '',
                th: '',
                en: '',
            }]
            updateUI();
            resolve();
        })
    }

    function register(dqQuiz, key) {
        return {
            value: dqQuiz[key],
            onChange: (e) => {
                let v = e.target.value;
                dqQuiz[key] = v;
                updateUI();
            }
        }
    }

    function removeQuiz(id){
        courseForm.dqQuizzes=_.reject(courseForm.dqQuizzes,q=>q.id==id);
        courseForm.dqQuizzes=_.reject(courseForm.dqQuizzes,q=>q.id==id);
        courseForm.removed.push({key:'dqQuizzes',id});
        updateUI()
    }

    function dqQuizInputWithSchema(objRef,keyName,fnUpdateUI,formSchema,errors,fnSetError){
        return {
            name:keyName,
            value:objRef[keyName] || '',
            onChange:e=>{
                let v = e.target.value;
                objRef[keyName]=v;
                fnUpdateUI();
                formSchema.validateAt(keyName,objRef,{abortEarly:true})
                    .then(data=>{
                        fnSetError({...errors,[`${objRef.id}-${keyName}`]: void 0})
                    })
                    .catch(e=>{
                        fnSetError({...errors,[`${objRef.id}-${keyName}`]: e.message})
                    })
            }
        }
    }

    function getQuizzes(lang) {
        return <>
            <h4>Quiz Language: {lang=='th'?<Badge variant='primary'>TH</Badge>:<Badge variant='danger'>EN</Badge>}</h4>
            {courseForm?.dqQuizzes?.map((q, i) =>{
                return <Row key={q.id}>
                    <Col>
                        <Card bg={lang=='th'?'primary':'danger'}>
                            <Card.Body>
                                <Row>
                                    <Col md={3}>
                                        <FormGroup>
                                            <FormLabel style={{color:'white'}}>({i + 1}) Quiz Name</FormLabel>
                                            <FormControl {...registerInputWithSchema(q,
                                                'name',
                                                updateUI,
                                                dqQuizSchema,
                                                errors,
                                                setErrors
                                            )}/>
                                            {showInputError(q, 'name', errors)}
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup>
                                            <FormLabel style={{color:'white'}}>Moodle Quiz</FormLabel>
                                            <FormControl as='select' {...dqQuizInputWithSchema(q,
                                                lang,
                                                updateUI,
                                                dqQuizSchema,
                                                errors,
                                                setErrors
                                            )}>
                                                <option value=''>- Select Quiz -</option>
                                                {quizzes.map(q =>
                                                    <option key={q.id} value={q.id}>{q.name} / Grade >= {q.passGrade}%</option>
                                                )}
                                                <option value='dq-workshop'>DQ Workshop Quiz Score >= 35</option>
                                            </FormControl>
                                            {showInputError(q, lang, errors)}
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs='auto' className='my-auto'>
                        <Button variant='secondary' onClick={removeQuiz.bind(this,q.id)}>X</Button>
                    </Col>
                </Row>
            })}
        </>
    }

    if (!quizzes) return <Alert>Loading...</Alert>
    return <div>
        <CourseSettingHeadBar title='(4) DQ Multiple language Quiz Setup' onClick={addDQQuizzes.bind(this)}/>
        <Card>
            <Card.Body>
                {filters.loading?
                    <div>Loading...</div>
                    :
                    <>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <FormLabel>EN lang condition</FormLabel>
                                    <FormControl value={courseForm.langFilterField || ''} as="select" onChange={e=>{
                                        courseForm.langFilterField=e.target.value;
                                        updateUI()
                                    }}>
                                        <option value=''>- Field -</option>
                                        {filters.data?.langFilter.map(v=>
                                            <option key={v.field} value={v.field}>{v.field}</option>
                                        )}
                                    </FormControl>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <FormLabel>RegExp Filter</FormLabel>
                                    <FormControl value={courseForm.langFilterValue || ''} type="text" onChange={e=>{
                                        courseForm.langFilterValue=e.target.value;
                                        updateUI()
                                    }}/>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Alert variant="secondary">
                                    {filters.data?.langFilter.find(v=>v.field==courseForm.langFilterField)?.detail || 'Please select field.'}
                                </Alert>
                            </Col>
                        </Row>
                    </>
                }
                <hr/>
                {courseForm?.dqQuizzes?.length > 0 ?
                    <Tabs>
                        <Tab eventKey="th" title="TH">
                            <Card>
                                <Card.Body>
                                    {getQuizzes('th')}
                                </Card.Body>
                            </Card>
                        </Tab>
                        <Tab eventKey="en" title="EN">
                            <Card>
                                <Card.Body>
                                    {getQuizzes('en')}
                                </Card.Body>
                            </Card>
                        </Tab>
                    </Tabs>
                    :
                    <Alert variant='danger'>Please create new one.</Alert>
                }
            </Card.Body>
        </Card>
    </div>
}
export default CourseMultipleLangQuizSetup;
