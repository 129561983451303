import {
    Alert,
    Badge,
    Button,
    Card,
    Col,
    FormControl,
    FormGroup,
    FormLabel,
    Modal,
    Nav,
    Row,
    Table
} from "react-bootstrap";
import {useQuery} from "@apollo/client";
import {GET_STUDENTS, SEARCH_USER} from "../../gql-query/select-query";
import DataTable from 'react-data-table-component';
import {useEffect, useRef, useState} from "react";
import {createGroup, stripTags} from "../../services/client-helper";
import clsx from "clsx";
import React from 'react';
import AdminStudentDetail from "../../components/admin-student-detail";

const Student = () => {
    // const {loading:loadingStudent,data:dataStudent,refetch:refectStudent}=useQuery(GET_STUDENTS);
    const [modalShow, setModalShow] = useState(false);
    const [selectedStudent, setSelectedStudent] = useState();
    const [searchText, setSearchText] = useState('');
    const [_searchText, _setSearchText] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const searchTimerRate = useRef();
    const [currentTab,setCurrentTab] = useState('score');

    const searchStudent = useQuery(SEARCH_USER, {
        variables: {
            text: _searchText
        },
        fetchPolicy: "network-only",
        skip: _searchText == '' ? true : false,
    });

    useEffect(() => {
        return () => {
            clearTimeout(searchTimerRate.current);
        }
    }, [])

    useEffect(() => {
        if (searchStudent.data) {
            console.log(searchStudent.data);
            setSearchResults(searchStudent.data.searchUser.map(user => {
                return {
                    id:user.id,
                    sourceType:user.sourceType,
                    studentID: user.studentID,
                    fullname: `${user.fname} ${user.lname}`,
                    email: user.email,
                    username: user.username,
                    examCount: user.oldStudentSchedule?.length>0?
                        `${user.oldStudentSchedule?.length || 0} / Times`:'- Never -',
                    oldStudentSchedule: user.oldStudentSchedule,
                    faculty: user.faculty||'- unknown -',
                    wallet:user.wallet,
                }
            }))
        }
    }, [searchStudent.data])

    const columns = [
        {
            name: '#',
            width: '50px',
            cell: (row, i) => <div>{i + 1}</div>
        },
        {
            name: 'StudentID',
            selector: 'studentID',
            allowOverflow: true,
            sortable: true,
        },
        {
            name: 'Fullname',
            selector: 'fullname',
            wrap: true,
            compact: true,
            sortable: true,
        },
        {
            name: 'Email',
            selector: 'email',
            wrap: true,
            sortable: true,
        },
        {
            name: 'Faculty',
            selector: 'faculty',
            wrap: true,
            sortable: true,
        },
        {
            name: 'Action',
            cell: row => <div><Button onClick={e => {
                console.log(row);
                setSelectedStudent(row);
            }}>View</Button></div>
        }
    ];

    useEffect(() => {
        if (selectedStudent) {
            setModalShow(true);
        } else {
            setModalShow(false);
        }
    }, [selectedStudent])

    function onSearch(e) {
        clearTimeout(searchTimerRate.current);
        let v = e.target.value;
        setSearchText(v);
        searchTimerRate.current = setTimeout(() => {
            console.log('Request search');
            v !== '' && _setSearchText(v);
        }, 500);
    }

    function billExistedData(){
        if(!selectedStudent)return [];
        let hasBill = selectedStudent?.oldStudentSchedule?.filter(v=>!!v.scheduleDetail.billing)
        return createGroup(hasBill,'scheduleDetail.SchdCode');
    }

    return <>
        <Card>
            <Card.Body>
                <h1>Students</h1>
                <div className="p-2">
                    <FormGroup>
                        <FormLabel>Search By (Name, Email, ID)</FormLabel>
                        <FormControl type="text" value={searchText} onChange={onSearch}/>
                    </FormGroup>
                </div>
                {searchResults.length>0 &&
                    <DataTable
                        title="Student List"
                        columns={columns}
                        data={searchResults}
                    />
                }
            </Card.Body>
        </Card>
        <Modal
            size={'xl'}
            show={modalShow}
            onHide={() => {
                setSelectedStudent(void 0)
            }}
        >
            <Modal.Header closeButton>
                <h3>Student detail</h3>
            </Modal.Header>
            <Modal.Body>
                {selectedStudent &&
                    <AdminStudentDetail student={selectedStudent}/>
                }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="light" onClick={e=>setSelectedStudent(void 0)}>Close</Button>
            </Modal.Footer>
        </Modal>
    </>
}
export default Student;
