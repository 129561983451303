import axios from "axios";
import {Alert, Card} from "react-bootstrap";
import {locationFormSchema} from "../yup-validators/course-form-schema";
import objectPath from 'object-path';

export function onHelmetReady(newState, addedTags, removedTags, callback) {
    if(Object.keys(addedTags).length===0)return;
    let loaded = 0;
    let waitTime=3;//sec
    let timer=setTimeout(()=>{
        if(typeof callback == 'function')callback();
        clearTimeout(timer);
    },waitTime*1000);
    if (addedTags && Array.isArray(addedTags.linkTags)) {
        let finishedCheck = ()=>{
            if (loaded == addedTags.linkTags.length) {
                if(typeof callback == 'function') {
                    callback();
                    clearTimeout(timer);
                }
            }
        }
        addedTags.linkTags.map(tag => {
            if(tag.rel!='stylesheet'){
                loaded++;
                finishedCheck();
            }
            tag.onload = () => {
                loaded++;
                finishedCheck();
            }
        })
    }
}

export function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}


export function getAllMonth(){
    return [
        'มกราคม',
        'กุมภาพันธ์',
        'มีนาคม',
        'เมษายน',
        'พฤษภาคม',
        'มิถุนายน',
        'กรกฎาคม',
        'สิงหาคม',
        'กันยายน',
        'ตุลาคม',
        'พฤศจิกายน',
        'ธันวาคม',
    ]
}

export function getYearList(th=true){
    let date = new Date();
    let year = date.getFullYear()-3;
    if(th)year=year+543;
    let results=[];
    for(let y of [...Array(6)]){
        results.push(year++)
    }
    return results;
}

/**
 *
 * @param tagName
 * @param attr script,link
 * @return {Promise<unknown>}
 */
export function createTag(tagName,attr={},appendTo='body'){
    return new Promise(resolve => {
        let script = document.createElement(tagName);
        script.async = true;
        for(let k in attr){
            script[k]=attr[k];
        }
        if(appendTo==='body'){
            document.body.appendChild(script);
        }else if(appendTo==='head'){
            document.head.appendChild(script);
        }
        script.onload=()=>{
            resolve()
        };
    });
}

export function getIDNumberWithCourseName(course) {
    return (course.idnumber ? `${course.idnumber} :: ` : '')+course.fullname
}

export function getCourseQuizFromContent(courseContent) {
    let quizzes=[];
    for(let topic of courseContent){
        for(let module of topic.modules.filter(v=>v.modname=='quiz')){
            quizzes.push(module);
        }
    }
    return quizzes;
}



