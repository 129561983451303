import {ListItem, ListItemIcon, ListItemText} from "@material-ui/core";
import ScheduleIcon from "@material-ui/icons/Schedule";
import {Link, NavLink, useLocation} from "react-router-dom";
import {css, StyleSheet} from "aphrodite";

const FrontEndTopLink = ({to, exact, children, className}) => {
    const {pathname} = useLocation();

    function getSelected() {
        if (exact) {
            if (pathname == to) {
                return true;
            }
        } else {
            let regex = new RegExp(to, 'i');
            if (pathname.match(regex)) {
                return true;
            }
        }
        return false;
    }

    return <NavLink {...{to}} className={'nav-link '+css(
        !getSelected()?styles.white:styles.active
    )}>{children}</NavLink>
}
export default FrontEndTopLink;

const styles = StyleSheet.create({
    white:{
        color:'white',
        transition:'all .5s',
        ':hover':{
            color:'#10d596',
        }
    },
    active:{
        color:'white',
        transition:'all .5s',
        border:'1px solid #6bb3e2',
        borderRadius:10,
        padding:10
    },
})
