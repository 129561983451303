import {useState} from "react";
import axios from "axios";
import Config from "../config";
import {toast} from "react-toastify";

export default function CheckInViewModel() {

    const [selectedDate, setSelectedDate] = useState('');
    const [selectedMonth, setSelectedMonth] = useState('');
    const [selectedYear, setSelectedYear] = useState('');

    function getSelectableFilterDate() {
        let y = new Date().getFullYear();
        return {
            date: [...new Array(31)].map((v, i) => i + 1),
            month: [...new Array(12)].map((v, i) => i + 1),
            year: [...new Array(10)].map((v, i) => (y + 1) - i)
        }
    }

    /**
     * Unsubmits a check-in.
     * @param {GraphQLCheckInType} checkIn - The check-in to unsubmit.
     */
    function unSubmit(checkIn,mode){
        axios.post(`${Config.api_url}/un-submit`,{checkInID:checkIn.id,mode}).then(res=>{
            console.log(res.data);
            let text = res.data.map((q,i)=>`${i+1} ) ${q.name}`).join("\n");
            let qIndex = prompt(text);
            if(qIndex===null) return;
            qIndex = parseInt(qIndex)-1;
            if(qIndex<0 || qIndex>=res.data.length){
                toast.error('Invalid index!');
            }else{
                let quiz = res.data[qIndex];
                console.log(quiz);
                axios.post(`${Config.api_url}/un-submit`,{checkInID:checkIn.id,quiz,mode:'action'}).then(res=>{
                    console.log(res.data);
                    toast.success('Unsubmitted successfully!');
                }).catch(err=>{
                    console.log(err);
                    toast.error('Error occurred!');
                })
            }
        }).catch(err=>{
            console.log(err);
        })
    }

    return {
        getSelectableFilterDate,
        setSelectedDate,
        selectedDate,
        selectedMonth,
        selectedYear,
        setSelectedMonth,
        setSelectedYear,
        unSubmit,
    }
}
