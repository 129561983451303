import {Badge, Button, ButtonGroup, Card, Col, FormLabel, Image, Nav, Row, Table} from "react-bootstrap";
import {createGroup, getThaiDateBetweenSingleDay, stripTags} from "../services/client-helper";
import clsx from "clsx";
import React, {useEffect, useState} from "react";
import {useMutation, useQuery} from "@apollo/client";
import ModuleDisplay from "./module-display";
import StudentProgression from "./student-progression";
import {GET_STUDENT_REGISTRATIONS, OLD_EMS_USER} from "../gql-query/select-query";
import {DELETE_SCHEDULE} from "../gql-query/delete-query";
import {toast} from "react-toastify";
import {confirmAlert} from "react-confirm-alert";
import AdminChangeSchedule from "./admin-change-schedule";
import {useMobxState} from "../mobx/global-context";
import {toJS} from "mobx";

const AdminStudentDetail = ({student}) => {

    const [currentTab, setCurrentTab] = useState('score');
    const [scheduleChange, setScheduleChange] = useState();
    const [groupedDQRegCourse, setGroupedDQRegCourse] = useState([]);
    const [isPassed,setIsPassed] = useState(false);
    const state = useMobxState();
    const {data:regData,loading:regLoading,refetch:regRefetch} = useQuery(GET_STUDENT_REGISTRATIONS, {
        fetchPolicy: 'network-only',
        variables: {
            userID:student.id
        },
        skip:student.sourceType!='user'
    });
    const resOldEmsStudent = useQuery(OLD_EMS_USER,{
        fetchPolicy:'network-only',
        variables:{
            username:student.username
        },
    });
    const [deleteReg] = useMutation(DELETE_SCHEDULE,{
        refetchQueries:[
            {
                query:GET_STUDENT_REGISTRATIONS,
                variables: {
                    userID:student.id
                },
            }
        ]
    }) ;


    useEffect(()=>{
        if(regData){
            let groupedRegCourse = createGroup(regData.studentRegistrations,'course.id');
            setGroupedDQRegCourse(groupedRegCourse);
            console.log('groupedReg==',groupedRegCourse);
        }
    },[regData])

    useEffect(() =>{
        console.log('student==',student);
    },[]);

    async function onDelete(reg){
        console.log('REG=',reg);
        try{
            let res = await deleteReg({
                variables:{
                    id:reg.schedule.id,
                    regID:reg.id,
                    userID:student.id,
                }
            })
            if(res?.data?.deleteSchedule){
                toast.success('Delete success.');
            }else{
                toast.error('Delete failed.');
            }
        }catch(e){
            toast.error(e.message);
        }
    }

    return <>
        <Card className="mb-1">
            <Card.Body>
                <h3>Profile</h3>
                <div className="p-2">
                    <Row>
                        <Col xs='auto'>
                            {student.sourceType=='user'?
                                <Image src={`https://dq.kku.ac.th/api/profile_picture/${student.studentID}`} style={{borderRadius:'1rem !important'}} rounded fluid/>
                                :
                                <div style={{width:150,textAlign:'center'}}>No picture</div>
                            }
                        </Col>
                        <Col>
                            <Row>
                                <Col md={6} className="mb-1 mb-md-1">
                                    <strong>StudentID</strong>
                                    <div>{student.studentID}</div>
                                </Col>
                                <Col md={6} className="mb-1 mb-md-1">
                                    <strong>Full Name</strong>
                                    <div>{student.fullname}</div>
                                </Col>
                                <Col md={6} className="mb-1 mb-md-1">
                                    <strong>Username</strong>
                                    <div>{student.username}</div>
                                </Col>
                                <Col md={6} className="mb-1 mb-md-1">
                                    <strong>Faculty</strong>
                                    <div>{student.faculty}</div>
                                </Col>
                                <Col md={6} className="mb-1 mb-md-1">
                                    <strong>Email</strong>
                                    <div>{student.email}</div>
                                </Col>
                                <Col md={6} className="mb-1 mb-md-1">
                                    <strong>Wallet</strong>
                                    <div>{student.wallet}</div>
                                </Col>
                                {isPassed &&
                                    <Col md={12} className="mb-1 mb-md-1">
                                        <strong>DQ Transcript</strong>
                                        <div>
                                            <a href={'/transcript/'+student.studentID} target={'_blank'} className={'btn btn-primary full-width'}>View</a>
                                        </div>
                                    </Col>
                                }
                            </Row>
                        </Col>
                    </Row>

                </div>
                <hr/>
                <Row>
                    <Col>
                        <h3>Progressions</h3>
                        {!regLoading &&
                            <div style={{maxHeight: 500, overflowY: 'auto'}}>
                                <StudentProgression title=""
                                         userID={student.id}
                                         calendar={false}
                                                    onLoaded={data=>{
                                                        let progression = data || [];
                                                        for(let prog of progression){
                                                            if(prog.course.id==='60e1b2c52d03f332a5dfbaab'){
                                                                if(prog.pass){
                                                                    setIsPassed(true);
                                                                }
                                                            }
                                                        }
                                                        console.log('state.studentProgression==',progression);
                                                    }}
                                />
                            </div>
                        }
                    </Col>
                </Row>
            </Card.Body>
        </Card>

        {!!scheduleChange ?
            <Card>
                <Card.Body>
                    <AdminChangeSchedule
                        register={scheduleChange}
                        onSuccess={async ()=>{
                            toast.success('Saved change.');
                            setScheduleChange(void 0);
                             await regRefetch();
                        }}
                        onError={(msg)=>{
                            toast.error(msg);
                        }}
                        onBack={()=>setScheduleChange(void 0)}
                    />
                </Card.Body>
            </Card>
            :
            <div>
                <Nav variant="tabs" activeKey={currentTab}>
                    <Nav.Item>
                        <Nav.Link eventKey="score" onClick={e => setCurrentTab('score')}>Registrations</Nav.Link>
                    </Nav.Item>
                    {/*<Nav.Item>*/}
                    {/*    <Nav.Link eventKey="bills" onClick={e => setCurrentTab('bills')}>Bills</Nav.Link>*/}
                    {/*</Nav.Item>*/}
                    {/*<Nav.Item>*/}
                    {/*    <Nav.Link eventKey="ems-history" onClick={e => setCurrentTab('ems-history')}>EMS History</Nav.Link>*/}
                    {/*</Nav.Item>*/}
                </Nav>
                {currentTab == 'score' &&
                <Card>
                    <Card.Header>
                        <h4>Over all</h4>
                    </Card.Header>
                    <Card.Body>
                        {groupedDQRegCourse.length == 0 && <div>No DQ registration list.</div>}
                        {
                            groupedDQRegCourse.map(g=>{
                                let course = g.group;
                                let groupedRegs = createGroup(g.data,'schedule.id');
                                return <div key={course.id} className="mb-4">
                                    <h4>{course.name}</h4>
                                    <Table>
                                        <thead>
                                        <tr>
                                            <th width="200">Schedule</th>
                                            <th>Round</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {groupedRegs.map(gReg=>{
                                            let schedule = gReg.group;
                                            let regs = gReg.data;
                                            let uniqueBillIDs = [
                                                ...new Set(
                                                    gReg.data
                                                        .filter(reg=>!!reg.bill)
                                                        .map(reg=>reg.bill.id)
                                                )
                                            ]
                                            let bills = uniqueBillIDs.map(billID=>gReg.data.find(reg=>reg.bill?.id==billID)?.bill)
                                            return <>
                                                <tr key={schedule.id}>
                                                    <td><strong>{schedule.title}</strong></td>
                                                    <td>
                                                        {regs.map(reg=>{
                                                            return <div key={reg.id} style={{
                                                                backgroundColor:'#f6f6f6',
                                                                borderRadius:10,
                                                                padding:10,
                                                                marginBottom:5
                                                            }}>
                                                                <Row>
                                                                    <Col xs='auto'>
                                                                        {getThaiDateBetweenSingleDay(reg.scheduleRound.examStart,reg.scheduleRound.examEnd)}
                                                                    </Col>
                                                                    <Col>
                                                                        <ModuleDisplay quizSet={reg.scheduleRound.quizSet}/>
                                                                    </Col>
                                                                    <Col xs='auto'>
                                                                        <ButtonGroup>
                                                                            <Button variant="primary" onClick={e=>{
                                                                                setScheduleChange(reg);
                                                                            }}>Change</Button>
                                                                            <Button variant="danger" onClick={e=>{
                                                                                confirmAlert({
                                                                                    title:'Delete registration',
                                                                                    message:`
                                                                                Do you want to delete registration, ${reg.schedule.title} / ${reg.scheduleRound.quizSet.name}
                                                                                `,
                                                                                    buttons:[
                                                                                        {
                                                                                            label:'Yes',
                                                                                            onClick:async ()=>{
                                                                                                await onDelete(reg)
                                                                                            }
                                                                                        },
                                                                                        {
                                                                                            label:'No',
                                                                                            onClick:async ()=>{}
                                                                                        }
                                                                                    ]
                                                                                })
                                                                            }}>Delete</Button>
                                                                        </ButtonGroup>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        })}
                                                    </td>
                                                </tr>
                                                {bills.length>0 &&
                                                <>
                                                    {bills.map((bill,index)=>{
                                                        return <tr key={index}>
                                                            <td>
                                                                <strong className="ml-4">- BILL</strong>
                                                            </td>
                                                            <td>
                                                                <Row>
                                                                    <Col>
                                                                        {bill.registers.map((bReg,index)=>
                                                                            <ModuleDisplay key={index} className="mr-1" quizSet={bReg.scheduleRound.quizSet}/>
                                                                        )}
                                                                    </Col>
                                                                    <Col xs='auto'>
                                                                        ID: {bill.id} / Amount: {bill.amount} THB / Paid: {
                                                                        bill.isPaid?
                                                                        <Badge variant="success">Yes</Badge>
                                                                        :
                                                                        <Badge variant='danger'>No</Badge>}
                                                                    </Col>
                                                                </Row>
                                                            </td>
                                                        </tr>
                                                    })}
                                                </>
                                                }
                                            </>
                                        })}
                                        </tbody>
                                    </Table>
                                </div>
                            })
                        }

                    </Card.Body>
                </Card>
                }
                {currentTab == 'ems-history' &&
                <Card>
                    <Card.Body>
                        <Table responsive>
                            <thead>
                                <tr>
                                    <th>Code</th>
                                    <th>Detail</th>
                                    <th>Score</th>
                                </tr>
                            </thead>
                            <tbody>
                            {createGroup(resOldEmsStudent?.data?.oldStudentSchedule?.[0].schedules,'scheduleDetail.SchdID')?.map((list,index) => {
                                let scheduleDetail = list.group;
                                let OldSchedules = list.data;
                                return <tr key={index}>
                                    <td>{scheduleDetail?.SchdCode}</td>
                                    <td>
                                        {stripTags(scheduleDetail?.DateRegist_Desc_En) || '-'}
                                    </td>
                                    <td>
                                        {OldSchedules.map((schd,index)=>{
                                            return <div key={index}>
                                                {schd.round.ModuleType==1?
                                                    <div>
                                                        <Badge style={{fontWeight:500}} variant="primary">Theory: {schd.score.theory}</Badge>
                                                    </div>
                                                    :
                                                    <div>
                                                        {schd.score.workshop.map((wk,index)=>{
                                                            return <div key={index}>
                                                                <Badge variant={clsx({
                                                                    'info': wk.type == 'word',
                                                                    'success': wk.type == 'excel',
                                                                    'warning': wk.type == 'powerpoint',
                                                                    'danger': wk.type == 'access',
                                                                })} style={{margin: 2,fontWeight:500}}><span
                                                            className='text-capitalize'>{wk.type}:</span> {wk.score}
                                                            </Badge>
                                                            </div>
                                                        })}
                                                    </div>
                                                }
                                            </div>
                                        })}
                                    </td>
                                </tr>
                            })}
                            </tbody>
                        </Table>
                    </Card.Body>
                </Card>
                }
                {currentTab == 'bills' &&
                <Card>
                    <Card.Header>
                        <Card.Title>Bills</Card.Title>
                    </Card.Header>
                    <Card.Body>
                        {/*{billExistedData().map(({group,data})=>{*/}
                        {/*    {console.log(data)}*/}
                        {/*    return <React.Fragment key={group.SchdCode}>*/}
                        {/*        <h5 className="p-1">Schedule: {group.SchdCode}</h5>*/}
                        {/*        <Table>*/}
                        {/*            <thead>*/}
                        {/*            <tr>*/}
                        {/*                <td>Date</td>*/}
                        {/*                <td>TransactionID</td>*/}
                        {/*                <td>Module</td>*/}
                        {/*                <td>Amount</td>*/}
                        {/*                <td>State</td>*/}
                        {/*            </tr>*/}
                        {/*            </thead>*/}
                        {/*            <tbody>*/}
                        {/*            {data.map(row=>*/}
                        {/*                <tr>*/}
                        {/*                    <td>{row.scheduleDetail.billing.created}</td>*/}
                        {/*                    <td>{row.scheduleDetail.billing.transactionID}</td>*/}
                        {/*                    <td>{row.scheduleDetail.billing.items.map(v=>v.ModuleType=='1'?'Theory':'Workshop').join()}</td>*/}
                        {/*                    <td>{row.scheduleDetail.billing.amount}</td>*/}
                        {/*                    <td>{row.scheduleDetail.billing.isPaid==1?'Paid':'Not paid'}</td>*/}
                        {/*                </tr>*/}
                        {/*            )}*/}
                        {/*            </tbody>*/}
                        {/*        </Table>*/}
                        {/*    </React.Fragment>*/}
                        {/*})}*/}
                    </Card.Body>
                </Card>
                }
            </div>
        }
    </>
}
export default AdminStudentDetail;
