import {Controller} from "react-hook-form";
import DateTimePicker from "react-datetime-picker";

const DateTimePickerWrapper = ({name,control,...props})=>{
    return <>
        <Controller {...props}
                    control={control}
                    render={({
                                 field: {onChange, onBlur, name, value, ref},
                                 fieldState: {invalid, isTouched, isDirty, error},
                                 formState
                             }) => (
                        <DateTimePicker onBlur={onBlur} onChange={v => {
                            onChange(v)
                            if (typeof props.onChange == 'function') props.onChange(v)
                        }} value={value}/>
                    )} name={name}/>
    </>

}
export default DateTimePickerWrapper;
