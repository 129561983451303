import gql from "graphql-tag";

export const SET_ROLE = gql`
mutation SetRole($userId:ID,$roleId:ID){
  setRole(userId:$userId,roleId:$roleId)
}
`;

export const COURSE_DETAIL =gql`
mutation COURSE_DETAIL($courseForm: CourseInput) {
  courseDetail(courseForm: $courseForm){
     error
     courseDB{
        id
     }
  }
}
`;

export const SYNC_COURSE = gql`
mutation SyncCourse($course:String){
  syncCourse(course:$course)
}
`;

export const SAVE_EXAM_SCHEDULE = gql`
mutation ExamSchedule($scheduleForm:GraphqlExamScheduleInputType){
  examSchedule(scheduleForm:$scheduleForm){
    success
    error
    message
  }
}
`;

export const SAVE_REGISTER_DATA = gql`
mutation SaveRegistration($schedule: ID, $rounds: [Registration]) {
  saveRegistration(schedule: $schedule, rounds: $rounds) {
    error
    registrations {
      id
      usedWallet
      schedule {
        id
        title
        detail
        month
        year
        registeredCount
      }
      scheduleRound {
        id
        quizSet {
          id
          name
          minutes
        }
        location {
          id
          name
        }
      }
      bill {
        id
        amount
        expire
        isPaid
        registers {
          id
        }
      }
    }
    bill {
      id
      registers {
        id
        usedWallet
        course {
          id
          name
        }
        schedule {
          id
          title
          month
          year
          registerStart
          registerEnd
        }
        scheduleRound {
          id
          examStart
          examEnd
          location{
            id
            name
          }
          quizSet {
            id
            name
            price   
            text_color
            bg_color            
          }
        }
      }
      amount
      isPaid
      expire
    }
    registrationTimes
  }
}
`;

export const SAVE_SYSTEM_CONFIG = gql`
mutation SaveSystemConfig($config: SystemConfigInputType!) {
  saveSystemConfig(configData: $config) {
    wallet_enabled
    change_schedule_enabled
    auto_cancel_expired_bill
    bill_ttl_hours
    check_in_group_size
  }
}
`;

export const SAVE_CHANGE_SCHEDULE = gql`
mutation ChangeScheduleRound($registrationID: ID!, $scheduleRoundID: ID!) {
  changeScheduleRound(registrationID: $registrationID, scheduleRoundID: $scheduleRoundID) {
    success
    error
    message
  }
}
`;



