import {
    Alert,
    Button,
    Card,
    Col,
    Form,
    FormControl,
    FormGroup,
    FormLabel,
    ListGroup,
    ListGroupItem,
    Modal,
    Row
} from "react-bootstrap";
import {getAllMonth, getIDNumberWithCourseName, getYearList} from "../services/helper";
import DateTimePicker from 'react-datetime-picker';
import {useEffect, useState} from "react";
import {useForm, Controller, useFieldArray} from "react-hook-form";
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {useLazyQuery, useMutation, useQuery} from "@apollo/client";
import {GET_DB_COURSE, GET_EXAM_SCHEDULE, GET_EXAM_SCHEDULES} from "../gql-query/select-query";
import {generateUniqueID} from "web-vitals/dist/modules/lib/generateUniqueID";
import {v4 as uuidv4} from 'uuid';
import _ from 'lodash';
import moment from "moment";
import DateTimePickerWrapper from "./datetime-picker-wrapper";
import CourseScheduleRound from "./course-schedule-round";
import {SAVE_EXAM_SCHEDULE} from "../gql-query/save-query";
import {ExamScheduleValidationSchema} from "../yup-validators/exam-schedule-form-schema";
import {toast} from "react-toastify";
import {observer} from "mobx-react";
import {useMobxState} from "../mobx/global-context";


const QuizSetForm = ({control, courseDB, register, setValue, getValues, errors, trigger}) => {
    const {fields, append, remove} = useFieldArray({
        control,
        name: 'quizSet'
    })

    function addRound(index, e) {
        let existed = getValues(`quizSet.${index}.rounds`);
        if (!existed) setValue(`quizSet.${index}.rounds`, []);
        setValue(`quizSet.${index}.rounds`,
            [...getValues().quizSet[index]?.rounds,
                {
                    id: uuidv4(),
                    examStart: '',
                    examEnd: '',
                    location: '',
                    maxSeats: '',
                }
            ]);
    }

    return <>
        {courseDB.quizSet.map((qSet, index) => {
            return <Row key={qSet.id}>
                <Col>
                    <Card>
                        <Card.Header>
                            <h2>({qSet?.quiz_mode == 'rnd' ? 'RND' : 'ALL'}) - {qSet?.name}</h2>
                            <Button onClick={addRound.bind(this, index)} variant='primary'>+</Button>
                        </Card.Header>
                        <Card.Body>
                            <ListGroup>
                                <CourseScheduleRound {...{
                                    control,
                                    setValue,
                                    indexKey: index,
                                    courseDB,
                                    trigger,
                                    register,
                                    errors
                                }}/>
                            </ListGroup>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        })}
    </>
}


const CourseScheduleCreateUpdate = ({courseDB, open, onClose, scheduleID}) => {


    const {
        register, handleSubmit,
        reset,
        watch,
        setValue,
        control,
        unregister,
        trigger, getValues, formState: {errors, isDirty, isValid}
    } = useForm({
        resolver: yupResolver(ExamScheduleValidationSchema),
        defaultValues: {
            course: courseDB.id,
            month: '',
            year: new Date().getFullYear() + 543,
            title: '',
            detail: '',
            registerStart: new Date(),
            registerEnd: new Date(),
            quizSet: courseDB.quizSet.map(q => ({id: q.id, rounds: []}))
        }
    });
    const formData = watch();

    const state = useMobxState();


    // let {append} = useFieldArray({control,name:`quizSet.0.rounds`});


    const [getData, {data, loading, called, error}] = useLazyQuery(GET_EXAM_SCHEDULE, {
        fetchPolicy: 'cache-and-network',
        onCompleted: (data) => {
            let buffer = _.cloneDeep(data);
            if (!buffer.examSchedule) return;
            let {id, month, year, title, detail, registerStart, registerEnd, rounds} = buffer.examSchedule;
            setValue('id', id);
            setValue('month', month);
            setValue('year', year);
            setValue('title', title);
            setValue('detail', detail);
            setValue('registerStart', new Date(parseInt(registerStart)));
            setValue('registerEnd', new Date(parseInt(registerEnd)));
            courseDB.quizSet.forEach((q,i)=>{
                setValue(`quizSet.${i}.id`,q.id);
                setValue(`quizSet.${i}.rounds`,[]);
                rounds.filter(r=>r.quizSet?.id==q.id).map((r,index)=>{
                    r.examStart = new Date(parseInt(r.examStart));
                    r.examEnd = new Date(parseInt(r.examEnd));
                    r.location = r.location.id;
                    delete r.__typename;
                    delete r.quizSet;
                    delete r.schedule;
                    delete r.created;
                    setValue(`quizSet.${i}.rounds`,[...getValues(`quizSet.${i}.rounds`),r]);
                })
            })
            // console.log('----------quizSet buffer=', formData);
        }
    });

    const [SaveExamSchedule] = useMutation(SAVE_EXAM_SCHEDULE, {
        refetchQueries: [
            {
                query: GET_EXAM_SCHEDULES,
                variables: {course: courseDB.id},
            }
        ]
    });




    useEffect(() => {
        if (scheduleID) {
            console.log('LOAD...', scheduleID);
            getData({
                variables: {
                    id: scheduleID
                }
            });
        }
    }, [scheduleID])


    useEffect(() => {
        console.log('error=',errors);
        console.log('formData=',formData);
    }, [errors])

    function onSubmit(data) {
        // display form data on success
        state.backdrop=true;
        SaveExamSchedule({
            variables: {
                scheduleForm: data
            }
        }).then((r) => {
            console.log(r);
            toast.success('Data saved.');
            onClose();
        }).catch(e => {
            console.log(e.message);
        }).finally(()=>{
            setTimeout(()=>{
                state.backdrop=false;
            },500);
        });

    }

    function addSchedule(qs, index, e) {
        console.log('=======', getValues()?.rounds[index]);
        // setValue(`rounds.${index}`,[...getValues()?.rounds,{id:'xxxxx'}])
        // let id = uuidv4();
        // let existed = schedules.find(v=>v.id==qs.id)
        // if(!existed){
        //     schedules.push({id:qs.id,rounds:[]});
        //     existed = schedules.find(v=>v.id==qs.id)
        // }
        // existed.rounds.push({
        //     id,
        //     exam_start_date:'',
        //     exam_end_date:'',
        //     location:'',
        //     participants:'',
        // });
        // setSchedules([...schedules]);
        // console.log('schedules==', schedules);


        // setSchedules(prevState => {
        // if (!prevState[qs.id]) prevState[qs.id] = [];
        // return {
        //     ...prevState,
        //     [qs.id]: [...prevState[qs.id], {id}]
        // }
        // });
    }

    // function onDelete(qs, id, e) {
    //     setSchedules(prevState => {
    //         prevState[qs.id] = _.reject(prevState[qs.id], v => v.id == id)
    //         unregister(`schedule[${qs.id}][${id}].start_date`);
    //         unregister(`schedule[${qs.id}][${id}].end_date`);
    //         return {...prevState};
    //     })
    // }

    // console.log('errors==',errors);

    if (!courseDB) return null;
    return <>
        <Modal
            show={open}
            onHide={e => {
                reset();
                onClose()
            }}
            size='xl'
            style={{overflowY: 'auto'}}
        >
            <Modal.Header closeButton>
                <Modal.Title>Create new schedule</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form id='form' onSubmit={handleSubmit(onSubmit)}>
                    <Form.Group>
                        <Form.Label>Course</Form.Label>
                        <Form.Control value={getIDNumberWithCourseName(courseDB)} readOnly/>
                    </Form.Group>
                    <Row>
                        <Col>
                            <Form.Group>
                                <Form.Label>Month</Form.Label>
                                <Form.Control as="select" {...register(`month`)}>
                                    <option value=''>- Month -</option>
                                    {getAllMonth().map((m, i) => <option key={'m_' + i} value={i + 1}>{m}</option>)}
                                </Form.Control>
                                {errors?.month &&
                                <Alert variant='danger'>Please select month to exam.</Alert>
                                }
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label>Year</Form.Label>
                                <Form.Control as="select" {...register(`year`)}>
                                    <option value=''>- Year -</option>
                                    {getYearList().map(y => <option key={y} value={y}>{y}</option>)}
                                </Form.Control>
                                {errors?.month &&
                                <Alert variant='danger'>Please select month to exam.</Alert>
                                }
                            </Form.Group>
                        </Col>
                    </Row>
                    <Form.Group>
                        <Form.Label>Title</Form.Label>
                        <Form.Control {...register('title')}/>
                        {errors?.title &&
                        <Alert variant='danger'>Please define title.</Alert>
                        }
                    </Form.Group>


                    <Form.Group>
                        <Form.Label>Detail</Form.Label>
                        <Form.Control as="textarea" {...register(`detail`)}/>
                    </Form.Group>

                    {/*<Row>*/}
                    {/*    <Col>*/}
                    {/*        <Form.Group>*/}
                    {/*            <Form.Label>Exam begin</Form.Label>*/}
                    {/*            <DateTimePickerWrapper name='exam_from_date' control={control}/>*/}
                    {/*        </Form.Group>*/}
                    {/*    </Col>*/}
                    {/*    <Col>*/}
                    {/*        <Form.Group>*/}
                    {/*            <Form.Label>Exam end</Form.Label>*/}
                    {/*            <DateTimePickerWrapper name='exam_close_date' control={control}/>*/}
                    {/*        </Form.Group>*/}
                    {/*    </Col>*/}
                    {/*</Row>*/}
                    <Row>
                        <Col>
                            <Form.Group>
                                <Form.Label>Register start</Form.Label>
                                <DateTimePickerWrapper name='registerStart' control={control}/>
                                {errors?.registerStart &&
                                <Alert variant='danger'>Please define Register start date.</Alert>
                                }
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label>Register end</Form.Label>
                                <DateTimePickerWrapper name='registerEnd' control={control}/>
                                {errors?.registerEtart &&
                                <Alert variant='danger'>Please define Register end date.</Alert>
                                }
                            </Form.Group>
                        </Col>
                    </Row>
                    <QuizSetForm {...{control, register, courseDB, setValue, getValues, errors, trigger}}/>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={e => onClose()}>
                    Close
                </Button>
                <Button onClick={e => handleSubmit(onSubmit)()} variant="primary">Save</Button>
            </Modal.Footer>
        </Modal>
    </>
}
export default observer(CourseScheduleCreateUpdate);
