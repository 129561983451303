import {createMuiTheme,createTheme } from "@material-ui/core/styles";
import {blue} from "@material-ui/core/colors";

const Theme = createTheme({
    typography:{
        fontFamily:[
            'Kanit',
            'sans-serif',
        ]
    },
    overrides:{
        MuiCssBaseline:{
            '@global': {
                '.btn':{
                     'box-shadow': '1px 1px 30px 0 rgba(0,0,0,0.2)',
                     'text-decoration': 'none',
                     'border-radius': '65px !important',
                     'padding-left': '15px  !important',
                     'padding-right': '15px  !important',
                     'font-size': '15px',
                     // 'font-weight': 600,
                     'display': 'inline-block',
                     '&-danger':{
                       color: '#ffffff !important',
                       background: '#dc3545',
                     },
                     '&-success':{
                       color: '#ffffff !important',
                       background: '#dc3545',
                     }
                }
            }
        },
        MuiButton:{
            containedPrimary:{
                borderRadius:65
            },
            contained:{
                borderRadius:65
            }
        }
    },
    palette:{
        primary:{
            main:blue[600]
        }
    }
});
export default Theme;
