import React, {useEffect, useState} from "react";
import {
    Alert, Button,
    Col,
    Form,
    FormControl,
    FormGroup,
    FormLabel,
    Image,
    ListGroup,
    ListGroupItem,
    Row, Tabs, Tab
} from "react-bootstrap";
import _ from "lodash";
import {getMongooseID} from "../../services/requester";
import QuizSet from "../../components/quiz-set";
import CourseQuizCondition from "../../components/course-quiz-condition";
import {useHistory, useParams} from "react-router-dom";
import {useMutation, useQuery} from "@apollo/client";
import {GET_DB_COURSE} from "../../gql-query/select-query";
import {
    CardContent,
    Paper,
    Card,
    Typography,
    CardHeader,
    Grid,
    CardActionArea,
    CardActions,
} from '@material-ui/core'
import XBreadcrumb from "../../components/xbreadcrumb";
import {courseFormSchema} from "../../yup-validators/course-form-schema";
import {toast} from "react-toastify";
import {COURSE_DETAIL} from "../../gql-query/save-query";
import {v4 as uuidv4} from 'uuid';
import CourseLocationSetup from "../../components/course-location-setup";
import CourseMultipleLangQuizSetup from "../../components/course-multiple-lang-quiz-setup";
import CourseMoodleQuizSetup from "../../components/course-moodle-quiz-setup";
import CoursePaymentSetup from "../../components/course-payment-setup";
import CourseQuizGroupSetup from "../../components/course-quiz-group-setup";
import {useMobxState} from "../../mobx/global-context";
import {runInAction} from "mobx";
import CourseRoleQuizSetup from "../../components/course-role-quiz-setup";

export const CourseSettingHeadBar = ({title, onClick}) => {
    return <div className="pt-2 pb-2">
        <Row>
            <Col xs={6} md='auto' className="my-auto">
                <h3 className="my-auto text-primary">{title}</h3>
            </Col>
            {onClick &&
            <Col xs={6} md='auto' className="my-auto">
                <Button onClick={onClick}>+</Button>
            </Col>
            }
        </Row>
    </div>
}

const ExamScheduleCourseSetting = () => {
    const [courseForm, setCourseForm] = useState({});
    const [formError, setFormError] = useState({});
    const state = useMobxState();
    const {id} = useParams();
    const history = useHistory();

    const {loading: l_courseDB, error: e_courseDB, data: d_courseDB, refetch: r_courseDB} = useQuery(GET_DB_COURSE, {
        variables: {mdCourseID: id},
        // fetchPolicy: "no-cache",
        fetchPolicy: "cache-and-network",
    });

    const [courseDetail, {loading: loadingCourseDetail,error:errorCourseDetail}] = useMutation(COURSE_DETAIL,{
        refetchQueries:[
            {
                query:GET_DB_COURSE,
                variables: {mdCourseID: id},
                // fetchPolicy: "no-cache",
                fetchPolicy: "cache-and-network",
            }
        ],
        awaitRefetchQueries:true,
        // onCompleted:(d)=>{
        //     console.log('wwwwwwwww',d);
        // }
    });

    useEffect(() => {
        courseForm.isPaid = '';
        courseForm.freeTimes = '';
        if(!courseForm.quizzes)courseForm.quizzes = [];
        if(!courseForm.quizSet) courseForm.quizSet = [];
        if(!courseForm.locations)courseForm.locations = [];
        if(!courseForm.dqQuizzes)courseForm.dqQuizzes = [];
        if(!courseForm.ssoRoles)courseForm.ssoRoles = [];
    }, []);

    useEffect(()=>{
        // console.log('useEffect======', errorCourseDetail?.message);
    } ,[errorCourseDetail])

    useEffect(() => {
        if (d_courseDB) {
            // console.log('courseDB========', d_courseDB.courseDB);
            let data = _.cloneDeep({...d_courseDB.courseDB,removed:[]});
            data.dqQuizzes=data.dqQuizzes?.map(v=>({
                ...v,
                th:v?.th?.id,
                en:v?.en?.id
            }))
            data.quizSet=data.quizSet.map(v=>({
                ...v,
                quizzes:v.dqQuizzes
            }))
            console.log('===', data);
            setCourseForm(data);
            // setCourseForm(_.cloneDeep({...{...d_courseDB.courseDB,removed:[]}}));
        }
    }, [d_courseDB]);

    function updateForm() {
        setCourseForm({...courseForm});
    }

    function displayError(key) {
        return <>
            {formError[key] &&
            <Alert variant="danger">{formError[key]}</Alert>
            }
        </>
    }

    function resetError(key) {
        let e = _.omit(formError, key);
        setFormError({...e});
    }

    function showBackdrop(v){
        runInAction(()=>{
            state.backdrop=v;
        })
    }

    function submit(e) {
        console.log('courseForm====',courseForm);
        showBackdrop(true);
        courseFormSchema.validate(courseForm, {stripUnknown: true,abortEarly:false}).then(data => {
            courseDetail({
                variables: {
                    courseForm: data
                }
            }).then((d)=>{
                toast.success('Data Saved');
                showBackdrop(false);
            }).catch(e=>{
                toast.error('Cannot save data.');
                showBackdrop(false);
            }).finally(()=>{
                history.push('/admin/exam-schedule/'+id);
            });
        }).catch((err, f) => {
            let errorString = '';
            for (let e of err?.errors) errorString += e;
            toast.error(errorString);
            console.log('courseFormSchema===',err.errors);
            showBackdrop(false);
        });
    }


    if (!courseForm || !Array.isArray(courseForm.quizzes)) return <></>
    return <>
        <XBreadcrumb data={[
            {title: 'Exam Schedule', url: '/admin/exam-schedule'},
            {title: 'Course Schedule', url: '/admin/exam-schedule/' + id},
            {title: 'Course Setup'}
        ]}/>
        <Paper>
            <Card>
                <CardContent>
                    <Typography variant='h3' className="mb-3">Course Setup</Typography>
                    <Form>
                        <Form.Group>
                            <h3>{courseForm.fullname}</h3>
                        </Form.Group>
                        <hr/>
                        <Form.Group>
                            <h3>Summary</h3>
                            <div dangerouslySetInnerHTML={{__html: courseForm.summary}}></div>
                            <hr/>
                        </Form.Group>
                        <CourseRoleQuizSetup courseForm={courseForm} updateUI={updateForm}/>
                        <hr/>
                        <CourseLocationSetup courseForm={courseForm} updateUI={updateForm}/>
                        <hr/>
                        <CourseMoodleQuizSetup courseForm={courseForm} updateUI={updateForm}/>

                        <hr/>
                        <CourseMultipleLangQuizSetup
                            courseForm={courseForm}
                            // quizzes={d_courseDB?.courseDB?.quizzes}
                            quizzes={courseForm?.quizzes}
                            updateUI={updateForm}
                        />
                        <hr/>
                        <CoursePaymentSetup
                                courseForm={courseForm}
                                updateUI={updateForm}
                                resetError={resetError}
                                // displayError={displayError}
                        />
                        <hr className="mt-2"/>
                        <CourseQuizGroupSetup courseForm={courseForm} updateUI={updateForm}/>
                        <CourseQuizCondition formError={formError}
                                             dataQuizSet={courseForm.quizSet}
                                             orConditionData={courseForm.orConditions}
                                             onChange={change => {
                                                 if(change.action=='add'){
                                                     setCourseForm({
                                                         ...courseForm,
                                                         orConditions:[...courseForm.orConditions,change.data]
                                                     })
                                                 }else if(change.action=='set'){
                                                     let {orConditionData} = change.data;
                                                     setCourseForm({
                                                         ...courseForm,
                                                         orConditions:[...orConditionData]
                                                     })
                                                 }

                                             }}
                                             onRemove={index => {
                                                 courseForm.orConditions = _.reject(courseForm.orConditions, (v, i) => i == index);
                                                 updateForm();
                                             }}
                                             onResetError={resetError}
                        />


                        <Grid container>
                            <Grid item xs={12}>
                                <div className='text-right'>
                                    <Button variant='secondary' className='mr-1'>Cancel</Button>
                                    <Button variant='primary' onClick={submit}>Save</Button>
                                </div>
                            </Grid>
                        </Grid>
                    </Form>
                </CardContent>
            </Card>
        </Paper>
    </>
}
export default ExamScheduleCourseSetting;
