import TopMenu from "./top-menu";
import {StyleSheet, css} from "aphrodite";
import {Helmet} from "react-helmet";
import {onHelmetReady} from "../../services/helper";
import {useContext, useEffect, useRef, useState} from "react";
import Config from "../../config";
import './../../css/front-end.css';
import {useMobxState} from "../../mobx/global-context";
import {
    AppBar, Avatar, Badge, Box, Button, Card, CardContent, CardHeader, Container,
    createMuiTheme, CssBaseline, Divider,
    Drawer, Grid, Grow, Hidden,
    IconButton, List,
    ListItem, ListItemIcon, ListItemText, makeStyles, NoSsr, Paper,
    ThemeProvider,
    Toolbar, Tooltip,
    Typography
} from '@material-ui/core'
import {blue, green} from "@material-ui/core/colors";
import Footer from "./footer";
import Theme from "./mui-theme-rules";
import clsx from "clsx";
import MenuIcon from '@material-ui/icons/Menu';
import HomeIcon from '@material-ui/icons/Home';
import {observer} from "mobx-react";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import PostAddIcon from '@material-ui/icons/PostAdd';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Alert from '@material-ui/lab/Alert';
import ScheduleIcon from '@material-ui/icons/Schedule';
import {Link, useHistory} from "react-router-dom";
import FrontEndLink from "../../components/front-end-link";
import * as PropTypes from "prop-types";
import NotificationsIcon from '@material-ui/icons/Notifications';
import BookIcon from '@material-ui/icons/Book';
import Countdown from "../../components/countdown";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import {TransitionGroup} from "react-transition-group";
import {AttachMoney} from "@material-ui/icons";
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import {getServerTime, getThaiDateBetween} from "../../services/client-helper";
import ReceiptIcon from '@material-ui/icons/Receipt';
import {useApolloClient, useMutation, useQuery} from "@apollo/client";
import {GET_OLD_SCHEDULE} from "../../gql-query/select-query";
import HistoryIcon from '@material-ui/icons/History';
import {runInAction, toJS} from "mobx";
import {toast} from "react-toastify";
import gql from "graphql-tag";
import PersonIcon from '@material-ui/icons/Person';
import StudentExamCountdown from "../../components/student-exam-countdown";
import axios from "axios";

const footerHeight = 50;
const sidebarWidth = 250;
const useStyles = makeStyles(theme => ({
    '@global':{
        '.modal-dialog,.modal-backdrop,.modal':{
            zIndex:2000
        }
    },
    root: {
        [theme.breakpoints.up('md')]: {
            display: 'flex'
        }
    },
    drawer: {
        width: sidebarWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: sidebarWidth,
    },
    toolbar: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        minHeight: `calc(100vh - ${footerHeight}px)`
    },
    footer: state => {
        if (!state.currentUser) {
            return {
                width: `100%`,
                background: '#2e3444',
                height: footerHeight,
            }
        }
        return {
            [theme.breakpoints.up('md')]: {
                marginLeft: sidebarWidth,
                width: `calc(100% - ${sidebarWidth}px)`,
                // width: `100%`,
            },
            background: '#2e3444',
            height: footerHeight,
        }
    },
    appBar: (state) => {
        if (!state.currentUser) {
            return {
                width: `100%`
            }
        }
        return {
            [theme.breakpoints.down('md')]: {
                width: `100%`,
            },
            [theme.breakpoints.up('md')]: {
                marginLeft: sidebarWidth,
                width: `calc(100% - ${sidebarWidth}px)`,
            },
        }
    },
}))

function FrontEndLinkto(props) {
    return null;
}

FrontEndLinkto.propTypes = {
    to: PropTypes.string,
    children: PropTypes.node
};

const CLOSE_QUIZ = gql`
mutation CloseQuiz($regID: ID!) {
  closeQuiz(regID: $regID)
}
`
const FrontEndMini = ({children}) => {
    const [ready, setReady] = useState(false);
    const [menu, setMenu] = useState(false);
    const state = useMobxState();
    const url = Config.frontEndPath;
    const classes = useStyles(state);
    const history = useHistory();
    const resOldSchedule = useQuery(GET_OLD_SCHEDULE);
    const expired = useRef(false);
    const apolloClient = useApolloClient();
    const timeDiff = useRef(0);
    const [isPassed, setIsPassed] = useState(false);
    const client = useApolloClient();

    const [closeQuiz] = useMutation(CLOSE_QUIZ,{
        variables:{
            regID:state?.examRegister?.id
        }
    });

    useEffect(()=>{
        if(!state.studentProgression)return;
        let progression = toJS(state.studentProgression);
        for(let prog of progression){
            if(prog.course.id==='60e1b2c52d03f332a5dfbaab'){
                if(prog.pass){
                    setIsPassed(true);
                }
            }
        }
        console.log('state.studentProgression==',progression);
    },[state.studentProgression])

    useEffect(()=>{
        if(resOldSchedule?.data){
            runInAction(()=>{
                state.oldExamData=resOldSchedule?.data?.oldSchedules;
            })
        }
    },[resOldSchedule.data]);

    useEffect(() => {
        if(state.examScheduleRound){
            console.log('state.examScheduleRound==',toJS(state.examScheduleRound));
            getServerTime().then(date=>{
                let sTime = date.getTime();
                let cTime = new Date().getTime();
                timeDiff.current = sTime-cTime;
                runInAction(()=>{
                    state.serverTimeDiff=timeDiff.current;
                });
            })
        }
    }, [state.examScheduleRound]);



    const drawer = <>
        <div>
            <Box mt={4} p={2} px={2} display='flex' flexDirection='column'>
                <Box display='flex' flexDirection='column' alignItems='center'>
                    <Box mb={1}>
                        {state?.currentUser?.studentID?
                            <Avatar style={{
                                cursor: 'pointer',
                                width: 64,
                                height: 64,
                            }} src={`${Config.api_url}/profile_picture`}/>
                            :
                            <Avatar style={{
                                cursor: 'pointer',
                                width: 64,
                                height: 64,
                            }} src={`${Config.root}/img/user.png`}/>
                        }

                    </Box>
                    <Typography color='textPrimary'
                                style={{
                                    fontSize: '90%',
                                    fontWeight: 'bold'
                                }}>{state.currentUser?.prefix}{state.currentUser?.fname} {state.currentUser?.lname}</Typography>
                    <Typography color='textSecondary' style={{fontSize: '90%'}}>
                        {state.currentUser?.studentID ? state.currentUser?.studentID : state.currentUser?.username}
                    </Typography>
                </Box>
            </Box>

            <StudentExamCountdown/>

            {/*{*/}
            {/*    state?.examScheduleRound &&*/}
            {/*        <>*/}
            {/*            {state?.examScheduleRound?.examStart <= (new Date()).getTime()+timeDiff.current?*/}
            {/*                <>*/}
            {/*                    <TransitionGroup>*/}
            {/*                        {state.exam && <Grow>*/}
            {/*                            <Countdown title="Exam close in" showDay={false} multiLine expireTimestamp={state.examScheduleRound.examEnd||0} onExpired={()=>{*/}
            {/*                                if(!expired.current){*/}
            {/*                                    state.examExpired=true;*/}
            {/*                                    toast.success('TIME IS UP!');*/}
            {/*                                    closeQuiz().then(success=>{});*/}
            {/*                                    expired.current=true;*/}
            {/*                                }*/}
            {/*                            }}/>*/}
            {/*                        </Grow>}*/}
            {/*                    </TransitionGroup>*/}
            {/*                </>*/}
            {/*                :*/}
            {/*                <>*/}
            {/*                    <Countdown title='Exam start in' showDay={false} multiLine expireTimestamp={state.examScheduleRound?.examStart||0}*/}
            {/*                    onExpired={()=>{*/}
            {/*                        document.location.reload();*/}
            {/*                    }}*/}
            {/*                    />*/}
            {/*                </>*/}
            {/*            }*/}
            {/*        </>*/}
            {/*}*/}

            {/*{state.examScheduleRound &&*/}
            {/*<TransitionGroup>*/}
            {/*    {state.exam && <Grow>*/}
            {/*        <Countdown title="Time remaining" multiLine expireTimestamp={state.examScheduleRound.examEnd||0} onExpired={()=>{*/}
            {/*            if(!expired.current){*/}
            {/*                state.examExpired=true;*/}
            {/*                toast.success('TIME IS UP!');*/}
            {/*                closeQuiz().then(success=>{});*/}
            {/*                expired.current=true;*/}
            {/*            }*/}
            {/*        }}/>*/}
            {/*    </Grow>}*/}
            {/*</TransitionGroup>*/}
            {/*}*/}
        </div>
        <Divider/>
        <Grow in={state.exam} timeout={500} unmountOnExit>
            <div align="center">
                <Box p={2}>
                    <Button
                        variant="contained"
                        color='primary'
                        size='large'
                        onClick={e => {
                            history.push(`/my`)
                        }}
                    ><ArrowBackIosIcon/> Quiz Examination</Button>
                </Box>
            </div>
        </Grow>
        <Grow in={!state.exam} timeout={500} unmountOnExit>
            <List>
                <FrontEndLink exact to={`/my`} icon={<HomeIcon/>}>
                    Home
                </FrontEndLink>
                <FrontEndLink exact to={`/my/profile`} icon={<PersonIcon/>}>
                    Profile
                </FrontEndLink>
                <FrontEndLink to={`/my/register`} icon={<PostAddIcon/>}>
                    Register
                </FrontEndLink>
                {/*{resOldSchedule?.data?.oldSchedules?.length > 0 &&*/}
                {/*    <FrontEndLink exact to={`/my/old-exam-data`} icon={<HistoryIcon/>}>*/}
                {/*        History*/}
                {/*    </FrontEndLink>*/}
                {/*}*/}
                {state.systemConfig?.wallet_enabled &&
                    <FrontEndLink to={`/my/wallet`} icon={<AccountBalanceWalletIcon/>}>
                        Wallet
                    </FrontEndLink>
                }
                {/*<FrontEndLink to={`/my/bill`} icon={<ReceiptIcon/>}>*/}
                {/*    Bill*/}
                {/*</FrontEndLink>*/}
                {/*<FrontEndLink to={`/my/certificated`} icon={<BookIcon/>}>*/}
                {/*    Certificated*/}
                {/*</FrontEndLink>*/}
                <FrontEndLink exact to={`/my/history`} icon={<HistoryIcon/>}>
                    Exam History
                </FrontEndLink>
                {isPassed &&
                    <a href='https://dq.kku.ac.th/api/download-transcript' target={'_blank'}>
                        <ListItem>
                            <ListItemIcon style={{color:'#1e88e5'}}><ReceiptIcon/></ListItemIcon>
                            <ListItemText>Download Transcript</ListItemText>
                        </ListItem>
                    </a>
                }
            </List>
        </Grow>
        <Divider/>
        <Box display="flex" flexDirection="column" px={2} pt={2}>
            <Button
                variant="contained"
                color="secondary"
                size="large"
                className={classes.button}
                startIcon={<ExitToAppIcon/>}
                onClick={async () => {
                    try{
                        await client.resetStore();
                        await axios.get('/api/clear-cache');
                    }catch (e){}
                    state.logout();
                }}
            >
                Logout
            </Button>
            <div style={{height:50}}></div>
        </Box>
    </>

    return <ThemeProvider theme={Theme}>
        <Helmet onChangeClientState={(newState, addedTags, removedTags) => {
            onHelmetReady(newState, addedTags, removedTags, () => {
                setReady(true);
            })
        }}>
            {/*<link*/}
            {/*    href="https://fonts.googleapis.com/css?family=Nunito:200,300,400,600,700,800,900"*/}
            {/*    rel="stylesheet"/>*/}
            <link rel="preconnect" href="https://fonts.googleapis.com"/>
            <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin/>
            <link href="https://fonts.googleapis.com/css2?family=Kanit:wght@300&display=swap" rel="stylesheet"/>
            <style>
                {`
                html,body{font-family: 'Kanit', sans-serif !important;}
                `}
            </style>
            {/*<link rel="stylesheet" href={`${url}/css/main.css`}/>*/}
            <CssBaseline/>
        </Helmet>
        <div className={classes.root}>
            <AppBar className={classes.appBar} elevation={0}>
                <Toolbar>
                    {state.currentUser &&
                    <Hidden mdUp>
                        <Box pr={2}>
                            <IconButton
                                color="inherit"
                                edge="end"
                                onClick={() => {
                                    setMenu(!menu)
                                }}
                            >
                                <MenuIcon/>
                            </IconButton>
                        </Box>
                    </Hidden>
                    }
                    <Typography variant="h6" noWrap style={{flexGrow: 1}}>
                        {/*KKU DQ Examinations*/}
                        {Config.siteName}
                    </Typography>

                    <div className="d-block d-lg-none" style={{width:200}}>
                        <StudentExamCountdown style={{fontSize: '95%',background:'#495057',borderRadius:6}}/>
                    </div>

                    {state.currentUser &&
                    <>
                        {state.systemConfig?.wallet_enabled &&
                        <IconButton color="inherit" onClick={e=>history.push('/my/wallet')}>
                            <Badge color="secondary"
                                   badgeContent={state.currentUser.wallet||0}
                                   max={9999}>
                                <AccountBalanceWalletIcon/>
                            </Badge>
                        </IconButton>
                        }

                        <Tooltip title="Logout" aria-label="logout">
                            <IconButton color="inherit" onClick={e=>state.logout()}>
                                <Badge color="primary">
                                    <ExitToAppIcon/>
                                </Badge>
                            </IconButton>
                        </Tooltip>
                    </>
                    }

                </Toolbar>
            </AppBar>
            <CssBaseline/>
            {state.currentUser &&
            <>
                <Hidden smDown implementation="css">
                    <Drawer
                        variant='permanent'
                        anchor="left"
                        className={classes.drawer}
                        classes={{
                            paper: classes.drawerPaper
                        }}
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
                <Hidden smUp implementation="css">
                    <Drawer
                        variant='temporary'
                        anchor="left"
                        className={classes.drawer}
                        open={menu}
                        onClose={() => setMenu(false)}
                        onClick={() => setMenu(false)}
                        classes={{
                            paper: classes.drawerPaper
                        }}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
            </>
            }
            <main className={classes.content}>
                <div className={classes.toolbar}/>
                {children}
            </main>
        </div>
        <footer className={classes.footer}>
            <Box display='flex' height='100%' flexDirection='column' justifyContent='center'>
                <Container>
                    <Grid container>
                        <Grid item xs={12}>
                            <Box align='center' color='white'>KKU DQ Examination</Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </footer>
        {/*<Footer/>*/}
    </ThemeProvider>
}
const style = StyleSheet.create({
    wrapper: {
        height: '100%',
    },
    bg: {
        background: 'linear-gradient(to right, #3061ad 0%,#2696da 100%)',
        height: 'auto',
        padding: '10px'
    },
    content: {
        marginTop: '50px',
        height: '100%',
    }
})
export default observer(FrontEndMini);
