import {Box, Paper} from "@material-ui/core";
import {observer} from "mobx-react";
import {useMobxState} from "../../mobx/global-context";
import {useEffect, useState} from "react";
import {Alert, Col, Image, Row, Table} from "react-bootstrap";
import Config from "../../config";

const Profile = () => {

    const [profile, setProfile] = useState();
    const state = useMobxState();

    useEffect(() => {
        setProfile(state.currentUser);
    }, [state.currentUser])

    if (!profile) return <Alert variant="info">Loading...</Alert>
    return <Box p={2}>
        <h3>My Profile</h3>
        <Paper>
            <div className="p-2">
                <Row>
                    <Col xs='12' md='auto'>
                        <div className="text-center">
                            <Image rounded fluid src={Config.api_url+'/profile_picture'}/>
                        </div>
                    </Col>
                    <Col>
                        <div className="mt-3">
                            <Table>
                                <tbody>
                                {profile?.studentID &&
                                <tr>
                                    <td>Student ID</td>
                                    <td>{profile?.studentID}</td>
                                </tr>
                                }
                                <tr>
                                    <td>Username</td>
                                    <td>{profile?.username}</td>
                                </tr>
                                <tr>
                                    <td>Full name</td>
                                    <td>{profile?.prefix}{profile?.fname} {profile?.lname}</td>
                                </tr>
                                <tr>
                                    <td>Email</td>
                                    <td>{profile?.email}</td>
                                </tr>
                                <tr>
                                    <td>Faculty</td>
                                    <td>{profile?.faculty}</td>
                                </tr>
                                </tbody>
                            </Table>
                        </div>
                    </Col>
                </Row>
            </div>
        </Paper>
    </Box>
}
export default observer(Profile);
