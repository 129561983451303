import {Alert, Badge, Button, Card, Col, FormControl, ListGroup, ListGroupItem, Row} from "react-bootstrap";
import {useEffect} from "react";
import moment from "moment";
import React from 'react';
import {StyleSheet, css} from "aphrodite";
import _ from 'lodash';
import {Typography} from "@material-ui/core";
import {CourseSettingHeadBar} from "../page/admin/exam-schedule-course-setting";

const CourseQuizCondition = ({formError, onResetError, orConditionData, dataQuizSet, onChange, onRemove}) => {
    useEffect(() => {

    }, []);

    function onConditionsChanged(cIndex, qsIndex, quizSet, e) {
        let currentOrConditionData = orConditionData[cIndex];
        let existed = currentOrConditionData.andConditions.find(v => v.quizSet.id == quizSet.id)
        if (existed) {
            existed.condition = e.target.value;
        } else {
            currentOrConditionData.andConditions.push(
                {
                    quizSet: {id:quizSet.id},
                    condition: e.target.value,
                }
            );
        }
        // console.log(orConditionData);
        onChange({action:'set',data:{orConditionData}});
    }

    function getValue(cIndex, qsIndex, quizSet) {

        let {andConditions} = orConditionData[cIndex];
        let c = andConditions && andConditions.find(v => v.quizSet?.id == quizSet?.id);
        return (c && c.condition) || 'not_set';
    }

    function displayError(key) {
        return <>
            {formError[key] &&
            <Alert variant="danger">{formError[key]}</Alert>
            }
        </>
    }

    if (!dataQuizSet || dataQuizSet.length == 0) return <></>;
    return <>
        <CourseSettingHeadBar title='(7) Pass Conditions' onClick={()=>{
            onChange({action:'add',data:{andConditions:[]}});
            onResetError('condition');
        }}/>
        <Card>
            <Card.Body>
                {displayError('condition')}
                {orConditionData.length > 0 &&
                <Card>
                    <Card.Body>
                        {orConditionData.map((andConditions, cIndex) => {
                            return <React.Fragment key={cIndex}>
                                {cIndex == 0 ? null : <Alert variant='info' className="text-center mt-1">OR</Alert>}
                                <Row>
                                    <Col>
                                        <ListGroup>
                                            {
                                                dataQuizSet?.map((quizSet, qsIndex) => {
                                                    return <ListGroupItem key={'item_'+quizSet.id} className={
                                                        getValue(cIndex, qsIndex, quizSet) == 'must_pass' ? 'bg-success text-white' : ''
                                                    }>
                                                        <Row>
                                                            <Col>{quizSet.name}</Col>
                                                            <Col xs="auto">
                                                                <div className={css(style.andBlock)}>
                                                                    <FormControl as='select'
                                                                                 value={getValue(cIndex, qsIndex, quizSet)}
                                                                                 onChange={onConditionsChanged.bind(this, cIndex, qsIndex, quizSet)}
                                                                    >
                                                                        <option value="not_set">- Not set -</option>
                                                                        <option value="must_pass">Must be pass</option>
                                                                    </FormControl>
                                                                    {qsIndex != dataQuizSet.length - 1 ?
                                                                        <Badge variant='warning'
                                                                               className={css(style.andSpan)}>AND</Badge>
                                                                        :
                                                                        null
                                                                    }
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </ListGroupItem>
                                                })
                                            }
                                        </ListGroup>
                                    </Col>
                                    <Col xs='auto'>
                                        <div className={css(style.vcenter)}>
                                            <Button variant='danger' className='btn-sm' onClick={e => {
                                                onRemove(cIndex)
                                            }}>Remove</Button>
                                        </div>
                                    </Col>
                                </Row>
                            </React.Fragment>
                        })}
                    </Card.Body>
                </Card>
                }
            </Card.Body>
        </Card>
    </>
}
const style = StyleSheet.create({
    andBlock: {
        position: 'relative',
    },
    andSpan: {
        position: 'absolute',
        zIndex: 1,
        top: '76%',
        left: '50%',
        transform: 'translate(-50%, 50%)',
    },
    vcenter: {}
});
export default CourseQuizCondition
