import {
    Box,
    Button,
    Card,
    CardActionArea, CardActions,
    CardContent,
    CardHeader,
    Container,
    CssBaseline,
    Grid,
    makeStyles,
    Paper, Typography
} from "@material-ui/core";
import {useQuery} from "@apollo/client";
import {GET_STUDENT_REGISTRATIONS} from "../../gql-query/select-query";
import {createGroup, getThaiDate, sort} from "../../services/client-helper";
import {useHistory} from 'react-router-dom';
import React, {useEffect} from 'react';
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';
import Config from "../../config";
import {observer} from "mobx-react";
import {useMobxState} from "../../mobx/global-context";
import {getAllMonth} from "../../services/helper";
import _ from 'lodash';

const useStyles = makeStyles({
    root: {
        minHeight: 'calc(100% - 100px)',
        height: 'auto !important',
        padding: '1rem',
    },
    control: {
        justifyContent: 'flex-end'
    }
})
const StudentExamSchedule = () => {
    const history = useHistory();
    const state = useMobxState();
    // const {data, error, loading} = useQuery(STUDENT_REGISTERED_SCHEDULE);
    const {data, error, loading} = useQuery(GET_STUDENT_REGISTRATIONS);

    const classes = useStyles();

    function startExam(roundID, seb = false) {
        if (!seb) {
            history.push('/my/examination/' + roundID);
        } else {
            document.location.href = `seb://dq.kku.ac.th${Config.root}/dq.seb`;
        }
    }

    useEffect(() => {

    }, []);

    function getRowObject(studentRegistrations) {
        // let result=_.cloneDeep(studentRegistrations);
        // result.registerRounds = sort(studentRegistrations.registerRounds,'scheduleRound.examStart');
        // let sortedStudentRegistrations = sort(result,'registerRounds[0].scheduleRound.examStart');
        // result = createGroup(sortedStudentRegistrations,'course.id');
        // console.log('result=', result);

        // return result;
        let results = createGroup(studentRegistrations, 'course.id');
        for (let r of results) {
            r.data = createGroup(r.data, 'schedule.id');
        }
        console.log('studentRegistrations==', results);
        return results;
    }

    if (loading) return <div>Loading...</div>
    return <Paper className={classes.root}>
        <Typography variant='h5' className="mb-2">Registered schedule</Typography>
        <hr/>
        {getRowObject(data.studentRegistrations)?.map((course, index) => {
                return <React.Fragment key={course.group.id}>
                    <Grid container key={'grid_' + index}>
                        <Grid item xs={12}>
                            <div className="mb-2" style={{display: 'flex', alignItems: 'center'}}>
                                <Typography variant="h5">{course.group.fullname}</Typography>
                                {/*<AccessAlarmIcon/>*/}
                                {/*<span>Exam {getAllMonth()?.[group.month - 1]} / {group.year}</span>*/}
                            </div>
                                {course.data.map((schedule, i) => {
                                    return <React.Fragment key={schedule.group.id}>
                                        <Box mb={2} mt={3}>
                                            <Grid container>
                                                <Grid item>
                                                    <Typography variant='h6'>{schedule.group.title}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        <Grid container spacing={3}>
                                            {
                                                schedule.data.map(reg => {
                                                    return <Grid item key={reg.id} xs={12} sm={6} style={{background:reg.scheduleRound.quizSet.bg_color}}>
                                                        <Box>
                                                            <Card>
                                                                <CardHeader subheader={'Examination'}
                                                                            title={reg.scheduleRound.quizSet.name}/>
                                                                <CardContent>
                                                                    <div>
                                                                        <div>Detail</div>
                                                                        <div>Time
                                                                            limit: {reg.scheduleRound.quizSet.minutes} Minutes
                                                                        </div>
                                                                    </div>
                                                                    <hr/>
                                                                    <div className="font-weight-bold">
                                                                        <div
                                                                            className="mb-1">Start: {getThaiDate(reg.scheduleRound.examStart)}</div>
                                                                        <div>End: {getThaiDate(reg.scheduleRound.examEnd)}</div>
                                                                    </div>
                                                                </CardContent>
                                                                <CardActions className={classes.control}>
                                                                    <Button variant='contained' color='primary'
                                                                            onClick={e => startExam(reg.id)}>Start
                                                                        Exam</Button>
                                                                    {!state.seb &&
                                                                    <Button variant='contained' color='secondary'
                                                                            onClick={e => startExam(reg.id, true)}>Start
                                                                        Seb</Button>
                                                                    }
                                                                </CardActions>
                                                            </Card>
                                                        </Box>
                                                    </Grid>
                                                })
                                            }
                                        </Grid>
                                        <Box p={1}>
                                            <hr/>
                                        </Box>
                                    </React.Fragment>
                                })}
                        </Grid>
                    </Grid>
                </React.Fragment>
            }
        )}
    </Paper>
}
export default observer(StudentExamSchedule);
