import StudentBreadcrumb from "../../components/student-breadcrumb";
import React, {useState} from "react";
import {Box, Button, makeStyles, Paper, Typography} from "@material-ui/core";
import {useQuery} from "@apollo/client";
import {GET_ALL_USER_BILLS} from "../../gql-query/select-query";
import BillPayment from "../../components/bill-payment";
import {Modal} from "react-bootstrap";
import {Alert} from "@material-ui/lab";
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import {getThaiDate} from "../../services/client-helper";

const useStyles = makeStyles(theme=>({
    scheduleType: {
        padding: 5,
        borderRadius: 10,
        textAlign: 'center'
    },
}))
const Bill = ()=>{
    const {data,loading,error} = useQuery(GET_ALL_USER_BILLS,{
        fetchPolicy:'network-only'
    })
    const [selectedBill,setSelectedBill] = useState();
    const classes = useStyles();

    if(loading)return<div>Loading...</div>
    return <Box>
        <Paper>
            <Box p={2}>
                <Typography variant="h5">Bills</Typography>
                <hr/>
                {data.getBills.length > 0?
                    <>
                        {data.getBills.map(bill=>
                            <>
                                {bill.registers.length > 0 && !bill.wallet?
                                    <React.Fragment key={bill.id}>
                                        <BillPayment bill={bill} type='no-qrcode'/>
                                        <Box display='flex' justifyContent='flex-end'>
                                            <Button variant='contained' color='primary' onClick={e=>setSelectedBill(bill)}>Pay Now / {bill.amount} THB</Button>
                                        </Box>
                                    </React.Fragment>
                                    :
                                    <Box p={2}>
                                       <AccountBalanceWalletIcon style={{color:'#ff9800'}}/> {getThaiDate(bill.wallet?.created)} / {bill.detail} / {bill.amount}
                                        <hr/>
                                    </Box>
                                }
                            </>
                        )}
                    </>
                    :
                    <Alert severity='info'>You have no any bill.</Alert>
                }

            </Box>
        </Paper>
        <Modal show={!!selectedBill} onHide={e => setSelectedBill(null)} size='xl'>
            {selectedBill &&
            <>
                <Modal.Header closeButton>
                    <Modal.Title>Bill detail</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <BillPayment bill={selectedBill} type='all'/>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='contained' color='primary' onClick={e=>setSelectedBill(null)}>Pay later</Button>
                </Modal.Footer>
            </>
            }
        </Modal>
    </Box>
}
export default Bill;
