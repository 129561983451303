import {
    Box,
    Checkbox,
    Fade,
    FormControl,
    FormControlLabel,
    FormLabel,
    makeStyles,
    MenuItem,
    Radio,
    RadioGroup
} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import RegisterStepButton from "./register-step-button";
import {useMobxState} from "../mobx/global-context";
import {observer} from "mobx-react";
import {runInAction} from "mobx";
import moment from "moment";
import {getThaiDateBetweenSingleDay} from "../services/client-helper";
import _ from 'lodash';
import {Block} from "../styled-components/block";
import {Badge} from "react-bootstrap";
import axios from "axios";

const useStyles = makeStyles(theme => ({
    root: {}
}))

const RegisterStep1 = ({courseDB, userRegistrations, setCurrentStep}) => {
    const classes = useStyles();
    const state = useMobxState();
    const [registrationCourse, setRegistrationCourse] = useState();
    const [schedules, setSchedules] = useState({});

    // const schedules = useQuery(GET_ALL_SCHEDULE_WITH_COUNTER, {
    //     fetchPolicy: "network-only",
    //     variables: {
    //         past: false
    //     }
    // });

    // const schedulesByCourse = useQuery(GET_SCHEDULE_BY_COURSE_ID, {
    //     fetchPolicy: "network-only",
    //     variables: {
    //         courseID: courseDB.data?.courseDBbyID.id
    //     }
    // });

    useEffect(() => {
        axios.get('/api/getAllSchedules').then(res => {
            setSchedules({
                data: {
                    getAllSchedules: res.data?.getAllSchedulesBySV || []
                }
            });
        }).catch(e => {
            console.log('getAllSchedules Error=', e.message);
        })
    }, []);


    useEffect(() => {
        if (schedules.data) {
            state.registerStep2Data = {
                ...state.registerStep2Data,
                allSchedules: schedules.data?.getAllSchedules,
            }
        }
        //     let regCourse = reg.data.studentRegistrations.find(v=>v.course.id==courseDB.data.courseDBbyID.id);
        //     setRegistrationCourse(regCourse);
        //     console.log(regCourse);

    }, [schedules.data])

    function onFormChange(quizSet, e) {
        let {checked} = e.target;
        let buffers;
        if (checked) {
            buffers = [...state.registerQuizSet, quizSet];
        } else {
            buffers = _.reject(state.registerQuizSet, v => v.id == quizSet.id);
        }
        runInAction(() => {
            state.registerQuizSet = [...buffers];
        });
    }

    function onSelScheduleChange(e) {
        let id = e.target.value;
        state.registerStep2Data = {
            ...state.registerStep2Data,
            scheduleID: id,
            selectedSchedule: schedules.data?.getAllSchedules.find(v => v.id == id),
        }
    }

    function userExistedRegistrationRound(scheduleID, quizSetID) {
        if (!scheduleID) return;
        return userRegistrations.data?.studentRegistrations.find(v =>
            v.schedule.id == scheduleID && v.scheduleRound.quizSet.id == quizSetID
        );
    }

    //already registered
    function scheduleIsNotAvailable(schedule) {
        let {studentRegistrations} = userRegistrations.data;
        let regSchedules = studentRegistrations.filter(reg => reg.schedule.id == schedule.id);
        let uniqueRegModuleTypes = [...new Set(regSchedules.map(reg => reg.scheduleRound.quizSet.name))];
        let uniqueModuleTypes = [...new Set(schedule.rounds.map(round => round.quizSet.name))];
        if (uniqueRegModuleTypes.length == uniqueModuleTypes.length) {
            return true;
        }
        return false;
    }

    if (schedules.loading) return <div>Loading...</div>
    return <div className={classes.root}>
        <Box p={2}>
            <FormControl component="fieldset" style={{width: '100%'}}>
                <FormLabel component="legend">Choose Schedules.</FormLabel>
                <RadioGroup name="schedule" value={state.registerStep2Data?.scheduleID || ''}
                            disabled={true}
                            onChange={onSelScheduleChange}>
                    {schedules.data?.getAllSchedules.length === 0 &&
                        <MenuItem value={''}>No schedule available</MenuItem>}
                    {schedules.data?.getAllSchedules
                        .filter(v => v.course.id == courseDB.data?.courseDBbyID.id)
                        .map(v => {
                            let disabledSchedule = scheduleIsNotAvailable(v);
                            let label = <div>
                                <strong style={{fontWeight: 500}}>{v.title}{disabledSchedule ?
                                    <Badge style={{fontWeight: 400}} className="ml-2"
                                           variant="success">Registered</Badge>
                                    :
                                    ''
                                }
                                </strong>
                                <div className="d-block d-md-none">
                                    <div>{moment(parseInt(v.registerStart, 10)).format('LLLL')}</div>
                                    <div><strong>- ถึง -</strong></div>
                                    <div>{moment(parseInt(v.registerEnd, 10)).format('LLLL')}</div>
                                </div>
                                <div className="d-none d-md-block">
                                    <div>
                                        <strong className="mr-2" style={{fontWeight: 500}}>ลงทะเบียนระหว่าง</strong>
                                        {moment(parseInt(v.registerStart, 10)).format('LLLL')}
                                        <strong style={{fontWeight: 500}}> ถึง </strong>
                                        {moment(parseInt(v.registerEnd, 10)).format('LLLL')}
                                    </div>
                                </div>
                            </div>
                            return <Block key={v.id} outline className="p-1 m-1"
                                          style={{boxShadow: '1px 1px 1px #c5c5c5'}}>
                                <FormControlLabel disabled={disabledSchedule} value={v.id} control={<Radio/>}
                                                  label={label}/>
                            </Block>
                        })}
                </RadioGroup>
            </FormControl>
        </Box>
        <Fade in={!!state.registerStep2Data?.scheduleID} unmountOnExit>
            <Box p={2}>
                <FormControl component="fieldset">
                    <FormLabel component="legend">Select Registration Type.</FormLabel>
                    <RadioGroup name="quizSet" value={true}>
                        {courseDB.data?.courseDBbyID.quizSet.map(qs => {
                            let existedReg = userExistedRegistrationRound(state.registerStep2Data?.scheduleID, qs.id);
                            let scheduleRound = existedReg?.scheduleRound;
                            console.log('existedReg=', existedReg);
                            return <FormControlLabel key={qs.id}
                                                     disabled={!!existedReg}
                                                     checked={
                                                         !!state.registerQuizSet.find(q => q.id == qs.id) || !!existedReg
                                                     }
                                                     onChange={onFormChange.bind(this, qs)} control={<Checkbox/>}
                                                     label={
                                                         existedReg ?
                                                             <div>
                                                                 <strong>{qs.name}</strong>
                                                                 <Block outline className="mt-1">
                                                                     <div><strong>Registered</strong></div>
                                                                     <div>
                                                                         {getThaiDateBetweenSingleDay(scheduleRound.examStart, scheduleRound.examEnd)}
                                                                         {/*<div>{moment(parseInt(scheduleRound.examStart,10)).format('LLLL')}</div>*/}
                                                                         {/*<div><strong>- ถึง -</strong></div>*/}
                                                                         {/*<div>{moment(parseInt(scheduleRound.examEnd,10)).format('LLLL')}</div>*/}
                                                                     </div>
                                                                 </Block>
                                                                 {/*<div>Registered: {getThaiDateBetween(existedReg.scheduleRound.examStart,existedReg.scheduleRound.examEnd,'date-time')}</div>*/}
                                                             </div>
                                                             :
                                                             qs.name
                                                     }/>
                        })}
                    </RadioGroup>
                </FormControl>
                <RegisterStepButton hr
                                    condition={state.registerQuizSet.length > 0}
                                    onNext={e => setCurrentStep(2)}
                                    onBack={e => setCurrentStep(0)}
                />
            </Box>
        </Fade>
    </div>
}
export default observer(RegisterStep1);
