import {Card} from 'react-bootstrap';

const Section = ({children,title})=>{
    return <div className="container text-center">
        <div className="heading">
            <h2>{title?title:'Untitled'}</h2>
        </div>
        <div className="line" />
        <div className="row">
            <div className="col-md-12">
                <div className="box">
                    {children}
                </div>
            </div>
        </div>
    </div>
}
export default Section
