import {Button, FormControl, FormGroup, FormLabel} from "react-bootstrap";
import {useEffect, useRef, useState} from "react";

const GradeUploader = ({name,dqQuizID,readFile,scoreData,onRemove}) => {

    const [data,setData] = useState(/** @type {ScoreData[]} */[]);
    const file = useRef();

    useEffect(() => {
        setData(scoreData.filter(s=>s.dqQuizID===dqQuizID));
    }, [scoreData]);

    return <>
        <div style={{
            border: '1px solid #ccc',
            padding: '10px',
            marginBottom: '10px'
        }}>
            <FormGroup>
                <FormLabel>Quiz {name}</FormLabel>
                <FormControl ref={file} type='file' onChange={e => readFile(dqQuizID, e)}/>
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginTop: 10,
                }}>
                    <div>Student: {data.length}</div>
                    <div><Button variant='danger' onClick={e => {
                        file.current.value = '';
                        onRemove(dqQuizID);
                    }}>Remove</Button></div>
                </div>
            </FormGroup>
        </div>
    </>
}
export default GradeUploader
