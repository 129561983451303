import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Grid,
    makeStyles,
    Paper, TableBody, TableCell,
    TableContainer, TableHead, TableRow,
    Typography,
    Table, List, ListItem, ListItemAvatar, Avatar, ListItemText, Tooltip, Divider, Hidden
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import {useApolloClient, useLazyQuery, useMutation, useQuery} from "@apollo/client";
import {
    GET_COURSE,
    GET_COURSE_DB_WITH_ROUNDS, GET_CURRENT_USER,
    GET_STUDENT_REGISTRATIONS,
    GET_USER_SCORE
} from "../../gql-query/select-query";
import React, {useEffect, useRef, useState} from 'react';
import {
    createGroup, getServerTime,
    getThaiDate,
    getThaiDateBetween,
    isCurrentDateBetween,
    pad2,
    sort
} from "../../services/client-helper";
import EventIcon from '@material-ui/icons/Event';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import EventBusyIcon from '@material-ui/icons/EventBusy';
import {deepOrange, green, lightGreen, orange, pink, red} from "@material-ui/core/colors";
import clsx from "clsx";
import EqualizerIcon from '@material-ui/icons/Equalizer';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import _ from 'lodash';
import moment from "moment";
import {DELETE_SINGLE_REG, DELETE_SCHEDULE} from "../../gql-query/delete-query";
import MButton from "../../components/mbutton";
import {Badge, Image, Modal} from "react-bootstrap";
import {useMobxState} from "../../mobx/global-context";
import {useHistory} from "react-router-dom";
import {runInAction} from "mobx";
import {requestAPI} from "../../services/requester";
import BillPayment from "../../components/bill-payment";
import {toast} from "react-toastify";
import {confirmAlert} from "react-confirm-alert";
import RegistrationPicker from "../../components/registration-picker";
import ReceiptIcon from '@material-ui/icons/Receipt';
import DeleteIcon from '@material-ui/icons/Delete';
import CompareArrowsIcon from '@material-ui/icons/CompareArrows';
import {SAVE_CHANGE_SCHEDULE} from "../../gql-query/save-query";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import EditIcon from '@material-ui/icons/Edit';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import Countdown from "../../components/countdown";
import {observer} from "mobx-react";
import gql from "graphql-tag";
import Config from "../../config";
import StudentProgression from "../../components/student-progression";
import StudentDashboard from "../../components/student-dashboard";

const useStyles = makeStyles(theme => ({
    '@global': {
        '.modal-backdrop': {
            zIndex: 2000,
        },
        '.modal': {
            zIndex: 3001,
            // marginTop: '0%'
        }
    },
    statusCard: {
        // minHeight: 180
    },
    pink: {
        color: theme.palette.getContrastText(pink[500]),
        backgroundColor: pink[500],
    },
    green: {
        color: '#fff',
        backgroundColor: green[500],
    },
    orange: {
        color: '#fff',
        backgroundColor: orange[500],
    },
    avatar: {
        width: 64,
        height: 64,
    },
    t: {
        flexWrap: 'nowrap'
    },
    topGrid: {
        justifyContent: 'center',
        alignItems: 'baseline',
    },
    scheduleType: {
        padding: 5,
        borderRadius: 10,
        textAlign: 'center'
    },
    tableCell: {
        cursor: 'pointer',
        '&:hover': {
            background: '#35afea',
            '& td': {
                color: '#fff'
            },
            transition: 'all 200ms'
        }
    },
    billDetailRow: {
        marginTop: 5,
        '& div:first-child': {
            fontWeight: 'bold'
        }
    }
}));


const TopGridCard = ({children, title, icon, avatarClass, lastChild}) => {

    const classes = useStyles();

    return <Grid item xs={12}>
        <Card className={classes.statusCard}>
            <CardContent>
                <Grid container spacing={2} className={classes.t}>
                    <Grid item xs>
                        <Typography variant="h6" color="textPrimary">{title}</Typography>
                        <Box mt={2}>
                            {children}
                        </Box>
                    </Grid>
                    <Hidden xsDown>
                        <Grid item>
                            <Avatar className={clsx(classes.avatar, avatarClass)}>
                                {icon}
                            </Avatar>
                        </Grid>
                    </Hidden>
                </Grid>
            </CardContent>
        </Card>
    </Grid>
}

// const gqlRequest=(info,query,options={})=>{
//     const [result,setResult] = useState();
//     const [getCourseWithRounds,courseWithRounds] = useLazyQuery(query,
//         {
//             fetchPolicy:'network-only',
//             onCompleted: (data) => {
//                 console.log('onCompleted11===',data);
//                 setResult(data);
//             },
//             ...options
//         });
//     return result;
// }


const Dashboard = () => {
    const state = useMobxState();
    const client = useApolloClient();
    const {data: dataSchedule, loading: loadingSchedule,refetch:refetchRegistration} = useQuery(GET_STUDENT_REGISTRATIONS, {
        fetchPolicy: 'cache-first',
        variables:{
            userID:state.currentUser?.id
        }
    });
    // const {loading: loadingScore, data: dataScore, refetch: refetchScore} = useQuery(GET_USER_SCORE, {
    //     fetchPolicy: 'network-only'
    // });
    const [deleteSingleReg] = useMutation(DELETE_SINGLE_REG, {
        refetchQueries: [
            {query: GET_STUDENT_REGISTRATIONS}
        ]
    });
    const [deleteSchedule] = useMutation(DELETE_SCHEDULE, {
        refetchQueries: [
            {query: GET_STUDENT_REGISTRATIONS}
        ]
    });

    const [saveChangeSchedule] = useMutation(SAVE_CHANGE_SCHEDULE, {
        refetchQueries: [
            {query: GET_STUDENT_REGISTRATIONS}
        ]
    });

    const [selectedBillReg, setSelectedBillReg] = useState(null);
    const [selectedRegRound, setSelectedRegRound] = useState();
    const [selectedChangeRegData, setSelectedChangeRegData] = useState();
    const [regRoundDic, setRegRoundDic] = useState([]);
    const [examReady, setExamReady] = useState({});
    const history = useHistory();

    const apolloClient = useApolloClient();


    // const resMyProgression = useQuery(GET_MY_PROGRESSION);


    // const [getCourseWithRounds, courseWithRounds] = useLazyQuery(GET_COURSE_DB_WITH_ROUNDS,
    //     {
    //         fetchPolicy: 'network-only',
    //         onCompleted: (data) => {
    //             console.log('onCompleted11===', data);
    //         }
    //     })


    // useEffect(() =>{
    //     if(selectedBillReg?.bill){
    //         requestAPI('GET','/qrcode/bill/'+selectedBillReg.bill.id).then(res=>{
    //             setCurrentBillQRCode(res.data.image);
    //         });
    //     }
    // },[selectedBillReg]);

    useEffect(()=>{
        state.updateServerTime();
    },[]);

    function closeModal() {
        setSelectedRegRound(null);
    }

    // useEffect(() => {
    //     // console.log('dataScheudle============',dataSchedule?.studentRegistrations);
    // }, [loadingSchedule])
    //
    // useEffect(() =>{
    //     console.log('courseWithRounds.data===', courseWithRounds.data);
    // },[courseWithRounds.data])

    // function groupedStudentRegistrations() {
    //     let sorted = sort(dataSchedule.studentRegistrations, 'course.id', 'asc');
    //     let groupedByCourse = createGroup(sorted, 'course.id');
    //     for (let g of groupedByCourse) {
    //         g.data = createGroup(g.data, 'schedule.id');
    //     }
    //     // console.log('groupedByCourse_schedule===', groupedByCourse);
    //     return groupedByCourse
    // }

    async function onChangeSchedule(course, schd, regs) {
        let gqlCourse = await apolloClient.query({
            query: GET_COURSE_DB_WITH_ROUNDS,
            variables: {id: course.id},
            fetchPolicy: 'network-only'
        })
        console.log('gqlCourse.data.courseDBbyID===', gqlCourse.data.courseDBbyID);
        // let regs = dataSchedule?.studentRegistrations.filter(v=>v.schedule.id==schd.id);
        let calendarDetail = {
            course: gqlCourse.data.courseDBbyID,
            schedule: schd,
            modules: regs.map(r => r.scheduleRound.quizSet),
            selectedRounds: regs.map(r => r.scheduleRound)
        }
        await client.resetStore();
        console.log('calendarDetail=', calendarDetail)
        setSelectedChangeRegData({...calendarDetail});
        setRegRoundDic(
            regs.map(reg=>({regID:reg.id,roundID:reg.scheduleRound.id}))
        )
    }

    function onDeleteSchedule(schedule) {
        confirmAlert({
            title: `Please confirm.`,
            message: `Do you want to delete schedule: ${schedule.title}.`,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        deleteSchedule({
                            variables: {
                                id: schedule.id
                            }
                        }).then(async res => {
                            if(res.data.deleteSchedule){
                                toast.success(`${schedule.title} has been canceled.`);
                                await client.resetStore();
                                try{
                                    let resUser = await apolloClient.query({
                                        query:GET_CURRENT_USER,
                                        fetchPolicy:'network-only',
                                    })
                                    state.currentUser=resUser.data.currentUser;
                                }catch(e){
                                    console.log(e.message);
                                }
                            }else{
                                toast.error(`${schedule.title} delete failed.`);
                            }
                        }).catch(e => {
                            toast.error(e.message);
                        })
                    }
                },
                {
                    label: 'No',
                    onClick: () => {
                    }
                }
            ]
        })
    }

    async function onDeleteSingleRegRound(reg) {
        confirmAlert({
            title: `Please confirm.`,
            message: `Do you want to delete module: ${reg.scheduleRound.quizSet.name}.`,
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        let success = await deleteSingleReg({
                            variables: {
                                regID: reg.id
                            }
                        })
                        if (success) {
                            setSelectedRegRound(null);
                            await client.resetStore();
                            toast.success('Deleted.');
                        } else {
                            toast.error('Cannot delete your schedule.');
                        }
                    }
                },
                {
                    label: 'No',
                    onClick: () => {
                    }
                }
            ]
        })
    }

    function submitChangeRound() {
        regRoundDic.forEach(async rDic => {
            let reg = dataSchedule?.studentRegistrations.find(r=>r.id==rDic.regID);
            let selectedRound=selectedChangeRegData?.selectedRounds.find(r=>r.id==rDic.roundID);
            if(!reg || !selectedRound)return;
            let label = `${reg.schedule.title} -> ${getThaiDateBetween(selectedRound.examStart,selectedRound.examEnd,'date-time')}`
            if(reg.scheduleRound.id == selectedRound.id){
                toast.info(`Unchanged schedule ${label}.`);
                setSelectedChangeRegData(null);
                return;
            }
            try{
                 let result = await saveChangeSchedule({
                    variables: {
                        registrationID:rDic.regID,
                        scheduleRoundID:rDic.roundID
                    }
                })
                let {success,error,message} = result?.data?.changeScheduleRound;
                 if(success){
                     await client.resetStore();
                     toast.success(`Change schedule to ${label} success.`)
                 }else{
                     toast.error(`Change schedule  to ${label} failed, Reason ${message}`)
                 }
                setSelectedChangeRegData(null);
            }catch(e){
                toast.error(`Something went wrong, ${e.message}`);
            }
        })
    }

    function getPaidState(reg){
        if(reg.bill){
            if(reg.bill.isPaid){
                return <span><CheckCircleIcon style={{color:'#4caf50'}}/> Paid</span>
            }else{
                return <span>No</span>
            }
        }else{
            if(reg.usedWallet && reg.usedWallet>0){
                return <span><AccountBalanceWalletIcon style={{color:'#ff9800'}}/> Wallet Paid</span>
            }
        }
        return <span>Free</span>
    }

    function getCounter(reg){
        return <Countdown showDay={false} expireTimestamp={reg.scheduleRound.examStart} style={{fontSize:'inherite'}} onExpired={e=>{
            setExamReady(prevState => ({
                ...prevState,
                [reg.id]:true,
            }))
        }}/>
    }

    function showScore(examResult){
        if(!examResult)return '-';
        let {quizResults} = examResult;
        if(quizResults.length > 0){
            return quizResults.map(q=><div>
                <div>{q.dqQuiz.name}: <Badge variant='primary'>{q.score}%</Badge></div>
            </div>);
        }else{
            return '-'
        }
    }

    const classes = useStyles();
    const reloadProgress = useRef();
    if (loadingSchedule) return <div>Loading...</div>
    return <Box className={classes.root}>
        <Paper>
            <Box p={2}>
                <Typography variant='h5'>Home</Typography>
                <hr/>
                {/*{!loadingSchedule &&*/}
                    <StudentProgression userID={state.currentUser.id} refetch={reloadProgress}/>
                {/*}*/}
                <hr/>

                <StudentDashboard
                    studentRegistrations={dataSchedule?.studentRegistrations}
                    onDelete={schdDetail=>{
                        onDeleteSchedule(schdDetail);
                        if(typeof reloadProgress?.current === 'function')reloadProgress.current();
                    }}
                    onChange={onChangeSchedule}
                />

            </Box>
        </Paper>
        <Modal show={!!selectedChangeRegData} onHide={e => setSelectedChangeRegData(null)} size='xl'>
            {selectedChangeRegData &&
            <>
                <Modal.Header closeButton>
                    <Modal.Title>Change Schedule</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <RegistrationPicker
                        removeAble={false}
                        userRegistrations={dataSchedule?.studentRegistrations}
                        currentCourse={selectedChangeRegData?.course}
                        currentSchedule={selectedChangeRegData?.schedule}
                        modules={selectedChangeRegData?.modules}
                        selectedRounds={selectedChangeRegData?.selectedRounds}
                        onSelectedRound={(round) => {
                            let oldSelected = selectedChangeRegData?.selectedRounds.find(v => v.quizSet.name == round.title);
                            let rDic = regRoundDic.find(v=>v.roundID==oldSelected.id);
                            if(rDic){
                                rDic.roundID=round.id;
                                setRegRoundDic([...regRoundDic]);
                                selectedChangeRegData.selectedRounds = _.reject(selectedChangeRegData.selectedRounds,
                                    v => v.quizSet.name == round.title
                                )
                                selectedChangeRegData.selectedRounds.push(round);
                                setSelectedChangeRegData({
                                    ...selectedChangeRegData
                                })
                            }
                        }}
                        onRemoveRound={(id) => {
                            selectedChangeRegData.selectedRounds = _.reject(selectedChangeRegData.selectedRounds,
                                v => v.id == id
                            )
                            setSelectedChangeRegData({
                                ...selectedChangeRegData
                            })
                        }}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Box display='flex' justifyContent="flex-end" style={{gap: 10}}>
                        <Button variant='contained' color='inherit'
                                onClick={e => setSelectedChangeRegData(null)}>Cancel</Button>
                        <Button variant='contained' color='primary' onClick={e => submitChangeRound()}>Save
                            change</Button>
                    </Box>
                </Modal.Footer>
            </>
            }
        </Modal>
        {/*<Modal show={!!selectedBillReg} onHide={e => setSelectedBillReg(null)} size='xl'>*/}
        {/*    {selectedBillReg &&*/}
        {/*    <>*/}
        {/*        <Modal.Header closeButton>*/}
        {/*            <Modal.Title>Bill detail</Modal.Title>*/}
        {/*        </Modal.Header>*/}
        {/*        <Modal.Body>*/}
        {/*            <BillPayment bill={selectedBillReg.bill} type='all' onExpired={()=>{*/}
        {/*                refetchRegistration()*/}
        {/*                    .then(()=>{})*/}
        {/*                    .catch(e=>{})*/}
        {/*            }}/>*/}
        {/*        </Modal.Body>*/}
        {/*        <Modal.Footer>*/}
        {/*            <Box display='flex' justifyContent="flex-end" style={{gap: 10}}>*/}
        {/*                <Button variant='contained' color='primary' onClick={e => setSelectedBillReg(null)}>Pay*/}
        {/*                    later</Button>*/}
        {/*            </Box>*/}
        {/*        </Modal.Footer>*/}
        {/*    </>*/}
        {/*    }*/}
        {/*</Modal>*/}
        <Modal show={!!selectedRegRound} onHide={closeModal}>
            <Modal.Header closeButton>
                <Modal.Title>Registration info</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {selectedRegRound &&
                <Box>
                    <Box p={2}>
                        <Typography variant='h6'>{selectedRegRound?.course.fullname}</Typography>
                    </Box>
                    <TableContainer>
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell>Type</TableCell>
                                    <TableCell>
                                        <Typography>{selectedRegRound?.regRound.scheduleRound.quizSet.name}</Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Start/End</TableCell>
                                    <TableCell>
                                        <Typography>
                                            {getThaiDateBetween(selectedRegRound?.regRound.scheduleRound.examStart, selectedRegRound?.regRound.scheduleRound.examEnd, 'date-time')}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Location</TableCell>
                                    <TableCell>
                                        <Typography>{selectedRegRound?.regRound.scheduleRound.location.name}</Typography>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
                }
            </Modal.Body>
            <Modal.Footer>
                <Box display='flex' style={{gap: 10, flexWrap: 'wrap'}}>
                    {state.systemConfig.change_schedule_enabled &&
                    <MButton variant="contained" color='warning' onClick={
                        e => onChangeSchedule(selectedRegRound?.course,
                            selectedRegRound?.regRound?.schedule,
                            [selectedRegRound?.regRound]
                        )}>
                        Change
                    </MButton>
                    }
                    {!selectedRegRound?.regRound?.bill && !selectedRegRound?.regRound?.usedWallet &&
                    <Button variant="contained" color='secondary'
                            onClick={e => onDeleteSingleRegRound(selectedRegRound?.regRound)}>
                        Delete
                    </Button>
                    }
                </Box>
            </Modal.Footer>
        </Modal>
    </Box>
}
export default observer(Dashboard);
