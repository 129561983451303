import {useParams} from "react-router-dom";
import {Box, Paper} from "@material-ui/core";
import ReactPlayer from 'react-player'
import {
    Alert,
    Badge,
    Button,
    ButtonGroup,
    Card,
    Col,
    Form,
    FormControl,
    Image,
    InputGroup,
    ListGroup,
    ListGroupItem,
    Modal,
    Row,
    Table
} from "react-bootstrap";
import gql from "graphql-tag";
import {useApolloClient, useQuery} from "@apollo/client";
import {useEffect, useRef, useState} from "react";
import {toast} from "react-toastify";
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import _ from 'lodash';
import {getThaiDate, getThaiDateBetween, getThaiDateBetweenSingleDay} from "../../services/client-helper";
import Config from "../../config";
import axios from "axios";
import {requestAPI} from "../../services/requester";
import {FaRocketchat, FaTv} from 'react-icons/fa';
import {useSocket} from "../../context/SocketProvider";
import { BsRewindCircle } from "react-icons/bs";
import CheckInViewModel from "../../view-models/check-in-view-models";

const Peer = require('simple-peer');

const COURSE_CHECK_IN_LIST = gql`
query CourseCheckInList($checkInID: ID) {
  scheduleRoundCheckIn(id: $checkInID) {
    id
    checkIns {
      id
      user {
        id
        username
        studentID
        prefix
        email
        fname
        lname
        ssoRole {
          id
          name
        }
      }
      register{
        id
      }
      scheduleRoundCheckIn{
        id
        groupName
        googleMeetURL
        lastUpdate        
      }
      rejectMessages{
        id
        content
        created
      }
      rejectMessageUpdate
      messages{
        id
        content
        created
      }
      messageUpdate
      avatarName
      approved
      lastUpdate
    }
    schedule {
      id
      examStart
      examEnd
      registerStart
      registerEnd
      course {
        id
        name
        fullname
        shortname
        idnumber
      }
    }
    scheduleRound {
      id
      examStart
      examEnd
      maxSeats
    }
    autoRejectLogs
    groupName
    googleMeetURL
    lastUpdate
  }
}
`;
const REQUEST_SCREEN = gql`
mutation RequestScreen($userID: ID,$roundID: ID) {
  requestScreen(userID: $userID,roundID: $roundID){
    uid,
    sdp
  }
}
`;
const ANSWER_SCREEN = gql`
mutation RequestScreen($uid: ID,$sdp: String) {
  requestScreen(uid: $uid,sdp: $sdp){
    uid
  }
}
`;


let GET_ROUND_DETAIL = gql`
    query GET_ROUND($roundID:ID!) {
        getRoundDetail(roundID:$roundID) {
            quizSet{
                name
                minutes
                bg_color
                text_color
            }
            location {
                id
                name
            }
            schedule{
                title
                course{
                    name
                    fullname
                }
            }
        }
    }
`
const CheckInStudentGroup = () => {
    // const [participants,setParticipants]  = useState([]);
    const {roundID, checkInID:scheduleRoundCheckIn} = useParams();
    const resCheckIn = useQuery(COURSE_CHECK_IN_LIST, {
        fetchPolicy: 'network-only', variables: {
            checkInID:scheduleRoundCheckIn
        }
    });
    const [checkInList, setCheckList] = useState([]);
    const [checkInInfo, setCheckInInfo] = useState();
    const [meetRoom, setMeetRoom] = useState();
    const [filter, setFilter] = useState();
    const [broadcastState, setBroadcastState] = useState(1);
    const [userPairingText, setUserPairingText] = useState('');
    const [userPairing, setUserPairing] = useState(null);
    const [enableBroadcast, setEnableBroadcast] = useState(false);
    const [modalData, setModalData] = useState();
    const [socketReady, setSocketReady] = useState(false);
    const [showScreen, setShowScreen] = useState(false);
    const [screen, setScreen] = useState();
    const [onlineUsers, setOnlineUsers] = useState([]);
    const apolloClient = useApolloClient();
    const screenSocketID = useRef(null);
    // const socket = useRef(null);
    const peerList = useRef([]);
    const [autoRejectToken,setAutoRejectToken] = useState('');
    const msgTextArea = useRef();
    const {adminConnect,isConnected,on,socket,emit} = useSocket();
    const refetchTimer = useRef(null);
    const [tempCheckInBtnState,setTempCheckInBtnState] = useState({});
    const [imageErrorList,setImageErrorList] = useState({});

    const [course,setCourse] = useState();
    const [schedule,setSchedule] = useState();
    const [quizSet,setQuizDet] = useState();
    const checkInViewModel = CheckInViewModel();
    const [checkInConfig,setCheckInConfig] = useState();
    const [allSchedules,setAllSchedules] = useState([]);

    const resRoundDetail = useQuery(GET_ROUND_DETAIL, {
        fetchPolicy: 'network-only', variables: {
            roundID
        }
    });

    useEffect(()=>{
        reloadCheckInConfig().then().catch(e=>console.log(e));
    },[])

    async function reloadCheckInConfig(){
        try{
            let schds = await axios.get('/api/schedules');
            setAllSchedules(schds.data);
            let res = await axios.get('/api/get-check-in-config');
            setCheckInConfig(res.data);
        }catch (e){
            console.log(e);
        }
    }

    useEffect(()=>{
        if(resRoundDetail.data?.getRoundDetail){
            let roundDetail = resRoundDetail.data?.getRoundDetail
            setCourse(roundDetail.schedule.course)
            setSchedule(roundDetail.schedule)
            setQuizDet(roundDetail.quizSet)
        }
    },[resRoundDetail.data])

    useEffect(()=>{
        if(socket && isConnected){
            console.log('OK CONTEXT CONNECTED');
            on('ip-check',async () => {
                await reloadCheckInConfig();
                console.log('check-in-config reloaded');
            });
            on('auto-reject',user=>{
                resCheckIn.refetch();
                setTempCheckInBtnState({});
            })
            on('online-users',users=>{
                setOnlineUsers([...users]);
            })
            on('connect',()=>{
                console.log('SOCKET CONNECTED');
                setSocketReady(true);
            })
            on('disconnect',()=>{
                peerList.current.forEach(p=>p?.destroy());
                console.log('SOCKET Disconnect');
            })
            on('reject-screen',()=>{
                setShowScreen(null);
                toast.error('Client has been reject screen sharing.')
            });
            on('accepted-screen',({socketID,sdp})=>{
                screenSocketID.current=socketID;
                for(let p of peerList.current) {
                    p?.destroy();
                }
                peerList.current=[];
                let peer = new Peer({ initiator: false,trickle: false});
                peer.on('signal',data => {
                    socket.emit('admin-answer',{socketID,sdp:data});
                })
                peer.on('connect',()=>{
                    console.log("CONNECTED");
                })
                peer.on('stream',stream=>{
                    setScreen(stream);
                })
                peer.on('close', () => {
                    console.log('PEER CLOSE');
                })
                peer.signal(sdp);
                peerList.current.push(peer);
            });
            requestAPI('GET','/get-meet-token/'+roundID).then(res=>{
                let {token} = res.data;
                setAutoRejectToken(token);
            }).catch(e=>{
                console.log(e);
            })
        }
        return ()=>{
            peerList.current.forEach(p=>p?.destroy());
            socket?.disconnect();
        }
    },[isConnected])

    useEffect(()=>{
        refetchTimer.current = setInterval(()=>{
            resCheckIn.refetch();
            setTempCheckInBtnState({});
        },30000);
        new Promise(async resolve => {
            let resToken = await axios.post(`${Config.api_url}/generate-token`,{admin:true});
            let token = resToken?.data?.token;
            adminConnect(roundID,token);

            // if(socket.current)socket.current?.disconnect();
            // socket.current = io(pkg.domain_name, {
            //     path:`/api/dq-socket`,
            //     reconnectionDelayMax: 10000,
            //     auth: {token},
            //     query: {roundID}
            // });
            // socket.current.on('auto-reject',user=>{
            //     resCheckIn.refetch();
            // })
            // socket.current.on('online-users',users=>{
            //     setOnlineUsers([...users]);
            // })
            // socket.current.on('connect',()=>{
            //     console.log('SOCKET CONNECTED');
            //     setSocketReady(true);
            // })
            // socket.current.on('disconnect',()=>{
            //     peerList.current.forEach(p=>p?.destroy());
            //     console.log('SOCKET Disconnect');
            // })
            // socket.current.on('reject-screen',()=>{
            //     setShowScreen(null);
            //     toast.error('Client has been reject screen sharing.')
            // });
            // socket.current.on('accepted-screen',({socketID,sdp})=>{
            //     screenSocketID.current=socketID;
            //     for(let p of peerList.current) {
            //         p?.destroy();
            //     }
            //     peerList.current=[];
            //     let peer = new Peer({ initiator: false,trickle: false});
            //     peer.on('signal',data => {
            //         socket.current.emit('admin-answer',{socketID,sdp:data});
            //     })
            //     peer.on('connect',()=>{
            //         console.log("CONNECTED");
            //     })
            //     peer.on('stream',stream=>{
            //         setScreen(stream);
            //     })
            //     peer.on('close', () => {
            //         console.log('PEER CLOSE');
            //     })
            //     peer.signal(sdp);
            //     peerList.current.push(peer);
            // });
        })
        // requestAPI('GET','/get-meet-token/'+roundID).then(res=>{
        //     let {token} = res.data;
        //     setAutoRejectToken(token);
        // }).catch(e=>{
        //     console.log(e);
        // })



        return ()=>{
            clearInterval(refetchTimer.current);
            peerList.current.forEach(p=>p?.destroy());
            socket?.current?.disconnect();
        }
    },[roundID]);

    // useEffect(()=>{
    //     if(modalData){
    //         msgTextArea.current?.select();
    //     }
    // },[modalData]);


    useEffect(() => {
        if (resCheckIn.data?.scheduleRoundCheckIn) {
            let {checkIns} = resCheckIn.data?.scheduleRoundCheckIn
            setCheckInInfo(resCheckIn.data?.scheduleRoundCheckIn);
            setCheckList(checkIns);
        }
        let participantID = modalData?.participant?.id;
        if(participantID && modalData.mode=='message'){
            try{
                setModalData({
                    ...modalData,
                    participant:resCheckIn.data?.studentCheckInList.find(v=>v.id==participantID) || {}
                })
            }catch(e){
                setModalData(null);
            }
        }
    }, [resCheckIn.data]);

    useEffect(() => {
        if (checkInInfo) {
            if (checkInInfo?.googleMeetURL) {
                setBroadcastState(2);
            } else {
                setBroadcastState(1);
            }
        }
    }, [checkInInfo])

    useEffect(() => {
        let textArr = userPairingText.split(/\n/);
        let buffer = {};
        let currentName = null;
        let expGetName = new RegExp('(.+)(?:\\d+:\\d+) ?(?:AM|PM)?');
        let expGetID = new RegExp('((?:\\d{9}\\-\\d{1})|(?:\\d{10}))');
        textArr.map(line => {
            let name = line.match(expGetName);
            let code = line.match(expGetID);
            if (!code) {
                code = line.match(/id=(.+)/i)
            }
            if (name && (name[1] == 'You' || name[1] == 'คุณ')) return;
            if (name) {
                currentName = name[1].trim();
                if (currentName.substr(-1).match(/1/)) {
                    currentName = currentName.substr(0, currentName.length - 1);
                }
            } else if (currentName && code) {
                let existed = checkInList.find(
                    checkIn => {
                        return checkIn.user.studentID == fixStudentCode(code[1]) ||
                            checkIn.user.username == code[1]
                    }
                );
                if (existed) {
                    let existedCopied = _.cloneDeep(existed);
                    if (existedCopied.avatar_name && existedCopied.avatar_name != currentName) {
                        console.log(existedCopied.avatar_name, currentName);
                        existedCopied.pairError = true;
                    }
                    buffer[currentName] = existedCopied;
                }
            }
        })
        if (Object.keys(buffer).length > 0) {
            setUserPairing({...buffer});
        } else {
            setUserPairing(null);
        }
    }, [userPairingText]);

    function fixStudentCode(code) {
        if (!code.match(/\d{9}\-\d{1}/)) {
            return code.substr(0, 9) + '-' + code.substr(-1)
        }
        return code;
    }

    async function broadcast(url) {
        const BROADCAST_MEET = gql`
        mutation BroadcastMeet($scheduleRoundCheckInID:ID,$url:String){
          broadcastMeet(scheduleRoundCheckInID:$scheduleRoundCheckInID,url:$url)
        }
        `;
        try{
            let res = await apolloClient.mutate({
                mutation: BROADCAST_MEET,
                variables: {
                    scheduleRoundCheckInID: scheduleRoundCheckIn,
                    url
                }
            });
            if (res.data?.broadcastMeet) {
                await resCheckIn.refetch();
                setTempCheckInBtnState({});
                if(url){
                    toast.success('Broadcasting... ' + url);
                }else{
                    toast.success('Broadcast stopped.');
                }
            }
        }catch(e){
            toast.error('Broadcast error.');
        }
    }

    async function pairUser(approved = false) {
        if (!userPairing) return;
        let buffer = [];
        Object.keys(userPairing).map(pair => {
            let participant = userPairing[pair];
            if (participant) {
                let {username, studentID, id} = participant.user;
                buffer.push({
                    approved,
                    regID:participant.register.id,
                    userID: id,
                    username,
                    studentID,
                    avatarName: pair,
                    checkInID: participant.id,
                });
            }

        })
        await pairUserData(buffer);
        setUserPairingText('');
        if (approved) {
            toast.success('Pair user and approve success.')
            await resCheckIn.refetch();
            setTempCheckInBtnState({});
        } else {
            toast.success('Pair user success.')
        }
    }

    async function pairUserData(buffer) {
        let PAIR_USER = gql`
            mutation CheckInState($checkStateData:[CheckStateData]){
              checkInState(checkStateData:$checkStateData)
            } 
        `;
        let ok = await apolloClient.mutate({
            mutation: PAIR_USER,
            variables: {
                checkStateData: buffer
            },
            context: {
                headers: {
                    'X-Special-Route': 'server1'
                }
            }
        });
        // if (ok) await resCheckIn.refetch();
    }

    async function changeApprovedState(checkIn, approved = true,options) {
        if(!checkIn?.register){
            console.log('No registration user.');
            return ;
        };
        let checkInstate = {
            regID:checkIn.register.id,
            userID:checkIn.user.id,
            checkInID:checkIn.id,
            approved,
            ...options,
        };
        setTempCheckInBtnState(old=>({...old,[checkIn.id]:approved}));
        return await pairUserData([checkInstate]);
    }

    useEffect(() => {
        console.log('tempCheckInBtnState===',tempCheckInBtnState);
    }, [tempCheckInBtnState]);

    async function showReject(checkIn) {
        setModalData({
            mode: 'reject',
            checkIn,
        })
    }

    async function showSendMessage(checkIn) {
        setModalData({
            mode: 'message',
            checkIn,
        })
    }

    function getFullname(user){
        return `${user.prefix}${user.fname} ${user.lname}`
    }

    async function modalSendData(mode){
        let {checkIn}=modalData;
        if(!checkIn)return;
        if(mode=='reject'){
            await changeApprovedState(checkIn,false,{
                rejectMessage:modalData?.message,
            });
            toast.success(`${getFullname(checkIn.user)} rejected.`);
            setModalData(null);
        }else{
            await changeApprovedState(checkIn,null,{
                message:modalData?.message,
            });
            toast.success(`Send message to ${getFullname(checkIn.user)} success.`);
            await resCheckIn.refetch();
            setTempCheckInBtnState({});
            setModalData(state=>{
                return {
                    ...state,
                    message:''
                }
            })
        }
    }

    function closeStream(){
        emit('close-screen',{socketID:screenSocketID.current});
        setScreen(null);
        setShowScreen(false);
    }

    async function requestScreen(reg){
        setShowScreen(true);
        setScreen(null);
        emit('request-screen',{
            userID:reg.user.id,
            roundID,
        })
    }

    function getOnlineState(user){

        let onlineList = onlineUsers.filter(u=>user.id==u.user._id);
        return  onlineList.length>0?
            <div style={{
                display:'flex',
                flexDirection:'column'
            }}>
                {onlineList.map((online,index) =>
                    <div key={index} style={{
                        display:'flex',
                        flexDirection:'row',
                        alignItems:'flex-start',
                    }}>
                        <div className="online" style={{marginRight: 5}}></div>
                        <div>{online.ip}</div>
                    </div>
                )}
            </div>
            :
            <>
                <div className="offline" style={{marginRight:5,float:'left'}}></div>
                <div>?.?.?.?</div>
            </>
    }

    function getUserPicture(user){
        return user.studentID?
           <>
               {imageErrorList[user.studentID]?
                    <Image src={`${Config.root}/user2.png`} width={80}/>
                   :
                   <Image src={`${Config.api_url}/profile_picture/${user.studentID}`} onError={()=>{
                       return setImageErrorList(old=>({...old,[user.studentID]:true}));
                   }} width={80}/>
               }
           </>
            :
            <Image src={`${Config.root}/user.png`} width={80}/>
    }

    async function checkInStateAll(scheduleRoundID,approved){
       try{
           await axios.post('/api/check-in-state-all',{
               roundID:scheduleRoundID,
               state:approved
           });
           if(approved){
               toast.success('Check-in state success.');
           }else{
                toast.success('Reject state success.');
           }
           await resCheckIn.refetch();
       }catch (e){
           toast.error('Error check-in state.');
       }
    }

    function  getCheckInButton(checkIn,className){
        let chk;
        if(Object.keys(tempCheckInBtnState).includes(checkIn.id)){
            chk = tempCheckInBtnState[checkIn.id];
        }else{
            chk = checkIn.approved;
        }
        return <>
            {chk ?
                <Button variant="danger"
                        className={className}
                        onClick={e => showReject(checkIn)}>
                    Reject
                </Button>
                :
                <Button variant="primary"
                        className={className}
                        onClick={e => changeApprovedState(checkIn, true)}>
                    Approve
                </Button>
            }
        </>
    }

    if (!checkInInfo || !checkInList) return <div>Loading...</div>
    return <>
        <h1 className="mb-2">Examination Check-In <Badge variant='info' className='text-capitalize'>{checkInInfo?.groupName}</Badge></h1>
        <Row>
            <Col>
                <Card>
                    <Card.Body>
                        <h3 className="mb-2">Info</h3>
                        <div>
                            <strong>Course:</strong>
                            <div>{checkInInfo?.schedule.course.fullname}</div>
                        </div>
                        <hr/>
                        <div>
                            <strong>Exam Between:</strong>
                            <div>{getThaiDateBetween(checkInInfo?.schedule.examStart, checkInInfo?.schedule.examEnd, 'lll')}</div>
                        </div>
                        <hr/>
                        <div>
                            <strong>Round:</strong>
                            <div>{getThaiDateBetweenSingleDay(checkInInfo?.scheduleRound.examStart, checkInInfo?.scheduleRound.examEnd, false)}</div>
                        </div>

                        <div>
                            <strong>Quiz Type:</strong>
                            <div style={{marginTop: 6}}>
                                <span style={{
                                    padding: 4,
                                    backgroundColor: quizSet?.bg_color,
                                    color: quizSet?.text_color,
                                    borderRadius: 8,
                                }}>{quizSet?.name}</span> / Time ({quizSet?.minutes}) Minutes
                            </div>
                        </div>

                        {checkInConfig &&
                            <>
                                <hr/>
                                <div className='mt-2'>
                                    <strong>Check-In Config:</strong>
                                    <div className='mt-2'>Allowed IPs:</div>
                                    {checkInConfig?.allowed_ip && checkInConfig?.allowed_ip?.length>0?
                                        <ListGroup>
                                            {checkInConfig?.allowed_ip.map((ip,i)=>
                                                <ListGroupItem key={i}>{ip}</ListGroupItem>
                                            )}
                                        </ListGroup>
                                        :
                                        <div>- none -</div>
                                    }
                                    <div className='mt-2'>Always access schedule:</div>
                                    {checkInConfig?.ignore_schedule_ids && checkInConfig?.ignore_schedule_ids?.length>0?
                                        <ListGroup>
                                            {checkInConfig?.ignore_schedule_ids.map((id,i)=>
                                                <ListGroupItem key={i}>{allSchedules.find(s=>s.id==id)?.title}</ListGroupItem>
                                            )}
                                        </ListGroup>
                                        :
                                        <div>- none -</div>
                                    }

                                </div>
                            </>
                        }

                    </Card.Body>
                </Card>
            </Col>
        </Row>
        <Row>
            <Col>
                {broadcastState == 1 &&
                    <InputGroup className="mb-3 mt-4" id="search">
                        <InputGroup.Prepend>
                            <InputGroup.Text id="basic-addon1">Google meet URL</InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                        placeholder="meet.google.com/xxx-yyy-zzz"
                        value={meetRoom || ''}
                        onChange={e => {
                            // let url = e.target.value.match(/(https:\/\/meet\.google\.com\/.+)/);
                            // if (url) {
                            //     let meetUrl = url[1].split('?');
                            //     setMeetRoom(meetUrl[0]);
                                setMeetRoom(e.target.value || '');
                                setEnableBroadcast(true);
                            // } else {
                            //     setEnableBroadcast(false);
                            // }
                        }}
                    />
                    <Button disabled={!enableBroadcast} variant="danger"
                            onClick={e => broadcast(meetRoom)}>Broadcast</Button>
                </InputGroup>
                }
                {broadcastState == 2 &&
                <Card>
                    <Card.Body>
                        <Alert variant='success'><PlayCircleOutlineIcon/> Broadcasting... {checkInInfo?.googleMeetURL}
                        </Alert>
                        <div className="text-center"><Button variant="danger" onClick={e => broadcast(null)}>Stop
                            broadcast</Button></div>
                        {autoRejectToken && checkInInfo?.googleMeetURL &&
                            <InputGroup className="mb-3 mt-2">
                                <FormControl readOnly type="text" defaultValue={`${checkInInfo?.googleMeetURL}?token=${autoRejectToken}`} onClick={e=>e.target.select()}/>
                                <InputGroup.Append>
                                    <a className="btn btn-dark" target='_blank' href={`${checkInInfo?.googleMeetURL}?token=${autoRejectToken}`} rel="noreferrer">Start Google Meet Admin</a>
                                </InputGroup.Append>
                            </InputGroup>
                        }
                        <Box mt={4}>
                            <Row>
                                <Col md={6}>
                                    <Form.Group>
                                        <Form.Label>User pairing tool</Form.Label>
                                        <Form.Control as="textarea" rows={7} value={userPairingText}
                                                      onChange={e => setUserPairingText(e.target.value)}/>
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group>
                                        <Form.Label>User pairing results</Form.Label>
                                        {userPairing
                                            ?
                                            <Table responsive>
                                                <thead>
                                                <tr>
                                                    <th>Code</th>
                                                    <th>Name</th>
                                                    <th>Google meet name</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {Object.keys(userPairing).map((pair, i) => {
                                                    let participant = userPairing[pair];
                                                    return <tr key={participant?.user?.id}>
                                                        <td>{participant.pairError
                                                            ?
                                                            <div>{participant.user?.studentID}
                                                                <Badge className="ml-1" variant="danger">
                                                                    Existed -> {participant.user?.avatar_name}
                                                                </Badge>
                                                            </div>
                                                            :
                                                            participant.user?.studentID}
                                                        </td>
                                                        <td>{participant.user?.fname} {participant.user?.lname}</td>
                                                        <td>{pair}</td>
                                                    </tr>
                                                })
                                                }
                                                </tbody>
                                            </Table>
                                            :
                                            <div>Waiting... input</div>

                                        }
                                    </Form.Group>
                                </Col>
                            </Row>
                            {userPairing && Object.keys(userPairing).length > 0 &&
                            <>
                                <Form.Group className="text-center">
                                    <Button onClick={e => pairUser()} className="mr-4">Pair</Button>
                                    <Button onClick={e => pairUser(true)} variant="secondary">Pair and approve</Button>
                                </Form.Group>
                            </>
                            }
                        </Box>
                    </Card.Body>
                </Card>
                }
            </Col>
        </Row>
        {checkInInfo?.autoRejectLogs.length > 0 &&
        <Box mt={2} mb={2}>
            <Row>
                <Col>
                    <h4>Auto reject logs</h4>
                    <Paper>
                        <div className="p-2" style={{maxHeight:300,overflowY:'auto'}}>
                            {_.cloneDeep(checkInInfo?.autoRejectLogs)?.reverse()?.map((log,n)=>
                                <Alert key={n} variant="danger" style={{marginBottom:2}}>{n+1}) {log}</Alert>
                            )}
                        </div>
                    </Paper>
                </Col>
            </Row>
        </Box>
        }
        <Box mt={2} mb={2}>
            <InputGroup>
                <InputGroup.Prepend>
                    <InputGroup.Text>Filter</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl onChange={e => {
                    setFilter(e.target.value);
                }}/>
            </InputGroup>
        </Box>
        <div style={{
            display:'flex',
            justifyContent:'flex-end',
            flexDirection:'row',
            gap:4,
            marginBottom:10
        }}>
            <Button variant='success' onClick={e=>checkInStateAll(roundID,true)}>Approve ALL</Button>
            <Button variant='warning' onClick={e=>checkInStateAll(roundID,false)}>Reject ALL</Button>
        </div>
        <Row>
            <Col>
                <Paper>
                    <Table responsive>
                        <thead>
                        <tr>
                            <td className="d-none d-lg-table-cell" width={20}>#</td>
                            <td className="d-none d-lg-table-cell">Picture</td>
                            <td className="d-none d-lg-table-cell">Avatar Name</td>
                            <td className="d-none d-lg-table-cell">Identify</td>
                            <td>Full name</td>
                            <td>Action</td>
                            <td className="d-none d-lg-table-cell">IP</td>
                            <td className="d-none d-lg-inline-block">Check-In</td>
                        </tr>
                        </thead>
                        <tbody>
                        {checkInList.filter(checkIn => {
                            if (!filter) return true;
                            let filterField = [
                                'user.studentID',
                                'user.username',
                                'user.prefix',
                                'user.fname',
                                'user.lname',
                                'checkIn.avatarName',
                            ]
                            for (let field of filterField) {
                                let regExp = new RegExp(`${filter}`, 'i')
                                let matched = _.get(checkIn, field)?.match(regExp);
                                if (matched) return true;
                            }
                            return false;
                        }).map((checkIn, i) =>{
                                return <tr key={checkIn.id}>
                                    <td className="d-none d-lg-table-cell">{i + 1}</td>
                                    <td className="d-none d-lg-table-cell">
                                        {getUserPicture(checkIn.user)}
                                    </td>
                                    <td className="d-none d-lg-table-cell">{checkIn?.avatarName || '-'}</td>
                                    <td className="d-none d-lg-table-cell">{checkIn.user.studentID || checkIn.user.username}</td>
                                    <td>
                                        <div className="d-table-block d-lg-none mb-1">
                                            <div className="mb-1">( {i + 1} ) {getUserPicture(checkIn.user)}</div>
                                            <div><Badge variant="dark">Ava</Badge> {checkIn?.checkIn?.avatarName || '-'}</div>
                                            <hr/>
                                            <div><Badge variant="secondary">{checkIn.user.studentID || checkIn.user.username}</Badge></div>
                                        </div>
                                        <div>{checkIn.user.prefix}{checkIn.user.fname} {checkIn.user.lname}</div>
                                        <hr/>
                                        <div>{checkIn.user.email}</div>
                                    </td>
                                    <td>
                                        <ButtonGroup>
                                            <Button size="sm" title="Send message" disabled={
                                                !isConnected || !!!onlineUsers.find(u=>checkIn.user.id==u.user._id)
                                            } variant="warning" onClick={e=>showSendMessage(checkIn)}>
                                                <FaRocketchat size={20}/>
                                            </Button>
                                            <Button size="sm" title="Request screen sharing" disabled={
                                                !isConnected || !!!onlineUsers.find(u=>checkIn.user.id==u.user._id)
                                            } variant="info" onClick={e=>requestScreen(checkIn)}>
                                                <FaTv size={20}/>
                                            </Button>
                                            <Button size='sm' title='Un-Submit' variant='outline-secondary' onClick={e=>{
                                                checkInViewModel.unSubmit(checkIn,'list');
                                            }}>
                                                <BsRewindCircle size='20'/>
                                            </Button>
                                            {getCheckInButton(checkIn,"d-inline-block d-lg-none")}
                                        </ButtonGroup>
                                        <div className="d-block d-lg-none" style={{display:'flex',marginTop:10,whiteSpace:'nowrap'}}>
                                            {getOnlineState(checkIn.user)}
                                        </div>
                                    </td>
                                    <td className="d-none d-lg-table-cell">
                                        <div style={{display:'flex',justifyContent:'center'}}>
                                            {getOnlineState(checkIn.user)}
                                        </div>
                                    </td>
                                    <td className="d-none d-lg-table-cell">
                                        {getCheckInButton(checkIn)}
                                    </td>
                                </tr>
                            }
                        )}
                        </tbody>
                    </Table>
                </Paper>
            </Col>
        </Row>
        <Modal show={!!modalData} onHide={e => setModalData(null)} onShow={()=>{
            msgTextArea.current?.select();
        }}>
            {modalData &&
            <>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {modalData?.mode=='reject'?
                            <>Reject message</>
                            :
                            <>Message</>
                        }
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                   <FormControl ref={msgTextArea} as="textarea" rows={5} value={modalData?.message || ''}  onChange={e=>{
                       setModalData(old=>{
                           return {
                               ...old,
                               message:e.target.value
                           }

                       })
                   }}/>
                   <Box mt={2}>
                       <div style={{maxHeight:500,overflow:'auto'}}>
                           {modalData?.mode=='message'?
                               <ListGroup>
                                   {modalData?.participant?.checkIn?.messages?.map((msg,i)=>
                                       <ListGroupItem key={i}>{getThaiDate(msg.created)} - {msg.content}</ListGroupItem>
                                   )}
                               </ListGroup>
                               :
                               <ListGroup>
                                   {modalData?.participant?.checkIn?.rejectMessages?.map((msg,i)=>
                                       <ListGroupItem key={i}>{getThaiDate(msg.created)} - {msg.content}</ListGroupItem>
                                   )}
                               </ListGroup>
                           }
                       </div>
                   </Box>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={e => setModalData(null)}>
                        Close
                    </Button>
                    {modalData?.mode=='reject'?
                        <Button variant="primary" onClick={async e => modalSendData(modalData?.mode)}>
                            Confirm to reject
                        </Button>
                        :
                        <Button variant="primary" onClick={async e => modalSendData(modalData?.mode)}>
                            Send
                        </Button>
                    }
                </Modal.Footer>
            </>
            }
        </Modal>

        <Modal size='lg' show={showScreen} onHide={e => closeStream()}>
            <Modal.Header closeButton>
                <Modal.Title>
                   Requesting screen share.
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {screen?
                    <Box display='flex' justifyContent='center'>
                        <ReactPlayer controls={true} url={screen} onReady={(player) => {
                            player.getInternalPlayer().play();
                        }}/>

                    </Box>
                    :
                    <Alert>Waiting user accept to share entry screen.</Alert>
                }
            </Modal.Body>
        </Modal>
    </>

}
export default CheckInStudentGroup;
