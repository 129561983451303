import gql from "graphql-tag";

export const SET_ROLE = gql`
mutation SetRole($userId:ID,$roleId:ID){
  setRole(userId:$userId,roleId:$roleId)
}
`;

export const COURSE_DETAIL =gql`
mutation COURSE_DETAIL($courseForm: CourseInput) {
  courseDetail(courseForm: $courseForm){
     error
     courseDB{
        id
     }
  }
}
`;
