import {Box, IconButton} from "@material-ui/core";
import XBreadcrumb from "./xbreadcrumb";
import {
    Alert,
    Button,
    ButtonGroup,
    Card,
    Col, Form,
    FormControl,
    FormGroup,
    InputGroup,
    Modal,
    Row,
    ToggleButton
} from "react-bootstrap";
import {useHistory, useParams} from "react-router-dom";
import {useMutation, useQuery} from "@apollo/client";
import {GET_DB_COURSE, GET_EXAM_SCHEDULE, GET_EXAM_SCHEDULES} from "../gql-query/select-query";
import {useEffect, useRef, useState} from "react";
import DatePicker from "react-datepicker";
import {v4 as uuidv4} from 'uuid';
import {StyleSheet, css} from "aphrodite";
import moment from 'moment';
import {getAllMonth} from "../services/helper";
import {createGroup, pad2} from "../services/client-helper";
import _ from 'lodash';
import gql from "graphql-tag";
import DateTimeRangePicker from '@wojtekmaj/react-datetimerange-picker';
import TimePicker from 'react-time-picker';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
import AddBoxIcon from '@material-ui/icons/AddBox';
import AddIcon from '@material-ui/icons/Add';
import Calendar from 'react-calendar';
import AlarmAddIcon from '@material-ui/icons/AlarmAdd';
import {ExamScheduleValidationSchema} from "../yup-validators/exam-schedule-form-schema";
import {toast} from "react-toastify";
import {SAVE_EXAM_SCHEDULE} from "../gql-query/save-query";
import EditIcon from '@material-ui/icons/Edit';

const RoundForm = ({course, day, round, onChange, onRemove}) => {

    function onTimeChanged(key, date) {
        let strDay = moment(day).format('YYYY-MM-DD');
        let strTime = moment(date).format('HH:mm');
        let dateTime = moment(strDay + ' ' + strTime)
        round[key] = dateTime.toDate()
        onChange();
    }

    function onFormChanged(e) {
        let {value, name} = e.target;
        round[name] = value
        onChange();
    }

    function timePickerController(name) {
        return {
            format: 'HH:mm',
            onChange: (time) => {
                let strDay = moment(day).format('YYYY-MM-DD');
                if (time) {
                    round[name] = moment(`${strDay} ${time}`).toDate()
                } else {
                    round[name] = moment(`${strDay} 00:00`).toDate()
                }
                onChange();
            },
            value: round?.[name] ?
                moment(round[name]).format('HH:mm')
                :
                '00:00'
        }
    }

    return <div className={css(styles.roundCard)}>
        <Box display="flex" justifyContent='flex-end'>
            <CancelPresentationIcon style={{color: '#404040', cursor: 'pointer'}} onClick={e => {
                if (onRemove) onRemove(round);
            }}/>
        </Box>
        <Row>
            <Col>
                <FormGroup>
                    <label>Module</label>
                    <FormControl as="select" name="module" onChange={onFormChanged} value={round.module}>
                        <option>- Module -</option>
                        {course.quizSet.map(q =>
                            <option key={q.id} value={q.id}>{q.name}</option>
                        )}
                    </FormControl>
                </FormGroup>
            </Col>
            <Col>
                <FormGroup>
                    <label>Start</label>
                    <div>
                        <Box display='flex' justifyContent='space-between' gridGap={5}>
                            <TimePicker {...timePickerController('start')}/>
                            <IconButton onClick={e => {
                                let moduleDetail = course.quizSet.find(q=>q.id==round?.module);
                                if(moduleDetail){
                                    round['end']=moment(round['start']).add(moduleDetail.minutes,'minutes').toDate();
                                    onChange()
                                }
                            }}>
                                <AlarmAddIcon/>
                            </IconButton>
                        </Box>
                    </div>
                </FormGroup>
            </Col>
            <Col>
                <FormGroup>
                    <label>End</label>
                    <div>
                        <TimePicker {...timePickerController('end')}/>
                    </div>
                </FormGroup>
            </Col>
            <Col>
                <FormGroup>
                    <label>Location</label>
                    <FormControl as='select' name='location' onChange={onFormChanged} value={round?.location || ''}>
                        <option value=''>- Choose -</option>
                        {course.locations.map(location =>
                            <option key={location.id} value={location.id}>{location.name}</option>
                        )}
                    </FormControl>
                </FormGroup>
            </Col>
            <Col>
                <FormGroup>
                    <label>Seats</label>
                    <FormControl name='seats' onChange={onFormChanged} value={round?.seats || ''}/>
                </FormGroup>
            </Col>
        </Row>
    </div>
}

const SCHEDULE_TYPE = gql`
{
  scheduleType {
    id
    code
    name
    detail
  }
}
`;
const CourseScheduleCreateAndUpdate = ({mode}) => {
    const {id,scheduleID} = useParams();
    const history = useHistory();
    const resScheduleType = useQuery(SCHEDULE_TYPE, {fetchPolicy: 'network-only'});
    const resEditSchedule = useQuery(GET_EXAM_SCHEDULE,{
        fetchPolicy:'network-only',
        variables:{
            id:scheduleID
        },
        skip:!!!scheduleID
    });
    const [course, setCourse] = useState();
    const [saveExamSchedule] = useMutation(SAVE_EXAM_SCHEDULE);
    const [startDate, setStartDate] = useState(new Date());
    const [days, setDays] = useState([]);
    const removed = useRef([]);
    const [changeDateModal,setChangeDateModal] = useState();
    const [changedDateValue,setChangedDateValue] = useState();
    const [useSeb,setUseSeb] = useState(false);
    const [show,setShow] = useState(true);
    const [form, setForm] = useState({
        course: null,
        month: '',
        year: '',
        scheduleType:'',
        registerStart: '',
        registerEnd: '',
        examStart:'',
        examEnd:'',
        title: '',
        detail: '',
        scoreDate:'',
        gradeDate:'',
        /**
         * @type {Array.<ExamRound>}
         */
        rounds: [],
    });
    const resCourse = useQuery(GET_DB_COURSE, {
        variables: {mdCourseID: id},
        fetchPolicy: "no-cache",
    });

    const [currentScheduleCode,setCurrentScheduleCode] = useState('');

    useEffect(()=>{
        if(mode=='edit' &&
            resEditSchedule.data &&
            resCourse.data &&
            resScheduleType.data
        ){
            let scheduleData = _.cloneDeep(resEditSchedule.data?.examSchedule);
            setCurrentScheduleCode(scheduleData?.code);
            scheduleData.scheduleType=scheduleData?.scheduleType?.id;
            scheduleData.examStart=new Date(parseInt(scheduleData.examStart,10));
            scheduleData.examEnd=new Date(parseInt(scheduleData.examEnd,10));
            scheduleData.scoreDate=new Date(parseInt(scheduleData.scoreDate,10));
            scheduleData.gradeDate=new Date(parseInt(scheduleData.gradeDate,10));
            scheduleData.registerEnd=new Date(parseInt(scheduleData.registerEnd,10));
            scheduleData.registerStart=new Date(parseInt(scheduleData.registerStart,10));
            scheduleData.rounds=scheduleData.rounds.map(r=>{
               return {
                   id:r.id,
                   module:r?.quizSet?.id,
                   start:new Date(parseInt(r.examStart,10)),
                   end:new Date(parseInt(r.examEnd,10)),
                   location:r.location.id,
                   seats:r.maxSeats || 0,
               }
            });
            let uniqueDay = scheduleData.rounds.map(r=>moment(r.start).startOf('day').format('YYYY-MM-DD'))
            uniqueDay=[...new Set(uniqueDay)];
            setDays(
                uniqueDay.map(day=>{
                    return {
                        id:uuidv4(),
                        day:moment(day,'YYYY-MM-DD').toDate()
                    }
                })
            );
            setForm(scheduleData);
            setUseSeb(scheduleData.useSeb);
            setShow(scheduleData.show);
        }
    },[resEditSchedule.data,resCourse.data,resScheduleType.data]);

    useEffect(() => {
        if (resCourse.data?.courseDB) {
            setCourse(resCourse.data.courseDB);
        }
    }, [resCourse.data]);

    useEffect(() =>{
        if(!changeDateModal)setChangedDateValue(null);
    },[changeDateModal]);

    function addDate(module,start, end, location, seats) {
        form.rounds.push({
            id: uuidv4(),
            module,
            start,
            end,
            location,
            seats,
        });
    }

    function onFormChanged(e) {
        let {value, name, type} = e.target;
        setForm({
            ...form,
            [name]: value
        })
    }

    function controller(name) {
        return {
            name,
            value: form?.[name] || '',
            onChange: e => onFormChanged(e)
        }
    }

    function datePickerController(name) {
        return {
            selected: form?.[name],
            onChange: date => setForm({
                ...form,
                [name]: date
            })
        }
    }

    async function onSubmit(e) {
        let quizSet = [];
        // console.log('form.rounds==', form.rounds);
        let roundBuffers = createGroup(form.rounds, 'module')
        roundBuffers.forEach(module => {
            quizSet.push({
                id: module.group,
                rounds: module.data.map(round => ({
                    id: round.id,
                    examStart: round.start,
                    examEnd: round.end,
                    location: round.location,
                    maxSeats: round.seats,
                }))
            });
        })
        let dataSend = {
            ...form,
            useSeb,
            show,
            removed:removed.current,
            id: scheduleID||null,
            course: course?.id,
            quizSet
        }
        dataSend = _.omit(dataSend, 'rounds');
        try{
            // try{
            //     console.log('dataSend==', dataSend);
                let order = [
                    'course',
                    'month',
                    'year',
                    'scheduleType',
                    'registerStart',
                    'registerEnd',
                    'examStart',
                    'examEnd',
                    'title',
                    'detail',
                    'scoreDate',
                    'gradeDate',
                ];
                for(let key of order){
                    await ExamScheduleValidationSchema.validateAt(key,dataSend);
                }
                ExamScheduleValidationSchema.validateSync(dataSend,{stripUnknown:true});
                dataSend=ExamScheduleValidationSchema.cast(dataSend,{stripUnknown:true});
            // }catch(e){
            //     throw new Error(e.message);
            // }
            // dataSend=ExamScheduleValidationSchema.validateSync(dataSend,{stripUnknown:true});
            console.log('data ok',dataSend);
            try{
               let result = await saveExamSchedule({variables:{
                        scheduleForm:dataSend
                }});
                result = result.data?.examSchedule||{};
                if(!result?.error){
                    toast.success('Data saved.');
                    history.push(`/admin/exam-schedule/` + id);
                }else{
                    throw new Error(result?.message);
                }
            }catch(e2){
                throw new Error(e2.message);
            }
        }catch(e){
            console.log('data error===',e.message);
            toast.error(e.message);
        }
    }

    function getScheduleCode() {
        if (form.month && form.year && form.scheduleType) {
            let scheduleType = resScheduleType?.data?.scheduleType.find(v=>v.id==form.scheduleType);
            if(scheduleType) return `dq-${pad2(form.month)}-${pad2(form.year)}-${pad2(scheduleType.code)}`;
        }
        return '- Please fill the form -'
    }

    function dateTimeRangeController(startKey, endKey) {
        return {
            format: 'dd-MM-y HH:mm',
            onChange: (arrDate) => {
                if (arrDate) {
                    let [s, e] = arrDate;
                    setForm({
                        ...form,
                        [startKey]: s,
                        [endKey]: e,
                    })
                } else {
                    setForm({
                        ...form,
                        [startKey]: void 0,
                        [endKey]: void 0,
                    });
                }
            },
            value: [form?.[startKey] || null, form?.[endKey] || null]
        }
    }

    function addDay(date){
        let day = moment(date).startOf('day').toDate();
        days.push({
            id: uuidv4(),
            day,
        });
        daySort();
        setDays([...days]);
    }

    function daySort(){
        days.sort((a, b) => {
            return a.day > b.day ? 1 : (a.day.getTime() == b.day.getTime() ? 0 : -1)
        });
    }

    function getDaysByBetweenDate(start,end){
        let num = moment(start).diff(end,'days');
        return Math.abs(num);
    }

    function changeDate(){
        let dayID = changeDateModal;
        let changedDate = moment(changedDateValue,'DD-MM-YYYY').toDate();
        let date = days.find(d=>d.id==dayID);
        if(date){
            let roundsInDates = form.rounds?.filter(round=>
                moment(round.start).startOf('day').isSame(date.day)
            );

            date.day=moment(changedDate).startOf('day').toDate();
            roundsInDates.map((r,i)=>{
                let start = moment(roundsInDates[i].start);
                let end = moment(roundsInDates[i].end);
                roundsInDates[i].start=moment(date.day).set({hour:start.hours(),minute:start.minutes()});
                roundsInDates[i].end=moment(date.day).set({hour:end.hours(),minute:start.minutes()});
            })
            setForm({...form});
            daySort();
            setDays([...days]);
            console.log('===',roundsInDates);
            console.log(dayID, changedDate);
        }
        toast.success('Day changed.')
        setChangeDateModal(null);
    }

    function generateChangeDateSelector(dayID){
        let d = days.find(d=>d.id==dayID);
        let format = 'DD-MM-YYYY';
        if(d){
            return <>
                {form.examStart && form.examEnd &&
                <FormGroup>
                    <FormControl as="select" value={changedDateValue || moment(d.day).format(format)}
                        onChange={e=>{
                            let v = e.target.value;
                            setChangedDateValue(v);
                        }}
                    >
                        {
                            [...new Array(getDaysByBetweenDate(form.examStart,form.examEnd)+1)]
                            .map((v,i)=>{
                                let dateObj = moment(form.examStart).add(i,'days').startOf('days').toDate();
                                let existed = days.find(d=>d.day.getTime()==dateObj.getTime());
                                let date = moment(form.examStart).add(i,'days').format(format);
                                if(!existed || moment(d.day).format(format)==date){
                                    return <option value={date} key={date}>{date}</option>
                                }else{
                                    return void 0;
                                }
                            })
                        }
                    </FormControl>
                </FormGroup>
                }
            </>
        }
        return void 0;
    }

    if ((resCourse.loading && !course) || resScheduleType.loading) return <Alert>Loading...</Alert>
    return <>
        <XBreadcrumb data={[
            {title: `Exam Schedule: ${course?.shortname}`, url: '/admin/exam-schedule/' + id},
            {title: 'Create new schedule'}
        ]}/>
        <Row>
            <Col>
                <Card>
                    <Card.Header>
                        <Card.Title>{course?.fullname}</Card.Title>
                        {currentScheduleCode && <Card.Subtitle className="mb-2 text-muted">{currentScheduleCode}</Card.Subtitle>}
                    </Card.Header>
                    <Card.Body>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <label>Schedule code</label>
                                    <FormControl readOnly value={getScheduleCode()}/>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <label>Month</label>
                                    <FormControl as="select" {...controller('month')}>
                                        <option value=''>- Select -</option>
                                        {getAllMonth().map((month, i) =>
                                            <option key={i + 1} value={i + 1}>{month}</option>
                                        )}
                                    </FormControl>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <label>Year</label>
                                    <FormControl as="select" {...controller('year')}>
                                        <option value=''>- Year -</option>
                                        {
                                            (()=>{
                                                let y = new Date().getFullYear();
                                                let begin = 2020;
                                                let diff=y-begin;
                                                return  [...new Array(diff+2)].map((v, i) => {
                                                    return <option key={begin+i} value={begin+i}>{begin+i}</option>
                                                })
                                            })()
                                        }
                                    </FormControl>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <label>Type</label>
                                    <FormControl as="select" {...controller('scheduleType')}>
                                        <option value=''>- Type -</option>
                                        {resScheduleType.data.scheduleType.map(v =>
                                            <option key={v.id} value={v.id}>{v.name}</option>
                                        )}
                                    </FormControl>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={6}>
                                <FormGroup>
                                    <label>Register Between</label>
                                    <Box>
                                        <DateTimeRangePicker {...dateTimeRangeController('registerStart', 'registerEnd')}/>
                                    </Box>
                                </FormGroup>
                            </Col>
                            <Col sm={6}>
                                <FormGroup>
                                    <label>Exam Between</label>
                                    <Box>
                                        <DateTimeRangePicker {...dateTimeRangeController('examStart', 'examEnd')}/>
                                    </Box>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <label>Title</label>
                                    <FormControl {...controller('title')}/>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <label>Detail</label>
                                    <FormControl as="textarea" {...controller('detail')}/>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <label>Score result date</label>
                                    <DatePicker {...datePickerController('scoreDate')}/>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <label>Grade result date</label>
                                    <DatePicker {...datePickerController('gradeDate')}/>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <label>Use SEB</label>
                                    <div>
                                        <Form>
                                            <div>
                                                <Form.Check inline type="radio"
                                                            id='seb-on'
                                                            label="On"
                                                            checked={useSeb}
                                                            onChange={e=>setUseSeb(true)}
                                                />
                                                <Form.Check inline type="radio"
                                                            id='seb-off'
                                                            label="Off"
                                                            checked={!useSeb}
                                                            onChange={e=>setUseSeb(false)}
                                                />
                                            </div>
                                        </Form>
                                        {/*<ButtonGroup>*/}
                                        {/*    <ToggleButton type="radio"*/}
                                        {/*                  checked={useSeb}*/}
                                        {/*                  onChange={e=>setUseSeb(true)}*/}
                                        {/*                  variant="outline-primary"> On</ToggleButton>*/}
                                        {/*    <ToggleButton type="radio"*/}
                                        {/*                  checked={!useSeb}*/}
                                        {/*                  onChange={e=>setUseSeb(false)}*/}
                                        {/*                  variant="outline-danger"> Off</ToggleButton>*/}
                                        {/*</ButtonGroup>*/}
                                    </div>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <label>Visibility</label>
                                    <div>
                                        <Form.Check inline type="radio"
                                                    id='show'
                                                    label="Show"
                                                    checked={show}
                                                    onChange={e=>setShow(true)}
                                        />
                                        <Form.Check inline type="radio"
                                                    id='hide'
                                                    label="Hide"
                                                    checked={!show}
                                                    onChange={e=>setShow(false)}
                                        />
                                    </div>
                                </FormGroup>
                            </Col>
                        </Row>
                        <hr/>
                        {
                            form.month && form.year &&
                                <div>
                                    <Box p={1}>
                                        <Row>
                                            <Col>
                                                <InputGroup>
                                                    <InputGroup.Prepend>
                                                        <InputGroup.Text id="basic-addon1">Default Location</InputGroup.Text>
                                                    </InputGroup.Prepend>
                                                    <FormControl as="select" {...controller('defaultLocation')}>
                                                        <option value=''>- Action -</option>
                                                        {course?.locations.map(location =>
                                                            <option key={location.id} value={location.id}>{location.name}</option>
                                                        )}
                                                    </FormControl>
                                                </InputGroup>
                                            </Col>
                                            <Col>
                                                <InputGroup>
                                                    <InputGroup.Prepend>
                                                        <InputGroup.Text id="basic-addon1">Default Seat</InputGroup.Text>
                                                    </InputGroup.Prepend>
                                                    <FormControl {...controller('defaultSeats')}/>
                                                </InputGroup>
                                            </Col>
                                        </Row>
                                    </Box>
                                    <Box p={1}>
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    {(form?.['examStart'] && form?.['examEnd']) &&
                                                    <>
                                                        <label>Select date</label>
                                                        <Calendar
                                                            defaultActiveStartDate ={form?.['examStart'] || new Date()}
                                                            tileDisabled={({activeStartDate, date, view}) => {
                                                                return !(
                                                                    (date >= form?.['examStart'] && date < form?.['examEnd'])
                                                                    &&
                                                                    !days.find(({id, day}) => day.getTime() == date.getTime())
                                                                );
                                                            }}
                                                            tileClassName={({activeStartDate, date, view}) => {
                                                                if (date >= form?.['examStart'] && date < form?.['examEnd']) {
                                                                } else {
                                                                    return css(styles.dayOutOfRange)
                                                                }
                                                            }}
                                                            onChange={(date) => addDay(date)}
                                                        />
                                                    </>
                                                    }
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Box>
                                    {days.map(({id, day}) =>
                                        <Row key={id}>
                                            <Col>
                                                <Box mt={1} display='flex' justifyContent='flex-end'>
                                                    <CancelPresentationIcon style={{color: '#dc3545', cursor: 'pointer'}}
                                                                            onClick={e => {
                                                                                setForm(prevState => {
                                                                                    return {
                                                                                        ...prevState,
                                                                                        rounds: prevState.rounds.filter(
                                                                                            r => {
                                                                                                let rDay=moment(r.start).startOf('day').toDate();
                                                                                                let isSame=moment(rDay).isSame(moment(day));
                                                                                                if(isSame){
                                                                                                    removed.current.push({
                                                                                                        type:'round',
                                                                                                        id:r.id,
                                                                                                    });
                                                                                                }

                                                                                                return !isSame
                                                                                            }
                                                                                        )
                                                                                    }
                                                                                });
                                                                                setDays(prevState => {
                                                                                    return _.reject(prevState, day => day.id == id);
                                                                                });
                                                                            }}/>
                                                </Box>
                                                <div className={css(styles.dayBlock)}>
                                                    <div className="mb-1">
                                                        <Col><span
                                                            style={{
                                                                fontWeight: 'bold',
                                                                marginRight: 10
                                                            }}>วันที่: {moment(day).format('DD/MM/YYYY')}</span>
                                                            <Button
                                                                style={{padding: 5,marginRight:5}}
                                                                onClick={e => {
                                                                    form.rounds.push({
                                                                        id: uuidv4(),
                                                                        start: moment(day).toDate(),
                                                                        end: '',
                                                                        location: form?.['defaultLocation'] || '',
                                                                        seats: form?.['defaultSeats'] || '',
                                                                    });
                                                                    setForm({
                                                                        ...form,
                                                                    });
                                                                }}>
                                                                <AddIcon/>
                                                            </Button>
                                                            <Button variant="warning" style={{padding: 5}} onClick={()=>{
                                                                setChangeDateModal(id);
                                                            }}>
                                                                <EditIcon/>
                                                            </Button>
                                                        </Col>
                                                    </div>
                                                    {
                                                        form.rounds.filter(v => {
                                                            return moment(v.start).startOf('day').toDate().getTime() == day.getTime()
                                                        }).map(round =>
                                                            <RoundForm course={course} key={round.id} day={day} round={round}
                                                                       onChange={() => {
                                                                           setForm({
                                                                               ...form,
                                                                           });
                                                                       }}
                                                                       onRemove={round => {
                                                                           removed.current.push({
                                                                               type:'round',
                                                                               id:round.id
                                                                           })
                                                                           setForm(prevState => {
                                                                               return {
                                                                                   ...prevState,
                                                                                   rounds: _.reject(prevState.rounds, r => r == round)
                                                                               }
                                                                           });
                                                                       }}
                                                            />
                                                        )
                                                    }
                                                </div>
                                            </Col>
                                        </Row>
                                    )}
                                </div>
                        }

                    </Card.Body>
                    <Card.Footer>
                        <div className="text-right">
                            <Button className='mr-1' variant="secondary" onClick={e=>{
                                history.push(`/admin/exam-schedule/` + id);
                            }}>Cancel</Button>
                            <Button onClick={onSubmit}>Save</Button>
                        </div>
                    </Card.Footer>
                </Card>
            </Col>
        </Row>
        <Modal show={!!changeDateModal} onHide={e=>setChangeDateModal(null)}>
            <Modal.Header closeButton>
                <Modal.Title>Change date</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {generateChangeDateSelector(changeDateModal)}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={e=>setChangeDateModal(null)}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={e=>changeDate()}>
                    OK
                </Button>
            </Modal.Footer>
        </Modal>
    </>
}
const styles = StyleSheet.create({
    globals: {},
    roundCard: {
        border: '1px solid #c3c2c2',
        padding: 10,
        marginBottom: 5,
        borderRadius: 5,
    },
    dayBlock: {
        padding: 10,
        border: '1px solid #aeaeae',
        marginBottom: 10,
        borderRadius: 5,
    },
    dayOutOfRange: {
        background: '#ffb6bd'
    }
})
CourseScheduleCreateAndUpdate.defaultProps={
    mode:'create'
}
export default CourseScheduleCreateAndUpdate;
