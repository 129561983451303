import * as ClassicEditor from "../ckeditor/ckeditor";
import {CKEditor} from "@ckeditor/ckeditor5-react";


const XCKEditor = ({onChange, onReady, value}) => {
    return <CKEditor
        editor={ClassicEditor}
        config={
            {
                allowedContent: true,
                htmlSupport: {
                    allow: [
                        {
                            name: 'iframe',
                            attributes: true
                        },
                        {
                            name: 'div',
                            attributes: true
                        }
                    ],
                    disallow: []
                },
                alignment: {
                    options: ['left', 'center', 'right']
                },
                toolbar: [
                    'heading', 'alignment','horizontalLine', '|',
                    'sourceEditing','uploadImage','mediaEmbed','htmlEmbed', 'table', 'insertTable', '|',
                    'bold', 'fontcolor', 'fontSize', 'fontbackgroundcolor', 'italic', 'link', 'undo', 'redo', 'bulletedList', 'numberedList', 'blockQuote','|',
                    'code', '|',
                ]
            }
        }
        data={value ? value : ''}
        onReady={editor => {
            if (typeof onReady == 'function') onReady(editor);
        }}
        onChange={(event, editor) => {
            const data = editor.getData();
            if (typeof onChange == 'function') onChange(data);
        }}
        onBlur={(event, editor) => {
            // console.log( 'Blur.', editor );
        }}
        onFocus={(event, editor) => {
            // console.log( 'Focus.', editor );
        }}
    />
}
export default XCKEditor;
