import {Button, Col, Image, Modal, Row} from "react-bootstrap";
import dq1 from "../img/dq1.png";
import Block from "./block";
import pic1 from "../img/pic1.png";
import graph1 from "../img/graph1.png";
import dq2 from "../img/dq2.png";
import dq3 from "../img/dq3.png";
import {useState} from "react";
import HeroBt from "./hero-bt";
import Home from "../../../page/home";

const qAndA = [
    {
        q:'สอบออนไลน์สอบอย่างไร?',
        a:'ดาวน์โหลดคู่มือ หรือชมคลิปตัวอย่างได้ที่หน้าเว็บไซต์',
    },
    {
        q:'สอบไม่ผ่านบางโมดูลทำอย่างไร?',
        a:'สอบใหม่เฉพาะโมดูลที่ยังไม่ผ่านเท่านั้น',
    },
    {
        q:'คะแนนและเกรดจะออกเมื่อไหร่?',
        a:'สามารถตรวจสอบของแต่ละรอบเดือนได้จากประกาศปฏิทินบนหน้าเว็บไซต์',
    },
    {
        q:'เปิดสอบทุกเดือนไหม?',
        a:'เปิดสอบทุกเดือน ไม่เว้นช่วงปิดภาคการศึกษา',
    },
];

const MainContent = ()=>{
    const [showImg,setShowImg] = useState(false);
    const [currentImage,setCurrentImage] = useState(null);

    return <>
        <div className="p-4">
            <Row>
                <Col>
                    <a href='#' onClick={e=>{
                        setCurrentImage({title:'สมรรถนะความฉลาดทางดิจิทัล - Digital intelligence',img:dq1});
                        setShowImg(true)
                    }}>
                        <Image src={dq1} fluid className="mb-4"/>
                    </a>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Home/>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Block title="ปฏิทินการจัดสอบ - Schedule">
                        <iframe
                            src="https://calendar.google.com/calendar/embed?height=600&amp;wkst=1&amp;bgcolor=%23ffffff&amp;src=j65n1hsu356ro85t2tfrf2tohg%40group.calendar.google.com&amp;color=%23AB8B00&amp;src=36af0s3o3eo5c0lod9np99vs00%40group.calendar.google.com&amp;color=%23711616&amp;ctz=Asia%2FBangkok"
                            style={{borderWidth:0}} width="100%" height="600" frameBorder="0"
                            scrolling="no"></iframe>
                    </Block>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Block bg title="คำถามที่พบบ่อย - Faq">
                        <Row>
                            <Col xs={12} md={6} className="flex-vcenter">
                                <Image src={pic1} style={{
                                    borderTopLeftRadius:'50%',
                                    borderTopRightRadius:'50%',
                                }} fluid/>
                            </Col>
                            <Col xs={12} md={6}>
                                <div className="mt-4 mt-lg-2">
                                    {qAndA.map(({q,a},n)=>
                                        <div className="rounded" key={n}>
                                            <div>Q.{n+1} {q}</div>
                                            <div>A.{n+1} {a}</div>
                                        </div>
                                    )}
                                </div>
                            </Col>
                        </Row>
                    </Block>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Block title="หลักสูตรความฉลาดทางดิจิทัล - Skill Mapping" className="shadow">
                        <a href='#' onClick={e=>{
                            setCurrentImage({title:'หลักสูตรความฉลาดทางดิจิทัล - Skill Mapping',img:graph1});
                            setShowImg(true)
                        }}>
                            <Image src={graph1} fluid/>
                        </a>

                    </Block>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Block title='ระดับพฤติกรรมการเรียนรู้ / สมรรถนะ' className="shadow">
                        <a href='#' onClick={e=>{
                            setCurrentImage({title:'ระดับพฤติกรรมการเรียนรู้ / สมรรถนะ',img:dq2});
                            setShowImg(true)
                        }}>
                            <Image src={dq2} fluid/>
                        </a>
                    </Block>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Block title="กรอบสมรรถนะความฉลาดทางดิจิทัล - KKU DQ" className="shadow">
                        <a href='#' onClick={e=>{
                            setCurrentImage({title:'กรอบสมรรถนะความฉลาดทางดิจิทัล - KKU DQ',img:dq3});
                            setShowImg(true)
                        }}>
                            <Image src={dq3} fluid/>
                        </a>
                    </Block>
                </Col>
            </Row>
        </div>
        <Modal show={showImg} size="xl" scrollable onHide={e=>setShowImg(false)}>
            <Modal.Header closeButton>
                <Modal.Title>{currentImage?.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Image src={currentImage?.img}/>
            </Modal.Body>
        </Modal>
    </>
}
export default MainContent;
