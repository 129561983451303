import {Badge, Box, IconButton, makeStyles, Paper} from "@material-ui/core";
import XBreadcrumb from "../../components/xbreadcrumb";
import {useQuery} from "@apollo/client";
import {GET_DB_COURSE, GET_EXAM_SCHEDULE, GET_SCHEDULE_PARTICIPANTS} from "../../gql-query/select-query";
import {useHistory, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {Button, Col, Modal, Row, Table} from "react-bootstrap";
import {getThaiDateBetween} from "../../services/client-helper";
import PeopleIcon from "@material-ui/icons/People";
import ModuleDisplay from "../../components/module-display";
import {getAllMonth} from "../../services/helper";
import ScheduleDetailBlock from "../../components/schedule-detail-block";
import gql from "graphql-tag";


const GET_SCHEDULE_PARTICIPANTS_MINI = gql`
    query ScheduleParticipant($scheduleID: ID) {
        scheduleParticipant(scheduleID: $scheduleID) {
            id
            title
            month
            year
            examStart
            examEnd
            gradeDate
            scoreDate
            registeredCount
            registerStart
            registerEnd
            code
            course {
                id
                name
                fullname
                shortname
            }
            scheduleType {
                id
                name
            }
            rounds {
                id
                examStart
                examEnd
                location {
                    id
                    name
                    maxSeats
                    address
                }
                maxSeats
                registeredCount
                quizSet {
                    id
                    name
                    minutes
                    text_color
                    bg_color
                }
            }
        }
    }
`;


const useStyles = makeStyles(theme=>({
    '@global':{
        '.close':{
            transform:'inherit !important',
            background:'none !important'
        },
    }
}))
const ExamScheduleParticipant = ()=>{
    const {id,scheduleID} = useParams();
    const history = useHistory();
    const [showModal,setShowModal] = useState(false);
    const [selectedRound,setSelectedRound] = useState();
    const [schedule,setSchedule] = useState();

    const resSchedule = useQuery(GET_SCHEDULE_PARTICIPANTS_MINI, {
        variables: {scheduleID},
        fetchPolicy: "network-only",
    });

    useEffect(() =>{
        if(resSchedule.data){
            setSchedule(resSchedule.data.scheduleParticipant);
        }
    },[resSchedule.data]);
    if(resSchedule.loading || !schedule)return <div>Loading...</div>;

    const {course,rounds} = schedule;
    return <>
        <XBreadcrumb data={[
            {title: `Exam Schedule: ${course?.shortname}`, url: '/admin/exam-schedule/' + id},
            {title: 'Participants'}
        ]}/>
    <Paper>
        <Box p={3}>
            <Box p={2}>
                <ScheduleDetailBlock schedule={schedule}/>
            </Box>
            <Table>
                <thead>
                <tr>
                    <td>Date / Time</td>
                    <td>Module</td>
                    <td>Location</td>
                    <td>Participants</td>
                </tr>
                </thead>
                <tbody>
                {rounds.map(round=>
                    <tr key={round.id}>
                        <td>
                            {getThaiDateBetween(round.examStart,round.examEnd,'date-time')}
                        </td>
                        <td>
                        <ModuleDisplay quizSet={round.quizSet}/>
                        </td>
                        <td>{round.location.name}</td>
                        <td>
                            <Button disabled={round.registeredCount==0?true:false}
                                    onClick={e=> {
                                        history.push(`/admin/exam-schedule/${id}/participants/${scheduleID}/round/${round.id}`);
                                    }}
                            >
                                <PeopleIcon/> {round.registeredCount}
                            </Button>
                        </td>
                    </tr>
                )}
                </tbody>
            </Table>
        </Box>
    </Paper>
        <Modal show={showModal} onHide={e=>setShowModal(false)} size='xl'>
            {selectedRound &&
            <>
                <Modal.Header closeButton>
                    <Modal.Title>Round participants detail</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    xxx
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={e=>setShowModal(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </>
            }
        </Modal>
    </>
}
export default ExamScheduleParticipant;
