import {Box, Button, Fade, Grid} from "@material-ui/core";
import React from "react";
import {BsCaretRight, BsCaretLeft} from "react-icons/bs";

const RegisterStepButton = ({onNext, onBack,condition,hr,nextButtonText,backButtonText,disabled}) => {
    return  <div className="p-4">
        <Box pt={2} >
            {hr  && (onBack||condition) && <hr/>}
            <Grid container spacing={2} justifyContent='flex-end'>
                {onBack &&
                <Grid item>
                    <Button variant='contained' color='default' onClick={e => {
                        onBack();
                    }}><BsCaretLeft/>&nbsp;{backButtonText?backButtonText:'Back'}</Button>
                </Grid>
                }
                {onNext &&
                <Grid item>
                    <Fade timeout={500} in={!!condition} unmountOnExit>
                        <Button disabled={disabled} variant='contained' color='primary' onClick={e => {
                            onNext();
                        }}>{nextButtonText?nextButtonText:'Next Step'}&nbsp;<BsCaretRight/></Button>
                    </Fade>
                </Grid>
                }
            </Grid>
        </Box>
    </div>
}
export default RegisterStepButton;
