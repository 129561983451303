import {useMutation, useQuery} from "@apollo/client";
import {GET_COURSE_DB_WITH_ROUNDS} from "../gql-query/select-query";
import {useEffect, useState} from "react";
import {Alert, Button, Col, Form, Row} from "react-bootstrap";
import ModuleDisplay from "./module-display";
import {getThaiDateBetweenSingleDay} from "../services/client-helper";
import {Block} from "../styled-components/block";
import {SAVE_CHANGE_SCHEDULE} from "../gql-query/save-query";
import {toast} from "react-toastify";

const AdminChangeSchedule = ({register,onSuccess,onError,onBack}) => {

    const [selectedScheduleID,setSelectedScheduleID] = useState();
    const [oldRoundID,setOldRoundID] = useState();

    const resCourse = useQuery(GET_COURSE_DB_WITH_ROUNDS, {
        fetchPolicy: 'network-only',
        variables: {
            id:register?.course.id
        },
        skip:!register
    });

    const [resChangeSchedule] = useMutation(SAVE_CHANGE_SCHEDULE);


    useEffect(()=>{
        setOldRoundID(register.scheduleRound.id);
        setSelectedScheduleID(register.schedule.id);
    },[]);

    async function saveChange(scheduleRoundID){
        try{
            let res = await resChangeSchedule({
                variables:{
                    registrationID:register.id,
                    scheduleRoundID
                }
            })
            let {error,message} = res.data.changeScheduleRound;
            if(!error){
                if(onSuccess)onSuccess()
            }else{
                if(onError)onError(message);
            }
        }catch(e){
            if(onError)onError(e.message);
        }
    }

    if(resCourse.loading)return <Alert variant="info">Loading...</Alert>
    return <div>
        <h2 className="mb-2">Schedule Changer</h2>
        <Block outline>
            <div><strong>From:</strong> <span>{register.schedule.title}</span>
                <span className="mr-1 ml-1">/</span>
                <ModuleDisplay quizSet={register.scheduleRound.quizSet}/>
                <hr/>
                <div>
                    {getThaiDateBetweenSingleDay(register.scheduleRound.examStart,register.scheduleRound.examEnd)}
                </div>
            </div>
        </Block>
        <hr/>
        <div>
            <h4 className="mb-2">Destination</h4>
            <Form>
                <Form.Group>
                    <Form.Control as='select' value={register.schedule.id} disabled onChange={e=>{
                        let id = e.target.value;
                        setSelectedScheduleID(id);
                    }}>
                        <option>- Schedule -</option>
                        {resCourse.data?.courseDBbyID?.schedules.map(schd=>
                            <option key={schd.id} value={schd.id}>{schd.title}</option>
                        )}
                    </Form.Control>
                </Form.Group>
            </Form>
        </div>
        {selectedScheduleID &&
            <>
                {resCourse.data?.courseDBbyID?.schedules
                    .filter(schd=>schd.id==selectedScheduleID)
                    .map(schd=>{
                    return <div key={schd.id} className="mb-2">
                        <h4>{schd.title}</h4>
                        <small className="ml-2">{schd.detail}</small>
                        <hr/>
                        <div className="pl-3">
                            <Row>
                                {schd.rounds.filter(round=>round.quizSet.id==register.scheduleRound.quizSet.id).map(round=>{
                                    return <Col md={3}>
                                        <div className="m-1 p-2" style={{
                                            backgroundColor:'#dfdfdf',
                                            borderRadius:10,
                                            border:'1px solid #b4b7bd'
                                        }}>
                                            <ModuleDisplay quizSet={round.quizSet}/>

                                            <hr/>
                                            <div className="mb-1" style={{
                                                backgroundColor: '#fff',
                                                borderRadius: 10,
                                                padding: 7,
                                            }}>
                                                {getThaiDateBetweenSingleDay(round.examStart,round.examEnd)}
                                            </div>
                                            <div style={{display:'flex',justifyContent:'flex-end'}}>
                                                {oldRoundID == round.id?
                                                    <Button variant='secondary' disabled>CURRENT</Button>
                                                    :
                                                    <Button variant="primary" onClick={async e=>{
                                                       await saveChange(round.id);
                                                    }}>SELECT</Button>
                                                }
                                            </div>
                                        </div>
                                    </Col>
                                })}
                            </Row>
                        </div>
                    </div>
                })}
            </>
        }
        <hr/>
        <div>
            <Button variant='primary' onClick={e=>{
                if(onBack)onBack();
            }}>Back</Button>
        </div>
    </div>
}
export default AdminChangeSchedule;
