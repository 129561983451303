import {Card, Col, FormControl, FormGroup, FormLabel, Image, ListGroup, ListGroupItem, Row} from "react-bootstrap";
import React from "react";
import {CourseSettingHeadBar} from "../page/admin/exam-schedule-course-setting";
import {css,StyleSheet} from 'aphrodite';
import ReceiptIcon from '@material-ui/icons/Receipt';

const CourseMoodleQuizSetup = ({courseForm,updateUI})=>{
    return <>
        <CourseSettingHeadBar title="(3) Moodle Quiz setup"/>
        <Card>
            <Card.Body>
                <ListGroup>
                    {courseForm.quizzes.map((q, i) => {
                        return <ListGroup.Item className={q.notExisted?css(styles.list):''} key={i} variant={q.notExisted?'danger':'light'}>
                            <Row>
                                <Col><Image src={q.modicon} fluid/> {q.name}</Col>
                                <Col xs='auto'>
                                    <FormGroup>
                                        <FormLabel>Pass grade (%)</FormLabel>
                                        <FormControl
                                            style={{width: '100px'}}
                                            value={courseForm.quizzes[i].passGrade || ''}
                                            onChange={e => {
                                                let v = e.target.value;
                                                if (!isNaN(v)) {
                                                    if (parseInt(v) <= 100) {
                                                        courseForm.quizzes[i].passGrade = parseInt(v);
                                                    } else {
                                                        courseForm.quizzes[i].passGrade = 0;
                                                    }
                                                } else {
                                                    courseForm.quizzes[i].passGrade = 0;
                                                }
                                                updateUI();
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                {q.notExisted?
                                    <Col xs='auto'>[Deleted]</Col>
                                    :
                                    null
                                }
                            </Row>
                        </ListGroup.Item>
                    })}
                    {/*<ListGroup.Item>*/}
                    {/*    <div style={{height:80}}>*/}
                    {/*        <Row>*/}
                    {/*            <Col><ReceiptIcon/> DQ Workshop</Col>*/}
                    {/*            <Col xs='auto'>*/}
                    {/*                <FormGroup>*/}
                    {/*                    <FormControl style={{width:380}} readOnly value="3 Module set / Word, Excel, Powerpoint pass score: 35"/>*/}
                    {/*                </FormGroup>*/}
                    {/*            </Col>*/}
                    {/*        </Row>*/}
                    {/*    </div>*/}
                    {/*</ListGroup.Item>*/}
                </ListGroup>
            </Card.Body>
        </Card>
    </>
}
const styles = StyleSheet.create({
    list:{
        ':hover':{
            backgroundColor:'#f9cfcf'
        }
    }
})
export default CourseMoodleQuizSetup;
