import {Box, Paper} from "@material-ui/core";
import {GET_EXAM_REPORT} from "../gql-query/select-query";
import {useQuery} from "@apollo/client";
import {Alert, Badge, Button, Modal, Table} from "react-bootstrap";
import {useHistory, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {createGroup} from "../services/client-helper";
import Config from "../config";

const CourseScheduleReport = () => {
    const history = useHistory();
    const {mdCourseID, scheduleID} = useParams();
    const [showModalResult, setShowModalResult] = useState(null);
    const [scheduleReports, setScheduleReport] = useState([]);
    const resReport = useQuery(GET_EXAM_REPORT, {
        variables: {
            mdCourseID,
            scheduleID,
        },
        fetchPolicy: 'cache-and-network'
    });

    useEffect(() => {
        if (resReport.data) {
            let report = resReport.data?.examScheduleReport;
            report = createGroup(report, 'user.id')
            setScheduleReport(report)
            console.log('report===', report);
        }
    }, [resReport.data])

    function displayResult(dataExamResults) {
        return dataExamResults.map(r => {
            let {dqQuiz, lang, score, register} = r?.examResult || {};
            let mdQuiz = dqQuiz?.[lang];
            if (mdQuiz) {
                if (score >= mdQuiz.passGrade) {
                    return <Box>
                        <Badge variant="success">{register.scheduleRound.quizSet.name}: {score || 0}% - Passed</Badge>
                    </Box>
                } else {
                    return <Box>
                        <Badge variant="danger">{register.scheduleRound.quizSet.name}: {score || 0}% - Failed</Badge>
                    </Box>
                }
            }
            return <div>-</div>
        })

        // return false;
    }

    function showAttempt(results) {
        let result = results?.[0];
        setShowModalResult(result);
        console.log(result);
    }

    if (resReport.loading) return <Alert>Loading...</Alert>
    return <Paper>
        <Box p={2}>
            <h1>Schedule Report</h1>
            <Box mt={2}>
                <Table>
                    <thead>
                    <tr>
                        <td>#</td>
                        <td>Identifier</td>
                        <td>Name</td>
                        <td>Result</td>
                        <td>Attempt History</td>
                    </tr>
                    </thead>
                    <tbody>
                    {scheduleReports.map((r, i) =>
                        <tr key={r.group.id}>
                            <td>{i + 1}</td>
                            <td>{r.group.studentID || r.group.username}</td>
                            <td>{r.group.fullname}</td>
                            <td>{displayResult(r.data)}</td>
                            <td><Button disabled={!r.data?.[0]?.examResult?.attemptDataHtml} variant='primary' onClick={e => showAttempt(r.data)}>View</Button></td>
                        </tr>
                    )}
                    </tbody>
                </Table>
            </Box>

        </Box>
        <Modal size='lg' show={!!showModalResult} onHide={e => setShowModalResult(null)}>
            {showModalResult &&
            <>
                <Modal.Header closeButton>
                    <Modal.Title>Result: {showModalResult?.user.prefix}{showModalResult?.user.fname} {showModalResult?.user.lname}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/*<div dangerouslySetInnerHTML={{__html: showModalResult?.examResult?.attemptDataHtml}}></div>*/}
                    <iframe frameBorder='no' width="100%" style={{height:'70vh'}} src={`${Config.api_url}/load-attempt-result/${showModalResult?.user.id}/${showModalResult?.examResult?.id}`}></iframe>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={e => setShowModalResult(null)}>
                        Close
                    </Button>
                </Modal.Footer>
            </>
            }
        </Modal>
    </Paper>
}
export default CourseScheduleReport;
