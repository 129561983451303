import {
    Box, Button,
    Card,
    CardContent,
    CardHeader, Fade, makeStyles,
    Paper,
    Table, TableBody, TableCell,
    TableContainer,
    TableHead, TableRow,
    Typography
} from "@material-ui/core";
import {getRegistrationCountWithThis} from "../services/client-helper";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
import thLocale from "@fullcalendar/core/locales/th";
import {Alert} from "@material-ui/lab";
import RegisterStepButton from "./register-step-button";
import React, {useEffect, useRef, useState} from "react";
import {useMobxState} from "../mobx/global-context";
import moment from "moment";
import clsx from "clsx";
import {GrUserAdd} from "react-icons/gr";
import {BsCheckCircle} from "react-icons/bs";
import _ from "lodash";
import {toast} from "react-toastify";
import {useApolloClient, useQuery} from "@apollo/client";
import gql from "graphql-tag";
import {GET_COURSE_DB_WITH_ROUNDS} from "../gql-query/select-query";
import {toJS} from "mobx";
import {Block} from "../styled-components/block";
import {Col, Row} from "react-bootstrap";
import axios from "axios";
import useCacheControl from "../hooks/cache-control";


const useStyles = makeStyles(theme => ({
    root: {
        '& .event': {
            width: '100%',
            borderRadius: 5,
            color: '#fff',
            transition: 'background-color 500ms'
        },
        '& .selected': {
            filter: 'brightness(1.2)',
            transition: 'background-color 500ms',
        }
    },
    table: {},
    listMonthSelected: {
        [theme.breakpoints.up('lg')]: {
            position: 'absolute', top: 5, left: 8, color: '#fff',
        },
        letterSpacing: '1px',
        border: '1px solid #fff',
        padding: 5,
        borderRadius: 10,
    },
    dayGridMonthSelected: {
        [theme.breakpoints.up('md')]: {
            border: '1px solid #fff',
        },
        textAlign: 'center',
        letterSpacing: '1px',
        borderRadius: 10,
    }
}))

const RegistrationPicker = ({
                                currentCourse,
                                userRegistrations,
                                currentSchedule,
                                modules = [],
                                selectedRounds = [],
                                onSelectedRound,
                                onRemoveRound,
                                removeAble = true
                            }) => {
    const calendar = React.useRef();
    const [events, setEvents] = useState([]);
    const classes = useStyles();
    const toastID = useRef({});
    const [_selectedRounds, _setSelectedRounds] = useState();
    const [myRegisteredCount, setMyRegisteredCount] = useState(-1);
    const apolloClient = useApolloClient();
    const [updateRounds, setUpdateRounds] = useState({getScheduleRoundById:[]});
    const cacheControl = useCacheControl();
    // const GET_ROUND_COUNTER = gql`
    // query GetScheduleRoundById($scheduleID: ID!) {
    //   getScheduleRoundById(scheduleID: $scheduleID) {
    //     id
    //     registeredCount
    //   }
    // }
    // `;
    // const {data: updateRounds, refetch} = useQuery(GET_ROUND_COUNTER, {
    //     fetchPolicy: 'network-only',
    //     variables: {
    //         scheduleID: currentSchedule?.id,
    //     },
    //     skip:!currentSchedule
    // })

    useEffect(()=>{
        syncScheduleRoundById().then(()=>{}).catch(e=>{})
    },[currentSchedule])

    useEffect(() =>{
       if(removeAble){
           axios.get('/api/user-registered-count?scheduleID='+currentSchedule?.id).then(res=>{
               let {count} = res.data;
               setMyRegisteredCount(count);
           })
       }
    },[]);

    useEffect(() => {
        // console.log('modules====', toJS(modules));
        let interval = setInterval( async () => {
           let needUpdate = await cacheControl.syncPublicCache('schedule-counter');
           if(needUpdate) {
               await syncScheduleRoundById()
           }
           //  await syncScheduleRoundById()
           //  await refetch();
        }, 5000);
        return () => {
            clearInterval(interval)
        }
    }, [])

    useEffect(() => {
        let buffers = [];
        currentCourse
            ?.schedules
            ?.filter(schd => schd.id == currentSchedule?.id)
            ?.forEach(s => {
                s.rounds.filter(r => {
                    return !!modules.find(v => v.id == r.quizSet.id)
                }).forEach(round => {
                    let existedUpdatedRound = updateRounds?.getScheduleRoundById.find(v => v.id == round.id);
                    let registeredCount = existedUpdatedRound ? existedUpdatedRound.registeredCount : round.registeredCount;
                    if(registeredCount>=(round?.maxSeats || 1) ){
                    // if (registeredCount >= 1) {
                        let existed = userRegistrations.find(v => String(v?.scheduleRound?.id) === String(round.id));
                        if (!existed) {
                            onRemoveRound(round.id);
                        }
                    }
                    buffers = [
                        ...buffers,
                        {
                            id: round.id,
                            title: round.quizSet?.name,
                            schedule: s,
                            quizSet: round.quizSet,
                            text_color: round.quizSet?.text_color,
                            bg_color: round.quizSet?.bg_color,
                            roundData: round,
                            location: round.location.name,
                            timeStart: moment(parseInt(round.examStart, 10)).format('HH:mm'),
                            timeEnd: moment(parseInt(round.examEnd, 10)).format('HH:mm'),
                            registeredCount,
                            maxSeats: round.maxSeats,
                            seats: `${registeredCount>=round.maxSeats?'No seats available':`${registeredCount} / ${round.maxSeats}`}`,
                            isFull: registeredCount >= round.maxSeats,
                            start: new Date(parseInt(round.examStart, 10)),
                            end: new Date(parseInt(round.examEnd, 10)),
                            examStart: round.examStart,
                            examEnd: round.examEnd,
                            // allDay: true,
                            // backgroundColor: round.quizSet.name == 'Theory' ? '#f73378' : '#4dabf5',
                            // textColor:round.quizSet.name=='Theory'?'red':'blue',
                        }
                    ]
                })
            })
        setEvents(buffers);
    }, [currentCourse, updateRounds])

    async function syncScheduleRoundById(){
        if(!currentSchedule)return;
        try{
            let res = await axios.get(`/api/getScheduleRoundById/${currentSchedule?.id}`);
            setUpdateRounds({
                getScheduleRoundById:res.data
            });
        }catch (e){
            console.log('getScheduleRoundById=',e?.message);
            setUpdateRounds({
                getScheduleRoundById:[]
            });
        }
    }


    function renderEvent(eventInfo) {
        let {
            id,
            title,
            extendedProps: {registeredCount,isFull, seats, maxSeats, timeStart, timeEnd, schedule, text_color, bg_color,location}
        } = eventInfo.event;
        let isSelected = selectedRounds.find(v => v.id === id) && !isFull;
        let viewType = calendar.current?.getApi()?.view?.type;
        return <div key={id} style={{position: 'relative', width: '100%', cursor: isSelected ? 'inherit' : 'pointer'}}>
            <div className={clsx({
                event: 'true',
                selected: isSelected,
            })}
                 style={
                     {
                         backgroundColor: bg_color,
                         color: text_color
                     }
                 }
            >
                <div className='text-center'><GrUserAdd className="mr-1"/> {title}</div>
                <div className='text-center'>{timeStart} น. - {timeEnd} น.</div>
                <div className='text-center'>{seats}</div>
                {isFull &&
                    <div className='text-center' style={{color:'red'}}>ที่นั่งเต็มแล้ว</div>
                }
                <div className='text-center' style={{
                    fontSize: '0.8rem',
                    borderTop: 2,
                    borderColor: 'black',
                    marginTop: 10,
                    background: `${bg_color}4f`,
                    filter: 'contrast(2)',
                    borderRadius: 5,
                    padding: 4,
                }}>{location}</div>
                {viewType == 'dayGridMonth' &&
                <Fade in={!!isSelected}>
                    <Box p={1}>
                        <div className={classes.dayGridMonthSelected}><BsCheckCircle
                            style={{fontSize: '1.5rem'}}/> SELECTED
                        </div>
                    </Box>
                </Fade>
                }
                {viewType == 'listMonth' &&
                <Fade in={!!isSelected}>
                    <Box p={1}>
                        <div className={classes.listMonthSelected}>
                            <Box display='flex' alignItems='center'><BsCheckCircle style={{fontSize: '1.5rem'}}/>&nbsp;
                                <span>SELECTED</span></Box>
                        </div>
                    </Box>
                </Fade>
                }
            </div>
        </div>
    }


    function selectRoundHandle(info) {
        let {
            id,
            title,
            extendedProps: {
                location,
                roundData,
                registeredCount,
                maxSeats,
                seats,
                isFull,
                timeStart,
                timeEnd,
                schedule,
                quizSet,
                examStart,
                examEnd
            }
        } = info.event;
        if (selectedRounds.find(v => v.id == id)) return;
        if(isFull)return;
        let buffers = {
            id,
            location,
            roundData,
            schedule,
            quizSet,
            title,
            registeredCount,
            maxSeats,
            seats,
            timeStart,
            timeEnd,
            examStart,
            examEnd,
        }
        onSelectedRound(buffers);
    }

    function getScheduleDate() {
        let {examStart} = toJS(currentSchedule);
        let d = new Date(parseInt(examStart, 10));
        d = moment(d).startOf('month').toDate();
        return d;
    }

    function showSelectedSchedule(selectedRounds) {
        let dataSet = [];
        selectedRounds.map((row) => {
            let ev = events.find(v => v.id == row.id);
            if (ev) {
                dataSet.push({
                    id: row.id,
                    scheduleTitle: ev?.schedule?.title,
                    title: ev?.title,
                    timeStart: ev?.timeStart,
                    timeEnd: ev?.timeEnd,
                    seats: ev?.seats,
                    location:ev?.location,
                    button: <>
                        {removeAble &&
                        <Button variant='outlined' color='secondary'
                                onClick={e => onRemoveRound(row.id)}>
                            Remove
                        </Button>
                        }
                    </>,
                });
            }
        })
        return <>
            <div className="d-none d-lg-block">
                <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Month</TableCell>
                                <TableCell>Exam Type</TableCell>
                                <TableCell>Round</TableCell>
                                <TableCell>Participants</TableCell>
                                {removeAble &&
                                <TableCell>Action</TableCell>
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {dataSet.length > 0 ?
                                <>
                                    {dataSet.map((d) => {
                                        return <TableRow key={d.id}>
                                            <TableCell>{d.scheduleTitle} ({d.location})</TableCell>
                                            <TableCell>{d.title}</TableCell>
                                            <TableCell>{d.timeStart} น. - {d.timeEnd} น.</TableCell>
                                            <TableCell>{d.seats}</TableCell>
                                            <TableCell>{d.button}</TableCell>
                                        </TableRow>
                                    })}
                                </>
                                :
                                <TableRow>
                                    <TableCell colSpan={6}>
                                        <Alert severity="info">Please choose exam schedule round below.</Alert>
                                    </TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            <div className="d-block d-lg-none">
                {dataSet.length > 0 ?
                    <>
                        {dataSet.map((d) => {
                            return <Block outline shadow key={d.id} className="mb-2">
                                <div><strong>{d.title}</strong></div>
                                <div style={{marginTop:4}}>{d.scheduleTitle} <span style={{fontWeight:'bold'}}>({d.location})</span></div>
                                <hr/>
                                <div>Time: {d.timeStart} น. - {d.timeEnd} น.</div>
                                <hr/>
                                <Row>
                                    <Col>
                                        <div>Seats {d.seats}</div>
                                    </Col>
                                    <Col>
                                        <div>
                                            <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                                                {d.button}
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Block>
                        })}
                    </>
                    :
                    <>
                        <Alert severity="info">Please choose exam schedule round below.</Alert>
                    </>
                }
            </div>
        </>
    }

    if (!events || !currentSchedule) return <div>Loading...</div>
    return <Box p={2} className={classes.root}>
        {/*<Typography variant='h5'>Choose exam schedule ครั้งที่ {getRegistrationCountWithThis(*/}
        {/*    userRegistrations,*/}
        {/*    currentSchedule?.id*/}
        {/*)}</Typography>*/}

        {removeAble &&
            <Typography variant='h5'>Choose exam schedule ครั้งที่ {!isNaN(myRegisteredCount)?myRegisteredCount:<span>Wait...</span>}</Typography>
        }

        <Box mb={3}></Box>
        <FullCalendar
            height='auto'
            ref={calendar}
            plugins={[dayGridPlugin, listPlugin, interactionPlugin]}
            // initialView="dayGridMonth"
            initialView="listMonth"
            selectable={true}
            headerToolbar={{
                // start: 'prev,next today',
                // center: 'title',
                end: 'dayGridMonth,listMonth'
            }}
            select={info => {
                console.log(info);
            }}
            locale={thLocale}
            eventClick={selectRoundHandle}
            // dateClick={info => {
            //     console.log(info);
            // }}
            events={events}
            eventContent={renderEvent}
            initialDate={getScheduleDate()}
        />
        <Box mb={3}></Box>
        <Card>
            <CardHeader title='Selected schedule'/>
            <CardContent>
                <Box p={1}>

                    {showSelectedSchedule(selectedRounds)}
                    {/*{selectedRounds.length > 0 ?*/}
                    {/*    <>*/}
                    {/*        {selectedRounds.map((row) => {*/}
                    {/*            let ev = events.find(v=>v.id==row.id)*/}
                    {/*            if(!ev)return <TableRow key={row.id}>*/}
                    {/*                <TableCell colSpan={6}>Loading...</TableCell>*/}
                    {/*            </TableRow>*/}
                    {/*            return <TableRow key={row.id}>*/}
                    {/*                <TableCell>{ev?.schedule?.title}</TableCell>*/}
                    {/*                <TableCell>{ev?.title}</TableCell>*/}
                    {/*                <TableCell>{ev.timeStart} - {ev.timeEnd}</TableCell>*/}
                    {/*                <TableCell>{ev.seats}</TableCell>*/}
                    {/*                {removeAble &&*/}
                    {/*                <TableCell>*/}
                    {/*                    <Box align="right">*/}
                    {/*                        <Button variant='outlined' color='secondary'*/}
                    {/*                                onClick={e => onRemoveRound(row.id)}>*/}
                    {/*                            Remove*/}
                    {/*                        </Button>*/}
                    {/*                    </Box>*/}
                    {/*                </TableCell>*/}
                    {/*                }*/}
                    {/*            </TableRow>*/}
                    {/*        })}*/}
                    {/*    </>*/}
                    {/*    :*/}
                    {/*    <TableRow>*/}
                    {/*        <TableCell colSpan={6}>*/}
                    {/*            <Alert severity="info">Please choose exam schedule round below.</Alert>*/}
                    {/*        </TableCell>*/}
                    {/*    </TableRow>*/}
                    {/*}*/}

                </Box>
            </CardContent>
        </Card>
    </Box>
}
export default RegistrationPicker;
