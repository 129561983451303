import MobxStore from "../mobx/mobx-store";
import axios from "axios";
import Config from "../config";

export const checkLogin=()=>{
    return  axios.get(Config.api_url+'/check-login');
}
export function logout(){
    return axios.post(Config.api_url+'/logout');
}

export function requestAPI(method,url,data){
    return axios({
        method,
        url:Config.api_url+url,
        data
    });
}

export function createMoodleAuth(){
    return new Promise(async resolve => {
        let user = await axios.post(`${Config.moodleURL}/auth/xtoken/check.php`)
        if(user.data && (user.data.username==MobxStore.currentUser.username)){
            resolve({
                needLogin:false,
            });
        }else{
            let token = await axios.post(Config.api_url+'/moodle-create-auth');
            resolve({
                needLogin:true,
                token:token.data,
            });
        }
    });
}

export function createExamMoodleAuth(reg_round_id){
    return new Promise(async resolve => {
        let token = await axios.post(Config.api_url+'/moodle-create-auth',{reg_round_id});
        resolve({
            needLogin:true,
            token:token.data,
        });
    });
}

export function createMoodleLink(url='/course/view.php?id=2'){
    let link=`${Config.api_url}/start-moodle?url=${url}`
    return link;
}

export function startMoodle(url=''){
   return new Promise(async resolve => {
       let res = await createMoodleAuth()
       if(res.needLogin && res.token){
           let authURL=`${Config.moodleURL}/login/?token=${res.token}&url=/`;
           await axios.get(authURL);
       }
       let popup = window.open(null,'KKU-DQ');
       popup.location.href=Config.moodleURL+`${url}/`
       resolve(true);
   })
}

export function getMoodleURL(url=''){
    return new Promise(async resolve => {
        let res = await createMoodleAuth()
        if(res.needLogin && res.token){
            let authURL=`${Config.moodleURL}/login/?token=${res.token}&url=/`;
            await axios.get(authURL);
        }
        resolve(Config.moodleURL+`${url}/`);
    })
}

export function getMongooseID(){
    return new Promise(resolve => {
        if(MobxStore.mongooseID.length==0){
            axios.get(Config.api_url+'/get-id').then((res)=>{
                MobxStore.mongooseID=res.data;
                let id = MobxStore.mongooseID.splice(0,1);
                resolve(id[0]);
            })
        }else{
            let id = MobxStore.mongooseID.splice(0,1);
            resolve(id[0]);
        }
    })
}
