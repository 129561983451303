import {Link, NavLink, useLocation} from "react-router-dom";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
const LiNav = ({to, exact,icon,muiIcon,external=false,onClick,children}) => {
    const location = useLocation();
    let active = '';

    if(external){
        return <li className={`nav-item ${active?'active':''}`}>
            <a href={to} className="d-flex align-items-center" onClick={onClick} target="_blank">
                <FontAwesomeIcon icon={icon}/> <span className="menu-title text-truncate"
            >{children}</span>
            </a>
        </li>
    }

    if (exact) {
        location.pathname===to?active=true:void 0;
    }else{
        let r = new RegExp(to)
        location.pathname.match(r,'i')?active=true:void 0;
    }

    return <li className={`nav-item ${active?'active':''}`}>
        <Link to={to} className="d-flex align-items-center" onClick={onClick}>
            {icon && <FontAwesomeIcon icon={icon}/>}{muiIcon} <span className="menu-title text-truncate"
        >{children}</span>
        </Link>
    </li>
}
export default LiNav;
