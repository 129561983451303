import {Col, Row, Card, Button, Table, Badge, Alert, Modal} from "react-bootstrap";
import {
    createGroup,
    getThaiDateBetween, getThaiDateBetweenSingleDay,
    isCurrentDateBetween,
    isCurrentDateBetweenServerTime,
    sort
} from "../services/client-helper";
import React, {useEffect, useState} from 'react';
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import StartExamButton from "./start-exam-button";
import {Box, Grid, Typography} from "@material-ui/core";
import MButton from "./mbutton";
import DeleteIcon from "@material-ui/icons/Delete";
import CompareArrowsIcon from "@material-ui/icons/CompareArrows";
import {observer} from "mobx-react";
import {useMobxState} from "../mobx/global-context";
import {confirmAlert} from "react-confirm-alert";
import {toast} from "react-toastify";
import {GET_CURRENT_USER, GET_OLD_SCHEDULE, GET_STUDENT_REGISTRATIONS} from "../gql-query/select-query";
import {useApolloClient, useMutation, useQuery} from "@apollo/client";
import {DELETE_SCHEDULE} from "../gql-query/delete-query";
import {Block} from "../styled-components/block";
import {Link} from "react-router-dom";
import ReceiptIcon from "@material-ui/icons/Receipt";
import ModuleDisplay from "./module-display";
import BillPayment from "./bill-payment";
import moment from "moment";
import {RiMoneyDollarBoxFill} from 'react-icons/ri';
import Countdown from "./countdown";
import axios from "axios";

function isCurrentDateBetweenWithTime(serverTime, start, end) {
    let currentDate = new Date();
    return currentDate >= start && currentDate < end;
}

const ScheduleStatus = ({
                            serverTime, scheduleDetail, schedules,
                            registerStart, registerEnd, onDelete, onChange,
                            course, reg
                        }) => {
    return <>
        {
            isCurrentDateBetweenWithTime(serverTime, registerStart, registerEnd)
            &&
            !schedules.find(reg => reg.examResult) ?
                <>
                    <Alert variant="warning">You can modify schedule between
                        ( {getThaiDateBetween(registerStart, registerEnd, 'date-time')} )</Alert>
                    <Box p={2} display="flex" justifyContent="flex-end" style={{gap: 10}}>
                        <Button variant="danger" onClick={e => onDelete(scheduleDetail)}><DeleteIcon/> Delete</Button>
                        <Button variant="warning"
                                onClick={e => onChange(course, scheduleDetail, schedules)}><CompareArrowsIcon/> Change</Button>
                    </Box>
                    <hr/>
                </>
                :
                <Box>
                    <Alert variant='success'>Confirmed schedule.</Alert>
                    <hr/>
                </Box>
        }
    </>
}


const BillingState = ({regSchedule,onClick}) => {
    let regWithBills = regSchedule.data.filter(v => !!v.bill).map(reg => reg.bill);
    let uniqueBills = [...new Set(regWithBills.map(b => b.id))]
        .map(id => regWithBills.find(b => b.id == id));
    if (regWithBills?.length == 0) return null;
    return <>
        <div style={{
            backgroundColor: '#e9ecef',
            borderRadius: 10,
            padding: 10,
            marginBottom: 5,
        }}>
            <h5>Fee Payment</h5>
            <hr/>
            <div>
                {uniqueBills?.map((bill,index) => {
                    let {registers} = bill;
                    return <Row key={index} className="mb-2">
                        <Col>
                            <div style={{display:'flex',alignContent:'flex-start'}}>
                                {registers.map((reg,n) =>
                                    <React.Fragment key={n}>
                                        <span className="p-1">
                                            <ModuleDisplay quizSet={reg.scheduleRound.quizSet}/>
                                        </span>
                                        {n<registers.length-1?<div className="flex-vcenter">+</div>:null}
                                    </React.Fragment>
                                )}
                            </div>
                            {!bill.isPaid &&
                                <div className='mt-2 ml-1' style={{display:'flex',alignContent:'flex-start'}}>
                                    <Countdown title='Expire in:' showDay={false} style={{fontSize:'.8rem',display:'inline-block',textAlign:'left'}} expireTimestamp={moment(parseInt(bill.expire)).toDate().getTime()}/>
                                </div>
                            }
                        </Col>
                        <Col xs='auto' className="flex-vcenter d-none d-md-flex">
                            <span style={{color:'#836f35',fontWeight:500,textDecoration:'underline'}}>Total: {bill.amount} THB</span>
                        </Col>
                        {bill.isPaid?
                            <Col xs='auto flex-vcenter'>
                                <div className="mr-2">
                                    <span style={{fontSize:'1.5rem'}}><CheckCircleIcon style={{color: '#4caf50'}}/> Paid</span>
                                </div>
                            </Col>
                            :
                            <Col xs='auto flex-vcenter'>
                                <div className='d-none d-md-block'>
                                    <Button variant='primary' style={{fontSize:'.80rem'}} onClick={e=>onClick(bill)}><RiMoneyDollarBoxFill/> Pay Now</Button>
                                </div>
                                <div className='d-block d-md-none'>
                                    <Button variant='primary' style={{fontSize:'.80rem'}} onClick={e=>onClick(bill)}>Pay: {bill.amount} THB</Button>
                                </div>
                            </Col>
                        }
                    </Row>;
                })}
            </div>
            {/*<Box p={1}>*/}
            {/*    <Grid container spacing={2}>*/}
            {/*        {createGroup(regWithBills, 'bill.id').map((gReg, i) => {*/}
            {/*            let quizList = gReg.group.registers.map(v => v.scheduleRound?.quizSet?.name);*/}
            {/*            return <Grid item key={gReg.group.id}>*/}
            {/*                <MButton variant='contained' color='primary'*/}
            {/*                         onClick={e => {*/}
            {/*                             console.log('gReg==', gReg.group);*/}
            {/*                             setSelectedBillReg({bill: gReg.group})*/}
            {/*                         }}>*/}
            {/*                    <ReceiptIcon style={{marginRight: 10}}/> ({i + 1})*/}
            {/*                    Payment: {quizList.join(', ')} / {gReg.group.amount} THB*/}
            {/*                </MButton>*/}
            {/*            </Grid>*/}
            {/*        })}*/}
            {/*    </Grid>*/}
            {/*</Box>*/}
        </div>
    </>
}

const StudentDashboard = ({studentRegistrations, onDelete, onChange, history,updateTime=false}) => {

    const state = useMobxState();
    const [selectedBill, setSelectedBill] = useState();
    const [oldSchedules, setOldSchedules] = useState([]);
    const client = useApolloClient();

    const oldSchedulesReq = useQuery(GET_OLD_SCHEDULE, {
        fetchPolicy: 'network-only',
        skip: !history
    });

    useEffect(() => {
        if (oldSchedulesReq.data) {
            let buffers = oldSchedulesReq?.data?.oldSchedules || [];
            let gData = createGroup(buffers, 'scheduleDetail.SchdID')
            console.log('gData==', gData);
            setOldSchedules(gData);
        }
    }, [oldSchedulesReq.data]);

    useEffect(() => {
       if(updateTime){
           state.updateServerTime();
       }
    }, []);


    const head = [
        'Module',
        'Exam date',
        'Location',
        'Score',
        'Paid',
        '',
    ]

    function groupedStudentRegistrations() {
        let sorted = sort(studentRegistrations, 'course.id', 'asc');
        let groupedByCourse = createGroup(sorted, 'course.id');
        for (let g of groupedByCourse) {
            g.data = createGroup(g.data, 'schedule.id');
        }
        return groupedByCourse
    }

    function showScore(examResult) {
        if (!examResult) return '-';
        let {quizResults,register,lang} = examResult;
        let {quiz_mode} = register.scheduleRound.quizSet;
        if (quizResults.length > 0) {
            return quizResults.map(q => {
                let {passGrade} = q.dqQuiz?.[lang];
                return <div key={q.dqQuiz.id}>
                    <div key={q.dqQuiz.id}>{q.dqQuiz.name}: <Badge style={{fontWeight:500}}
                        variant={
                            isNaN(q.score)?
                                'default'
                                :
                                (q.score>=passGrade?'success':'danger')
                        }>{isNaN(q.score) ? 'Wait..' : q.score + '%'}</Badge></div>
                </div>
            });
        } else {
            return '-'
        }
    }

    function getPaidState(reg) {
        if (reg.bill) {
            if (reg.bill.isPaid) {
                return <span><CheckCircleIcon style={{color: '#4caf50'}}/> Paid</span>
            } else {
                return <span>No</span>
            }
        } else {
            if (reg.usedWallet && reg.usedWallet > 0) {
                return <span><AccountBalanceWalletIcon style={{color: '#ff9800'}}/> Wallet Paid</span>
            }
        }
        return <span>Free</span>
    }


    return <div>
        {groupedStudentRegistrations().length == 0 &&
        <>
            {history ?
                <Box mt={4}>
                    <Alert variant="info">No DQ history found.</Alert>
                </Box>
                :
                <Box mt={4}>
                    <Alert variant="info">No schedule found, Please registration first. <Link to='/my/register'>REGISTER
                        NOW</Link></Alert>
                </Box>
            }
        </>
        }
        {groupedStudentRegistrations()?.map((course, n) => {
            let schedules;
            if (history) {
                schedules = course.data.filter(schd => state.serverTime?.getTime() >= schd.group?.examEnd);
            } else {
                schedules = course.data.filter(schd => state.serverTime?.getTime() < schd.group?.examEnd);
            }
            return <React.Fragment key={'c_' + n}>
                <h4 className="mb-2">{course.group.fullname}</h4>
                {schedules.length == 0 &&
                <Box mt={4}>
                    <Alert variant="info">No incoming schedule, <Link to="/my/history">View history here.</Link></Alert>
                </Box>
                }
                {
                    schedules?.map(schedule => {
                        let isEditableSchedule = isCurrentDateBetweenServerTime(state.serverTime, schedule.group.registerStart, schedule.group.registerEnd) &&
                            schedule.data.find(reg => !reg.examResult);
                        let dataSets = schedule.data.map(reg => {
                            return {
                                key: reg.scheduleRound.id,
                                isEditable: isEditableSchedule,
                                module: <span style={{
                                    borderRadius: 5,
                                    padding: 5,
                                    color: reg.scheduleRound.quizSet.text_color,
                                    background: reg.scheduleRound.quizSet.bg_color
                                }}>{reg.scheduleRound.quizSet.name}</span>,
                                // examDate: getThaiDateBetween(reg.scheduleRound.examStart, reg.scheduleRound.examEnd, 'LLL', true),
                                examDate: getThaiDateBetweenSingleDay(reg.scheduleRound.examStart, reg.scheduleRound.examEnd),
                                location: reg.scheduleRound?.location.name,
                                score: showScore(reg.examResult),
                                paid: getPaidState(reg),
                                startIn: <StartExamButton serverTime={state.serverTime} reg={reg}/>,
                            }
                        })
                        let detail = <>
                            <h5 style={{fontSize:'1rem'}}>{schedule.group.title} / <small style={{fontSize:'.55rem'}}>({schedule.group.code})</small></h5>
                            <div>Register: {getThaiDateBetween(schedule.group.registerStart, schedule.group.registerEnd, 'llll')}</div>
                        </>
                        return <Block shadow className="mb-4" key={schedule.group.id} outline>
                            <div className={'d-none d-md-block'}>
                                <Table responsive>
                                    <thead>
                                    <tr>
                                        <td colSpan="6">
                                            {detail}
                                        </td>
                                    </tr>
                                    <tr>
                                        {head.map((h, n) => <th key={n}>{h}</th>)}
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {dataSets.map((d, n) => <tr key={'_' + n}>
                                        <td>{d.module}</td>
                                        <td>{d.examDate}</td>
                                        <td>{d.location}</td>
                                        <td>{d.score}</td>
                                        <td>{d.paid}</td>
                                        {!history ? <td>{d.startIn}</td> :
                                            <td><Button disable variant="secondary">Ended</Button></td>}
                                    </tr>)}
                                    </tbody>
                                </Table>
                            </div>
                            <div className={'d-block d-md-none'}>
                                {detail}
                                <hr/>
                                {dataSets.map(d =>
                                    <Card key={d.key} className="mb-4">
                                        <Card.Header>{d.module}</Card.Header>
                                        <Card.Body>
                                            <div>
                                                <div className="mb-1" style={{fontWeight: 'bold'}}>{head[1]}</div>
                                                <div style={{paddingLeft: 5}}>{d.examDate}</div>
                                            </div>
                                            <div>
                                                <div className="mb-1" style={{fontWeight: 'bold'}}>{head[2]}</div>
                                                <div style={{paddingLeft: 5}}>{d.location}</div>
                                            </div>
                                            <div>
                                                <div className="mb-1" style={{fontWeight: 'bold'}}>{head[3]}</div>
                                                <div style={{paddingLeft: 5}}>{d.score}</div>
                                            </div>
                                            <div>
                                                <div className="mb-1" style={{fontWeight: 'bold'}}>{head[4]}</div>
                                                <div style={{paddingLeft: 5}}>{d.paid}</div>
                                            </div>
                                        </Card.Body>
                                        <Card.Footer>
                                            <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                                                <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                                                    <div>{d.startIn}</div>
                                                </div>
                                            </div>

                                        </Card.Footer>
                                    </Card>
                                )}
                            </div>
                            <BillingState regSchedule={schedule} onClick={bill=>setSelectedBill(bill)}/>
                            {!history &&
                            <ScheduleStatus
                                serverTime={state.serverTime}
                                course={course.group}
                                registerStart={schedule.group.registerStart}
                                registerEnd={schedule.group.registerEnd}
                                scheduleDetail={schedule.group}
                                schedules={schedule.data}
                                onDelete={onDelete}
                                onChange={onChange}

                            />
                            }
                        </Block>
                    })
                }
            </React.Fragment>
        })}
        {history && oldSchedules.length > 0 &&
        <>
            <hr/>
            <h4>EMS EXAM HISTORY</h4>
            <Table>
                <thead>
                <tr>
                    <th>#</th>
                    <th>Schedule</th>
                    <th>Results</th>
                </tr>
                </thead>
                <tbody>
                {oldSchedules.map((g, n) => {
                    let schd = g.group;
                    let rounds = g.data;
                    return <tr key={schd.id}>
                        <td>{n + 1}</td>
                        <td>{schd.SchdCode}</td>
                        <td>
                            {
                                rounds.map(r => {
                                    let {round,scheduleDetail,score} = r;
                                    return <Block shadow outline style={{marginBottom:5}}>
                                        <Row>
                                            <Col>
                                                <div className="mb-2">{round.ModuleType==1?
                                                    <Badge variant="danger" style={{fontWeight:500}}>Theory</Badge>
                                                    :
                                                    <Badge variant="primary" style={{fontWeight:500}}>Workshop</Badge>
                                                }</div>
                                                <div>{moment(round.ExamDate).format('Do MMMM YYYY')}</div>
                                                <div>{moment(round.ExamTimeStart).format('HH:mm')} - {moment(round.ExamTimeEnd).format('HH:mm')}</div>
                                            </Col>
                                            <Col>
                                                {round.ModuleType==1?
                                                    <div>Score: {score.theory?(score.theory>28?<Badge variant='success' style={{fontWeight:500}}>{score.theory} - Passed</Badge>:score.theory):'-'}</div>
                                                    :
                                                    <div>
                                                        {
                                                            score.workshop.map(wk=>
                                                                <div key={wk.type}><span className="text-capitalize">{wk.type}</span>: {wk.score?(wk.score>35?<Badge variant="success" style={{fontWeight:500}}>{wk.score} - Passed</Badge>:wk.score):'-'}</div>
                                                            )
                                                        }
                                                    </div>
                                                }
                                            </Col>
                                        </Row>
                                    </Block>
                                })
                            }
                        </td>
                    </tr>
                })}
                </tbody>
            </Table>
        </>
        }
        <Modal show={!!selectedBill} onHide={e => setSelectedBill(null)} size='xl'>
            {selectedBill &&
            <>
                <Modal.Header closeButton>
                    <Modal.Title>Bill Detail</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <BillPayment bill={selectedBill} type='all' onExpired={async () => {
                        try{
                            await client.resetStore();
                            await axios.get('/api/clear-cache');
                        }catch (e){}
                        // refetchRegistration()
                        //     .then(()=>{})
                        //     .catch(e=>{})
                    }}/>
                </Modal.Body>
                <Modal.Footer>
                    <Box display='flex' justifyContent="flex-end" style={{gap: 10}}>
                        <Button variant='primary' onClick={e => setSelectedBill(null)}>Close</Button>
                    </Box>
                </Modal.Footer>
            </>
            }
        </Modal>
    </div>
}
export default observer(StudentDashboard);
