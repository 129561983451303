import axios from "axios";
import {useApolloClient} from "@apollo/client";
import {useMobxState} from "../mobx/global-context";
import {observer} from "mobx-react";


const useCacheControl = ()=>{
    const client = useApolloClient();
    const state = useMobxState();

    async function syncUserCache(fieldName){
        try{
            let cache = await axios.get('/api/sync-client-cache');
            if(cache.data.clear){
                if(fieldName) {
                    client.cache.evict({fieldName});
                    client.cache.gc();
                }else{
                    await client.clearStore();
                }
                return true;
            }
            return false;
        }catch (e){
            console.log('CacheControl=',e?.message);
            await client.resetStore();
            return true;
        }
    }

    async function syncPublicCache(redisName){
        try{
            //state is mobx state
            let cache = await axios.get('/api/sync-client-cache?publicCache=1&redisName='+redisName);
            let clientLastUpdateTimestamp = state?.getCacheLastUpdate(redisName) || 0;
            console.log('clientLastUpdateTimestamp===',clientLastUpdateTimestamp,'res=',cache.data);
            if(cache.data?.update > clientLastUpdateTimestamp){
                state.setCacheUpdate(redisName,cache.data?.update);
                return true; //need to refetch
            }
            return false; //no need to refetch
        }catch (e){
            return true; //need to refetch
        }
    }

    async function clearClientAndServerCache(){
        try{
            await client.resetStore();
            await axios.get('/api/clear-cache');
        }catch (e){
            console.log('clearServerCache=',e?.message);
        }
    }
    return {
        // syncUserCache,
        syncPublicCache,
        clearClientAndServerCache
    }
}
export default useCacheControl;
