import {useHistory, useParams} from "react-router-dom";
import {Box, Paper} from "@material-ui/core";
import {Button, Card, Col, FormControl, FormGroup, Row, Table} from "react-bootstrap";
import gql from "graphql-tag";
import {useQuery} from "@apollo/client";
import {useEffect, useState} from "react";
import {createGroup, getThaiDateBetweenSingleDay} from "../../services/client-helper";
import XBreadcrumb from "../../components/xbreadcrumb";
import Config from "../../config";

const COURSE_CHECK_IN_LIST = gql`
query StudentCheckInList($roundID: ID!) {
  studentCheckInList(roundID: $roundID) {
    id
    course {
      id
      name
      shortname
      fullname
      summary      
    }
    bill {
      id
      amount
      isPaid
    }
    user {
      id
      username
      studentID
      prefix
      fname
      lname
      ssoRole {
        id
        name
      }
    }
    checkIn {
      id
      schedule {
        id        
      }
      scheduleRound {
        id
      }
      scheduleRoundCheckIn{
        id
        groupName
        googleMeetURL
        lastUpdate        
      }
      avatarName
      approved
      lastUpdate
    }
  }
}
`;

let GET_ROUND_DETAIL = gql`
    query GET_ROUND($roundID:ID!) {
        getRoundDetail(roundID:$roundID) {
            examStart
            examEnd
            quizSet{
                name
                minutes
                bg_color
                text_color
            }
            location {
                id
                name
            }
            schedule{
                title
                course{
                    name
                    fullname
                }
            }
        }
    }
`
const CheckInStudent = () => {
    // const [participants,setParticipants]  = useState([]);
    const {roundID} = useParams();
    const history = useHistory();
    const [course,setCourse] = useState();
    const [schedule,setSchedule] = useState();
    const [quizSet,setQuizDet] = useState();
    const resCheckIn = useQuery(COURSE_CHECK_IN_LIST, {
        fetchPolicy: 'network-only', variables: {
            roundID
        }
    });
    const resRoundDetail = useQuery(GET_ROUND_DETAIL, {
        fetchPolicy: 'network-only', variables: {
            roundID
        }
    });
    const [checkInList, setCheckList] = useState([]);
    useEffect(() => {
        if (resCheckIn.data?.studentCheckInList) {
            let checkListByGroup = createGroup(resCheckIn.data?.studentCheckInList,'checkIn.scheduleRoundCheckIn.groupName')
            setCheckList(checkListByGroup);
            // setCourse(resCheckIn.data?.studentCheckInList?.[0]?.course)
        }
    }, [resCheckIn.data])

    useEffect(()=>{
        if(resRoundDetail.data?.getRoundDetail){
            let roundDetail = resRoundDetail.data?.getRoundDetail
            setCourse(roundDetail.schedule.course)
            setSchedule(roundDetail.schedule)
            setQuizDet(roundDetail.quizSet)
        }
    },[resRoundDetail.data])

    if (resCheckIn.loading) return <div>Loading...</div>
    return <>
        <XBreadcrumb data={[
            {title: 'Check-In', url: '/admin/check-in'},
            {title: 'Choose Group'}
        ]}/>
        <h1>Examination Check-In</h1>
        <Box>
            <Paper>
                <Box p={2}>
                    {course && <>
                        <h3>Course: {course?.name}</h3>
                        <hr/>
                        <h5 className="mb-2">Schedule: {schedule?.title}</h5>
                        <h5 className="mb-2">Time: {getThaiDateBetweenSingleDay(resRoundDetail.data?.getRoundDetail?.examStart,resRoundDetail.data?.getRoundDetail?.examEnd,false)}</h5>
                        <h5><span style={{
                            padding: 4,
                            backgroundColor: quizSet?.bg_color,
                            color: quizSet?.text_color,
                            borderRadius: 8,
                        }}>{quizSet?.name}</span> / Time ({quizSet?.minutes}) Minutes</h5>
                    </>
                    }
                </Box>
                <Box p={2}>
                    <Row>
                        {checkInList?.map((p, i) =>
                            <Col key={p.group.id} md={6} lg={4}>
                                <Card>
                                    <Card.Body>
                                        <span className="text-capitalize">{p.group.groupName}</span>
                                    </Card.Body>
                                    <Card.Footer>
                                        <Box display='flex' justifyContent='space-between'>
                                            <span>Participants: {p.data.length}</span>
                                            <Button onClick={e=>{
                                                const encodedRoundID = encodeURIComponent(roundID);
                                                const encodedGroupID = encodeURIComponent(p.group.id);
                                                // history.push(`/admin/check-in/${encodedRoundID}/${encodedGroupID}`)
                                                document.location.href=`${Config.base_url}/admin/check-in/${encodedRoundID}/${encodedGroupID}`
                                            }}>Enter</Button>
                                        </Box>
                                    </Card.Footer>
                                </Card>
                            </Col>
                        )}
                    </Row>
                </Box>
            </Paper>
        </Box>
    </>

}
export default CheckInStudent;
