import {Button, Card, Col, FormGroup, FormLabel, Row} from "react-bootstrap";
import XCKEditor from "../../components/XCKEditor";
import {Box} from "@material-ui/core";

const WorkshopQuiz = () => {
    return <>
        <Card>
            <Card.Body>
                <h1 className="mb-3">EMS Quiz Setup</h1>
                <Row>
                    <Col>
                        <div>
                            <h3>MS Word Quiz</h3>
                            <XCKEditor/>
                        </div>
                    </Col>
                </Row>
                <hr/>
                <Row>
                    <Col>
                        <div>
                            <h3>MS Excel Quiz</h3>
                            <XCKEditor/>
                        </div>
                    </Col>
                </Row>
                <hr/>
                <Row>
                    <Col>
                        <div>
                            <h3>MS Powerpoint Quiz</h3>
                            <XCKEditor/>
                        </div>
                    </Col>
                </Row>
            </Card.Body>
            <Card.Footer>
                <Box display='flex' justifyContent={'flex-end'} gridGap={10}>
                    <Button variant='secondary'>Cancel</Button>
                    <Button>Save</Button>
                </Box>
            </Card.Footer>
        </Card>
    </>
}
export default WorkshopQuiz;