import {
    Alert,
    Button,
    Card,
    Col,
    FormControl,
    FormGroup,
    FormLabel,
    ListGroup,
    ListGroupItem,
    Row,
    Badge
} from "react-bootstrap";
import {useContext, useEffect, useState} from "react";
import {useMutation, useQuery} from "@apollo/client";
import {GET_ROLES, GET_STAFF_USERS} from "../../gql-query/select-query";
import {SET_ROLE} from "../../gql-query/update-query";
import {observer} from "mobx-react";
import StateContext from "../../mobx/global-context";

const RoleAssign = ()=>{
    const [searchText,setSearchText]=useState('');
    const [roleFilter,setRoleFilter] = useState('admin');
    const [roleDisplayName,setRoleDisplayName] = useState('');
    const {loading:loadingRoles,data:dataRoles} = useQuery(GET_ROLES);
    const {loading:loadingUsers,data:dataUsers,refetch:refecthUsers} = useQuery(GET_STAFF_USERS,{fetchPolicy:'network-only'});

    const [setRole, {loading: setRoleLoading, error: setRoleError, data: setRoleData}] = useMutation(SET_ROLE);
    const state = useContext(StateContext);


    async function _setRole(user,roleName){
        setRole({
            variables:{
                userId:user.id,
                roleId:dataRoles.roles.find(r=>r.name==roleName).id,
            }
        }).then(res=>{
            refecthUsers()
        });
    }

    function getRoleDisplayName(role){
        return dataRoles.roles.find(r=>r.name==role)?.displayName
    }

    useEffect(()=>{
            if(dataRoles){
                let currentRole = dataRoles.roles.find(r=>r.name==roleFilter);
                setRoleDisplayName(currentRole.displayName);
            }
    },[roleFilter,dataRoles])


    if(loadingRoles || loadingUsers) return <Alert>Loading...</Alert>
    return <>
        <h1 className="mb-4">DQ Admin Role Assignment</h1>
        <Row>
            <Col>
                <FormGroup>
                    <FormLabel>Select Role</FormLabel>
                    <FormControl as="select" value={roleFilter}
                                 className='text-capitalize'
                                 onChange={e=>{
                        setRoleFilter(e.target.value);
                        refecthUsers();
                    }}>
                        {dataRoles.roles.filter(r=>r.name!='student').map(r=><option value={r.name} key={r.id} className='text-capitalize'>{r.displayName}</option>)}
                    </FormControl>
                </FormGroup>
            </Col>
        </Row>
        <Row>
            <Col md={6}>
                <Card className="mb-4 mb-md-0">
                    <Card.Body>
                        <FormGroup>
                            <FormLabel>Find user</FormLabel>
                            <FormControl value={searchText} onChange={e=>{
                                let v = e.target.value;
                                setSearchText(v);
                            }}></FormControl>
                        </FormGroup>
                        <ListGroup>
                            {/*{console.log(dataUsers)}*/}
                            {
                                dataUsers?.staffUsers
                                    .filter(v=>v.role!=roleFilter)
                                    .filter(v=>v.username.match(new RegExp(searchText)))
                                    .map(user=>
                                        <ListGroupItem key={user.username}>
                                            <Row>
                                                <Col>{user.username}</Col>
                                                <Col xs='auto'><Badge variant='secondary' className='text-capitalize'>{getRoleDisplayName(user.role)}</Badge></Col>
                                                <Col xs='auto'>
                                                    <Button variant='primary'
                                                            disabled={user.id==state.currentUser.id}
                                                            onClick={e=>{_setRole(user,roleFilter)}}>
                                                        Set
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </ListGroupItem>
                                    )
                            }
                        </ListGroup>
                    </Card.Body>
                </Card>
            </Col>
            <Col md={6}>
                <Card>
                    <Card.Header>
                        <h4>Assigned Role: <Badge variant='warning' className="text-capitalize">{roleDisplayName}</Badge></h4>
                    </Card.Header>
                    <Card.Body>
                        <ListGroup>
                            {dataUsers.staffUsers.filter(u=>u.role==roleFilter).map(user=>
                                <ListGroupItem key={user.username}>
                                    <Row>
                                        <Col>{user.username}</Col>
                                        <Col><Badge variant='warning' className='text-capitalize'>{getRoleDisplayName(user.role)}</Badge></Col>
                                        <Col xs='auto'>
                                            <Button variant='danger'
                                                    onClick={e=>{_setRole(user,'guest')}}
                                                    disabled={user.id==state.currentUser.id}>
                                                Unset
                                            </Button>
                                        </Col>
                                    </Row>
                                </ListGroupItem>
                            )}
                        </ListGroup>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    </>
}
export default observer(RoleAssign);
