import {useApolloClient, useQuery} from "@apollo/client";
import {MY_PROGRESSION} from "../gql-query/select-query";
import React, {useEffect, useState, useMemo} from "react";
import {Alert, Badge, Button, Card, Col, Row} from "react-bootstrap";
import {Block} from "../styled-components/block";
import {useMobxState} from "../mobx/global-context";
import {runInAction, toJS} from "mobx";

const StudentProgression = ({refetch,userID,title="My Progressions",calendar=true,onLoaded}) => {
    const state = useMobxState();
    const client = useApolloClient();
    const resMyProgression = useQuery(MY_PROGRESSION,{
        fetchPolicy:'cache-first',
        variables:{
            userID
        },
    });
    const [progressions, setProgressions] = useState([]);
    const [stdRegs, setStdRegs] = useState([]);
    const [show, setShow] = useState(true);

    useEffect(() => {
        if (resMyProgression?.data) {
            let {myProgression, studentRegistrations} = resMyProgression.data;
            runInAction(()=>{
                state.studentProgression = [...myProgression];
            });
            onLoaded && onLoaded(myProgression);
            setProgressions([...myProgression]);
            setStdRegs([...studentRegistrations]);
        }
    }, [resMyProgression.data]);

    useEffect(() =>{
        if(refetch){
            refetch.current=resMyProgression.refetch;
        }
        if(!title){
            setShow(true);
        }
    },[])

    function existedHistory(){
        let r=false;
        stdRegs.forEach(reg=>{
            let existed = reg?.examResult?.quizResults.find(result=>result!=null);
            if(existed){
                r=true;
            }
        });
        return r;
    }

    if (resMyProgression.loading) return <Alert>Loading...</Alert>
    // if (progressions.length === 0) return <Alert>Wait for first result.</Alert>
    return <Card>
        {title &&
        <Card.Header>{title} <Button onClick={e=>setShow(!show)} size="sm" className="ml-2">{show?'Hide':'Show'}</Button></Card.Header>
        }
        <Card.Body>
            {show?
            <>
                <div className="d-none d-md-block">
                    <Row>
                        <Col><strong>Course overall score</strong></Col>
                        {/*<Col><strong>Conditions</strong></Col>*/}
                    </Row>
                    <hr/>
                </div>
                {progressions.length === 0 && <Alert>Wait for first result.</Alert>}
                {progressions.map((courseResult,n) => {
                    let {course: {name,id,summary}, conditions} = courseResult;
                    return <Row key={id} className="mb-2">
                        <Col md={12}>
                            <div className='my-2'>({n+1}) <span className="d-inline d-md-none">Course: </span>{name}</div>
                            {/*{summary &&*/}
                            {/*<div className="p-2 mt-2" style={*/}
                            {/*    {*/}
                            {/*        border:'1px solid #dee2e6',*/}
                            {/*        borderRadius:5,*/}
                            {/*        maxHeight:250,*/}
                            {/*        overflowY:'scroll',*/}

                            {/*    }*/}
                            {/*}>*/}
                            {/*    <div dangerouslySetInnerHTML={{__html:summary}}></div>*/}
                            {/*</div>*/}
                            {/*}*/}
                        </Col>
                        <Col md={12}>
                            {conditions.map((condition,n) => {
                                return <Block key={n} outline>
                                    {condition.map(qs => {
                                        let {quizSet, pass,results} = qs;
                                        let {dqQuizzes,quiz_mode} = quizSet;
                                        return <Row key={quizSet.id}>
                                            <Col>
                                                <Row>
                                                    <div style={{
                                                        marginLeft:10,
                                                        marginTop:10,
                                                        marginBottom:6,
                                                        display:'flex',
                                                        flexDirection:'row',
                                                        justifyContent:'space-between',
                                                    }}>
                                                        <div style={{flex:1}}>
                                                            <strong style={{fontWeight: 500}}>{quizSet.name}</strong>
                                                        </div>
                                                        <div>
                                                            {pass?
                                                              <Badge variant='success' className='ml-4'>Pass</Badge>
                                                                :
                                                              <Badge variant='danger' className='ml-4'>Failed</Badge>
                                                            }
                                                        </div>
                                                    </div>
                                                </Row>
                                                <Row>
                                                    {dqQuizzes.map((dqQuiz,n)=>{
                                                        if(quiz_mode=='rnd' && n>0)return;
                                                        let {passGrade:passGradeTH} = dqQuiz.th;
                                                        let {passGrade:passGradeEN} = dqQuiz.en;
                                                        let totalResult = results?.find(r=>r.dqQuizID==dqQuiz.id);
                                                        return <Col md={4} key={dqQuiz.id}>
                                                            <div style={{
                                                                fontSize:'.7rem',
                                                                border:'1px solid #ddd',
                                                                borderRadius:5,
                                                                padding:7,
                                                                margin:3
                                                            }}>
                                                               <div>- {dqQuiz.name}</div>
                                                                <div style={{display:'flex',flexDirection:'row',justifyContent:'flex-end'}}>
                                                                    {
                                                                        stdRegs?.length === 0?
                                                                            <Badge variant='secondary' style={{fontWeight:500,margin:3}}>Never</Badge>
                                                                            :
                                                                            <>
                                                                                {
                                                                                    totalResult?.pass?
                                                                                        <span style={{fontWeight:500,color:'green'}}><span className="d-none d-md-inline">Best score</span> = {totalResult.score?`${totalResult.score}% Pass`:'-'}</span>
                                                                                        :
                                                                                        <span style={{fontWeight:500,color:'#dc3545'}}><span className="d-none d-md-inline">Best score</span> = {totalResult.score?`${totalResult.score}% Failed`:'?'}</span>
                                                                                }
                                                                            </>
                                                                    }
                                                                </div>
                                                                {/*<div>*/}
                                                                {/*    <div>*/}
                                                                {/*        {passGradeTH == passGradeEN?*/}
                                                                {/*            <span>Grade >= {passGradeEN}%</span>*/}
                                                                {/*            :*/}
                                                                {/*            <span>TH >= {passGradeTH}% / EN >= {passGradeEN}%</span>*/}
                                                                {/*        }*/}
                                                                {/*    </div>*/}
                                                                {/*    <div style={{display:'flex',flexDirection:'row',justifyContent:'flex-end'}}>*/}
                                                                {/*        {*/}
                                                                {/*            totalResult?.pass?*/}
                                                                {/*                <span style={{fontWeight:500,color:'green'}}><span className="d-none d-md-inline">Best score</span> = {totalResult.score?`${totalResult.score}% Pass`:'-'}</span>*/}
                                                                {/*                :*/}
                                                                {/*                <span style={{fontWeight:500,color:'#dc3545'}}><span className="d-none d-md-inline">Best score</span> = {totalResult.score?`${totalResult.score}% Failed`:'?'}</span>*/}
                                                                {/*        }*/}
                                                                {/*    </div>*/}
                                                                {/*</div>*/}
                                                            </div>
                                                        </Col>
                                                    })}
                                                </Row>
                                            </Col>
                                            {/*{stdRegs.length == 0 || (!existedHistory() && !courseResult.ems) ?*/}
                                            {/*    <Col xs='auto'><Badge variant='secondary' style={{fontWeight:500,margin:3}}>Never</Badge></Col>*/}
                                            {/*    :*/}
                                            {/*    <Col xs='auto'><Badge*/}
                                            {/*        style={{fontWeight:500,margin:3}}*/}
                                            {/*        variant={pass ? 'success' : 'danger'}>{pass ? 'Passed' : 'Failed'}</Badge></Col>*/}
                                            {/*}*/}
                                        </Row>
                                    })}
                                </Block>
                            })}
                        </Col>
                    </Row>
                })}
            </>
                :
                <div className="text-center">- My exam progressions here -</div>
            }
            {calendar && stdRegs.length == 0 &&
            <>
                <hr/>
                <Row>
                    <Col>
                        <iframe
                            src="https://calendar.google.com/calendar/embed?height=600&amp;wkst=1&amp;bgcolor=%23ffffff&amp;src=j65n1hsu356ro85t2tfrf2tohg%40group.calendar.google.com&amp;color=%23AB8B00&amp;src=36af0s3o3eo5c0lod9np99vs00%40group.calendar.google.com&amp;color=%23711616&amp;ctz=Asia%2FBangkok"
                            style={{borderWidth: 0}} width="100%" height="600" frameBorder="0"
                            scrolling="no"></iframe>
                    </Col>
                </Row>
            </>
            }
        </Card.Body>
    </Card>
}
export default StudentProgression;
