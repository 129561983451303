import block_bg1 from '../img/block_bg1.png';
const Block = ({children,title,style,bg,className,textColor})=>{
    let bgCss = null;
    if(bg){
        bgCss = {
            backgroundImage:`url(${block_bg1})`,
            backgroundSize:'cover',
            borderBottom: '3px solid #dbdbdb',
        }
    }
    return <div className="mb-4">
        {(!bg && title) && <h2 className="text-center mb-2" style={{color:textColor?textColor:'#000'}}>{title}</h2>}
        <div style={{
            backgroundColor:'#dbdbdb',
            padding:'15px',
            borderRadius:'15px',
            border:'1px solid #d1d1d1',
            ...bgCss,
            ...style,
        }} className={className}>
            {
                bg &&
                <div style={{display:'flex',justifyContent:'center',marginBottom:10}}>
                    <h2 style={{color:textColor?textColor:'#000'}}>{title}</h2>
                </div>
            }
            <div>{children}</div>
        </div>
    </div>
}
export default Block;
