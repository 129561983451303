import {
    Box,
    Button, Checkbox,
    Fade,
    FormControl,
    FormControlLabel,
    FormLabel, Grid,
    makeStyles,
    Radio,
    RadioGroup, Typography
} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import RegisterStepButton from "./register-step-button";
import {useMobxState} from "../mobx/global-context";
import {observer} from "mobx-react";
import {runInAction, toJS} from "mobx";
import {Alert} from "@material-ui/lab";
import {useMutation, useQuery} from "@apollo/client";
import BillPayment from "./bill-payment";
import {useHistory} from "react-router-dom";

const useStyles = makeStyles(theme => ({
    root: {

    }
}))

const RegisterStep4 = ({courseDB, setCurrentStep}) => {
    const classes = useStyles();
    const state = useMobxState();
    const history = useHistory();


    useEffect(() =>{
        console.log('registerStep3Data==',toJS(state.registerStep3Data));
    },[state.registerStep3Data]);

    const {courseDBbyID} = courseDB.data;
    if(!state.registerStep3Data)return <></>;
    return <>
        <Box p={2} className={classes.root}>
            {state.registerStep3Data?.saveResult?.bill?
                <BillPayment bill={state.registerStep3Data?.saveResult?.bill} type='all' onPayLater={e=>history.push('/my')}/>
                :
                <Box>
                    <Alert severity='success'>Registration has been successfully.</Alert>
                    <Box p={2} display='flex' justifyContent='flex-end'>
                        <Button variant='contained' color='primary' onClick={e=>history.push('/my')}>OK</Button>
                    </Box>
                </Box>
            }
        </Box>
    </>
}
export default observer(RegisterStep4);
