const ModuleDisplay = ({quizSet,style,className})=>{
    return <div className={className} style={{
        display:'inline-block',
        padding:'5px',
        borderRadius:'5px',
        color:quizSet.text_color,
        background:quizSet.bg_color,
        ...style
    }}>{quizSet.name}</div>
}
export default ModuleDisplay;
