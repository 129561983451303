import {useHistory, useParams} from 'react-router-dom';
import {useMutation, useQuery} from "@apollo/client";
import {GET_COURSE, GET_COURSE_CONTENTS, GET_COURSE_QUESTIONS, GET_DB_COURSE} from "../../gql-query/select-query";
import {useEffect, useState} from "react";
import {
    Alert,
    Card,
    Row,
    Col,
    Button,
    Modal,
    FormGroup,
    FormControl,
    Form,
    Image,
    ListGroup,
    ListGroupItem, Badge, FormLabel, Table
} from "react-bootstrap";
import XBreadcrumb from "../../components/xbreadcrumb";
import {getAllMonth, getCourseQuizFromContent, getIDNumberWithCourseName} from "../../services/helper";
import _ from 'lodash';
import QuizSet from "../../components/quiz-set";
import moment from "moment";
import CourseQuizCondition from "../../components/course-quiz-condition";
import {getMongooseID} from "../../services/requester";
import {courseFormSchema} from "../../yup-validators/course-form-schema";
import {toast} from "react-toastify";
import {COURSE_DETAIL} from "../../gql-query/update-query";
import CourseScheduleCreateUpdate from "../../components/course-schedule-create-update";
import ExamScheduleList from "../../components/exam-schedule-list";
import {Box} from "@material-ui/core";

const ExamScheduleDetail = () => {
    const {id} = useParams();
    const history = useHistory();
    const [questions,setQuestions] = useState([]);
    const [showQuestionTypeDialog,setShowQuestionTypeDialog] = useState(false);

    const {loading: l_courseDB, error: e_courseDB, data: d_courseDB, refetch: r_courseDB} = useQuery(GET_DB_COURSE, {
        variables: {mdCourseID: id},
        fetchPolicy: "cache-and-network",
    });

    const resQuestion = useQuery(GET_COURSE_QUESTIONS,{
        variables:{
            courseID:d_courseDB?.courseDB?.id
        },
        fetchPolicy:'cache-and-network',
        skip:l_courseDB
    });

    const [show, setShow] = useState(false);
    const [scheduleID, setScheduleID] = useState(null);
    const [qGroupData, setQGroupData] = useState({});
    const [conditionData, setConditionData] = useState({});
    const [courseForm, setCourseForm] = useState({});
    const [formError, setFormError] = useState({});
    const [courseDetail, {loading: loadingCourseDetail}] = useMutation(COURSE_DETAIL);
    const [quizzes, setQuizzes] = useState([]);

    useEffect(()=>{
        // if(resQuestion?.data?.courseLocalQuestions){
        //     setQuestions(resQuestion?.data?.courseLocalQuestions);
        // }
    },[resQuestion.data])

    const handleClose = () => {
        setShow(false);
        setScheduleID(null);
    }

    const handleShow = (_scheduleID) => {
        if(_scheduleID) {
            setScheduleID(_scheduleID);
        }else{
            setScheduleID(null);
            setShow(true);
        }
    };


    useEffect(() => {
        window.onfocus = () => {
            // refetch();
            // refetch2();
        };
        // courseForm.isPaid = '';
        // courseForm.freeTimes = ''
        // courseForm.quizzes = [];
        // courseForm.quizSet = [];
        // courseForm.maxSeat = [];
    }, []);

    useEffect(()=>{
        if(scheduleID)setShow(true);
    },[scheduleID]);


    // useEffect(() => {
    //     if (!l_courseDB) {
    //
    //         setCourseForm({...courseDB});
    //     }
    // }, [l_courseDB]);


    useEffect(() => {
        if(d_courseDB && d_courseDB.courseDB){
            let data = _.cloneDeep(d_courseDB.courseDB);
            data.dqQuizzes=data.dqQuizzes?.map(v=>({
                ...v,
                th:v?.th?.id,
                en:v?.en?.id
            }))
            data.quizSet=data.quizSet?.map(v=>({
                ...v,
                quizzes:v.dqQuizzes
            }))
            setCourseForm(data)
        }
    }, [l_courseDB]);

    if (l_courseDB) return <Alert variant="info">Loading...</Alert>
    return <>
        <XBreadcrumb data={[
            {title: 'Exam Schedule', url: '/admin/exam-schedule'},
            {title: d_courseDB?.courseDB?.fullname}
        ]}/>
        <Row>
            <Col>
                <Card>
                    <Card.Header>
                        <Card.Title>{getIDNumberWithCourseName(courseForm)}</Card.Title>
                    </Card.Header>
                    <Card.Body>
                        {courseForm.summary ?
                            <div dangerouslySetInnerHTML={{__html: courseForm.summary}}></div> : '- None summary -'}
                    </Card.Body>
                    <Card.Footer>
                        <Button onClick={e => {
                            // setModalSetupCourse(true)
                            history.push('/admin/exam-schedule/'+id+'/setting');
                        }}>Setup Course</Button>
                    </Card.Footer>
                </Card>
            </Col>
        </Row>
        {/*<Row>*/}
        {/*    <Col>*/}
        {/*        <Card>*/}
        {/*            <Card.Header>*/}
        {/*                <Card.Title>Local Question</Card.Title>*/}
        {/*            </Card.Header>*/}
        {/*            <Card.Body>*/}
        {/*                <Button variant="primary" onClick={*/}
        {/*                    e=>setShowQuestionTypeDialog(true)*/}
        {/*                }>+ Create</Button>*/}
        {/*                <Box pt={2}>*/}
        {/*                    {questions?.length>0?*/}
        {/*                        <>*/}
        {/*                            <Table>*/}
        {/*                                <thead>*/}
        {/*                                <tr>*/}
        {/*                                    <td>#</td>*/}
        {/*                                    <td>Name</td>*/}
        {/*                                    <td>Question text</td>*/}
        {/*                                    <td>Type</td>*/}
        {/*                                    <td>Score</td>*/}
        {/*                                </tr>*/}
        {/*                                </thead>*/}
        {/*                                <tbody>*/}
        {/*                                {questions.map((q,i)=>*/}
        {/*                                    <tr key={q.id}>*/}
        {/*                                        <td>{i+1}</td>*/}
        {/*                                        <td>{q.name}</td>*/}
        {/*                                        <td>{q.title}</td>*/}
        {/*                                        <td>{q.questionType}</td>*/}
        {/*                                        <td>{q.maxScore}</td>*/}
        {/*                                    </tr>*/}
        {/*                                )}*/}
        {/*                                </tbody>*/}
        {/*                            </Table>*/}

        {/*                        </>*/}
        {/*                        :*/}
        {/*                        <Alert variant="warning">No questions available.</Alert>*/}
        {/*                    }*/}
        {/*                </Box>*/}
        {/*            </Card.Body>*/}
        {/*        </Card>*/}
        {/*    </Col>*/}
        {/*</Row>*/}
        {/*<Row>*/}
        {/*    <Col>*/}
        {/*        <Card>*/}
        {/*            <Card.Header>*/}
        {/*                <Card.Title>Local Quiz</Card.Title>*/}
        {/*            </Card.Header>*/}
        {/*            <Card.Body>*/}
        {/*                <Button variant="primary" onClick={*/}
        {/*                    e=>history.push(`/admin/course/${courseForm.id}/quiz`)*/}
        {/*                }>+ Create</Button>*/}
        {/*            </Card.Body>*/}
        {/*        </Card>*/}
        {/*    </Col>*/}
        {/*</Row>*/}
        {
            courseFormSchema.isValidSync(courseForm) && <>
                <Row className="mt-2">
                    <Col>
                        <Card>
                            <Card.Header>
                                <Card.Title>Schedule</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col md='auto'>
                                        <Button variant='secondary' onClick={e=>history.push('/admin/exam-schedule/'+id+'/new')}>
                                            + Create Exam Schedule
                                        </Button>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col><ExamScheduleList courseDB={courseForm} handleShow={handleShow.bind(this)}/></Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </>
        }
        {/*<CourseScheduleCreateUpdate courseDB={courseForm}*/}
        {/*                            open={show}*/}
        {/*                            onClose={e=>handleClose()}*/}
        {/*                            scheduleID={scheduleID}*/}
        {/*/>*/}
        <Modal show={showQuestionTypeDialog} onHide={e=>setShowQuestionTypeDialog(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Question type</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Box pt={2}>
                    <Row>
                        <Col><Button onClick={e=>history.push(`/admin/course/${courseForm.id}/question/file-submission`)}>File Submission</Button></Col>
                    </Row>
                </Box>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={e=>setShowQuestionTypeDialog(false)}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    </>
}
export default ExamScheduleDetail;
