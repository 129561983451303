import Config from "../../config";
import {observer} from "mobx-react";
import {useContext} from "react";
import axios from "axios";
import StateContext, {useMobxState} from "../../mobx/global-context";
import {Container, Nav, Navbar} from "react-bootstrap";
import {css,StyleSheet} from 'aphrodite';
import {Link, NavLink} from "react-router-dom";
import FrontEndTopLink from "../../components/front-end-top-link";
import {Box, Grid, Hidden, makeStyles} from "@material-ui/core";



const useStyles = makeStyles(theme=>({
    loginBtnBlock:{
        minWidth:250,
        marginLeft:theme.spacing(2),
        [theme.breakpoints.down('sm')]:{
            marginTop:theme.spacing(2)
        }
    }
}))

const TopMenu = ()=>{

    const state = useMobxState();
    const classes  = useStyles();

    function onLogout(e){
        e.preventDefault();
        state.logout();
    }
    return <Navbar collapseOnSelect expand="lg" variant="light" style={{zIndex:'1'}}>
        <Container>
            <Navbar.Brand href={Config.root} style={{color:'white'}}>{Config.siteName}</Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Hidden mdUp>
                    <hr/>
                </Hidden>
                <Nav className={'ml-auto'}>
                    <FrontEndTopLink exact to="/">Home</FrontEndTopLink>
                    <FrontEndTopLink to="/manual">Manual</FrontEndTopLink>
                    {state.currentUser &&
                        <Nav.Link href={`${Config.root}/my`} className={css(styles.white)}>Dashboard</Nav.Link>
                    }
                    <Nav.Link target='_blank' href="https://tawk.to/chat/5e7984ca69e9320caabc4f2d/default" className={css(styles.white)}>Contact</Nav.Link>
                    {state.currentUser && ['admin','finance','examiner'].includes(state.currentUser.role) &&
                    <Nav.Link href={`${Config.root}/admin`} className={css(styles.white)}>
                        Administrator
                    </Nav.Link>
                    }
                    {state.currentUser &&
                        <>
                            <Nav.Link style={{color:'#fff700',cursor:'default'}} className="ml-2">
                                {state.currentUser?.studentID?
                                    <span>ID: {state.currentUser.studentID}</span>
                                    :
                                    <span>User: <span className="text-capitalize">{state.currentUser.username}</span></span>
                                }
                            </Nav.Link>
                            <Nav.Link onClick={onLogout.bind(this)} className="btn btn-danger ml-1">Logout</Nav.Link>
                        </>
                    }
                </Nav>
                {!state.currentUser &&
                <Box className={classes.loginBtnBlock}>
                    <Grid container spacing={2}>
                        {/*<Grid item xs={6}>*/}
                        {/*    <NavLink to="/login-local" className="btn btn-dark text-white nav-link" >Login</NavLink>*/}
                        {/*</Grid>*/}
                        <Grid item xs={6}>
                            <Nav.Link href="/apiv2/login" className="btn btn-success nav-link">KKU Login</Nav.Link>
                        </Grid>
                    </Grid>
                </Box>
                }
            </Navbar.Collapse>
        </Container>
    </Navbar>
}
const styles = StyleSheet.create({
    white:{
        color:'white',
        transition:'all .5s',
        ':hover':{
            color:'#10d596',
        }
    },
    color:{
        ':nth-child(1n) .nav-link':{
           ':link':{
               color:'#ffffff',
               transition:'all .5s',
               ':hover':{
                   color:'#8f1111',
               }
           }
        },
    }
})
export default observer(TopMenu);
