import {
    Box,
    Card,
    Grid,
    makeStyles,
    Paper,
    Table, TableBody,
    TableCell,
    TableContainer, TableHead,
    TableRow,
    Typography
} from "@material-ui/core";
import {observer} from "mobx-react";
import {useMobxState} from "../../mobx/global-context";
import {useQuery} from "@apollo/client";
import gql from "graphql-tag";
import {Alert} from "@material-ui/lab";
import {getThaiDate} from "../../services/client-helper";

const useStyles = makeStyles(theme=>({
    scheduleType: {
        padding: 5,
        borderRadius: 10,
        textAlign: 'center'
    },
}))
const GET_WALLET_HISTORY=gql`
{
  walletHistory {
    id   
    detail
    amount
    bill {
      id
      amount    
      detail
    }
    created
  }
}
`;
const Wallet = ()=>{
    const state = useMobxState();
    const classes = useStyles();
    const {data,loading,error} = useQuery(GET_WALLET_HISTORY,{fetchPolicy:'network-only'});
    if(loading)return <div>Loading...</div>
    return <Paper>
        <Box p={2}>
            <Typography variant='h4'>Wallet: {state.currentUser.prefix}{state.currentUser.fname} {state.currentUser.lname}</Typography>
            <hr/>
            <Grid container>
                <Grid item xs>
                    <Typography variant="h5">Registration Wallet:</Typography>
                </Grid>
                <Grid item xs align='right'>
                    <Typography variant="h4">{state.currentUser.wallet||0} THB</Typography>
                </Grid>
            </Grid>
            <hr/>
            <Box mt={4}>
                <Typography>Transaction History</Typography>
                <Grid container>
                    <Grid item xs>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Time</TableCell>
                                        <TableCell>Detail</TableCell>
                                        <TableCell>Amount</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data?.walletHistory?.length>0?
                                        <>
                                            {data?.walletHistory.map(history=>
                                                <>
                                                    {history.bill?
                                                        <TableRow>
                                                            <TableCell>{getThaiDate(history.created)}</TableCell>
                                                            <TableCell>{history.bill.detail}</TableCell>
                                                            <TableCell>+{history.bill.amount} THB</TableCell>
                                                        </TableRow>
                                                        :
                                                        <TableRow>
                                                            <TableCell>{getThaiDate(history.created)}</TableCell>
                                                            <TableCell>{history.detail}</TableCell>
                                                            <TableCell style={{background:history.amount>0?'#ddefff':'#ffe9f1'}}>{history.amount>0?`+${history.amount}`:history.amount} THB</TableCell>
                                                        </TableRow>
                                                    }
                                                </>
                                            )}
                                        </>
                                        :
                                        <TableRow>
                                            <TableCell colSpan={4}>
                                                <Alert severity='info'>No transaction history</Alert>
                                            </TableCell>
                                        </TableRow>
                                    }

                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    </Paper>
}
export default observer(Wallet);
