import {Card, Form} from "react-bootstrap";
import React from "react";
import {CourseSettingHeadBar} from "../page/admin/exam-schedule-course-setting";

const CoursePaymentSetup = ({courseForm,updateUI})=>{
    return <>
        <CourseSettingHeadBar title='(5) Payment Setup'/>
        <Card>
            <Card.Body>
                <Form.Group>
                    <Form.Label>This course must be paid with fee</Form.Label>
                    <Form.Check type="checkbox" label="Yes" id='paid'
                                checked={courseForm.isPaid || false} onChange={e => {
                                    let checked = e.target.checked;
                        courseForm.isPaid = checked;
                        if(!checked){
                            for(let q of courseForm.quizSet){
                                if(isNaN(q.price) || !q.price)q.price=0;
                            }
                        }
                        updateUI();
                    }}/>
                </Form.Group>
                {courseForm.isPaid &&
                <Form.Group>
                    <Form.Label>Free Times</Form.Label>
                    <Form.Control style={{width: '100px'}} value={courseForm.freeTimes || 0} onChange={e => {
                        courseForm.freeTimes = parseInt(e.target.value) || 0;
                        updateUI();
                    }}/>
                </Form.Group>
                }
            </Card.Body>
        </Card>
    </>
}
export default CoursePaymentSetup;
