import {Button, Card, Col, FormControl, FormGroup, FormLabel, Row} from "react-bootstrap";
import {Box, Paper} from "@material-ui/core";
import {useEffect, useState} from "react";
import {v4 as uuidv4} from 'uuid';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
import _ from 'lodash';
// import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';
// import * as ClassicEditor from './../../../ckeditor/ckeditor';
// import {Base64UploadAdapter} from "@ckeditor/ckeditor5-upload";


import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
//
// import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';
// import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials';
// import Paragraph from '@ckeditor/ckeditor5-paragraph/src/paragraph';
// import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold';
// import Italic from '@ckeditor/ckeditor5-basic-styles/src/italic';
import * as ClassicEditor from './../../../ckeditor/ckeditor'
import {useMutation} from "@apollo/client";
import gql from "graphql-tag";
import {useParams} from "react-router-dom";
import {toast} from "react-toastify";
import {LocalQuestionFormSchema} from "../../../yup-validators/local-question-form-schema";

const SAVE_DATA = gql`
mutation SaveQuestionData($courseID: ID!,$questionType:String!,$questions: [LocalQuestionInputType]!) {
  saveQuestionData(courseID: $courseID,questionType:$questionType, questions: $questions) {
    success
    error
    message
  }
}
`;

const FileSubmissionForm = ({courseID})=>{

  const [questions,setQuestions] = useState([]);
  const [mutateSave] = useMutation(SAVE_DATA);
  const questionType = 'FileSubmission';

  useEffect(()=>{

  },[]);

  function addQuestion(e){
      setQuestions([...questions,{
          id:uuidv4(),
          name:'',
          title:'',
          detail:'',
          maxScore:0,
          metadata:{
              fileType:'',
              fileMaxSize:0,
          }
      }]);
  }

  function removeQuestion(q){
      setQuestions(prevState => {
          return _.reject(prevState,v=>v.id==q.id)
      })
  }

  async function save(){
      try{
        let form = await LocalQuestionFormSchema.validate({
            courseID,
            questionType,
            questions
        },{cast:true});
        console.log('form===', form);
        let result = await mutateSave({
            variables:{
                courseID:form.courseID,
                questionType:form.questionType,
                questions:form.questions
            }
        });
        result = result.data.saveQuestionData;
        if(!result.error){
            toast.success('Question created.');
        }else{
            toast.error(result.message);
        }
    }catch(e){
        toast.error(e.message);
    }
  }

  function formController(qID,name){
      let context = questions.find(q=>q.id==qID);
      if(!context)return;
      return {
          name,
          value:_.get(context,name)||'',
          onChange:e=>{
              _.set(context,name,e.target.value)
              setQuestions([...questions]);
          }
      }
  }

  return <Paper>
      <Box p={2}>
          <Button onClick={addQuestion}>Add Question</Button>
          <Box pt={2}>
              <Row>
                  <Col>
                      {
                          questions.map((q,i)=>{
                              return <Card key={q.id}>
                                  <Card.Header>
                                      <Box display='flex' width="100%" justifyContent='space-between'>
                                          <Card.Title>Question {i+1}</Card.Title>
                                          <span>
                                              <CancelPresentationIcon style={{color: '#404040', cursor: 'pointer'}} onClick={e => removeQuestion(q)}/>
                                          </span>
                                      </Box>
                                  </Card.Header>
                                  <Card.Body>
                                      <Box>
                                          <FormGroup>
                                              <FormLabel>Name</FormLabel>
                                              <FormControl {...formController(q.id,'name')}/>
                                          </FormGroup>
                                          <FormGroup>
                                              <FormLabel>Title</FormLabel>
                                              <FormControl {...formController(q.id,'title')}/>
                                          </FormGroup>
                                          <FormGroup>
                                              <FormLabel>Detail</FormLabel>
                                              <CKEditor
                                                  editor={ ClassicEditor }
                                                  config={
                                                      {
                                                          toolbar: [
                                                            'heading', '|',
                                                              'bold', 'italic', 'link','undo', 'redo', 'bulletedList', 'numberedList', 'blockQuote','viewSource','|',
                                                              'code','sourceEditing','|',
                                                              'uploadImage'
                                                          ]
                                                      }
                                                  }
                                                  data="<p>Question text here.</p>"
                                                  onReady={ editor => {
                                                      console.log( 'Editor is ready to use!', editor );
                                                  } }
                                                  onChange={ ( event, editor ) => {
                                                      const data = editor.getData();
                                                      // console.log( { event, editor, data } );
                                                      let context = questions.find(qe=>qe.id=q.id);
                                                      if(context)context['detail']=data;
                                                      setQuestions([...questions]);
                                                  } }
                                                  onBlur={ ( event, editor ) => {
                                                      // console.log( 'Blur.', editor );
                                                  } }
                                                  onFocus={ ( event, editor ) => {
                                                      // console.log( 'Focus.', editor );
                                                  } }
                                              />
                                          </FormGroup>
                                          <FormGroup>
                                              <FormLabel>File type</FormLabel>
                                              <FormControl {...formController(q.id,'metadata.fileType')}/>
                                          </FormGroup>
                                          <FormGroup>
                                              <FormLabel>File max size</FormLabel>
                                              <FormControl {...formController(q.id,'metadata.fileMaxSize')}/>
                                          </FormGroup>
                                          <FormGroup>
                                              <FormLabel>Score</FormLabel>
                                              <FormControl {...formController(q.id,'maxScore')}/>
                                          </FormGroup>
                                      </Box>
                                  </Card.Body>
                                  <Card.Footer>
                                      <Box display="flex" justifyContent="flex-end" gridGap={20}>
                                          <Button variant="light">Cancel</Button>
                                          <Button variant="primary" onClick={e=>save()}>Save</Button>
                                      </Box>
                                  </Card.Footer>
                              </Card>
                          })
                      }
                  </Col>
              </Row>
          </Box>
      </Box>
  </Paper>
}
export default FileSubmissionForm;
