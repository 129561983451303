import MoodleIFrame from "../../components/moodle-iframe";
import {useEffect} from "react";
import {Redirect, useParams} from "react-router-dom";
import {useQuery} from "@apollo/client";
import {GET_REGISTRATION_BY_ID, START_EXAM} from "../../gql-query/select-query";
import {Alert} from "react-bootstrap";
import {observer} from "mobx-react";
import {useMobxState} from "../../mobx/global-context";
import {runInAction} from "mobx";
import {Box, Typography} from "@material-ui/core";
import {getThaiDateBetween} from "../../services/client-helper";
import ClientCheckInProcess from "../../components/client-check-in-process";
import Config from "../../config";
import {toast} from "react-toastify";
import axios from "axios";
import {useSocket} from "../../context/SocketProvider";
import gql from "graphql-tag";

/**
 * @typedef {Object} GraphQLResultDQQuizObjectType
 * @property {string} dqQuizID - The ID of the quiz.
 * @property {string} name - The name of the quiz.
 * @property {string} score - The score obtained in the quiz.
 * @property {boolean} pass - Whether the quiz was passed or not.
 * @property {string} __typename - The type name of the GraphQL object.
 */

/**
 * @typedef {Object} GraphQLResultObjectType
 * @property {GraphQLResultDQQuizObjectType[]} results - The results of the quizzes.
 * @property {string} __typename - The type name of the GraphQL object.
 */

/**
 * @typedef {Object} CourseDB
 * @property {string} id - The ID of the course.
 * @property {string} fullname - The full name of the course.
 * @property {string} __typename - The type name of the GraphQL object.
 */

/**
 * @typedef {Object} GraphQLResultProgressionType
 * @property {CourseDB} course - The course details.
 * @property {GraphQLResultObjectType[][]} conditions - The conditions of the progression.
 * @property {boolean} pass - Whether the progression was passed or not.
 * @property {string} __typename - The type name of the GraphQL object.
 */

const GET_MY_PROGRESS = gql`
    query myProgression {
        myProgression {
            course {
                id
                fullname
            }
            conditions {
                quizSet {
                    id
                    name
                }
                pass
                results {
                    dqQuizID
                    name
                    score
                    pass
                }
            }
            pass
        }
    }
`
const StartExam = ()=>{
    const {id} = useParams();
    const state = useMobxState();
    const {studentConnect,isConnected} = useSocket();

    const {data:student} = useQuery(GET_MY_PROGRESS,{fetchPolicy:'network-only'});

    const register = useQuery(GET_REGISTRATION_BY_ID,{
        fetchPolicy:'network-only',
        variables:{
            id
        }
    })
    const {data,loading,error,refetch} = useQuery(START_EXAM,{
        fetchPolicy:'network-only',
        variables: {
            id
        }
    });

    useEffect(()=>{
        if(student){
            let regCourse = register.data?.registrationByID?.course;
            let progressionCourse = /** @type {GraphQLResultProgressionType[]} */ student?.myProgression;
            let existedResults = progressionCourse.find(p=>p.course.id===regCourse?.id)
            console.log(student.myProgression);
            console.log(register.data);
            console.log('existedResults',existedResults);
            runInAction(()=>{
                state.existedResults = existedResults;
            });
        }
    },[student,register.data])

    useEffect(()=>{
        return ()=>{
            state.exam=false;
        }
    },[]);

    useEffect(()=>{
        if(register.data?.registrationByID){
            axios.post(`${Config.api_url}/generate-token`, null).then(resToken=>{
                let token = resToken?.data?.token;
                studentConnect(register.data?.registrationByID.id,token);
            });
        }
        runInAction(()=>{
            state.examScheduleRound=register.data?.registrationByID?.scheduleRound;
            state.examRegister = register?.data?.registrationByID;
        })
    },[register.data]);

    useEffect(()=>{
        if(data){
            console.log('StartExam');
            state.exam=true;
        }
    },[data]);

    if(loading || register.loading || !student)return <Alert variant='info'>Loading...</Alert>
    // if(error)return <Alert variant='danger'>404 Page not found.</Alert>
    if(!register.data?.registrationByID){
        toast.error('No Schedule.');
        return <Redirect to='/my'/>
    }
    if(register?.data?.registrationByID?.examResult?.examStat=='submitted'){
        return <Redirect to={`/my`}/>
    }
    return <>
        <Box p={2}>
            <Typography variant="h5">
                Examination&nbsp;
            </Typography>
        </Box>
        <ClientCheckInProcess
              registration={register.data?.registrationByID}
              onReload={()=>{
                register?.refetch()
              }}
        />
    </>
}
export default observer(StartExam);
