import * as yup from "yup";

export const ExamScheduleValidationSchema = yup.object().shape({
    id: yup.mixed(),
    course: yup.string().required(),
    month: yup.number().integer().min(1).max(12).required().label('Month').typeError('Please select month.'),
    year: yup.number().integer().min(1).required().label('Year').typeError('Please select Year.'),
    title: yup.string().trim().required().label('Title'),
    detail: yup.string().label('Detail'),
    examStart: yup.date().required().typeError('Start exam date is required.'),
    examEnd: yup.date().required().typeError('End exam date is required.'),
    scoreDate: yup.date().required().typeError('Score date is required.'),
    gradeDate: yup.date().required().typeError('Grade date is required.'),
    useSeb: yup.boolean().required(),
    show: yup.boolean().required(),
    registerStart: yup.date().required().typeError('Start register date is required.'),
    registerEnd: yup.date().min(yup.ref('registerStart')).required().typeError('End register date is required.'),
    scheduleType: yup.string().required().label('Type'),
    quizSet: yup.array().of(
        yup.object({
            id: yup.string().required(),
            rounds: yup.array().of(yup.object({
                id: yup.string().trim().required(),
                examStart: yup.date().required(),
                examEnd: yup.date().min(yup.ref('examStart')).required(),
                location: yup.string().required().label('Location'),
                maxSeats: yup.number().integer().min(1).required().label('Seats').typeError('Seat must be number.'),
            })),
        })
    ),
    removed:yup.array().of(yup.object({
        type:yup.string(),
        id:yup.string(),
    }))
});
