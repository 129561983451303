import styled from 'styled-components';

const getColor = (props)=>{
    switch(props.variant){
        case 'default':
            return '#d7d7d7';
        case 'success':
            return '#439b47';
        default:
            return props.variant
    }
}

export const Block = styled.div`
  padding: 0.7rem;
  background: ${props => getColor(props)};
  border:${props=>props.outline?'1px solid #d7d7d7':'none'};
  border-radius: 0.5rem;
  ${props=>props.shadow?'box-shadow:2px 1px 2px 1px #e3e3e3':''}
`;