import StudentBreadcrumb from "../../components/student-breadcrumb";
import React from "react";
import {Box, Paper} from "@material-ui/core";
import StudentDashboard from "../../components/student-dashboard";
import {useQuery} from "@apollo/client";
import {GET_STUDENT_REGISTRATIONS} from "../../gql-query/select-query";
import {Alert} from "react-bootstrap";

const ExamHistory = ()=>{

    const {data: dataSchedule, loading: loadingSchedule,refetch:refetchRegistration} = useQuery(GET_STUDENT_REGISTRATIONS, {
        fetchPolicy: 'cache-first'
    });

    if(loadingSchedule)return <Alert variant="info">Loading...</Alert>
    return <>
        <Paper>
            <Box p={2}>
                <StudentDashboard studentRegistrations={dataSchedule.studentRegistrations} updateTime={true} history/>
            </Box>
        </Paper>
    </>
}
export default ExamHistory;
