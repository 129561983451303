import {Box, Paper} from "@material-ui/core";
// import {FileUploader} from "react-drag-drop-files";
import {useEffect, useState} from "react";
import {Alert, Button, Form, ListGroup, ListGroupItem, Modal, Table} from "react-bootstrap";
import gql from "graphql-tag";
import {useQuery} from "@apollo/client";
import {getThaiDateBetween} from "../../services/client-helper";
import {requestAPI} from "../../services/requester";
import {toast} from "react-toastify";
import {observer} from "mobx-react";
import {useMobxState} from "../../mobx/global-context";

const GET_SCHEDULES_SIGN_ENABLED = gql`
{
  signEnabledSchedule {
    id
    title
    detail
    month
    year
    code
    registeredPassedUsers {
      id
      prefix
      username
      fname
      lname
      email
      citizenId
      faculty
      department
      facultyEN
      studentID
    }
  }
}
`;

const fileTypes = ["p12"];

const Certificate = () => {
    const [file, setFile] = useState(null);
    const resSchedule = useQuery(GET_SCHEDULES_SIGN_ENABLED,{fetchPolicy:'network-only'});
    const [passphrase, setPassphrase] = useState('');
    const [schedules, setSchedules] = useState([]);
    const [schedulesState, setSchedulesState] = useState({});
    const [viewStudents, setViewStudents] = useState([]);
    const state = useMobxState();

    useEffect(() => {
        let ids = resSchedule.data?.signEnabledSchedule?.map(schd => schd.id) || [];
        setSchedules(ids);
    }, [resSchedule.loading]);

    async function startProcess() {
        if(!file){
            toast.error('Please choose *.p12 file');
            return;
        }
        try {
            state.backdrop=true;
            let form = new FormData();
            form.append('p12', file);
            form.append('passphrase', passphrase);
            form.append('scheduleIDs', JSON.stringify(schedules));
            let resData = await requestAPI('POST', '/start-digital-signature-process', form);
            let {scheduleFails,scheduleSuccess} = resData.data
            for(let schdID of scheduleFails){
                setSchedulesState({
                    ...schedulesState,
                    [schdID]:'Failed'
                })
            }
            for(let schdID of scheduleSuccess){
                setSchedulesState({
                    ...schedulesState,
                    [schdID]:'Success'
                })
            }
            setTimeout(()=>{
                state.backdrop=false
                toast.success('Sign successfully.');
            },1000);
        } catch (e) {
            setTimeout(()=>{
                state.backdrop=false
                toast.error('Sign error.');
            },1000);
        }
    }

    function getStudentList(students){
        setViewStudents([...students]);
    }

    if (resSchedule.loading) return <Alert>Loading...</Alert>
    return <Paper>
        <Box p={2}>
            <h3>Course certificate signer.</h3>
            <Box mt={2}>
                <Form.Label>Exam schedules</Form.Label>
                <Table striped>
                    <thead>
                    <tr>
                        <td>Code</td>
                        <td>Title</td>
                        <td>Count</td>
                        <td>State</td>
                    </tr>
                    </thead>
                    <tbody>
                    {resSchedule.data.signEnabledSchedule.length==0 &&
                        <tr>
                            <td colSpan={4}>
                                <Alert variant='light'>No schedule to sign</Alert>
                            </td>
                        </tr>
                    }
                    {resSchedule.data.signEnabledSchedule.map(schedule => {
                        return <tr key={schedule.id}>
                            <td>{schedule.code}</td>
                            <td>{schedule.title}</td>
                            <td><Button variant="primary" onClick={e=>getStudentList(schedule.registeredPassedUsers)}>{schedule.registeredPassedUsers.length} Students</Button></td>
                            {schedulesState[schedule.id]?
                                <td>
                                    <Alert
                                    variant={schedulesState[schedule.id]=='Success'?'success':'danger'}
                                    >
                                        {schedulesState[schedule.id]}
                                    </Alert>
                                </td>
                                :
                                <td>
                                    <Alert variant='primary'>Waiting...</Alert>
                                </td>
                            }
                        </tr>
                    })}
                    </tbody>
                </Table>
                <hr/>
                <Form.Group>
                    <Form.Label>Digital signature P12 File</Form.Label>
                    <div>
                        <input type='file' onChange={e=>{
                            setFile(e.target.files?.[0])
                        }}/>
                    </div>
                    <Form.Group>
                    </Form.Group>
                    <Form.Label>Passphrase</Form.Label>
                    <Form.Control value={passphrase} onChange={e => setPassphrase(e.target.value)} type='password'/>
                </Form.Group>
                <Box mt={4}>
                    <Button disabled={resSchedule.data.signEnabledSchedule.length==0} variant='primary' onClick={e => startProcess()}>Process</Button>
                </Box>
            </Box>
        </Box>
        <Modal size='lg' show={viewStudents.length>0} onHide={e=>setViewStudents([])}>
            <Modal.Header closeButton>
                <h3>Detail</h3>
            </Modal.Header>
            <Modal.Body>
                <Table responsive>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Student ID</th>
                            <th>Fullname</th>
                            <th>Faculty</th>
                        </tr>
                    </thead>
                    <tbody>
                    {viewStudents.map((std,n)=>{
                        return <tr key={std.id}>
                            <td>{n+1}</td>
                            <td>{std?.studentID || std.email}</td>
                            <td>{std.prefix}{std.fname} {std.lname}</td>
                            <td>{std.faculty}</td>
                        </tr>
                    })}
                    </tbody>
                </Table>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="dark" onClick={e=>setViewStudents([])}>Close</Button>
            </Modal.Footer>
        </Modal>
    </Paper>
}
export default observer(Certificate);
