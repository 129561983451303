import Config from "./../../config";
import TopMenu from "./top-menu";
import {Button, Image, Modal} from "react-bootstrap";
import {useContext, useState} from "react";
import {observer} from 'mobx-react';
import StateContext, {useMobxState} from "../../mobx/global-context";
import {Helmet} from "react-helmet";
import Hero from "./hero";
import {onHelmetReady} from "../../services/helper";
import './../../css/front-end.css';
import Footer from "./footer";
import {CssBaseline, ThemeProvider} from '@material-ui/core'
import Theme from "./mui-theme-rules";
import {useLocation} from "react-router-dom";

const Index = ({children}) => {
    const [ready, setReady] = useState(false);
    const url = Config.frontEndPath;
    const location = useLocation();
    console.log(location);


    return <ThemeProvider theme={Theme}>
        <CssBaseline/>
        <Helmet onChangeClientState={(newState, addedTags, removedTags) => {
            onHelmetReady(newState, addedTags, removedTags, () => {
                setReady(true);
            })
        }}>
            <link
                href="https://fonts.googleapis.com/css?family=Nunito:200,300,400,600,700,800,900"
                rel="stylesheet"/>
            <link rel="stylesheet" href={`${url}/css/bootstrap.min.css`}/>
            <link rel="stylesheet" href={`${url}/css/plugins/animate.css`}/>
            <link rel="stylesheet" href={`${url}/css/main.css`}/>
        </Helmet>
        {ready &&
        <>
            <TopMenu/>
            {location.pathname=='/'?
                <Hero/>
                :
                <div></div>
            }
            <section className="features" id="features" style={{paddingBottom:'50px'}}>
                {children}
            </section>
            <Footer/>
        </>
        }
    </ThemeProvider>
}
export default observer(Index);
