import {Avatar, Box, Button, Card, CardContent, CardHeader, Grid, Paper} from "@material-ui/core";
import MoodleIFrame from "./moodle-iframe";
import {Alert, Col, Row, Tab, Tabs} from "react-bootstrap";
import {FlexBox} from "../styled-components/flex-box";
import ModuleDisplay from "./module-display";
import StudentExamCountdown from "./student-exam-countdown";
import {useEffect, useRef, useState} from "react";
import {useMobxState} from "../mobx/global-context";
import {observer} from "mobx-react";
import * as mobx from "mobx";

const MoodleQuizDisplay = ({quizURLs, selectedQuizIndex,registration, onChange}) => {

    const [show,setShow] = useState(true);
    const {existedResults} = useMobxState();
    const [_quizURLs, _setQuizURLs] = useState([]);
    const [iframeUID, setIframeUID] = useState(Math.random());

    function getTitle(name) {
        let t1 = name?.substr(0, 1);
        return `${name}`;
    }

    // useEffect(() => {
    //     console.log('=xmuz',mobx.toJS(existedResults));
    // }, [existedResults]);

    useEffect(()=>{
        let datas = quizURLs.map(mapStateQuizSet).filter(q=>!q.pass);
        _setQuizURLs(datas);
        let _brake = false;
        datas.map((d,i)=>{
            if(!d.pass && !_brake) {
                _brake=true;
                onChange?.(i);
                return;
            }
        })
    },[quizURLs])

    useEffect(() => {
        console.log('_quizURLs===',_quizURLs);
    }, [_quizURLs]);

    function filterQuizSet(quizSet){
        let id = quizSet.dqQuiz?.id
        for(let conditions of existedResults?.conditions){
            for(let condition of conditions){
                let existed = condition?.results?.find(dq=>dq.dqQuizID===id);
                if(existed && existed.pass){
                    return false;
                }
            }
        }
        return true;
    }

    function mapStateQuizSet(quizSet){
        let id = quizSet.dqQuiz?.id
        for(let conditions of existedResults?.conditions){
            for(let condition of conditions){
                let existed = condition?.results?.find(dq=>dq.dqQuizID===id);
                if(existed && existed.pass){
                    return {...quizSet,pass:true};
                }
            }
        }
        return {...quizSet,pass:false};
    }

    return <>
        <Paper>
            <Box p={2}>
                <h3>Course: {registration?.course.name}</h3>
                <h4 className="mt-4">Module: <ModuleDisplay
                    style={{fontSize:'90%'}}
                    quizSet={registration?.scheduleRound?.quizSet}/></h4>
            </Box>
            <hr/>
            <Box p={2}>
                <Row>
                    <Col>
                        {Array.isArray(_quizURLs) && _quizURLs.length>0?
                            <Tabs activeKey={selectedQuizIndex}
                                  fill
                                  onSelect={k=>{
                                      // if(k===selectedQuizIndex) return;
                                      setIframeUID(Math.random());
                                      onChange(k);
                                      setShow(false);
                                  }}
                            >
                                {_quizURLs?.map((qUrl, n) => {
                                    let url = selectedQuizIndex==n?_quizURLs?.[selectedQuizIndex].url:'';
                                    let timer;
                                    return <Tab key={n} disabled={qUrl.pass} eventKey={n} title={getTitle(qUrl.dqQuiz?.name) + `${qUrl.pass?' (PASS)':''}`}>
                                        {url &&
                                            <MoodleIFrame visible={show}
                                                          url={url+'&rnd='+iframeUID}
                                                          onload={()=>{
                                                              clearTimeout(timer);
                                                              timer=setTimeout(()=>{
                                                                  setShow(true);
                                                              },2000);
                                                          }}
                                            />
                                        }
                                    </Tab>
                                })}
                            </Tabs>
                            :
                            <Alert>No quiz to exam.</Alert>
                        }

                    </Col>
                </Row>
                {/*<Box pt={2}>*/}
                {/*    <Grid container>*/}
                {/*        <Grid item xs={12}>*/}
                {/*            {quizURLs?.[selectedQuizIndex].url &&*/}
                {/*            <MoodleIFrame url={quizURLs?.[selectedQuizIndex].url}/>*/}
                {/*            }*/}
                {/*        </Grid>*/}
                {/*    </Grid>*/}
                {/*</Box>*/}
            </Box>
        </Paper>
    </>
}
MoodleQuizDisplay.defaultProps = {
    selectedQuizIndex: 0,
};
export default observer(MoodleQuizDisplay);
