import {Box, Paper} from "@material-ui/core";
import {Alert, Button, Col, FormCheck, FormControl, FormGroup, FormLabel, Row, Table} from "react-bootstrap";
import {useHistory, useParams} from "react-router-dom";
import {useQuery} from "@apollo/client";
import {GET_COURSE_QUESTIONS} from "../../gql-query/select-query";
import {useEffect, useState} from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";


const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const Quiz = ()=>{
    const history = useHistory();
    const {courseID} = useParams();
    const [questions,setQuestions] = useState([]);
    const [selectedQuestions,setSelectedQuestions] = useState([]);
    const resQuestion = useQuery(GET_COURSE_QUESTIONS,{
        variables:{
            courseID
        },
        fetchPolicy:'cache-and-network',
    });
    useEffect(() =>{
        if(resQuestion.data){
            setQuestions(resQuestion?.data?.courseLocalQuestions || [])
            setSelectedQuestions(resQuestion?.data?.courseLocalQuestions || [])
        }
    },[resQuestion.data]);

    function onDragEnd(result) {
        if (!result.destination) {
            return;
        }
        const items = reorder(
            selectedQuestions,
            result.source.index,
            result.destination.index
        );
        setSelectedQuestions([...items]);
    }

    if(resQuestion.loading)return <Alert>Loading...</Alert>
    return <Paper>
        <Box p={2}>
            <h3>Create Local Quiz</h3>
            <Box pt={2}>
                <FormGroup>
                    <FormLabel>Name</FormLabel>
                    <FormControl/>
                </FormGroup>
                <FormGroup>
                    <FormLabel>Detail</FormLabel>
                    <FormControl/>
                </FormGroup>
                <FormGroup>
                    <FormLabel>Score</FormLabel>
                    <FormControl/>
                </FormGroup>
                <Box pt={2}>
                    {questions?.length>0?
                        <>
                            <Table>
                                <thead>
                                <tr>
                                    <td>#</td>
                                    <td>Name</td>
                                    <td>Question text</td>
                                    <td>Type</td>
                                    <td>Score</td>
                                </tr>
                                </thead>
                                <tbody>
                                {questions.map((q,i)=>
                                    <tr key={q.id}>
                                        <td><FormCheck label={i+1}/></td>
                                        <td>{q.name}</td>
                                        <td>{q.title}</td>
                                        <td>{q.questionType}</td>
                                        <td>{q.maxScore}</td>
                                    </tr>
                                )}
                                </tbody>
                            </Table>

                        </>
                        :
                        <Alert variant="warning">No questions available.</Alert>
                    }
                </Box>
                <FormGroup>
                    <FormLabel>Questions</FormLabel>
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="droppable">
                            {(provided, snapshot) => (
                                <div
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                >
                                    {selectedQuestions.map((q,i)=>
                                        <Draggable key={q.id} draggableId={q.id} index={i}>
                                            {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                >
                                                    {i+1} {q.title}
                                                </div>
                                            )}
                                        </Draggable>
                                    )}

                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                </FormGroup>
            </Box>
        </Box>
    </Paper>
}
export default Quiz;
