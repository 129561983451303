import React, {useEffect, useRef, useState} from "react";
import {requestAPI} from "../services/requester";
import {
    Box, Button,
    Grid,
    makeStyles, Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@material-ui/core";
import {Alert, Badge, Col, Image, Row} from "react-bootstrap";
import {observer} from "mobx-react";
import {createGroup, getThaiDateBetween, getThaiDateBetweenSingleDay} from "../services/client-helper";
import {useHistory} from "react-router-dom";
import ReceiptIcon from '@material-ui/icons/Receipt';
import gql from "graphql-tag";
import {useApolloClient, useQuery} from "@apollo/client";
import DoneAllIcon from '@material-ui/icons/DoneAll';
import Countdown from "./countdown";
import ModuleDisplay from "./module-display";
import {toJS} from "mobx";
import Block from "../layouts/front-end-bt/components/block";
import axios from "axios";

const useStyles = makeStyles(theme => ({
    billDetailRow: {
        marginTop: 5,
        '& div:first-child': {
            fontWeight: 'bold'
        }
    },
    qrBox: {
        [theme.breakpoints.down('md')]: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: theme.spacing(1)
        }
    },
    scheduleType: {
        padding: 5,
        borderRadius: 10,
        textAlign: 'center'
    },
}))

const CHECK_TRANSACTION = gql`
query CheckRemoteTransactionIsPaid($userID: ID,$billID: ID!) {
  checkRemoteTransactionIsPaid(userID: $userID,billID: $billID)
}
`;

//type = qrcode / no-qrcode / all
const BillPayment = ({bill, onPayLater, type = 'qrcode',onPaidSuccess,onExpired}) => {

    const [QRImage, setQRImage] = useState();
    const [isPaid, setIsPaid] = useState(bill.isPaid);
    const classes = useStyles();
    const [expired,setExpired] = useState(false);
    const client = useApolloClient();
    const lastUpdate = useRef(-1);

    let {data: remoteBill, loading, refetch} = useQuery(CHECK_TRANSACTION, {
        fetchPolicy: 'network-only',
        variables: {
            billID: bill.id,
            skip:bill.isPaid
        }
    })

    useEffect(() => {

        expirationChecker();

        if(!bill.isPaid){
            let timer = setInterval(async () => {
                // let res = await axios.get('/api/sync-client-cache?redisName=bill')
                // let {update} = res.data;
                // if(update>lastUpdate.current || update===0){
                //     lastUpdate.current=update;
                    await refetch();
                // }
            }, 10000);
            return () => {
                clearInterval(timer);
            }
        }
    }, [])

    useEffect(() => {
        if(remoteBill && remoteBill?.checkRemoteTransactionIsPaid){
            new Promise( async (resolve)=>{
                try{
                    await client.resetStore();
                    await axios.get('/api/clear-cache');
                }catch (e){}
                setIsPaid(true);
                if(onPaidSuccess)onPaidSuccess(true);
                resolve();
            }).then(()=>{});
        }
    }, [remoteBill])

    useEffect(() => {
        if (bill && (type == 'qrcode' || type == 'all')) {
            requestAPI('GET', '/qrcode/bill/' + bill.id).then(res => {
                setQRImage(res.data.image);
            });
        }
    }, [bill, type])

    function getRegistrationGroupedByCourse() {
        return createGroup(bill.registers, 'course.id')
    }

    function expirationChecker(){
        if(new Date(parseInt(bill.expire))<=new Date()){
            setExpired(true);
            if(onExpired)onExpired();
        }
    }


    if (!bill) return <></>;
    if (type == 'qrcode') {
        return <>
            <Box>
                {/*<Typography variant="caption">Scan this QRCode to pay amount: {bill.amount} THB</Typography>*/}
                {QRImage &&
                <Image src={QRImage} fluid/>
                }
            </Box>
        </>;
    }
    if (loading) return <div>Loading...</div>
    return <div>
        <div>
            {getRegistrationGroupedByCourse().map((gReg) => {


                let dataSets = gReg.data.map(reg=>{
                    // console.log('reg==',reg);
                    return {
                        regID:reg.id,
                        scheduleTitle:reg.schedule.title,
                        module:<ModuleDisplay style={{fontWeight:500}} quizSet={reg.scheduleRound.quizSet}/>,
                        examStart:reg.scheduleRound.examStart,
                        examEnd:reg.scheduleRound.examEnd,
                        examDate:getThaiDateBetweenSingleDay(reg.scheduleRound.examStart, reg.scheduleRound.examEnd,false),
                        fee:reg.usedWallet > 0 ?
                            <div>{reg.scheduleRound.quizSet.price} THB <span style={{color:'#4caf50'}}>- {reg.usedWallet} Wallet</span></div>
                            :
                            <div>Yes</div>
                            // <div>{reg.scheduleRound.quizSet.price} THB</div>,
                    }
                })

                return <div key={gReg.group.id}>
                    <h4 className="mb-4"><ReceiptIcon/> {gReg.group.name}</h4>
                    <hr/>
                    <Row>
                        {/*<Col item md={12} lg={type != 'no-qrcode' ? 8 : 12}>*/}
                        <Col>
                            <div className="d-block d-lg-none">
                                {dataSets.map(d=>
                                    <Block key={d.regID}>
                                        <div>{d.scheduleTitle}</div>
                                        <hr/>
                                        <div className="mb-1">
                                            <Row>
                                                <Col>
                                                    <div>Module:</div>
                                                    <div>{d.module}</div>
                                                </Col>
                                                <Col>
                                                    <div>Fee:</div>
                                                    {/*<div><Badge variant='light' style={{fontSize:'.85rem'}}>{d.fee}</Badge></div>*/}
                                                    <div><Badge variant='light' style={{fontSize:'.85rem'}}>Yes</Badge></div>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div className="mb-1">
                                            <div>Date:</div>
                                            <div>{d.examDate}</div>
                                        </div>
                                    </Block>
                                )}
                                <Alert variant="info" className="text-right">Total fee: {bill.amount} THB </Alert>
                            </div>
                            <div className="d-none d-lg-block">
                                <Table>
                                    <thead>
                                    <tr>
                                        <th>Schedule</th>
                                        <th>Module</th>
                                        <th>Exam date</th>
                                        <th>Fee</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {dataSets.map(d =>
                                        <tr key={d.regID}>
                                            <td>{d.scheduleTitle}</td>
                                            <td>{d.module}</td>
                                            <td>{d.examDate}</td>
                                            <td><Badge variant='light' style={{fontSize:'.80rem'}}>{d.fee}</Badge></td>
                                        </tr>
                                    )}
                                    </tbody>
                                </Table>
                                <Alert variant="info" className="text-right mt-2">Total fee: {bill.amount} THB </Alert>
                            </div>
                            {/*{onPayLater &&*/}
                            {/*<>*/}
                            {/*    {isPaid ?*/}
                            {/*        <Button variant="contained" color="default"*/}
                            {/*                onClick={onPayLater}>Close</Button>*/}
                            {/*        :*/}
                            {/*        <Box p={2} display='flex' justifyContent='center'>*/}
                            {/*            <Button variant='contained' color='primary' onClick={onPayLater}>*/}
                            {/*                PAY LATER*/}
                            {/*            </Button>*/}
                            {/*        </Box>*/}
                            {/*    }*/}
                            {/*</>*/}
                            {/*}*/}
                        </Col>
                    </Row>
                    <hr/>
                    {isPaid ?
                        <Box p={2} display='flex' justifyContent='center'>
                            <Typography variant="h1" style={{color: 'green'}}><DoneAllIcon fontSize='large'
                                                                                           htmlColor='green'/> PAID</Typography>
                        </Box>
                        :
                        <>
                        {(new Date(parseInt(bill.expire))>new Date() && !expired)?
                            <div style={{textAlign:'center'}}>
                                {type != 'no-qrcode' &&
                                <>
                                    <div style={{fontSize:'.95rem'}}>Scan this QRCode to pay: <Badge variant="warning">{bill.amount} THB</Badge></div>
                                    {QRImage &&
                                    <>
                                        <Image src={QRImage} fluid/>
                                        <Box>
                                            <Countdown
                                                title={<span style={{color:'#c79601'}}>Expiration:</span>}
                                                expireTimestamp={new Date(parseInt(bill.expire)).getTime()}
                                                onExpired={()=>{
                                                    setExpired(true);
                                                    if(onExpired)onExpired();
                                                }}
                                            />
                                        </Box>
                                        <div className="mt-3">
                                            <a className="btn btn-info" href={QRImage} download={`qrcode_${bill.id}.png`}>Download</a>
                                        </div>
                                    </>
                                    }
                                </>
                                }
                            </div>
                            :
                            <Box pt={2} display='flex' justifyContent='center'>
                                <Typography variant="h3" style={{color:'rgb(229, 88, 106)'}}>Bill is been expired.</Typography>
                            </Box>
                        }
                        </>
                    }
                    {onPayLater &&
                    <>
                        {isPaid ?
                            <Button variant="contained" color="default"
                                    onClick={onPayLater}>Close</Button>
                            :
                            <Box p={2} display='flex' justifyContent='center'>
                                <Button variant='contained' color='primary' onClick={onPayLater}>
                                    PAY LATER
                                </Button>
                            </Box>
                        }
                    </>
                    }
                </div>
            })}
        </div>
    </div>
}
export default BillPayment;
