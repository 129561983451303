import {
    Alert,
    Button,
    Card,
    Col,
    FormControl,
    FormGroup,
    FormLabel,
    ListGroup,
    ListGroupItem,
    Row
} from "react-bootstrap";
import {useEffect, useState} from "react";
import {CourseSettingHeadBar} from "../page/admin/exam-schedule-course-setting";
import {v4 as uuidv4} from "uuid";
import _ from "lodash";
import {locationFormSchema} from "../yup-validators/course-form-schema";
import objectPath from 'object-path';

const CourseLocationSetup = ({courseForm,updateUI})=>{

    const [errors,setErrors] = useState({})

    function register(location,keyName){
        return {
            name:keyName,
            value:location[keyName] || '',
            onChange:e=>{
                let v = e.target.value;
                location[keyName]=v;
                updateUI();
                locationFormSchema.validateAt(keyName,location,{abortEarly:true})
                    .then(data=>{
                        setErrors({...errors,[`${location.id}-${keyName}`]: void 0})
                    })
                    .catch(e=>{
                        setErrors({...errors,[`${location.id}-${keyName}`]: e.message})
                })
            }
        }
    }

    function addLocation(e){
        let id = uuidv4();
        courseForm.locations=[...courseForm.locations,{
            id,
            name:'',
            address:'',
            maxSeats:'',
        }]
        updateUI();
    }

    function removeLocation(id){
        courseForm.locations=_.reject(courseForm.locations,v=>v.id==id);
        courseForm.removed.push({key:'locations',id});
        updateUI();
    }

    function displayError(location,keyName){
        return <>
            {errors[`${location.id}-${keyName}`] &&
            <Alert className="mt-0" variant='danger'>{errors[`${location.id}-${keyName}`]}</Alert>
            }
        </>
    }

    return <>
        <CourseSettingHeadBar title="(2) Location Setup" onClick={addLocation.bind(this)}/>
        <Card>
            <Card.Body>
                {courseForm?.locations?.length > 0 ?
                    <>
                        {courseForm?.locations?.map(location =>
                            <ListGroup key={location.id} className='mb-1'>
                                <ListGroupItem>
                                    <Row>
                                        <Col md={12} lg={6}>
                                            <FormGroup>
                                                <FormLabel>Name</FormLabel>
                                                <FormControl {...register(location,'name')}/>
                                                {displayError(location,'name')}
                                            </FormGroup>
                                        </Col>
                                        <Col md={12} lg={6}>
                                            <FormGroup>
                                                <FormLabel>Address</FormLabel>
                                                <FormControl {...register(location,'address')}/>
                                                {displayError(location,'address')}
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup>
                                                <FormLabel>Max Seats</FormLabel>
                                                <FormControl {...register(location,'maxSeats')}/>
                                                {displayError(location,'maxSeats')}
                                            </FormGroup>
                                        </Col>
                                        <Col className="my-auto text-right">
                                            <Button variant='danger' onClick={removeLocation.bind(this,location.id)}>X</Button>
                                        </Col>
                                    </Row>
                                </ListGroupItem>
                            </ListGroup>
                        )}
                    </>
                    :
                    <Alert variant='danger'>Please create new one.</Alert>
                }
            </Card.Body>
        </Card>
    </>
}
export default CourseLocationSetup;
