import React, {useRef, useState} from 'react';
import { io } from 'socket.io-client';
import pkg from "../../package.json";

// Create a new context
const SocketContext = React.createContext();

export function SocketProvider({ children }) {

    const socket = useRef()
    const [isConnected,setIsConnected] = useState(false);
    //create jsdoc for our context
    /**
     * @param {string} roundID
     * @param {string} token
     * @return {SocketIOClient.Socket}
     */
    const adminConnect=(roundID,token)=>{
        return _connect({
            token,
            query:{
                roundID
            }
        });
    }

    /**
     * @param {string} regID
     * @param {string} token
     * @return {SocketIOClient.Socket}
     */
    const studentConnect=(regID,token)=>{
        return _connect({
            token,
            query:{
                regID
            }
        });
    }

    const disconnect=()=>{
        socket.current?.disconnect();
        socket.current?.removeAllListeners();
        socket.current=null;
        setIsConnected(false);
    }

    const _connect=(config)=>{
        if(socket.current)return;
        socket.current = io(pkg.domain_name, {
            path: `/api/dq-socket`,
            reconnectionDelayMax: 10000,
            auth: {token:config.token},
            query: config.query
        });
        socket.current.on('connect',()=>{
            setIsConnected(true);
        })
        socket.current.on('disconnect',()=>{
            setIsConnected(false);
        })
        return socket.current;
    }

    const emit=(event,data)=>{
        isConnected && socket.current?.emit(event,data);
    }
    const on=(event,callback)=>{
       socket.current?.removeListener(event);
       socket.current?.on(event,callback);
    }
    const remove=(event,callback)=>{
        socket.current?.removeListener(event,callback);
    }

  return (
    <SocketContext.Provider value={{
        adminConnect,
        studentConnect,
        emit,
        on,
        remove,
        disconnect,
        socket:socket.current,
        isConnected
    }}>
      {children}
    </SocketContext.Provider>
  );
}

// Create jsdoc for our context
/**
 * @typedef {Object} SocketContext
 * @property {function(string,string):SocketIOClient.Socket} adminConnect
 * @property {function(string,string):SocketIOClient.Socket} studentConnect
 * @property {function(string,any):void} emit
 * @property {function(string,function):void} on
 * @property {function(string,function):void} remove
 * @property {SocketIOClient.Socket} socket
 * @property {boolean} isConnected
 */
export function useSocket() {
  return /** @type {SocketContext} */ React.useContext(SocketContext);
}
