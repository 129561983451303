import {Col, Row} from "react-bootstrap";
import Block from "../../layouts/front-end-bt/components/block";

const Contact = ()=>{
    return <>
    <div className="p-4">
        <Row>
            <Col>
                <Block title="ติดต่อ/สอบถาม/แจ้งปัญหา - Contact us" className="shadow">
                    <Row>
                        <Col xs={12} lg={8}>
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3825.9908388822473!2d102.82284080176768!3d16.476001547298228!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31228a8a4b2f1911%3A0xc718e7a7249c74f8!2z4Liq4Liz4LiZ4Lix4LiB4Lir4Lit4Liq4Lih4Li44LiUIOC4oeC4q-C4suC4p-C4tOC4l-C4ouC4suC4peC4seC4ouC4guC4reC4meC5geC4geC5iOC4mQ!5e0!3m2!1sth!2sth!4v1482383152020" width="100%" height={450} frameBorder={0} style={{border: '0px', pointerEvents: 'none'}} allowFullScreen />
                        </Col>
                        <Col xs={12} lg={4}>
                            <div className="text-center text-lg-left">
                                <div>
                                    ศูนย์นวัตกรรมการเรียนการสอน
                                    อาคารศูนย์สารสนเทศ ชั้น 6
                                </div>
                                <div>โทร. 043-202899 ต่อ 42504</div>
                                <div>Email: <a href="mail:ilti.psli@kku.ac.th">ilti.psli@kku.ac.th</a></div>
                            </div>
                        </Col>
                    </Row>
                </Block>
            </Col>
        </Row>
    </div>
    </>
}
export default Contact;
