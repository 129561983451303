import FileSubmissionForm from "./file-submission-form";
import {useParams} from "react-router-dom";

const FileSubmission = ()=>{
    const {courseID} = useParams();
    return <>
        <div>COURSE ID {courseID}</div>
        <FileSubmissionForm courseID={courseID}/>
    </>
}
export default FileSubmission;
