import {Alert, Button, ButtonGroup, Card, Col, Row} from "react-bootstrap";
import {createMoodleLink, startMoodle} from "../../services/requester";
import {useEffect, useRef, useState} from "react";
import axios from "axios";
import Config from "../../config";
import {useQuery} from "@apollo/client";
import {GET_COURSES} from "../../gql-query/select-query";
import React from 'react';
import {Link} from "react-router-dom";

const ExamSchedule = () => {
    const [btnState, setBtnState] = useState({});
    const {loading, error, data, refetch} = useQuery(GET_COURSES,{fetchPolicy: "no-cache"});

    useEffect(() => {
        // axios.post('/api/moodle-api',{fn:'core_course_get_courses'}).then(res=>{
        //
        // })
        window.onfocus = () => {
            refetch({});
        };
        window.onblur = () => {
        };
    }, []);

    useEffect(() => {
        console.log(data);
    }, [data])

    function _setBtnState(btnName, state) {
        setBtnState(prev => {
            return {
                ...prev,
                [btnName]: state
            }
        })
    }

    // async function editCourse(id) {
    //     await startMoodle('/course/edit.php?id=' + id);
    // }
    //
    // async function viewCourse(id) {
    //     await startMoodle('/course/view.php?id=' + id);
    // }

    if (loading) return <Alert variant="info">Loading...</Alert>
    return <Card>
        <Card.Body>
            <Row>
                <Col>
                    {/*<Button disabled={btnState['create_course']} onClick={async e => {*/}
                    {/*    _setBtnState('create_course', 'loading');*/}
                    {/*    await startMoodle('/course/edit.php')*/}
                    {/*    _setBtnState('create_course', null);*/}
                    {/*}}>Create New Course</Button>*/}
                    <a className="btn btn-primary" target='_blank' href={createMoodleLink(`/course/edit.php`)}>Create New Course</a>
                </Col>
            </Row>
            <Card className="mt-2">
                <Card.Body>
                        {data && data.courses
                            .filter(c => c.id != 1)
                            .map(c =>
                            <React.Fragment key={c.id}>
                                <Card className="mb-1">
                                    <Card.Body>
                                        <Row>
                                            <Col>
                                                <h3>{c.idnumber == '' ? '' : `${c.idnumber} :: `}{c.fullname}</h3>
                                            </Col>
                                            <Col md="auto">
                                                <ButtonGroup>
                                                    <Link to={`/admin/exam-schedule/${c.id}/setting`} className="btn btn-primary">Setting</Link>
                                                    <Link to={`/admin/exam-schedule/${c.id}`} className="btn btn-dark">Exam Schedule</Link>
                                                    <a className="btn btn-info" target='_blank' href={createMoodleLink(`/course/view.php?id=${c.id}`)}>View</a>
                                                    <a className="btn btn-warning" target='_blank' href={createMoodleLink(`/course/edit.php?id=${c.id}`)}>Edit</a>
                                                </ButtonGroup>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                                <hr/>
                            </React.Fragment>
                        )}
                </Card.Body>
            </Card>
        </Card.Body>
    </Card>
}
export default ExamSchedule;
