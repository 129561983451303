import {Image, Nav, Navbar} from "react-bootstrap";
import dqLogo from "../img/dq_logo.png";
import FrontEndTopLinkBT from "./front-end-top-link-bt";
import {observer} from "mobx-react";
import {useMobxState} from "../../../mobx/global-context";
import {useEffect} from "react";
import {toJS} from "mobx";
import Config from "../../../config";

const NavbarBt = ({className})=>{
    const state = useMobxState();


    useEffect(() =>{

    },[]);


    function onLogout(e){
        e?.preventDefault();
        document.location.href = `/api/logout-kkunext`;
        //state.logout();
    }

    return <Navbar bg="light" expand="lg" style={{backgroundColor:'#fff'}} className={className}>
        <Navbar.Brand href="#home">
            <Image src={dqLogo} style={{width:75}}/>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav"/>
        <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto">
                <FrontEndTopLinkBT to="/" exact>HOME</FrontEndTopLinkBT>
                {state.currentUser &&
                    <FrontEndTopLinkBT to="/my">START EXAM / DASHBOARD</FrontEndTopLinkBT>
                }
                {/*<FrontEndTopLinkBT to="/manual">MANUAL</FrontEndTopLinkBT>*/}
                <FrontEndTopLinkBT to="/contact">CONTACT US</FrontEndTopLinkBT>
                {state.currentUser && state.currentUser.role=='admin' &&
                  <FrontEndTopLinkBT external to={`${Config.root}/admin`}>ADMINISTRATOR</FrontEndTopLinkBT>
                }
                {state.currentUser && state.currentUser.role=='examiner' &&
                <FrontEndTopLinkBT external to={`${Config.root}/admin/check-in`}>CHECK-IN STAFF</FrontEndTopLinkBT>
                }
                {state.currentUser && state.currentUser.role=='finance' &&
                <FrontEndTopLinkBT external to={`${Config.root}/admin/billing`}>FINANCE</FrontEndTopLinkBT>
                }
                {state.currentUser && state.currentUser.role=='signer' &&
                <FrontEndTopLinkBT external to={`${Config.root}/admin/certificate`}>CERTIFICATE SIGN</FrontEndTopLinkBT>
                }
                {state.currentUser?
                    <FrontEndTopLinkBT to="#logout" onClick={onLogout}>LOGOUT ({state.currentUser?.username})</FrontEndTopLinkBT>
                    :
                    <>
                        {/*<FrontEndTopLinkBT to={Config.base_url+'/login-local'} external>LOCAL LOGIN</FrontEndTopLinkBT>*/}
                        {/*<FrontEndTopLinkBT to="/api/login" external>LOGIN</FrontEndTopLinkBT>*/}
                        <FrontEndTopLinkBT to="/api/login-kkunext-redirect" external>LOGIN</FrontEndTopLinkBT>
                    </>
                }
            </Nav>
        </Navbar.Collapse>
    </Navbar>
}
export default observer(NavbarBt);
