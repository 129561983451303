import {useTimer} from "react-timer-hook";
import moment from "moment";
import {useEffect, useState} from "react";

// const time = new Date();
// time.setSeconds(time.getSeconds() + 300);
const Countdown = ({expireTimestamp,multiLine,title,showDay,onExpired,style})=>{
    const [expired,setExpire] = useState(false);

    const {
        seconds,
        minutes,
        hours,
        days,
        isRunning,
        start,
        pause,
        resume,
        restart,
    } = useTimer({
        expiryTimestamp: new Date(parseInt(expireTimestamp)),
        format: '24-hour',
        autoStart: true,
        onExpire: () => {
            if(typeof onExpired == 'function' && !expired){
                onExpired();
                setExpire(true);
            }
        }
    });

    useEffect(()=>{
        let now = (new Date()).getTime();
        let expire = parseInt(expireTimestamp);
        if(now>=expire && typeof onExpired == 'function' && !expired) {
            onExpired();
            setExpire(true);
        }
    },[]);

    function pad(d) {
        return (d < 10) ? '0' + d.toString() : d.toString();
    }

    function getDays(){
        if(!showDay)return null;
        if(days==0)return <><span>Today</span> / </>
        return <><span>{days}</span> days / </>
    }

    return <>
        <div style={{textAlign: 'center'}}>
            <div style={style}>
                {title?title:null}{multiLine && <br/>} {getDays()} <span>{pad(hours)}</span>:<span>{pad(minutes)}</span>:<span>{pad(seconds)}</span>
            </div>
        </div>
    </>
}
Countdown.defaultProps = {
    showDay:true,
    multiLine:false,
    style:{
        fontSize: 20,
    }
}
export default Countdown;
