import {observer} from "mobx-react";
import Block from "../../layouts/front-end-bt/components/block";

const Manual = () => {


    return <div className="p-4">
    <Block title="Manual / Documentation">
        <h3>Manual video</h3>
        <ul className="list-group">
            <li className="list-group-item"><a href="https://youtu.be/sSFCgdmyYR4" target="_blank" className="btn btn-success">- Ep1 ความสำคัญของการสอบวัดความรู้ความสามารถพื้นฐานทางคอมพิวเตอร์พื้นฐาน KKU EMS</a></li>
            <li className="list-group-item"><a href="https://youtu.be/twfdzZPk0f8" target="_blank" className="btn btn-success">- Ep2 การเตรียมตัวก่อนสอบออนไลน์ KKU EMS</a></li>
            <li className="list-group-item"><a href="https://youtu.be/T3ZYPFSeyPs" target="_blank" className="btn btn-success">- Ep3 การเข้าสอบออนไลน์ KKU EMS</a></li>
            <li className="list-group-item"><a href="https://youtu.be/DBI_VThRbTE" target="_blank" className="btn btn-success">- Ep4 ข้อปฎิบัตระหว่างการสอบ KKU EMS</a></li>
            <li className="list-group-item"><a href="https://youtu.be/22H9WzeuhtU" target="_blank" className="btn btn-success">- Ep5 การส่งข้อสอบ KKU EMS</a></li>
        </ul>
        <hr />
        <h3>Document and software</h3>
        <ul className="list-group">
            <li className="list-group-item"><a href="https://ems.kku.ac.th/EMS_PAYMENT.pdf" target="_blank" className="btn btn-info">- คู่มือการสมัคร และ ชำระบบค่าธรรมเนียมการสอบ (Register Manual)</a></li>
            <li className="list-group-item"><a href="https://ems.kku.ac.th/static_files/Booklet_EMS_160421.pdf" target="_blank" className="btn btn-info">- คู่มือการสอบ (Exam Manual)</a></li>
            <li className="list-group-item"><a href="https://safeexambrowser.org/download_en.html" target="_blank" className="btn btn-warning">- Download Safe Exam Browser</a></li>
            <li className="list-group-item"><a href="https://o365.kku.ac.th" target="_blank" className="btn btn-warning">- Download Microsoft Office 365 (Click - Install Office)</a></li>
            <li className="list-group-item"><a href="https://software.kku.ac.th/data/software.php" target="_blank" className="btn btn-warning">- Download Microsoft Office 2010</a></li>
        </ul>
    </Block>
    </div>
}
export default observer(Manual);
