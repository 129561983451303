import {Link} from "react-router-dom";
import React from 'react';
import {Box, Breadcrumbs, makeStyles, Typography} from "@material-ui/core";

const useStyles = makeStyles(theme=>({
    root:{
        '& a':{
            textDecoration: 'none',
            transition:'all 300ms',
        }
    }
}))

const StudentBreadcrumb = ({data})=>{
    const classes = useStyles();
    return <Box p={2} className={classes.root}>
        <Breadcrumbs className="mb-2">
            {
                data.map((v,i)=>
                    v.url?
                        <Link key={'link_'+i} color="inherit" to={v.url} onClick={v.onClick}>{v.title}</Link>
                        :
                        <Typography key={'text_'+i} color="textPrimary">{v.title}</Typography>

                )
            }
        </Breadcrumbs>
        <hr/>
    </Box>
}
export default StudentBreadcrumb
