import {Button, FormControl, FormGroup, FormLabel, InputGroup} from "react-bootstrap";
import {css, StyleSheet} from "aphrodite";
import {SketchPicker} from "react-color";
import {useEffect, useRef, useState} from "react";
import $ from 'jquery';

const ColorPicker = ({title,onChange,value})=>{
    const [show,setShow] = useState(false);
    const bodyBind = useRef();
    useEffect(()=>{
        bodyBind.current=$('body').on('click',(e)=>{
            let targetLength = $(e.target).parents('.form-color-picker').length;
            if(targetLength==0){
                setShow(false);
            }
        })
        return ()=>{
            $('body').unbind('click',bodyBind.current);
        }
    },[]);
    return <FormGroup className='form-color-picker'>
        <FormLabel>{title}</FormLabel>
        <InputGroup>
            <FormControl style={{fontWeight:'bold',color:value||''}} name='color' value={value||'#fff'} onChange={e=>{}} onClick={e => {
                setShow(true);
            }}/>
            {show &&
            <InputGroup.Append>
                <Button className={css(styles.buttonGroup)} onClick={e => {
                    setShow(false);
                }}>Set</Button>
            </InputGroup.Append>
            }
        </InputGroup>

        {show &&
        <div style={{position: 'relative'}}>
            <div style={{
                position: 'absolute',
                right: 0,
                zIndex: 999,
            }}>
                <SketchPicker
                    color={value||'#fff'}
                    onChange={e => {
                        onChange(e.hex);
                    }}/>
            </div>
        </div>
        }
    </FormGroup>
}
const styles = StyleSheet.create({
    buttonGroup: {
        borderRadius: '0px 20px 20px 0px'
    }
})
export default ColorPicker;
