import {useEffect, useState} from "react";
import Config from "../config";
import axios from "axios";

const useMoodleLogin = ()=>{
    const [logined,setLogined] = useState(false);

    useEffect(()=>{
        sync();
    },[]);

    async function sync(){
        let mdUser = await getMoodleSession();
        if(!mdUser?.data?.id){
            try{
                await mdLogin();
                mdUser = await getMoodleSession();
            }catch(e){
                console.log(e);
                return;
            }
        }
        let dqUser = await axios.get(Config.api_url+'/check-login');
        let mdUsername = mdUser?.data?.username;
        let dqUsername = dqUser.data?.user?.username;
        let regExp = new RegExp(`${dqUsername}`,'ig');
        if(mdUsername?.match(regExp)){
            setLogined(true);
        }else{
            await axios.get(Config.moodleURL+'/auth/xtoken/api.php?action=logout');
            document.location.reload();
        }
    }

    async function getMoodleSession(){
        return await axios.get(Config.moodleURL+'/auth/xtoken/api.php?action=current-user');
    }

    async function mdLogin(){
        let authURI = await axios.get(Config.api_url+`/get-moodle-auth-uri`);
        await axios.get(authURI.data);
    }

    return [logined,sync];
}
export default useMoodleLogin;
