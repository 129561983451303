import {Box, Button, Paper, Typography} from "@material-ui/core";
import React, {useState} from "react";
import {Alert, FormCheck, FormGroup, Tab, Tabs} from "react-bootstrap";
import gql from "graphql-tag";
import {useMutation, useQuery} from "@apollo/client";
import {toast} from "react-toastify";
import axios from 'axios';
import Config from "../../config";
import {preventContextMenu} from "@fullcalendar/core";

const GET_DQ_WORKSHOP_QUIZ_AND_RESULT = gql`
query dqWorkshopQuizResult($regID: ID) {
  dqWorkshopQuizResult(regID: $regID) {
    id
    DQWorkshopScore {
      moduleType
      fileName
      score
    }
    DQWorkshopPassedState{
      moduleType
      passed  
    }
  }
  dqWorkshopQuiz {
    id
    name
    detail
    timeLimit
    fileSizeLimit
    passScore
    questions {
      id
      name
      moduleType
      questionText
      isPassed
    }
  }
}
`;
const UPDATE_DOWNLOADED_STATE = gql`
mutation UpdateDownloadState($regID:ID,$moduleType:String){
    updateDownloadState(regID:$regID,moduleType:$moduleType)
}
`;
const DqQuizExam = ({regID}) => {
    const resDqWkQuiz = useQuery(GET_DQ_WORKSHOP_QUIZ_AND_RESULT, {
        variables: {
            regID
        }
    });
    const [changeDLState] = useMutation(UPDATE_DOWNLOADED_STATE, {
        refetchQueries: [GET_DQ_WORKSHOP_QUIZ_AND_RESULT]
    });

    const [notSend, setNotSend] = useState();
    const [uploadedFiles, setUploadedFiles] = useState({});

    function isDownloaded(moduleType) {
        let {dqWorkshopQuizResult, dqWorkshopQuiz} = resDqWkQuiz.data;
        let existed = dqWorkshopQuizResult.DQWorkshopScore.find(v => v.moduleType == moduleType)
        return !!existed;
    }

    async function download(name, moduleType) {
        try {
            await changeDLState({
                variables: {
                    regID,
                    moduleType
                }
            })
            toast.success(`${name} is starting to download.`);
        } catch (e) {
            toast.error(e.message);
        }
    }

    async function uploadWorkshopFile(formData) {
        try {
            let res = await axios.post(`${Config.api_url}/update-dq-workshop`, formData);
            return res.data;
        } catch (e) {
            return e.response.data;
        }
    }

    async function uploadFile(e, dqWkQuiz) {
        let file = e.target.files[0];
        if (!file) return;
        if (file.size > 20 * 1048576) {
            toast.error(`Max upload size is 20 MB`);
            return;
        }
        const formData = new FormData();
        formData.append('wkFile', file, file.name);
        formData.append('regID', regID);
        formData.append('moduleType', dqWkQuiz.moduleType);
        let uploaded = await uploadWorkshopFile(formData);
        if (!uploaded.error) {
            await resDqWkQuiz.refetch();
            setUploadedFiles({...uploadedFiles, [dqWkQuiz.moduleType]: true})
            toast.success('Update successfully.');
        } else {
            toast.error(uploaded.error);
        }
        e.target.value = '';
    }

    function displayFileName(question) {
        let result = resDqWkQuiz.data.dqWorkshopQuizResult.DQWorkshopScore.find(v => v.moduleType == question.moduleType);
        if (result?.fileName) {
            return <Alert className="mt-4" variant="success"><b>Uploaded:</b> {result.fileName}</Alert>
        }
        return <Box mt={2}>
            <FormGroup>
                <FormCheck id={'check_' + question.moduleType} checked={notSend?.[question.moduleType]} type="checkbox"
                           label="I don't want to send this file." onChange={e => {
                    let c = e.target.checked;
                    setNotSend({...notSend, [question.moduleType]: c})
                }}/>
            </FormGroup>
        </Box>
    }

    function displaySubmitButton() {
        let disabled = false;
        return <Button disabled variant="contained" color="primary">Submit and end examination</Button>
    }

    function isDisabledTab(q) {
        let existed = resDqWkQuiz?.data?.dqWorkshopQuizResult.DQWorkshopPassedState?.find(m => m.moduleType == q.moduleType);
        console.log('dqWorkshopPassedState==', existed);
        return !!existed;
    }

    if (resDqWkQuiz.loading) return <div>Loading...</div>
    return <div>
        <Paper>
            <Box p={2}>
                <Typography variant="h5">DQ Workshop Examination</Typography>
                <hr/>
                <Box>
                    <Tabs defaultActiveKey="profile" id="uncontrolled-tab-example" className="mb-3">
                        {resDqWkQuiz?.data?.dqWorkshopQuiz?.questions?.map(q => {
                            let passed = isDisabledTab(q);
                                return <Tab disabled={passed} eventKey={q.moduleType} title={passed?`${q.name} (Passed)`:q.name}>
                                    <Box p={2}>
                                        <Box>
                                            <Button variant='contained' color='primary'
                                                    onClick={e => download(q.name, q.moduleType)}>Download
                                                Answer sheet</Button>
                                        </Box>
                                        {isDownloaded(q.moduleType) &&
                                        <>
                                            <hr/>
                                            <Box p={2}>{q.questionText}</Box>
                                            <hr/>
                                            <Box pt={2}>
                                                {!notSend?.[q.moduleType] &&
                                                <>
                                                    <strong className="mr-4">Upload document for score:</strong>
                                                    <input type='file' style={{
                                                        width: 70
                                                    }} onChange={e => uploadFile(e, q)}/>
                                                </>
                                                }
                                                {displayFileName(q)}
                                            </Box>
                                        </>
                                        }
                                    </Box>
                                </Tab>
                            }
                        )}
                    </Tabs>
                </Box>
            </Box>
            <hr/>
            <Box p={2} display='flex' justifyContent='center'>
                {displaySubmitButton()}
            </Box>
        </Paper>
    </div>
}
export default DqQuizExam;
