import {getAllMonth} from "../services/helper";
import {getThaiDateBetween} from "../services/client-helper";
import {Link, useHistory} from "react-router-dom";
import {Button} from "react-bootstrap";
import axios from "axios";
import {toast} from "react-toastify";

const ScheduleDetailBlock = ({schedule}) => {
    const history = useHistory();
    const {course,scheduleType,registeredCount,month,year,examStart,examEnd,registerStart,registerEnd,id} = schedule;
    return <div className="p-2">
        <h3>Course: {course?.fullname}</h3>
        <hr/>
        <div className="p-2" style={{backgroundColor: '#efefef'}}>
            <div className="p-2" style={{backgroundColor: '#efefef'}}>
                <div><strong>Month:</strong> {getAllMonth()?.[month - 1]} / {year}</div>
                <div><strong>Exam type:</strong> {scheduleType.name}</div>
                <div><strong>Register between:</strong> {getThaiDateBetween(registerStart, registerEnd, 'lll')}</div>
                <div><strong>Exam between:</strong> {getThaiDateBetween(examStart, examEnd, 'lll')}</div>
                <div><strong>Total Registered:</strong>
                    <Link
                        to={`/admin/exam-schedule/${course.mdCourseID}/participants/${id}/all`}
                        className="btn btn-info ml-1" variant='info'>{registeredCount} Participants</Link>
                </div>
                <div className='mt-1'>
                    <strong>Re-sync Bill:</strong>
                    <Button className='ml-1' variant='danger' onClick={()=>{
                       let ok = window.confirm('Are you sure to re-sync bill?');
                       if(ok) {
                           axios.post('/api/recheck-receipt', {scheduleID: id}).then(res => {
                               toast.info('Sync started, please wait a moment.');
                           }).catch(e => {
                               console.log(e.message);
                               toast.error('Error occurred, please try again later.');
                           });
                       }
                    }}>Sync Now</Button>
                </div>
            </div>
        </div>
    </div>
}
export default ScheduleDetailBlock;
