import {Badge, Button, Card, FormControl, FormGroup, FormLabel, Row, Table} from "react-bootstrap";
import {useEffect, useRef, useState} from "react";
import CSVReader from "react-csv-reader";
import {useApolloClient, useMutation, useQuery} from "@apollo/client";
import {
    GET_ALL_COURSES_WITH_SCHEDULE,
} from "../../gql-query/select-query";
import {getThaiDateBetween, pad2} from "../../services/client-helper";
import gql from "graphql-tag";
import {toast} from "react-toastify";
import _ from 'lodash';

const GET_IMPORT_INFO = gql`
query StudentImportInfo($field: String!, $dataList: [String]!) {
  studentImportInfo(field: $field, dataList: $dataList) {
    id
    username
    studentID
    prefix
    fname
    lname
    email
    faculty
    department
    ssoRole {
      id
      name
    }
    registrations {
      id
      course {
        id
      }
      schedule {
        id
      }
      scheduleRound {
        id        
        quizSet{
          id
        }
      }
    }
  }
}
`;

const REGISTRATION_DATA = gql`
mutation ImportRegistration($courseID: ID!, $scheduleID: ID!, $enrolData: [ImportRegistrationInputType]!) {
  importRegistration(courseID: $courseID, scheduleID: $scheduleID, enrolData: $enrolData){
    success {
      id
      username
      studentID
    }
    failed {
      id
      username
      studentID
    }
  }
}
`;

const Importer = () => {

    const courses = useQuery(GET_ALL_COURSES_WITH_SCHEDULE, {
        fetchPolicy: 'network-only'
    });
    const [fileData, setFileData] = useState();
    const [selectedCourse, setSelectedCourse] = useState();
    const [selectedSchedule, setSelectedSchedule] = useState();
    const [selectedField, setSelectedField] = useState('');
    const apolloClient = useApolloClient();
    const [formData, setFormData] = useState([]);

    const [mutate, {data}] = useMutation(REGISTRATION_DATA);
    const [textValueMatch, setTextValueMatch] = useState([]);
    const timer = useRef(0);

    const handleForce = async (data, fileInfo) => {
        if (fileInfo.type == 'application/vnd.ms-excel' || fileInfo.type == 'text/csv') {
            try {
                let dataResults = await apolloClient.query({
                    fetchPolicy: 'network-only',
                    query: GET_IMPORT_INFO,
                    variables: {
                        field: selectedField,
                        dataList: [...new Set(data.map(v => v[0]))]
                    }
                })
                console.log(dataResults.data.studentImportInfo);
                setFileData(dataResults.data.studentImportInfo);
            } catch (e) {
                toast.error('Cannot read document.')
            }
        }
    }

    useEffect(()=>{
        return ()=>{
            clearTimeout(timer.current);
        }
    },[])

    const handleImportList = async (e) => {
        //match like 643040239-1
        let regExp = new RegExp(/(\d{9})-(\d{1})/g);
        let match = e.target.value.match(regExp)||[];
        console.log(match);
        setTextValueMatch([...match]);
        clearTimeout(timer.current);
        timer.current=setTimeout(async ()=>{
            try {
                let dataResults = await apolloClient.query({
                    fetchPolicy: 'network-only',
                    query: GET_IMPORT_INFO,
                    variables: {
                        field: selectedField,
                        dataList: match
                    }
                })
                console.log(dataResults.data.studentImportInfo);
                setFileData(dataResults.data.studentImportInfo);
            } catch (e) {
                toast.error('Cannot read document.')
            }
        },1000);
    }

    const csvParseOptions = {
        header: false,
        dynamicTyping: true,
        skipEmptyLines: true,
        transformHeader: header => header.toLowerCase().replace(/\W/g, "_")
    };

    function onCancel() {
        document.getElementById('csv_input').value = '';
        setFileData(void 0);
    }

    function onSelectedCourse(e) {
        let id = e.target.value;
        let c = courses.data.allCourseDB.find(v => v.id == id);
        setSelectedCourse(c);
        setSelectedSchedule(void 0);
    }

    function onSelectedSchedule(e) {
        let id = e.target.value;
        let schd = selectedCourse?.schedules.find(v => v.id == id);
        setSelectedSchedule(schd);
    }

    function onSelectedField(e) {
        let v = e.target.value;
        setSelectedField(v);
    }

    function renderSelectModuleRound(title, quizSet, selectedSchedule, value, onChange, props) {
        return <>
            {selectedSchedule ?
                <FormGroup>
                    <FormControl {...props} as="select" value={value} onChange={e => {
                        let roundID = e.target.value;
                        onChange(quizSet, roundID);
                    }}>
                        <option value=''>{title}</option>
                        {title == '- Action -' &&
                        <option value='remove'>Remove all</option>
                        }
                        {selectedSchedule?.rounds?.filter(v => v.quizSet.id == quizSet?.id)?.map(round =>
                            <option key={round.id}
                                    value={round.id}>{getThaiDateBetween(round.examStart, round.examEnd, 'date-time')}</option>
                        )}
                    </FormControl>
                </FormGroup>
                :
                <div>-</div>
            }
        </>
    }

    useEffect(() => {
        console.log(formData);
    }, [formData]);

    function setRoundSelect(std, module, roundID) {
        let existedUser = formData.find(v => v.userID == std?.id);


        if (roundID == 'remove' || roundID == '') {
            if (existedUser) {
                existedUser.modules = _.reject(existedUser?.modules, r => r.moduleID == module.id)
            }
            return;
        }


        if (existedUser) {
            let existedModule = existedUser.modules.find(vv => vv.moduleID == module.id);
            if (existedModule) {
                if (roundID) {
                    existedModule.roundID = roundID;
                } else {
                    existedUser.modules = _.reject(existedUser.modules, r => r.roundID == existedModule.roundID)
                }
            } else {
                existedUser.modules.push({
                    moduleID: module.id,
                    roundID: roundID,
                });
            }
        } else {
            formData.push({
                userID: std?.id,
                modules: [
                    {
                        moduleID: module.id,
                        roundID: roundID,
                    }
                ],
            })
        }
    }

    if (courses.loading) return <div>Loading...</div>
    return <Card>
        <Card.Body>
            <h1 className="mb-3">Import Participants</h1>
            <FormGroup>
                <FormLabel>Select .csv upload field</FormLabel>
                <FormControl disabled={!!fileData} as="select" value={selectedField} onChange={onSelectedField}>
                    <option value=''>- Select field -</option>
                    <option value='username'>Username</option>
                    <option value='studentID'>StudentID</option>
                    <option value='email'>Email</option>
                </FormControl>
            </FormGroup>
            {selectedField &&
            <>
                <FormGroup>
                    <CSVReader
                        cssClass="form-control"
                        accept=".csv"
                        inputId='csv_input'
                        onFileLoaded={handleForce}
                        parserOptions={csvParseOptions}
                    />
                </FormGroup>
                <hr/>
                <FormGroup>
                    <FormLabel>Fill list</FormLabel>
                    <FormControl as='textarea' rows={5} onChange={handleImportList}/>
                    <div className="mt-1">
                        {textValueMatch.map((v, i) => <Badge style={{margin:2,fontWeight:'normal'}} variant="primary" key={i}>{v}</Badge>)}
                    </div>
                </FormGroup>
            </>
            }
            {fileData &&
            <>
                <FormGroup>
                    <div className="text-center">
                        <Button variant="danger" onClick={onCancel}>Cancel</Button>
                    </div>
                </FormGroup>
                <hr/>
                <FormGroup>
                    <FormControl as='select' value={selectedCourse?.id || ''} onChange={onSelectedCourse}>
                        <option value=''>- Select Course -</option>
                        {courses.data.allCourseDB.map(v =>
                            <option key={v.id} value={v.id}>{v.fullname}</option>
                        )}
                    </FormControl>
                </FormGroup>
                {selectedCourse &&
                <FormGroup>
                    <FormControl as='select' value={selectedSchedule?.id || ''} onChange={onSelectedSchedule}>
                        <option value=''>- Select Schedule -</option>
                        {selectedCourse?.schedules?.map(schd =>
                            <option key={schd.id} value={schd.id}>{schd.title} -
                                ( {pad2(schd.month)} / {schd.year} )</option>
                        )}
                    </FormControl>
                </FormGroup>
                }
                <Table responsive>
                    <thead>
                    <tr>
                        <td><span className={"text-capitalize"}>{selectedField}</span></td>
                        <td>Name</td>
                        {selectedCourse?.quizSet?.map(v =>
                            <td key={v.id}>{v.name}</td>
                        )}
                    </tr>
                    {selectedSchedule &&
                    <tr>

                        <td colSpan='2'>
                            <div className="text-center">All Action</div>
                        </td>
                        {selectedCourse?.quizSet?.map(qSet =>
                            <td key={qSet.id}>{renderSelectModuleRound('- Action -', qSet, selectedSchedule, '', (module, roundID) => {
                                for (let std of fileData) {
                                    setRoundSelect(std, module, roundID)
                                }
                                setFormData([...formData]);
                            })}</td>
                        )}
                    </tr>
                    }
                    </thead>
                    <tbody>
                    {fileData?.map((std, i) => {
                        return <tr key={`${std.id}_${i}`}>
                            <td>{std?.[selectedField]}</td>
                            <td>{std.prefix}{std.fname} {std.lname}</td>
                            {selectedCourse?.quizSet?.map(qSet => {
                                let value;
                                let locked=false;
                                let registeredSchedule = std.registrations.find(v=>
                                    v.schedule.id==selectedSchedule?.id && v.scheduleRound.quizSet.id==qSet.id
                                );
                                let registeredRound = registeredSchedule?.scheduleRound?.id;
                                if(registeredRound){
                                    value=registeredRound;
                                    locked=true;
                                }else{
                                    value= formData.find(v => v.userID == std.id)?.modules?.find(v => v?.moduleID == qSet?.id)?.roundID||'';
                                }
                                return <td key={qSet.id}>
                                    {renderSelectModuleRound('- Select Round -',
                                        qSet,
                                        selectedSchedule,
                                        value,
                                        (module, roundID) => {
                                            setRoundSelect(std, module, roundID);
                                            setFormData([...formData]);
                                        },
                                        {disabled:locked}
                                    )}
                                </td>
                            })}
                        </tr>
                    })}
                    </tbody>
                </Table>
                <FormGroup className="mt-4">
                    <div className="text-center">
                        <Button disabled={!selectedSchedule} onClick={async () => {
                            try{
                                let {data} = await mutate({
                                    variables: {
                                        courseID: selectedCourse?.id,
                                        scheduleID: selectedSchedule?.id,
                                        enrolData: formData,
                                    }
                                })
                                let {success,failed}=data?.importRegistration;
                                toast.success(`Import success ${success.length} users.`)
                                toast.error(`Import failed ${failed.length} users.`)
                            }catch(e){
                                toast.error(e.message);
                            }
                        }}>Enrol</Button>
                    </div>
                </FormGroup>
            </>
            }
        </Card.Body>
    </Card>
}
export default Importer;
